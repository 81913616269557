import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { CurrentUser, SubscriptionItem } from '../types/table';
import http from "../utils/http";
import Button from "./shared/Button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./table";

type DataTableProps = {
  data: SubscriptionItem[];
  currentUser: CurrentUser;
  cancel_url: string;
};

export default function SubscriptionTable({
  data,
  currentUser,
  cancel_url,
}: DataTableProps) {
  const navigate = useNavigate();

  const handleSubscription = async (selectedPlan: string) => {
    const res = await http.post("/stripe/create-provider-subscription", {
      customerId: currentUser?.stripeCustomerId,
      plan: selectedPlan,
      cancel_url,
    });

    if (res.status === 200) {
      navigate(res.data.url);
    } else {
      toast.error("Error subscribing, please try again");
    }
  };

  return (
    <div className="max-w-7xl mx-auto my-8">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[200px]">Services</TableHead>
            <TableHead className="w-[150px] text-center">Basic<br />(Free Forever)</TableHead>
            <TableHead className="w-[150px] text-center">Standard<br />($99/Month)</TableHead>
            <TableHead className="w-[150px] text-center">Pro<br />($149/Month)</TableHead>
            <TableHead className="w-[150px] text-center">Elite<br />($199/Month)</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.item}</TableCell>
              <TableCell className="text-center">{item.free}</TableCell>
              <TableCell className="text-center">{item.m99}</TableCell>
              <TableCell className="text-center">{item.m149}</TableCell>
              <TableCell className="text-center">{item.m199}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="mt-8 flex justify-evenly items-center relative border-[#E7FBEC] bg-[#e7fbec]">
        <div className="absolute right-0 flex justify-end space-x-32 mr-10">
          <Button
            variant="secondary"
            label="Buy Standard"
            size="medium"
            onClick={() => handleSubscription('price_1OErqtGoxPbqLMwDgC93rbJh')}
            additionalClassname="!w-[120px]"
          />
          <Button
            variant="secondary"
            label="Buy Pro"
            size="medium"
            onClick={() => handleSubscription('price_1OErrJGoxPbqLMwDzwzoVlMB')}
            additionalClassname="!w-[120px]"
          />
          <Button
            variant="secondary"
            label=" Buy Elite"
            size="medium"
            onClick={() => handleSubscription('price_1OErrZGoxPbqLMwDqz9SZuqh')}
            additionalClassname="!w-[120px]"
          />
        </div>
      </div>
    </div>
  );
}
