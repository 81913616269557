import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import billerHttp from "../../../utils/billerHttp";

type Payload = {
  id: string;
}


export const useDeleteProviderFromBillerList = () => {
  const queryClient = useQueryClient();

  const { mutate, data, variables, reset, ...rest } = useMutation({
    mutationKey: ['delete providers biller list'],
    mutationFn: async ({ id }: Payload) => {
      const response = await billerHttp.delete(`billers/provider/${id}`)
      return response.data;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get all biller providers']
      });
      toast.success("Provider removed");
    },

    onError: (error) => {
      if (axios.isAxiosError(error)) {
        toast.error(
          error?.response?.data?.error || error.response?.statusText,
        );
      } else {
        toast.error(
          error?.message
          || "Error deleting provider from biller list",
        );
      }
    },
  });

  return {
    removeProviderFromBillersList: mutate,
    variables,
    ...rest,
  };
}
