import { useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { resendEmail } from "../../api/auth";
import { ReactComponent as EmailBoxIcon } from "../../svgs/email-box.svg";
import Spacer from "../Spacer";
import Button from "../shared/Button";

const VerifyEmail = () => {
  const [isSendingVerifyEmail, setIsSendingVerifyEmail] = useState(false);
  const { userId, userType, email } = useParams();
  const searchParams = useSearchParams()[0];
  const referrer = searchParams.get("referrer");

  const handleResendVerificationEmail = async () => {
    try {
      setIsSendingVerifyEmail(true);
      const res = await resendEmail(
        userId ?? "",
        userType ?? "patients",
        referrer ?? ""
      );
      setIsSendingVerifyEmail(false);
      toast(res.message ?? "Verification email sent");
    } catch (e: any) {
      setIsSendingVerifyEmail(false);
      toast(e?.message ?? "Failed to send verification email");
      // console.log(e);
    }
  };

  return (
    <>
      <div className="w-full box-border lg:w-[614px] bg-white flex flex-col justify-center h-full">
        <div className="text-center">
          <div className="w-full mx-auto flex justify-center">
            <EmailBoxIcon />
          </div>
          <Spacer height={30} />
          {referrer === "appointment" ? (
            <p className="text-[24px] font-[800] text-[#103C1B]">
              Verify your email to complete your booking
            </p>
          ) : (
            <p className="text-[34px] font-[800] text-[#103C1B]">
              Verify your email
            </p>
          )}
          <Spacer height={10} />
          <p className="font-[400] text-[20px] text-[#103C1B] w-full lg:w-[555px] mx-auto">
            We sent a link to your email {email}. Please click on the link to
            verify that the email belongs to you and you have access to it.
          </p>
        </div>
        <div className="lg:h-[40px] h-[20px]" />
        <div className="flex justify-center items-center w-full mx-auto">
          <Link to="#">
            <Button
              additionalClassname={"text-[18px] font-[400]"}
              width={178}
              height={60}
              onClick={handleResendVerificationEmail}
              type="submit"
              variant="primary"
              label="Resend email"
              loading={isSendingVerifyEmail}
            />
          </Link>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
