import * as Yup from "yup";

export type BillerFormValues = {
  patientName: string;
  dateOfBirth: string;
  gender:
    | "Ambiguous"
    | "Female"
    | "Male"
    | "Not applicable"
    | "Other"
    | "Unknown"
    | undefined;
  copay: string;
  providerName: string;
  appointmentDate: string;
  appointmentLocation: string;
  modeOfEncounter: "virtual" | "physical";
  procedureCodes: string | string[];
  diagnosisCodes: string | string[];
  patientInsurance: string;
};

export const claims_initialValues: BillerFormValues = {
  patientName: "",
  dateOfBirth: "",
  gender: undefined,
  patientInsurance: "",
  copay: "",
  providerName: "",
  appointmentDate: "",
  appointmentLocation: "",
  modeOfEncounter: "virtual",
  procedureCodes: [],
  diagnosisCodes: []
};

export const claims_validationSchema = Yup.object({
  patientName: Yup.string().required("Required"),
  dateOfBirth: Yup.string().required("Required"),
  gender: Yup.string().default(undefined),
  patientInsurance: Yup.string().required("Required"),
  primaryInsurance: Yup.string().required("Required"),
  secondaryInsurance: Yup.string(),
  copay: Yup.string().required("Required"),
  providerName: Yup.string().required("Required"),
  appointmentDate: Yup.string().required("Required"),
  appointmentLocation: Yup.string().required("Required"),
  modeOfEncounter: Yup.string().required("Required"),
  diagnosisCode: Yup.string().required("Required")
});
