
interface ChipProps {
  width?: number;
  height?: number;
  color?: string;
  label?: any;
  labelBold?: string;
  value?: any;
  onClick?: (arg: any) => any;
}

const Chip = ({
  width = 120,
  height = 45,
  label,
  onClick,
  value,
  labelBold
}: ChipProps) => (
  <div
    className="flex justify-evenly items-center bg-[#fff] border border-[#103C1B] text-[#103C1B] rounded-[50px] p-2 text-[10px] font-[400]"
    style={{
      width: `${width}px`,
      height: `${height}px`
    }}
    onClick={() => {
      if (onClick) {
        onClick(value);
      }
    }}
  >
    <div className="text-[12px] w-[130px] flex flex-row item-center justify-center">
      <span className="font-bold mr-2">{labelBold}</span>
      <span>{label}</span>
    </div>
  </div>
);

export default Chip;
