import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Spacer from "../../../components/Spacer";
import { ReactComponent as LogoutIcon } from "../../../svgs/temple-logout.svg";
import { styled } from "@mui/material";
import ProviderMenuItem from "./ProviderMenuItem";
import { PatientSidebarIcon } from "../../../svgs/PatientSidebarIcon";
import { ProviderAppointmentSidebarIcon } from "../../../svgs/ProviderAppointmentSidebarIcon";
import { ProviderOverviewSidebarIcon } from "../../../svgs/ProviderOverviewSidebarIcon";
import { ProviderMessageSidebarIcon } from "../../../svgs/ProviderMessageSidebarIcon";
import { ProviderSettingsSidebarIcon } from "../../../svgs/ProviderSettingsSidebarIcon";
import { BusinessOfficeIcon } from "../../../svgs/BusinessOfficeIcon";
import { ProviderTemplateSidebarIcon } from "../../../svgs/ProviderTemplateSidebarIcon";
import { PaymentBillingIcon } from "../../../svgs/PaymentBillingIcon";
import { InfoIcon2 } from "../../../svgs/InfoIcon2";
import ReportProblemModal from "../../../components/ReportProblemModal";
import { TempleHSLogo } from "../../../svgs/TempleHSLogo";

const ProviderSidebar: React.FC = () => {
  const [isReportModalVisible, setIsReportModalVisible] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleMenuClick = (path: string) => {
    navigate(`${path}`);
  };

  const menuItems = [
    {
      path: "/provider/dashboard",
      label: "Overview",
      icon: <ProviderOverviewSidebarIcon color="#000" />,
      activeIcon: <ProviderOverviewSidebarIcon color="#3D874E" />
    },
    {
      path: "/provider/patients",
      label: "Patients",
      icon: <PatientSidebarIcon color="#000" />,
      activeIcon: <PatientSidebarIcon color="#3D874E" />
    },
    {
      path: "/provider/appointments",
      label: "Appointments",
      icon: <ProviderAppointmentSidebarIcon color="#000" />,
      activeIcon: <ProviderAppointmentSidebarIcon color="#3D874E" />
    },
    {
      path: "/provider/messages",
      label: "Messages",
      icon: <ProviderMessageSidebarIcon color="#000" />,
      activeIcon: <ProviderMessageSidebarIcon color="#3D874E" />
    },
    {
      path: "/provider/payment-billing",
      label: "Payment & Billing",
      icon: <PaymentBillingIcon color="#000" />,
      activeIcon: <PaymentBillingIcon color="#ABE9B9" />
    },
    {
      path: "/provider/subscription",
      label: "Subscription",
      icon: <PaymentBillingIcon color="#000" />,
      activeIcon: <PaymentBillingIcon color="#ABE9B9" />
    },
    {
      path: "/provider/templates",
      label: "Templates",
      icon: <ProviderTemplateSidebarIcon color="#000" />,
      activeIcon: <ProviderTemplateSidebarIcon color="#3D874E" />
    },
    {
      path: "/provider/settings",
      label: "Settings",
      icon: <ProviderSettingsSidebarIcon color="#000" />,
      activeIcon: <ProviderSettingsSidebarIcon color="#3D874E" />
    },
    {
      path: "/provider/business-office",
      label: "Business Office",
      icon: <BusinessOfficeIcon color="#000" />,
      activeIcon: <BusinessOfficeIcon color="#ABE9B9" />
    }
  ];

  const bottomMenus = [
    {
      path: "",
      label: "Log out",
      icon: <LogoutIcon stroke="#000" />,
      activeIcon: <LogoutIcon stroke="#3D874E" />
    }
  ];

  return (
    <SidebarContainer>
      <div className="w-full">
        <div className="px-[25px] pt-10 max-w-full box-border">
          <TempleHSLogo />
        </div>
        <Spacer height={30} />
        <div className="w-[100%]">
          {menuItems.map(item => (
            <ProviderMenuItem
              key={item.label}
              isActive={location.pathname.startsWith(item.path)}
              icon={
                location.pathname === item.path ? item.activeIcon : item.icon
              }
              label={item.label}
              onClick={() => {
                handleMenuClick(item.path);
              }}
            />
          ))}
        </div>

        <div className="w-full mt-[1.375rem]">
          <div
            onClick={() => {
              setIsReportModalVisible(true);
            }}
            className="w-full cursor-pointer text-[#34623F] bg-white border-y border-[#8BDF9F] flex justify-center items-center h-[50px]"
          >
            <InfoIcon2 />
            <p className="ml-2">Support Center</p>
          </div>
        </div>
      </div>
      <footer className="w-full flex flex-row items-center">
        <div className="bottom-0 w-full">
          {bottomMenus.map(item => (
            <ProviderMenuItem
              key={item.label}
              isActive={location.pathname === item.path}
              icon={
                location.pathname === item.path ? item.activeIcon : item.icon
              }
              label={item.label}
              onClick={() => {
                if (item.label === "Log out") {
                  localStorage.removeItem("temple_user_token");
                  navigate("/provider/login");
                } else {
                  handleMenuClick(item.path);
                }
              }}
            />
          ))}
        </div>
      </footer>
      <ReportProblemModal
        isVisible={isReportModalVisible}
        closeModal={() => setIsReportModalVisible(false)}
        actionTrigger={() => {
          setIsReportModalVisible(false);
        }}
      />
    </SidebarContainer>
  );
};

const SidebarContainer = styled("div")(() => ({
  position: "relative",
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-between",
  paddingBottom: "10%",
  backgroundColor: "#fff",
  boxShadow: "7px 0px 25px rgba(192, 191, 191, 0.05)",
  " @media (max-width: 1023px)": {
    display: "none"
  }
}));

export default ProviderSidebar;
