import axios from "axios";
import { toast } from "react-toastify";
import { Fragment } from "react/jsx-runtime";
import {
  getCurrentAdminUser
} from "../../api/admin";
import { useGetAdminDashboard } from "../../api/hooks/admin/get_admin_dashboard";
import { Loading } from "../../components/shared/Loading";
import { AdminRevenueIcon } from "../../svgs/AdminRevenueIcon";
import { OverviewAppointmentIcon } from "../../svgs/OverviewAppointmentIcon";
import { OverviewComplaintIcon } from "../../svgs/OverviewCompliantIcon";
import { OverviewPatientsIcon } from "../../svgs/OverviewPatientsIcon";
import { OverviewProvidersIcon } from "../../svgs/OverviewProvidersIcon";
import { OverviewSProviderIcon } from "../../svgs/OverviewSProviderIcon";
import AdminDashboardLayout from "../components/AdminDashboardLayout";
import AdminOverviewCard from "../components/OverviewCard";

const AdminOverview = () => {
  const currentUser = getCurrentAdminUser();
  const { adminOverView, isLoading, isError, error } = useGetAdminDashboard()

  if (isError) {
    const message = axios.isAxiosError(error) ? error?.response?.data?.error : "Error";
    toast.error(message, { toastId: "customId" })
  }

  return (
    <AdminDashboardLayout headerTitle="Overview">
      <div className="w-full h-full px-4">

        <p className="text-[28px] text-[#324705] font-[600] py-4 ml-4">
          Welcome, {currentUser && currentUser?.lastName}
        </p>
        {
          isLoading ? (<div className="flex justify-center items-center h-1/2">
            <Loading />
          </div>) : <Fragment>
            <div className="flex flex-wrap lg:flex-nowrap items-center justify-start my-4" />
            <div className="flex items-center w-full">
              <div className="w-[430px]">
                <AdminOverviewCard
                  icon={<AdminRevenueIcon />}
                  title="Revenue"
                  dataCount={`$${adminOverView?.revenue?.total || 0}`}
                  footerText={`$${adminOverView?.revenue?.todaysRevenue || 0} made today`}
                />
              </div>
              <div className="w-2" />
              <div className="w-[430px]">
                <AdminOverviewCard
                  icon={<OverviewProvidersIcon />}
                  title="Total Providers"
                  dataCount={adminOverView?.providers?.allProviders?.count || 0}
                  footerText={
                    `${adminOverView?.providers?.newWeekProviders?.count || 0
                    } joined this week` || ""
                  }
                />
              </div>
              <div className="w-2" />
              <div className="w-[430px]">
                <AdminOverviewCard
                  icon={<OverviewPatientsIcon />}
                  title="Total Patients"
                  dataCount={adminOverView?.patients?.allPatients?.count || 0}
                  footerText={
                    `${adminOverView?.patients?.newWeekPatients?.count || 0
                    } joined this week` || ""
                  }
                />
              </div>
            </div>
            <div className="h-6" />

              <div className="flex items-center w-full">
                <div className="w-[430px]">
                  <AdminOverviewCard
                    icon={<OverviewAppointmentIcon />}
                    title="Total Appointments"
                    dataCount={adminOverView?.appointments?.allAppointments?.count || 0}
                    footerText={
                      `${adminOverView?.appointments?.newDailyAppointments?.count || 0
                      } appointments today` || ""
                    }
                  />
                </div>
                <div className="w-2" />
                <div className="w-[430px]">
                  <AdminOverviewCard
                    icon={<OverviewSProviderIcon />}
                    title="Total Service Providers"
                    dataCount={adminOverView?.serviceProviders?.allServiceProviders || 0}
                    footerText={
                      `${adminOverView?.serviceProviders?.newWeekServiceProviders || 0
                      } joined this week` || ""
                    }
                  />
                </div>
                <div className="w-2" />
                <div className="w-[430px]">
                  <AdminOverviewCard
                    icon={<OverviewComplaintIcon />}
                    title="Active Complaints"
                    dataCount={adminOverView?.complaints?.activeComplaints || 0}
                    footerText={
                      `${adminOverView?.complaints?.newWeekResolved || 0} resolved this week` ||
                      ""
                    }
                  />
                </div>
              </div>
          </Fragment>
        }
      </div>
    </AdminDashboardLayout>
  );
};

export default AdminOverview;
