import { InitialSettingsValues } from "../types/data.types";

export const initialSettingsValues: InitialSettingsValues = {
  address: "",
  avatarUrl: "",
  cardToCharge: null,
  dateOfBirth: "",
  dxScriptId: "",
  email: "",
  insuranceNumber: "",
  insuranceName: "",
  emancipationDecisionKey: null,
  firstName: "",
  gender: "",
  governmentIdUrl: null,
  guardianId: null,
  hasFilledPersonalDetails: true,
  idNumber: "",
  isActivated: false,
  isEmailVerified: false,
  isEmancipatedMinor: false,
  lastName: "",
  maritalStatus: null,
  phoneNumber: "+",
  pronoun: null,
  race: null,
  ssn: "",
  stateOfIdIssue: "",
  userId: "",
  userType: "",
  zipCode: "",
  state: "",
  city: ""
};
