import clsx from "clsx";
import "react-phone-number-input/style.css";
import Spacer from "../Spacer";

interface TextAreaFieldProps {
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<any, Element>) => void;
  value: string;
  label: string;
  placeholder: string;
  rows: number;
  name: string;
  size?: string;
  disabled?: boolean;
  error?: string;
  width?: number;
  isRequired?: boolean;
}

const TextAreaField: React.FC<TextAreaFieldProps> = ({
  onChange = () => {},
  onBlur = () => {},
  value,
  label,
  placeholder,
  rows,
  size,
  name,
  disabled = false,
  error,
  width,
  isRequired
}) => {
  const computedRootStyle = () => {
    const style: { [key: string]: number | string } = {};

    if (width) {
      style.width = `${width}px`;
    }

    return style;
  };

  return (
    <div
      className={clsx({
        "w-full flex flex-col": true,
        "lg:w-1/2": size === "half"
      })}
      style={computedRootStyle()}
    >
      <span className="flex">
        <label
          className="text-[#141921] font-medium mb-2 text-[16px]"
          htmlFor={`grid-${name}`}
        >
          {label}
        </label>
        {isRequired && (
          <>
            <Spacer width={3} />
            <p className="text-yellow-500 text-xs">*</p>
          </>
        )}
      </span>
      <div>
        <textarea
          // TO-DO: Pass className from parent?
          className="appearance-none block w-full border border-[#949589] text-[#103C1B] rounded py-3 px-4 leading-tight focus:outline-none focus:bg-none focus:border-gray-500 hover:border-black"
          rows={rows}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
        />
        {error && (
          <p className="mt-2 text-xs text-red-600 capitalize">{error}</p>
        )}
      </div>
    </div>
  );
};

export default TextAreaField;
