export const PharmacyHomeIcon = ({
  width = 16,
  height = 17,
  color = "#87928A",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.167 2.166a.5.5 0 01.5-.5H11a.5.5 0 01.5.5v12.667a.5.5 0 01-.5.5H1.667a.5.5 0 01-.5-.5V2.166zm1 .5v11.667H10.5V2.666H2.167z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 7.5c0-.336.224-.5.5-.5h3.333a.5.5 0 01.5.5v7.333a.5.5 0 01-.5.5H11a.5.5 0 110-1h2.833V8H11a.5.5 0 01-.5-.5zM3.833 7.5a.5.5 0 01.5-.5h4a.5.5 0 110 1h-4a.5.5 0 01-.5-.5z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.333 5a.5.5 0 01.5.5v4a.5.5 0 11-1 0v-4a.5.5 0 01.5-.5z"
        fill={color}
      />
    </svg>
  );
};
