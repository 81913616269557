import axios, {
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig
} from "axios";
import { API_URL } from "../constants/base_url";
import { getBillerToken, removeBillerToken } from "./helpers";

const billerHttp: AxiosInstance = axios.create({
  baseURL: API_URL
});

billerHttp.interceptors.request.use(
  (config: InternalAxiosRequestConfig<any>) => {
    const token = getBillerToken();
    if (token) {
      config.headers = config.headers || {};
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: any) => {
    if (error.response && error.response.status === 401) {
      removeBillerToken(billerHttp);
    }
    return Promise.reject(error);
  }
);

billerHttp.interceptors.response.use(
  function (response: AxiosResponse<any, any>) {
    return response;
  },
  function (error: any) {
    if (error.response.status === 401) {
      localStorage.removeItem("temple_biller_token");
      window.location.href = `/biller/login?authFail=true`;
    }
    return Promise.reject(error);
  }
);

export default billerHttp;
