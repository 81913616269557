export const CorrectGreenCheck = ({
  width = 18,
  height = 18,
  color = "#324705",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.375 2.813a.563.563 0 00-.563.562v11.25c0 .31.252.563.563.563h11.25c.31 0 .563-.252.563-.563V7.5a.562.562 0 111.124 0v7.125c0 .932-.755 1.688-1.687 1.688H3.375a1.687 1.687 0 01-1.688-1.688V3.375c0-.932.756-1.688 1.688-1.688h7.875a.562.562 0 110 1.125H3.375z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.702 2.167c.253.18.311.532.13.785l-5.624 7.875a.562.562 0 01-.81.112l-3.75-3a.562.562 0 11.703-.878L9.638 9.69l5.28-7.392a.563.563 0 01.784-.13z"
        fill={color}
      />
    </svg>
  );
};
