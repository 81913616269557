import styled from "@emotion/styled";

type RadioDivType = {
  isSelected?: boolean,
  label: string,
  onClick: () => any,
  value?:string,
}

const RadioDiv = (props: RadioDivType) => { 
  return (
    <RadioDivContainer onClick={props.onClick}>
      <div className="radio-btn"
        style={{
          backgroundColor: props.isSelected ? '#103C1B' : 'transparent'
        }}
      >
        <div className="inner-radio-child"></div>
      </div>
      <span>{props.label}</span>
    </RadioDivContainer>
  )
}

const RadioDivContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin: .3rem 0;

  & .radio-btn {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    position: relative;
    border: 1px solid #EEEEE9;
    margin-right: .4rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    & .inner-radio-child {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background-color: #fff;
    }
  }
`;

export default RadioDiv;