import AuthLayout from "../components/auth/AuthLayout";
import VerifyEmailForm from "../components/auth/VerifyEmail";

const VerifyEmail = () => {
  return (
    <AuthLayout>
      <VerifyEmailForm />
    </AuthLayout>
  );
};

export default VerifyEmail;
