import { Divider } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useCreateBillerChargeSheet } from "../../api/hooks/biller/useCreateBillerChargeSheet";
import { useEditBillerChargeSheet } from "../../api/hooks/biller/useEditBillerChargeSheet";
import { useGeBillerChargeSheet } from "../../api/hooks/biller/useGetAllBillerChargeSheet";
import { useVerifyDiagnosisCode } from "../../api/hooks/provider/useVerifyDiagnosisCodes";
import { useVerifyProcedureCode } from "../../api/hooks/provider/useVerifyProcedureCodes ";
import { BillerChargeSheet, DataSourceType } from '../../api/types';
import RadioField from "../../components/form/RadioField";
import TextField from "../../components/form/TextField";
import ReusableTable from "../../components/ReusableTable";
import Button from "../../components/shared/Button";
import { useDebounce } from "../../hooks/useDebounce";
import { ArrowBackIcon } from "../../svgs/ArrowBackIcon";
import { DeleteIcon } from "../../svgs/DeleteIcon";
import { PlusIcon } from "../../svgs/PlusIcon";
import SPDashboardLayout from "../components/SPDashboardLayout";
import { useClaimReviewTableRow } from "../components/table_row/hooks/claim_review";
import { claims_initialValues } from "../schema/claims_for_review.schema";
import { useSyncBillerFormValues } from './hooks/use_sync_biller_form_values';
import { useFormikWithInitialValues } from './hooks/useFormik';


function SPClaimsReview() {
  const [isDetailsView, setIsDetailsView] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [billerChargeSheet, setBillerChargeSheet] = useState<BillerChargeSheet>({} as BillerChargeSheet);
  const [tableClicked, setTableClicked] = useState<boolean>(false);


  const { postBillerChargeSheet, isPending } = useCreateBillerChargeSheet()
  const { editBillerChargeSheet, isPending: isEditing } = useEditBillerChargeSheet()

  const { allBillerChargeSheet } = useGeBillerChargeSheet()
  const { CLAIM_REVIEW } = useClaimReviewTableRow()

  const { values, setFieldValue, handleChange, setValues, resetForm } = useFormikWithInitialValues();

  useSyncBillerFormValues({
    initialValues: claims_initialValues,
    prefilledValues: billerChargeSheet,
    isTableClickedViewMode: tableClicked,
    setValues,
    values,
  })

  const { diagnosisCodes, procedureCodes } = values ?? {};

  const procedurecode = Array.isArray(procedureCodes) ? procedureCodes.join(",") : "";
  const diagnosiscode = Array.isArray(diagnosisCodes) ? diagnosisCodes.join(", ") : "";

  const debouncedDiagnosisValue = useDebounce(diagnosiscode, 500);
  const debouncedProcedureValue = useDebounce(procedurecode, 500);

  const {
    isLoading: isFetchingProCode,
    procedureData,
    refetch
  } = useVerifyProcedureCode(debouncedProcedureValue);

  const {
    isLoading: isFetchingDiagCode,
    refetch: refetchDiagnosisCode,
    diagnosisData
  } = useVerifyDiagnosisCode(debouncedDiagnosisValue);

  const handleAddDiagnosisCode = () => {
    if (typeof diagnosisCodes === 'string') {
      setFieldValue('diagnosisCodes', [values.diagnosisCodes, '']);
    } else {
      setFieldValue('diagnosisCodes', [...diagnosisCodes, '']);
    }
  };

  const handleAddProcedureCode = () => {
    if (typeof procedureCodes === 'string') {
      setFieldValue('procedureCodes', [procedureCodes, '']);
    } else {
      setFieldValue('procedureCodes', [...procedureCodes, '']);
    }
  };

  const toggleEditButton = (e: React.FormEvent<HTMLFormElement>) => {
    if (isEditMode) {
      e.preventDefault();
      editBillerChargeSheet({
        procedureCodes: values.procedureCodes,
        diagnosisCodes: values.diagnosisCodes,
        patientName: values.patientName,
        patientDateOfBirth: values.dateOfBirth,
        patientGender: values.gender,
        patientPrimaryInsurance: values.patientInsurance,
        providerName: values.providerName,
        dateOfAppointment: values.appointmentDate,
        locationOfAppointment: values.appointmentLocation,
        modeOfAppointment: values.modeOfEncounter,
        chargeSheetId: billerChargeSheet.externalChargeSheetId,
      })
      setIsEditMode(false);
    } else {
      setIsEditMode(true);
    }
  };

  return (
    <SPDashboardLayout headerTitle={isDetailsView ? "Claim Details" : "Claims for review"}>
      {isDetailsView ? (
        <div className="w-full h-full">
          <div className="flex justify-start items-start">
            <div
              onClick={() => setIsDetailsView(false)}
              className="w-full cursor-pointer pl-4 flex justify-start items-center"
            >
              <ArrowBackIcon />
              <div className="ml-3">Go back to claims for review</div>
            </div>
          </div>
          <div className="w-[85%] my-8 mx-4">
            <div className="w-full my-10">
              <p className="text-[#324705] text-[24px] font-[500]">
                Patient Information
              </p>
              <div className="h-6" />
              <div className="flex flex-wrap lg:flex-nowrap items-center">
                <div className="w-[358px]">
                  <span className="text-black text-[18px] font-[500]">
                    Patient Name
                  </span>
                  <TextField
                    name="patientName"
                    placeholder="Patient Name"
                    type="text"
                    value={values.patientName}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                </div>
                <div className="w-3" />
                <div className="w-[358px]">
                  <span className="text-black text-[18px] font-[500]">
                    Date of Birth
                  </span>
                  <TextField
                    name="dateOfBirth"
                    placeholder="10/01/1996 (27 years)"
                    type="text"
                    value={values.dateOfBirth}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                </div>
                <div className="w-3" />
                <div className="w-[358px]">
                  <span className="text-black text-[18px] font-[500]">
                    Gender
                  </span>
                  <TextField
                    name="gender"
                    placeholder="Gender"
                    type="text"
                    value={values.gender || ""}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                </div>
              </div>
              <div className="h-8" />
              <div className="flex flex-wrap lg:flex-nowrap items-center">
                <div className="w-[358px]">
                  <span className="text-black text-[18px] font-[500]">
                    Primary Insurance
                  </span>
                  <TextField
                    name="patientInsurance"
                    placeholder="Enter primary insurance"
                    type="text"
                    value={values.patientInsurance}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                </div>
                <div className="w-3" />
                <div className="w-[358px]">
                  <span className="text-black text-[18px] font-[500]">
                    Copay
                  </span>
                  <TextField
                    name="copay"
                    placeholder="$150"
                    type="text"
                    onChange={handleChange}
                    value={values.copay}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <Divider />
            <div className="w-full my-8">
              <p className="text-[#324705] text-[24px] font-[500]">
                Visit/Provider Information
              </p>
              <div className="h-6" />
              <div className="flex flex-wrap lg:flex-nowrap items-center">
                <div className="w-[358px]">
                  <span className="text-black text-[18px] font-[500]">
                    Provider Name
                  </span>
                  <TextField
                    name="providerName"
                    placeholder="Provider Name"
                    type="text"
                    value={values.providerName}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                </div>
                <div className="w-3" />
                <div className="w-[358px]">
                  <span className="text-black text-[18px] font-[500]">
                    Date of Appointment
                  </span>
                  <TextField
                    name="appointmentDate"
                    placeholder="06/13/1998"
                    type="text"
                    value={values.appointmentDate}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                </div>
                <div className="w-3" />
                <div className="w-[358px]">
                  <span className="text-black text-[18px] font-[500]">
                    Location of Appointment
                  </span>
                  <TextField
                    name="appointmentLocation"
                    placeholder="149 Fink Ln, Linden, PA 17744, USA"
                    type="text"
                    onChange={handleChange}
                    value={values.appointmentLocation}
                    disabled={!isEditMode}
                  />
                </div>
              </div>
              <div className="h-6" />
              <div className="form-item-text">Mode of Appointment</div>
              <div className="flex flex-row items-center justify-start">
                <div className="form-item-data">
                  <RadioField
                    name="modeOfEncounter"
                    placeholder="Mode of Appointment"
                    display="block"
                    options={[
                      { item: "Physical", value: "physical" },
                      { item: "Virtual", value: "virtual" }
                    ]}
                    onChange={handleChange}
                    value={values.modeOfEncounter}
                    disabled={!isEditMode}
                    isRequired={true}
                  />
                </div>
              </div>
            </div>
            <Divider />
            <div className="w-full my-2">
              <p className="text-[#324705] text-[24px] font-[500]">
                Procedure Code(s)
              </p>
              <div className="w-full">
                <p className="text-[18px] text-[#324705] font-[500] my-2 w-full block lg:hidden">
                  Procedure code(s)
                </p>
                <div className="w-full flex flex-col my-3 lg:my-0 lg:flex-nowrap items-center justify-start">
                  {
                    procedureCodes && Array.isArray(procedureCodes) &&
                    procedureCodes.map((text, index) => (
                      <Fragment key={index}>
                        <div className="w-full p-2 flex justify-start items-center">
                          <div className="w-full lg:w-[358px]">
                            <TextField
                              name="procedureCode"
                              type="text"
                              placeholder="Enter procedure code"
                              onChange={(e) => {
                                const newCodes = [...procedureCodes];
                                newCodes[index] = e.target.value
                                setFieldValue("procedureCodes", newCodes);
                              }}
                              label={`Procedure code ${index + 1}`}
                              value={values.procedureCodes ? (Array.isArray(values.procedureCodes) ? values.procedureCodes.join(", ") : values.procedureCodes) : text}
                              disabled={!isEditMode}
                            />
                          </div>
                          <div
                            className="flex justify-start px-4 mt-4"
                            onClick={() => {
                              if (Array.isArray(procedureCodes) && procedureCodes.length > 1) {
                                const newCodes = [...procedureCodes];
                                newCodes.pop();
                                setFieldValue('procedureCodes', newCodes);
                              }
                            }}
                          >
                            {index > 0 && (
                              <DeleteIcon className="cursor-pointer" />
                            )}
                          </div>
                        </div>
                      </Fragment>

                    ))}
                </div>
              </div>
              <div className="flex flex-col justify-start items-start">
                <div className="w-[122px] lg:mr-5">
                  <Button
                    type="button"
                    variant="secondary"
                    label="Verify code"
                    size="small"
                    loading={isFetchingProCode}
                    onClick={() => refetch()}
                    disabled={isFetchingProCode || procedureCodes.length === 0}
                  />
                </div>
                {isFetchingProCode ? (
                  "...verifying"
                ) : (
                  <div className="text-sm shadow-sm w-72 border flex flex-col justify-evenly items-start mt-4">
                    {(procedureData || [])?.map(
                      ({ code, title }: DataSourceType) => (
                        <div key={code} className="text-sm">
                          <p className="text-sm">Code: {code}</p>
                          <p className="text-sm">Name: {title}</p>
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
              <div className="flex flex-row pb-4  mt-4">
                <span
                  className="cursor-pointer flex items-center"
                  onClick={handleAddProcedureCode}
                >
                  <PlusIcon />
                  <span className="text-[#1A9D39] text-[16px] ml-3" >
                    Add procedure code
                  </span>
                </span>
              </div>
            </div>
            <Divider />
            <div className="w-full my-2">
              <p className="text-[#324705] text-[24px] font-[500]">
                Diagnosis Code(s)
              </p>
              <div className="w-full">
                <p className="text-[18px] text-[#324705] font-[500] my-2 w-full block lg:hidden">
                  Diagnosis code(s)
                </p>
                <div className="w-full flex flex-col my-3 lg:my-0 lg:flex-nowrap items-center justify-start">
                  {diagnosisCodes && Array.isArray(diagnosisCodes) &&
                    diagnosisCodes.map((text, index) => (
                      <Fragment key={`diagnosisCode-${index}`}>
                        <div className="w-full p-2 flex justify-start items-center">
                          <div className="w-full lg:w-[358px]">
                            <TextField
                              name="diagnosisCode"
                              type="text"
                              placeholder="Enter Diagnosis Code"
                              onChange={(e) => {
                                const newCodes = [...diagnosisCodes];
                                newCodes[index] = e.target.value
                                setFieldValue("diagnosisCodes", newCodes);
                              }}
                              label={`Diagnosis code ${index + 1}`}
                              value={values.diagnosisCodes ? (Array.isArray(values.diagnosisCodes) ? values.diagnosisCodes.join(", ") : values.diagnosisCodes) : text}
                              disabled={!isEditMode}
                            />
                          </div>
                          <div
                            className="flex justify-start px-4 mt-4"
                            onClick={() => {
                              if (Array.isArray(diagnosisCodes) && diagnosisCodes.length > 1) {
                                const newCodes = [...diagnosisCodes];
                                newCodes.pop();
                                setFieldValue('diagnosisCodes', newCodes);
                              }
                            }}
                          >
                            {index > 0 && (
                              <DeleteIcon className="cursor-pointer" />
                            )}
                          </div>
                        </div>
                      </Fragment>
                    )
                    )}
                </div>
              </div>
              <div className="flex flex-col justify-start items-start">
                <div className="w-[122px] lg:mr-5">
                  <Button
                    type="button"
                    variant="secondary"
                    label="Verify code"
                    size="small"
                    loading={isFetchingDiagCode}
                    onClick={() => refetchDiagnosisCode()}
                    disabled={isFetchingDiagCode || diagnosisCodes.length === 0}
                  />
                </div>
                {isFetchingDiagCode ? (
                  "...verifying"
                ) : (
                  <div className="text-sm shadow-sm w-72 border flex flex-col justify-evenly items-start mt-4">
                    {(diagnosisData || [])?.map(
                      ({ code, title }) => (
                        <div key={code} className="text-sm">
                          <p className="text-sm">Code: {code}</p>
                          <p className="text-sm">Name: {title}</p>
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
              <div className="flex flex-row pb-4  mt-4">
                <span
                  className="cursor-pointer flex items-center"
                  onClick={handleAddDiagnosisCode}
                >
                  <PlusIcon />
                  <span className="text-[#1A9D39] text-[16px] ml-3">
                    Add diagnosis code
                  </span>
                </span>
              </div>
            </div>
            <div className="h-10" />
          </div>
          <div className="w-full flex items-center justify-end pb-5">
            <div className="w-full flex items-center justify-end gap-5 px-3">
              <div>
                <Button variant="secondary" label="Check insurance eligibility" />
              </div>
              <div >
                {
                  tableClicked ? <Button variant={!isEditMode ? "secondary" : "primary"}

                    label={!isEditMode ? "Edit" : "Save changes"}
                    size="medium"
                    onClick={toggleEditButton} disabled={isEditing}
                  /> : <Button
                    type="submit"
                      variant="primary"
                      label="Approve"
                      size="medium"
                      onClick={() => {
                        postBillerChargeSheet({
                          procedureCodes: values.procedureCodes,
                          diagnosisCodes: values.diagnosisCodes,
                          patientName: values.patientName,
                          patientDateOfBirth: values.dateOfBirth,
                          patientGender: values.gender,
                          patientPrimaryInsurance: values.patientInsurance,
                          providerName: values.providerName,
                          dateOfAppointment: values.appointmentDate,
                          locationOfAppointment: values.appointmentLocation,
                          modeOfAppointment: values.modeOfEncounter
                        });
                        setIsEditMode(false);
                        setIsDetailsView(false);
                        resetForm();
                      }}
                      disabled={isPending}
                  />
                }
              </div>
            </div>
          </div>
        </div>
      ) : (
          <Fragment>
          <div className="w-full flex items-center justify-end mb-4">
            <div className="w-[169px]">
                <Button variant="primary" label="New Billing" size="medium" onClick={() => {
                  setIsEditMode(true);
                  setIsDetailsView(true)
                  setTableClicked(false);
                  resetForm();
                }} />
            </div>
            </div>
          <div className="w-full">
            <ReusableTable
              tableColumns={CLAIM_REVIEW}
                tableData={allBillerChargeSheet ?? []}
                onClick={(value: BillerChargeSheet) => {
                  setBillerChargeSheet(value);
                  setIsDetailsView(true);
                  setTableClicked(true);
                  setIsEditMode(false);
              }}
              pageSizeCount={10}
              searchFieldPlaceholder="Search claims"
            />
          </div>
          </Fragment>
      )}
    </SPDashboardLayout>
  );
}

export default SPClaimsReview;