import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import adminHttp from "../../../utils/adminHttp";


type Payload = {
  userId: string;
}

export const useDeleteAdminComplaint = () => {
  const queryClient = useQueryClient();
  const { mutate, data, variables, ...rest } = useMutation({
    mutationKey: ['delete admin complaints'],
    mutationFn: async ({ userId }: Payload) => {
      const response = await adminHttp.delete(`complaint/${userId}`);
      return response.data;
    },

    onSuccess: () => {
      toast.success(
        `deleted successfully`,
        { toastId: "customId" }
      );
      queryClient.invalidateQueries({
        queryKey: ["get all admin complaints list"]
      });
    },

    onError: (error) => {
      if (axios.isAxiosError(error)) {
        toast.error(
          `Error deleting data ` || error.response?.statusText,
          { toastId: "customId" }
        );

      } else {
        toast.error(
          error?.message
          || "Error processing your request",
        );
      }
    },
  });

  return {
    deleteAdminComplaint: mutate,
    data,
    variables,
    ...rest,
  };
}