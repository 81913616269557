import { useQuery } from '@tanstack/react-query';
import http from '../../../utils/http';
import { CompletedAppointmentsResponse, ErrorResponse } from '../../types';


export function useGetCompletedAppointmentData(patientId: string) {
  const { data, isLoading, refetch, ...rest } = useQuery<CompletedAppointmentsResponse, ErrorResponse>({
    queryKey: [`completed appointment ${patientId ?? null}`],
    queryFn: async () => {
      const response = await http.get(`/appointments/provider/patient/completed?patientId=${patientId}`);
      return response.data;
    },
    enabled: !!patientId,
  });
  return {
    completedAppointments: data as CompletedAppointmentsResponse,
    isLoading,
    refetchProvidersLastppointment: refetch,
    ...rest,
  }
}