
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { toast } from 'react-toastify';
import http from '../../../utils/http';


type Payload = {
  sessionId: any;
}

export default function useRetrievePaymentSession() {
  const queryClient = useQueryClient();
  const { mutate, data, variables, reset, ...rest } = useMutation({
    mutationKey: ['retrieve payment sessions'],
    mutationFn: async ({ sessionId }: Payload) => {
      const response = await http.post(`stripe/retrieve-payment-session`, {
        session: sessionId
      });
      return response;
    },

    onSuccess: () => {
      toast.success("session retrieved successfully!");
      queryClient.invalidateQueries({ queryKey: ['retrieve payment sessions'] });
    },

    onError: (error) => {
      if (axios.isAxiosError(error)) {
        toast.error(error?.response?.data?.error);
      } else {
        toast.error(error?.message || "Error retrieving payment session data",
        );
      }
    },
  });

  return {
    retrieveSessions: mutate,
    session: data,
    variables,
    ...rest,
  };
}
