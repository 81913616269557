import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import http from "../../../utils/http";
import { status } from "../../types";

type Payload = {
  patientId: string;
  appointmentId: string
  procedureCodes: string | string[]
  diagnosisCodes: string | string[]
  status: status,
  modeOfEncounter: "virtual" | "physical";
  locationOfEncounter: string;
  dateOfEncounter: string;
}

export const useSaveChargeSheetDraft = () => {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation({
    mutationKey: ['save charge-sheet'],
    mutationFn: async ({ ...data }: Payload) => {
      const response = await http.post("charge-sheet", { ...data })
      return response.data?.newCharge;
    },

    onSuccess: () => {
      toast.success("Saved as draft");
      queryClient.invalidateQueries({ queryKey: ['payment and billing table'] });
    },

    onError: (error) => {
      if (axios.isAxiosError(error)) {
        toast.error(
          error?.response?.data?.error || error.response?.statusText,
        );
      } else {
        toast.error(
          error?.message
          || "Error saving charge sheet",
        );
      }
    }
  });

  return {
    saveChargeSheet: mutate,
    ...rest,
  };
}
