import { CircularProgress } from "@mui/material";
import { Form, Formik } from "formik";
import { Dispatch, SetStateAction, useState } from "react";
import * as Yup from "yup";
import { getNearbyPlaces } from "../../../api/common";
import { ArrowBackIcon } from "../../../svgs/ArrowBackIcon";
import { ToastFunction } from "../../../types/common";
import { removeEmptyKeys } from "../../../utils/removeEmptyFromObjectOrArray";
import Spacer from "../../Spacer";
import SinglePhamarcyCard from "../../cards/SinglePhamarcyCard";
import TextField from "../../form/TextField";
import Button from "../../shared/Button";

const EnterPharmacy = ({
  toast,
  setCurrentPage,
  handleSkip,
  setFormData,
  formData
}: {
  toast: ToastFunction;
    setCurrentPage: Dispatch<SetStateAction<number>>;
  handleSkip: () => void;
    setFormData: Dispatch<any>;
  formData: any;
}) => {
  const [pharmacySearchResults, setPharmacySearchResults] = useState<any[]>([]);
  const [showPharmacySearchResult, setShowPharmacySearchResult] =
    useState(false);
  const [pharmacySearchItem, setPharmacySearchItem] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSearchPharmacy = () => {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(
              successCallback,
              errorCallback,
              options
            );
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(
              successCallback,
              errorCallback,
              options
            );
          } else if (result.state === "denied") {
            toast.info(
              "User must grant permission to read location before they can perform this search.",
              {
                type: "info"
              }
            );
          }
        });
    }

    const successCallback: PositionCallback = async (
      location: GeolocationPosition
    ) => {
      setIsLoading(true);
      const nearbyPharmacies = await getNearbyPlaces({
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
        place: "healthcare.pharmacy",
        ...(pharmacySearchItem && { name: pharmacySearchItem })
      });

      if (nearbyPharmacies.nearbyPlaces) {
        setPharmacySearchResults(nearbyPharmacies.nearbyPlaces);
        setShowPharmacySearchResult(true);
      } else {
        toast.error("Error getting nearby pharmacies", {
          type: "error"
        });
      }
      setIsLoading(false);
    };

    const errorCallback: PositionErrorCallback = (
      error: GeolocationPositionError
    ) => {
      toast.error(`Error getting user's geolocation data: ${error.message}`, {
        type: "error"
      });
    };
  };

  return (
    <>
      {isLoading ? (
        <div className="w-full h-[100vh] flex items-center justify-center">
          <CircularProgress></CircularProgress>
        </div>
      ) : showPharmacySearchResult ? (
        <div>
          <div className="w-full flex">
            <Button
              onClick={() => setShowPharmacySearchResult(false)}
              icon={<ArrowBackIcon />}
              iconPosition="left"
              size="medium"
              label="Go back"
              variant="tertiary"
              additionalClassname="text-[#103C1B]"
            ></Button>
          </div>
          <div className="w-full lg:w-[550px]">
            {pharmacySearchResults.length ? (
              pharmacySearchResults?.map(
                (
                  { name, city, formatted, state, postcode, distance },
                  index
                ) => (
                  <SinglePhamarcyCard
                    key={index}
                    onClick={() => {
                      setFormData({
                        ...formData,
                        pharmacyZipcode: postcode,
                        pharmacyName: name,
                        pharmacyAddress: formatted,
                        pharmacyCity: city,
                        pharmacyState: state
                      });
                      setCurrentPage(8);
                    }}
                    name={name}
                    city={city}
                    address={formatted}
                    state={state}
                    postcode={postcode}
                    distance={distance}
                  />
                )
              )
            ) : (
              <p className="text-[18px] font-medium p-5">
                No Pharmacies found close to you. Modify your search or enter an
                address manually.
              </p>
            )}
          </div>
        </div>
      ) : (
        <section className="px-5 min-h-screen">
          <Formik
            initialValues={{
              pharmacyZipcode: "",
              pharmacyName: "",
              pharmacyAddress: "",
              pharmacyCity: "",
              pharmacyState: ""
            }}
            validationSchema={Yup.object().shape({
              pharmacyZipcode: Yup.string(),
              pharmacyName: Yup.string(),
              pharmacyAddress: Yup.string(),
              pharmacyCity: Yup.string(),
              pharmacyState: Yup.string()
            })}
            onSubmit={values => {
              const nonEmptyValues = removeEmptyKeys(values);
              setFormData({
                ...formData,
                ...nonEmptyValues
              });
              setCurrentPage(8);
            }}
          >
            {({ errors, touched, values, handleChange, handleSubmit }) => (
              <Form className="flex flex-col items-center w-full overflow-scroll pb-[50px]">
                <div className="flex flex-col items-center w-full">
                  <div className="mt-4 w-full">
                    <TextField
                      name="pharmacyName"
                      type="text"
                      placeholder="e.g. Walgreen, CVS"
                      value={values.pharmacyName}
                      onChange={handleChange}
                      label="Pharmacy Name"
                      error={touched.pharmacyName ? errors.pharmacyName : ""}
                    />
                  </div>
                  <div className="mt-4 w-full">
                    <TextField
                      name="pharmacyAddress"
                      type="text"
                      placeholder="123 John Doe Drive"
                      value={values.pharmacyAddress}
                      onChange={handleChange}
                      label="Address"
                      error={
                        touched.pharmacyAddress ? errors.pharmacyAddress : ""
                      }
                    />
                  </div>
                  <div className="mt-4 w-full">
                    <TextField
                      name="pharmacyZipcode"
                      type="text"
                      placeholder="e.g 10001"
                      value={values.pharmacyZipcode}
                      onChange={handleChange}
                      label="Zip Code"
                      error={
                        touched.pharmacyZipcode ? errors.pharmacyZipcode : ""
                      }
                    />
                  </div>
                  <div className="mt-4 w-full flex gap-5">
                    <TextField
                      name="pharmacyCity"
                      type="text"
                      placeholder="Seattle"
                      value={values.pharmacyCity}
                      onChange={handleChange}
                      label="City"
                      error={touched.pharmacyCity ? errors.pharmacyCity : ""}
                    />
                    <TextField
                      name="pharmacyState"
                      type="text"
                      placeholder="Washington"
                      value={values.pharmacyState}
                      onChange={handleChange}
                      label="State"
                      error={touched.pharmacyState ? errors.pharmacyState : ""}
                    />
                  </div>

                  <div className="mt-5 w-full flex gap-2">
                    <Button
                      label="Skip"
                      variant="secondary"
                      additionalClassname="!w-[50%]"
                      height="40px"
                      onClick={() => handleSkip()}
                    />
                    <Spacer width={10} />
                    <Button
                      label="Continue"
                      variant="primary"
                      additionalClassname="!w-[50%]"
                      height="40px"
                      onClick={handleSubmit}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <section className="text-[22px] font-medium mt-10">
            <p className="pb-5">Search for a Pharmacy close to you instead?</p>
            <div className="flex items-end justify-center gap-5">
              <TextField
                name="name"
                type="text"
                placeholder="e.g Walgreens, CVS"
                value={pharmacySearchItem}
                onChange={e => setPharmacySearchItem(e.target.value)}
                label="Pharmacy Name (optional)"
                height={25}
                additionalClassname="!w-[50%]"
              />
              <Button
                label="Search"
                variant="primary"
                height="50px"
                additionalClassname="!w-[50%]"
                onClick={handleSearchPharmacy}
              />
            </div>
          </section>
        </section>
      )}
    </>
  );
};

export default EnterPharmacy;
