import { format } from "date-fns";
import { Fragment, useMemo } from "react";
import { Column } from "react-table";
import { BillerChargeSheet } from "../../../../api/types";


export function useClaimReviewTableRow() {
  const CLAIM_REVIEW = useMemo<Column<BillerChargeSheet>[]>(
    () => [
      {
        Header: "ID",
        accessor: "id",
        Cell: ({ cell: { value } }) => <Fragment>{value}</Fragment>,
      },
      {
        Header: "External Charge Sheet ID",
        accessor: "externalChargeSheetId",
        Cell: ({ cell: { value } }) => <Fragment>{value}</Fragment>,
      },
      {
        Header: "Biller ID",
        accessor: "billerId",
        Cell: ({ cell: { value } }) => <Fragment>{value}</Fragment>,
      },
      {
        Header: "Patient Name",
        accessor: "patientName",
        Cell: ({ cell: { value } }) => <Fragment>{value}</Fragment>,
      },
      {
        Header: "Patient DOB",
        accessor: "patientDateOfBirth",
        Cell: ({ cell: { value } }) => <Fragment>{value}</Fragment>,
      },
      {
        Header: "Patient Gender",
        accessor: "patientGender",
        Cell: ({ cell: { value } }) => <Fragment>{value}</Fragment>,
      },
      {
        Header: "Primary Insurance",
        accessor: "patientPrimaryInsurance",
        Cell: ({ cell: { value } }) => <Fragment>{value}</Fragment>,
      },
      {
        Header: "Secondary Insurance",
        accessor: "patientSecondaryInsurance",
        Cell: ({ cell: { value } }) => <Fragment>{value ?? "N/A"}</Fragment>,
      },
      {
        Header: "Co-Pay",
        accessor: "patientCoPay",
        Cell: ({ cell: { value } }) => <Fragment>{value ?? "N/A"}</Fragment>,
      },
      {
        Header: "Provider Name",
        accessor: "providerName",
        Cell: ({ cell: { value } }) => <Fragment>{value}</Fragment>,
      },
      {
        Header: "Visit Date",
        accessor: "dateOfAppointment",
        Cell: ({ cell: { value } }) => (
          <Fragment>
            {value ? format(new Date(value), "MM/dd/yyyy") : "N/A"}
          </Fragment>
        ),
      },
      {
        Header: "Meeting Location",
        accessor: "locationOfAppointment",
        Cell: ({ cell: { value } }) => <Fragment>{value}</Fragment>
      },
      {
        Header: "Mode Of Appointment",
        accessor: "modeOfAppointment",
        Cell: ({ cell: { value } }) => <Fragment>{value}</Fragment>
      },
      {
        Header: "Procedure Codes",
        accessor: "procedureCodes",
        Cell: ({ cell: { value } }) => (
          <Fragment>{value.length > 0 ? value.join(", ") : "N/A"}</Fragment>
        ),
      },
      {
        Header: "Diagnosis Codes",
        accessor: "diagnosisCodes",
        Cell: ({ cell: { value } }) => (
          <Fragment>{value.length > 0 ? value.join(", ") : "N/A"}</Fragment>
        ),
      },
      {
        Header: "Created At",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => (
          <Fragment>
            {value ? format(new Date(value), "MM/dd/yyyy HH:mm:ss") : "N/A"}
          </Fragment>
        ),
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
        Cell: ({ cell: { value } }) => (
          <Fragment>
            {value ? format(new Date(value), "MM/dd/yyyy HH:mm:ss") : "N/A"}
          </Fragment>
        ),
      },
    ],
    []
  );

  return { CLAIM_REVIEW }

}