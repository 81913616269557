export function removeEmptyKeys(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.filter(item => {
      return !isEmpty(item);
    });
  } else if (typeof obj === "object") {
    return Object.keys(obj).reduce((acc, key) => {
      const value = removeEmptyKeys(obj[key]);
      if (!isEmpty(value)) {
        acc[key] = value;
      }
      return acc;
    }, {} as any);
  }
  return obj;
}

function isEmpty(value: any): boolean {
  if (value === null || value === undefined) {
    return true;
  } else if (typeof value === "string" && value.trim() === "") {
    return true;
  } else if (typeof value === "object" && Object.keys(value).length === 0) {
    return true;
  }
  return false;
}
