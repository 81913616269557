import AuthLayout from "../../components/auth/AuthLayout";
import SPForgotPasswordForm from "../components/SPForgotPassword";

const SPForgotPassword = () => {
  return (
    <AuthLayout>
      <SPForgotPasswordForm />
    </AuthLayout>
  );
};

export default SPForgotPassword;
