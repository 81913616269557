export const CancelIcon = ({
  width = 16,
  height = 16,
  color = "#626465",
  className,
  onClick
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
  onClick?: () => void;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M8.00119 8.00022L11.4965 11.4955M4.50586 11.4955L8.00119 8.00022L4.50586 11.4955ZM11.4965 4.50488L8.00053 8.00022L11.4965 4.50488ZM8.00053 8.00022L4.50586 4.50488L8.00053 8.00022Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
