import { useNavigate } from "react-router-dom";
import Button from "../components/shared/Button";

const NotFound = () => {
  const navigate = useNavigate();
  const backToPrevious = () => navigate(-1)
  return (
    <div className="w-full h-full m-auto flex flex-col justify-center items-center text-bold space-y-4">
      <div className="text-[200px] text-bold">404</div>
      <div className="text-[25px]">PAGE NOT FOUND</div>
      <div className="">
        <Button
          label="Go Back"
          additionalClassname={"text-[18px] font-[400] mb-8"}
          type="button"
          onClick={backToPrevious}
          variant="primary"
          size="medium"
        />
      </div>
    </div>
  );
};

export default NotFound;
