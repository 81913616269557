import { Fragment, useEffect, useState } from "react";
import {
  getPatientPrescriptionsList
  // getSinglePatientPrescriptiontDetails
} from "../api/patients";
import PrescriptionCard from "../components/cards/PrescriptionCard";
import DashboardLayout from "../components/dashboard/DashboardLayout";
import CustomDrawer from "../components/drawer";
import ListTabsWidget from "../components/shared/ListTabsWidget";
import { CalendarDateIcon } from "../svgs/CalendarDateIcon";
import { ReactComponent as DosageIcon } from "../svgs/dosage-small.svg";
import { ReactComponent as PharmacyIcon } from "../svgs/pharmacy-small.svg";

const Prescriptions = () => {
  const [activeTabLabel, setActiveTabLabel] = useState("Current Prescriptions");
  const [prescriptionsList, setPrescriptionsList] = useState<any[]>([]);
  const [showPrescriptionDrawer, setShowPrescriptionDrawer] = useState(false);
  // const [singlePrescription, setSinglePrescription]: any = useState(null);
  const [singlePrescriptionData, setSinglePrescriptionData]: any =
    useState(null);

  const fetchPrescriptions = async () => {
    const result = await getPatientPrescriptionsList();
    setPrescriptionsList(result?.prescriptions);
  };

  useEffect(() => {
    fetchPrescriptions();
  }, []);

  const handlePrescriptionCardClick = (id: number, data: any) => {
    setSinglePrescriptionData(data);
    setShowPrescriptionDrawer(true);
  };

  const handleCloseDrawer = () => {
    setShowPrescriptionDrawer(false);
  };

  const handleTabClick = (tabName: string) => {
    setActiveTabLabel(tabName);
  };

  return (
    <DashboardLayout headerTitle="Prescription">
      <>
        <CustomDrawer
          open={showPrescriptionDrawer}
          onClose={handleCloseDrawer}
          drawerTitle="Prescription Details"
          noWidth
        >
          <div className="h-full w-full lg:w-[600px] overflow-y-auto py-[.8rem] px-[.9rem] mt-4">
            <div className="text-[#324705] w-full text-[24px] mb-8">
              {singlePrescriptionData?.drug}
            </div>
            <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center mb-8">
              <div className="flex flex-col">
                <span className="flex items-center justify-start mb-4">
                  <DosageIcon className="mr-2" />
                  <p className="text-[#87928A]">Dosage</p>
                </span>
                <div className="text-[#324705] text-[.77rem]">
                  {singlePrescriptionData?.dosage}
                </div>
              </div>
              <div className="flex flex-col">
                <span className="flex items-center justify-start mb-4">
                  <CalendarDateIcon className="mr-2" />
                  <p className="text-[#87928A]">Duration</p>
                </span>
                <div className="text-[#324705] text-[.77rem]">
                  {singlePrescriptionData?.duration}
                </div>
              </div>
              <div className="flex flex-col w-full mt-2 lg:w-auto lg:mt-0">
                <span className="flex items-center justify-start mb-4">
                  <PharmacyIcon className="mr-2" />
                  <p className="text-[#87928A]">Pharmacy</p>
                </span>
                <div className="text-[#324705] text-[.77rem]">
                  {singlePrescriptionData?.pharmacy}
                </div>
              </div>
            </div>

            <div className="text-[24px] font-[500] text-[#324705] mt-8">
              Provider’s Note
            </div>
            <div className="text-[.77rem] font-[400] text-[#324705]">
              {singlePrescriptionData?.note}
            </div>
          </div>
        </CustomDrawer>
      </>
      <div className="pr-0 pl-0 px-4 flex flex-col justify-end w-full">
        <div className="overflow-y-hidden my-6 w-full overflow-x-scroll lg:overflow-x-hidden px-4">
          <ListTabsWidget
            tabs={[
              { label: "Current Prescriptions" },
              { label: "Previous Prescriptions" }
            ]}
            handleTabClick={label => {
              handleTabClick(label);
            }}
            activeTabLabel={activeTabLabel}
          />
        </div>
        {/* TODO FETCH API DATA */}
        {activeTabLabel === "Current Prescriptions" && (
          <div className="flex items-center justify-start gap-x-3 mt-8 flex-wrap gap-y-4">
            {prescriptionsList && prescriptionsList?.length ? (
              prescriptionsList?.filter(prescription => !prescription?.hasCompletedDose)
                .map((data: any, idx) => (
                  <Fragment key={data?.id}>
                    <PrescriptionCard
                      drug={data?.drug || "Ibruprofen oral capsule (200 mg)"}
                      duration={data?.duration || "For 14 days"}
                      dosage={data?.dosage || "3 tablets, once a day"}
                      pharmacy={data?.pharmacy || "Spriing Oak Pharmacy"}
                      note={data?.note}
                      onClick={() => {
                        handlePrescriptionCardClick(data?.id, data);
                      }}
                    />
                  </Fragment>
                ))
            ) : (
              <div className="h-full w-full m-auto flex justify-center items-center font-semibold text-[#34623f]">
                NO CURRENT PRESCRIPTIONS
              </div>
            )}
          </div>
        )}

        {/* PASDT PRESCRIPTIONS */}
        {activeTabLabel === "Previous Prescriptions" && (
          <div className="flex flex-wrap items-center justify-start gap-x-3 mt-8 gap-y-4">
            {prescriptionsList?.length ? (
              prescriptionsList
                ?.filter(prescription => prescription?.hasCompletedDose)
                ?.map((data: any, idx) => (
                  <Fragment key={idx}>
                    <PrescriptionCard
                      drug={data?.drug}
                      duration={data?.duration}
                      dosage={data?.dosage}
                      pharmacy={data?.pharmacy}
                      note={data?.note}
                      onClick={() => {
                        handlePrescriptionCardClick(idx, data);
                      }}
                    />
                  </Fragment>
                ))
            ) : (
              <div className="h-full w-full m-auto flex justify-center items-center font-semibold text-[#34623f]">
                NO PREVIOUS PRESCRIPTIONS
              </div>
            )}
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default Prescriptions;
