import Button from "../shared/ButtonSecondary";

interface MentalHealthProviderCardProps {
  description: string;
  providerType: string;
  icon: React.ReactNode;
  onClick?: (arg: any) => void;
}

const MentalHealthProviderCard: React.FC<MentalHealthProviderCardProps> = ({
  description,
  providerType,
  icon,
  onClick = () => {}
}) => {
  return (
    <div className="p-4 border box-border border-[#EBEBEB] rounded bg-[#fff] w-full min-h-[232px] bg-gradient-to-b-[rgba(0, 0, 0, .36), rgba(0, 0, 0, .36)] cursor-pointer grow shadow-md">
      <div className="flex items-center justify-between w-full">
        <div className="flex justify-start">{icon}</div>
        <div className="hidden lg:flex justify-end">
          <Button
            onClick={onClick}
            variant="secondary"
            label="Schedule an appointment"
            // size="large"
          />
        </div>
      </div>
      <div className="h-4" />
      <p className="font-[400] text-[24px]">{providerType}</p>
      <div className="h-4" />
      <div className="w-full flex justify-start text-[#324705] text-[18px] font-[400] text-left">
        {description}
      </div>
      <div className="flex lg:hidden justify-start">
        <Button
          onClick={onClick}
          variant="secondary"
          label="Schedule an appointment"
          // size="large"
        />
      </div>
    </div>
  );
};

export default MentalHealthProviderCard;
