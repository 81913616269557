export const ArrowDownIcon = ({
  width = 13,
  height = 9,
  color = "#454647",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 13 9" fill="none">
      <path
        d="M12.2667 1.46668L6.63333 7.53334L1 1.46667"
        stroke={color}
        strokeLinecap="square"
      />
    </svg>
  );
};
