import { Divider } from "@mui/material";
import { format } from "date-fns";
import { Fragment, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { ArrowLeftIcon2 } from "../svgs/ArrowLeftIcon2";
import { ArrowRightIcon2 } from "../svgs/ArrowRightIcon2";
import Chip from "./Chip";
import CustomDrawer from "./drawer";

interface SingleProviderFullProfileDrawerProps {
  drawerTitle: string;
  onClose: () => void;
  open: boolean;
  data: any;
  availability: any[];
  onTimeSlotClick: ({
    date,
    time,
    providerId
  }: {
    date: string;
    time: string;
    providerId: string;
  }) => any;
}

const SingleProviderFullProfileDrawer = ({ drawerTitle, onClose, open, onTimeSlotClick, data, availability }: SingleProviderFullProfileDrawerProps) => {
  const [currentAvailability, setCurrentAvailability]: any = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [availabilityOffset, setAvailabilityOffset] = useState(0);

  useEffect(() => {
    const endOffset = availabilityOffset + 5;
    setCurrentAvailability(availability?.slice(availabilityOffset, endOffset));
    setPageCount(Math.ceil(availability?.length / 5));
  }, [availability, availabilityOffset]);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * 5) % availability?.length;
    setAvailabilityOffset(newOffset);
  };

  return (
    <div className="w-full lg:w-[838px]">
      <CustomDrawer open={open} onClose={onClose} drawerTitle={drawerTitle}>
        <div className="max-h-[100vh] flex flex-col overflow-y-auto px-8">
          <div className="w-full flex justify-between gap-4 items-center">
            <div>
              <img
                src={data?.avatarUrl || "/assets/images/avatar.png"}
                alt=""
                className="rounded-full w-[100px] h-[100px]"
              />
            </div>
            <div className="w-5" />
            <div className="flex flex-col">
              <p className="text-[26px] font-[400] text-[#103C1B]">
                {`${data?.firstName} ${data?.lastName}, ${data?.title}`}
              </p>
              <div className="h-1" />
              <p className="text-[15px] font-[400] text-[#103C1B]">
                {data?.specialty}
              </p>
            </div>
            <div className="w-5" />
            {data?.followUpAppointmentFee && data?.initialAppointmentFee && (
              <div className="flex-1 flex flex-col items-start justify-start gap-1">
                <div className="text-[13px] font-[400] text-[#103C1B]">
                  {data?.isInitialAppointment
                    ? "Initial Appointment Fee"
                    : "Follow Up Appointment Fee"}
                </div>
                <div className="text-[16px] font-[500] text-[#103C1B]">
                  $
                  {data?.isInitialAppointment
                    ? data?.initialAppointmentFee
                    : data?.followUpAppointmentFee}
                </div>
              </div>
            )}
          </div>
          <div className="h-4" />

          {data?.bio && (
            <p className="w-full text-[#103C1B] text-[400] text-[15px]">
              {data?.bio}
            </p>
          )}

          <div className="h-4" />

          <div className="w-full flex flex-col justify-start">
            <div>
              <div className="h-4" />

              {data?.languages && (
                <p className="text-[#87928A] text-[15px] font-[400]">
                  Languages
                </p>
              )}
              <div className="h-1" />
              <div className="flex justify-start">
                {data?.languages?.map((l: string, idx: number) => (
                  <p
                    className="text-[#324705] text-[15px] font-[400] pr-2"
                    key={idx}
                  >
                    {idx === data.languages.length - 1 ? l : `${l},`}
                  </p>
                ))}
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col justify-start">
            <div>
              <div className="h-4" />

              {data?.focusAreas && (
                <p className="text-[#87928A] text-[15px] font-[400]">
                  Focus Areas
                </p>
              )}
              <div className="h-1" />
              <div className="flex justify-start">
                {data?.focusAreas?.map((l: string, idx: number) => (
                  <p
                    className="text-[#324705] text-[15px] font-[400] pr-2"
                    key={idx}
                  >
                    {idx === data.focusAreas.length - 1 ? l : `${l},`}
                  </p>
                ))}
              </div>
            </div>
          </div>
          <div className="my-4" />
          <Divider />

          <div>
            <div className="h-6" />
            <p className="text-[#87928A] text-[15px] font-[400]">
              Available Slots
            </p>
            <div className="h-4" />

            <div className="flex items-cemter w-full">
              {currentAvailability?.length && (
                <p className="text-[25px]">
                  {" "}
                  {format(
                    new Date(currentAvailability[0]?.appointmentDate),
                    "LLL dd"
                  )}{" "}
                  -
                  {format(
                    new Date(
                      currentAvailability[
                        currentAvailability?.length - 1
                      ]?.appointmentDate
                    ),
                    "LLL dd"
                  )}
                </p>
              )}

              <div className="pagination flex items-center ml-8">
                <ReactPaginate
                  nextLinkClassName="next"
                  nextLabel={
                    <i>
                      <ArrowRightIcon2 />
                    </i>
                  }
                  onPageChange={handlePageClick}
                  pageCount={pageCount}
                  previousLinkClassName="prev"
                  previousLabel={
                    <i>
                      <ArrowLeftIcon2 />
                    </i>
                  }
                  renderOnZeroPageCount={null}
                  disabledClassName="disabled"
                  containerClassName={"pagination"}
                  pageRangeDisplayed={0}
                  marginPagesDisplayed={0}
                />
              </div>
            </div>
            {currentAvailability?.map((item: any, idx: number) => {
              return (
                <Fragment key={idx}>
                  <div className="w-1/2 flex items-center justify-start">
                    <p className="text-[18px] text-[#324705] gap-y-2 mr-2">
                      {format(new Date(item?.appointmentDate), "EEE, dd LLL")}
                    </p>
                    {item && item?.appointmentTimes.length === 0 && (
                      <p className="text-[20px] text-[#324705]font-[500] my-2">
                        : No Appointment
                      </p>
                    )}
                  </div>
                  <div className="flex justify-start flex-wrap flex-row w-full gap-y-4 my-2">
                    {item && item?.appointmentTimes.length ? (
                      item?.appointmentTimes.map((time: any, idx: number) => {
                        return (
                          <div className="cursor-pointer mr-4" key={idx}>
                            <Chip
                              label={time}
                              width={75}
                              value={time}
                              onClick={() => {
                                onTimeSlotClick({
                                  date: item.appointmentDate,
                                  time: time,
                                  providerId: data.providerId
                                });
                              }}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>
      </CustomDrawer>
    </div>
  );
};

export default SingleProviderFullProfileDrawer;
