import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useGetPatientDependents } from "../../../api/hooks/accounts/getPatientDependants";
import { Loading } from "../../shared/Loading";
import DependantListItem from "./DependantListItem";
import EditDependantDrawer from "./EditDependantDrawer";
import MobileDependantListItem from "./MobileDependantListItem";


const DependantsLists = () => {
  const [singleDependantData, setSingleDependantData] = useState<any>();
  const [isNewDependantFormVisible, setIsNewDependantFormVisible] =
    useState(false);

  const openDependantDetails = (data: any) => {
    setIsNewDependantFormVisible(true);
    setSingleDependantData(data);
  };

  const { patientDependents, isLoading, isError, error } = useGetPatientDependents();

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <Loading />
      </div>
    );
  }

  if (isError && axios.isAxiosError(error)) {
    toast.error(error.response?.data?.message || "Something went wrong");
  }

  return (
    <div className="flex flex-col justify-start lg:min-w-full lg:h-full border-[1px]  border-none rounded-lg px-0 py-2">
      <EditDependantDrawer
        open={isNewDependantFormVisible}
        onClose={() => setIsNewDependantFormVisible(false)}
        drawerTitle="Edit Dependent Details"
        dependantDetails={singleDependantData}
      />
      {patientDependents?.user?.map((listItem, index) => {
        return (
          <React.Fragment key={listItem?.id}>
            <DependantListItem
              cardData={listItem}
              onClick={() => {
                openDependantDetails(listItem);
              }}
              index={index}
            />
            <MobileDependantListItem
              cardData={listItem}
              onclick={() => {
                openDependantDetails(listItem);
              }}
              index={index}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default DependantsLists;
