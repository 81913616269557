import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import { NewPrescription } from "../../../types/prescription";
import http from "../../../utils/http";


type Payload = {
  patientId: string,
  data: NewPrescription
}

export const useAddNewPrescription = () => {
  const queryClient = useQueryClient();
  const { mutate, data, variables, reset, ...rest } = useMutation({
    mutationKey: ['new Prescription'],
    mutationFn: async ({ data, patientId }: Payload) => {
      const response = await http.post(`prescriptions/patient/${patientId}`, data)
      return response.data;
    },

    onSuccess: (schedule) => {
      toast.success("Prescription successfully added");
      queryClient.invalidateQueries({ queryKey: ['new Prescriptione'] });
    },
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        toast.error(
          error?.response?.data?.message || error.response?.statusText,
        );
      } else {
        toast.error(
          error?.message
          || "Error occured whiled adding prescription. Refresh and try again..",
        );
      }
    },
  });

  return {
    addNewPrescription: mutate,
    newPrescription: data,
    variables,
    ...rest,
  };
}