export const PlusWithCircleIcon = ({
  width = 24,
  height = 25,
  color = "#3D874E",
  className
}: {
  width?: string | number;
  height?: string | number;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 12.4004C1.25 6.46333 6.06294 1.65039 12 1.65039C17.9371 1.65039 22.75 6.46333 22.75 12.4004C22.75 18.3375 17.9371 23.1504 12 23.1504C6.06294 23.1504 1.25 18.3375 1.25 12.4004ZM12 3.15039C6.89136 3.15039 2.75 7.29175 2.75 12.4004C2.75 17.509 6.89136 21.6504 12 21.6504C17.1086 21.6504 21.25 17.509 21.25 12.4004C21.25 7.29175 17.1086 3.15039 12 3.15039Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7.65039C12.4142 7.65039 12.75 7.98618 12.75 8.40039V16.4004C12.75 16.8146 12.4142 17.1504 12 17.1504C11.5858 17.1504 11.25 16.8146 11.25 16.4004V8.40039C11.25 7.98618 11.5858 7.65039 12 7.65039Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25 12.4004C7.25 11.9862 7.58579 11.6504 8 11.6504H16C16.4142 11.6504 16.75 11.9862 16.75 12.4004C16.75 12.8146 16.4142 13.1504 16 13.1504H8C7.58579 13.1504 7.25 12.8146 7.25 12.4004Z"
        fill={color}
      />
    </svg>
  );
};
