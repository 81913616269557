import { format } from "date-fns";
import React, { useState } from "react";
import { Dependent } from "../../../api/hooks/accounts/types/dependant.type";
import DeleteDependantModal from "../../../components/MyAccount/Patient/DeleteDependantModal";
import { DeleteIcon } from "../../../svgs/DeleteIcon";
import { ReactComponent as EditIcon } from "../../../svgs/edit-pen.svg";

interface MobileDependantListItemProps {
  onclick: (arg: any) => any;
  imageUrl?: string;
  cardData: Dependent;
  index: number;
}

const MobileDependantListItem: React.FC<MobileDependantListItemProps> = ({
  cardData,
  onclick,
}) => {
  const [showDeleteDependantModal, setShowDeleteDependantModal] =
    useState(false);
  return (
    <div
      className="block lg:hidden w-full min-h-[215px] p-4 rounded-[8px] bg-white mb-4"
      style={{ cursor: "pointer", boxShadow: "-2px 4px 25px #EEEEE9" }}
    >
      <div className="w-full flex justify-start">
        <p className="text-[#103C1B] font-[500] text-[18px] break-all">
          {`${cardData?.firstName} ${cardData?.lastName}`}
        </p>
      </div>

      <div className="w-full flex items-center justify-between my-4">
        <p className="text-[#87928A] text-[14px] font-500">Date of Birth</p>
        <p className="text-[#324705] text-[14px] font-500">
          {format(new Date(cardData?.dateOfBirth), "MM/dd/yyyy")}
        </p>
      </div>

      <div className="w-full flex items-center justify-between">
        <p className="text-[#87928A] text-[14px] font-500">Email Address</p>
        <p className="text-[#324705] text-[14px] font-500 break-all">
          {cardData?.email}
        </p>
      </div>

      <div className="w-full flex items-center justify-between my-4">
        <p className="text-[#87928A] text-[14px] font-500">Gender</p>
        <p className="text-[#324705] text-[14px] font-500">
          {cardData?.gender}
        </p>
      </div>

      <div className="w-full flex justify-end items-center text-[.7rem]">
        <button
          onClick={() => {
            onclick(cardData);
          }}
          className="mr-4"
        >
          <EditIcon />
        </button>
        <button
          onClick={() => {
            setShowDeleteDependantModal(true);
          }}
        >
          <DeleteIcon className="ml-4" />
        </button>
      </div>
      <DeleteDependantModal
        isVisible={showDeleteDependantModal}
        closeModal={() => setShowDeleteDependantModal(false)}
        dependantId={cardData?.userId}
        dependantName={`${cardData?.firstName} ${cardData?.lastName}`}
      />
    </div>
  );
};

export default MobileDependantListItem;
