import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { usePostPatientLogin } from '../../api/hooks/patient/auth/usePostPatientLogin';
import { MainModal } from "../../reuseables/mainModal";
import Spacer from "../Spacer";
import PasswordField from "../form/PasswordField";
import TextField from "../form/TextField";
import Button from "../shared/Button";
import { loginFormInitialValues, loginFormSchema } from "./schema/loginForm";

const LoginForm = () => {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const [urlStatus, setUrlStatus] = useState(urlParams.get("status"));
  const [loginError, setLoginError] = useState<string>("");
  const [showEmailVerification, setShowEmailVerification] = useState<boolean>(false);
  const referrer = urlParams.get("referrer");
  const authFail = urlParams.get("authFail");

  const { patientsLogin, isPending: isLoading } = usePostPatientLogin({ setLoginError })

  const { values, errors, handleChange, touched, submitForm } = useFormik({
    initialValues: loginFormInitialValues,
    validationSchema: loginFormSchema,
    onSubmit: () => {
      const { email, password } = values
      setLoginError("");
      patientsLogin({
        email,
        password
      });
    }
  });

  const handleCloseEmailVerification = () => {
    setUrlStatus("");
    setShowEmailVerification(false);
  };

  useEffect(() => {
    if (urlStatus) {
      if (urlStatus === "email-verified") {
        setShowEmailVerification(true);
      }
    }
  }, [urlStatus, showEmailVerification]);

  return (
    <div className="w-full sm:w-[70%] md:w-[60%] lg:w-[40%] bg-white px-5 lg:px-0">
      <div className="text-center">
        <p className="text-[34px] font-[800] text-[#103C1B]">Welcome back</p>
        <Spacer height={10} />
        {referrer === "appointment" ? (
          <p>Login to continue your booking</p>
        ) : authFail === "true" ? (
            <p className="bg-[#fdf6f6] text-[#c20707] border-[#E45B3D] border-[1px] p-[10px] text-[18px] rounded-md my-10">
            Your session has expired. Please, log in again
            </p>
        ) : (
          <p className="font-[400] text-[20px] text-[#103C1B]">
            Enter your login details to continue
          </p>
        )}
      </div>
      <Spacer height={40} />
      <div className="flex flex-col mx-auto items-center w-full">
        {loginError?.length > 0 && (
          <p className="bg-[#fdf6f6] text-[#c20707] border-[#E45B3D] border-[1px] p-[10px] text-[18px] rounded-md mb-4">
            Login failed: {loginError}
          </p>
        )}
        <form className="relative flex flex-col items-center w-full lg:w-[478px]">
          <TextField
            name="email"
            type="email"
            placeholder="name@mail.com"
            value={values.email}
            onChange={handleChange}
            label="Email address"
            error={touched.email ? errors.email : ""}
          />

          <Spacer height={25} />

          <PasswordField
            name="password"
            placeholder="At least 8 characters"
            value={values.password}
            onChange={handleChange}
            label="Password"
            showPasswordToggler={true}
            error={touched.password ? errors.password : ""}
          />
          <div className="w-[100%] flex justify-between items-center mt-8 text-xs text-normal">
            <Link
              to="/forgot-password"
              className="text-[18px] font-[400] flex flex-start text-[#1A9D39]"
            >
              Forgot password?
            </Link>
            <div className="flex flex-end">
              <Button
                onClick={submitForm}
                additionalClassname={
                  "text-[18px] font-[400] w-[108px] relative"
                }
                width={108}
                height={60}
                variant="primary"
                label={isLoading ? "Signing In..." : "Sign in"}
                disabled={isLoading}
                size="medium"
              />
            </div>
          </div>
          <div className="lg:h-[80px] h-[40px]" />
          <div className="border-[1px] border-gray-200 w-[300px]" />
          <div className="lg:h-[80px] h-[40px]" />
          <p className="text-center font-[400]">
            Don't have an account?{" "}
            <span className="text-[#1A9D39]">
              {" "}
              <Link to="/patient/signup"> Create an account</Link>
            </span>
          </p>
          <div className="lg:h-[20px] hidden lg:block" />
        </form>
      </div>
      <MainModal
        open={showEmailVerification}
        onClose={() => setShowEmailVerification(false)}
        closeAfterTransition={false}
        title="Email Verified Successfully, Please log in"
        onOk={() => handleCloseEmailVerification()}
      />
    </div>
  );
};

export default LoginForm;
