import { useQuery } from '@tanstack/react-query';
import { InsuranceCredentialing, InsuranceCredentialingResponse } from '../../../admin/types/insurance_credentialing';
import adminHttp from '../../../utils/adminHttp';
import { ErrorResponse } from '../../types';

export function useGeInsuranceCredentialing() {
  const { data, isLoading, ...rest } = useQuery<InsuranceCredentialingResponse, ErrorResponse>({
    queryKey: ["admin insurance credentialing"],
    queryFn: async () => {
      const response = await adminHttp.get(`/credentialing/all`);
      return response.data;
    },
    staleTime: Infinity,
  });
  return {
    insuranceCredelingData: data?.credentialingRequests as InsuranceCredentialing[],
    isLoading,
    ...rest,
  }
}