import AuthLayout from "../../components/auth/AuthLayout";
import SPLoginForm from "../components/SPLoginForm";

const SPLoginPage = () => {
  return (
    <AuthLayout>
      <SPLoginForm />
    </AuthLayout>
  );
};

export default SPLoginPage;
