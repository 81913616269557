export const OverviewPatientsIcon = ({
  width = 90,
  height = 90,
  color = "#0863B8",
  className
}: {
  height?: number;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={height/2} cy={height/2} r={height/2} fill="#DCEEFF" />
      <g clip-path="url(#clip0_4495_16024)">
        <path
          d="M30.0199 56.9655H51.6195C52.2338 56.9658 52.8421 56.845 53.4096 56.6101C53.9772 56.3752 54.4929 56.0307 54.9272 55.5963C55.3616 55.162 55.706 54.6463 55.941 54.0787C56.1759 53.5112 56.2967 52.9029 56.2964 52.2886V47.2425L58.1426 46.2579C58.8653 45.8687 59.4697 45.2917 59.8921 44.5878C60.3144 43.8839 60.539 43.0791 60.5422 42.2582V34.0725C60.5422 33.7033 60.2961 33.4572 59.9268 33.4572H51.4345C51.0653 33.4572 50.8191 33.7033 50.8191 34.0725V42.2582C50.8224 43.0793 51.0471 43.8842 51.4695 44.5883C51.892 45.2923 52.4965 45.8694 53.2195 46.2586L55.0657 47.2432V52.2893C55.0663 52.7421 54.9776 53.1905 54.8047 53.6089C54.6317 54.0273 54.3779 54.4074 54.0577 54.7276C53.7376 55.0477 53.3575 55.3015 52.9391 55.4745C52.5207 55.6474 52.0722 55.7361 51.6195 55.7355H51.0041V55.3663C51.0041 50.4432 47.681 46.2586 43.1891 44.9659C44.9741 43.9194 46.2048 42.0121 46.2048 39.7967C46.2026 38.2141 45.5729 36.697 44.4539 35.578C43.3349 34.459 41.8178 33.8293 40.2352 33.8271C36.9733 33.8271 34.2657 36.5348 34.2657 39.7967C34.2657 42.0121 35.4964 43.9194 37.2814 44.9659C32.7887 46.2579 29.4664 50.4425 29.4664 55.3663V56.3509C29.4052 56.6582 29.6513 56.9663 30.0206 56.9663L30.0199 56.9655ZM52.0506 42.2582V34.6893H59.251V42.2582C59.2535 42.8513 59.0944 43.4338 58.7908 43.9433C58.4873 44.4528 58.0507 44.87 57.5279 45.1502L55.6817 46.1348L53.8356 45.1502C53.3023 44.8772 52.8539 44.4634 52.5394 43.9535C52.2248 43.4436 52.056 42.8573 52.0513 42.2582H52.0506ZM35.4352 39.7967C35.4358 38.5402 35.9351 37.3352 36.8236 36.4467C37.712 35.5581 38.9168 35.0586 40.1733 35.0579C42.7587 35.0579 44.9122 37.2121 44.9122 39.7967C44.9122 42.3813 42.7587 44.5355 40.1733 44.5355C38.9168 44.5347 37.712 44.0352 36.8236 43.1467C35.9351 42.2581 35.4358 41.0532 35.4352 39.7967ZM30.6352 55.3663C30.6352 50.0739 34.9429 45.7663 40.2352 45.7663C45.5275 45.7663 49.8352 50.0739 49.8352 55.3663V55.7355H30.6352V55.3663Z"
          fill="#0863B8"
          stroke="#0863B8"
          strokeWidth="0.620408"
          strokeMiterlimit="10"
        />
        <path
          d="M56.2351 41.9501V40.4732H57.712C58.0813 40.4732 58.3274 40.2271 58.3274 39.8578C58.3274 39.4886 58.0813 39.2425 57.712 39.2425H56.2351V37.7655C56.2351 37.3963 55.989 37.1501 55.6197 37.1501C55.2505 37.1501 55.0044 37.3963 55.0044 37.7655V39.2425H53.5274C53.1582 39.2425 52.912 39.4886 52.912 39.8578C52.912 40.2271 53.1582 40.4732 53.5274 40.4732H55.0044V41.9501C55.0044 42.3194 55.2505 42.5655 55.6197 42.5655C55.7828 42.5652 55.9391 42.5002 56.0545 42.3849C56.1698 42.2696 56.2348 42.1132 56.2351 41.9501Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_4495_16024">
          <rect
            width="32"
            height="24.422"
            fill="white"
            transform="translate(29 33)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
