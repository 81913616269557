import React, { useMemo, useState } from "react";
import { Column } from "react-table";
import { useGetBillerProvidersTableList } from "../../api/hooks/biller/useGetBillersProviderTableList";
import { useSearchProviderForBillers } from '../../api/hooks/biller/useSearchProvidersForBillers';
import { useDeleteProviderFromBillerList } from "../../api/hooks/biller/userDeleteProviderFromBillersList";
import { usePostProviderBillerList } from "../../api/hooks/biller/userPostProviderToBillersList";
import ReusableTable from "../../components/ReusableTable";
import Button from "../../components/shared/Button";
import { ArrowBackIcon } from "../../svgs/ArrowBackIcon";
import SPDashboardLayout from "../components/SPDashboardLayout";
import SPNewProviderModal from "../components/SPNewProviderModal";
import SPProviderActionCell from "../components/SPProviderActionCell";
import SingleProviderDetails from "../components/SingleProviderDetails";
import SingleSearchedProvider from "../components/SingleSearchedProvider";

interface TableType {
  userId: string;
  firstName: string;
  lastName: string;
  specialty: string;
  title: string;
  email: string;
  phoneNumber: string;
}

function SPProvidersPage() {
  const [isProviderDetailsView, setIsProviderDetailsView] =
    useState<boolean>(false);
  const [isAddProviderView, setIsAddProviderView] = useState<boolean>(false);
  const [isAddProviderModalView, setIsAddProviderModalView] =
    useState<boolean>(false);
  const [singleBillerProviderInfo, setSingleBillerProviderInfo]: any =
    useState(null);

  const { allProviders } = useGetBillerProvidersTableList()
  const { addProviderToBillersList } = usePostProviderBillerList()
  const { removeProviderFromBillersList } = useDeleteProviderFromBillerList()
  const { searchedProvidersList, refetch } = useSearchProviderForBillers()

  const handleGetSingleBillersProvider = (data: any) => {
    setSingleBillerProviderInfo(data);
  };

  const TABLE_COLUMNS = useMemo<Column<TableType>[]>(
    () => [
      {
        Header: "Name",
        accessor: "firstName",
        Cell: ({ cell: { value }, row }) => {
          return (
            <React.Fragment>
              {" "}
              {`${row.original.firstName}
          ${row.original.lastName}`}
            </React.Fragment>
          );
        }
      },
      {
        Header: "Specialty",
        accessor: "specialty",
        Cell: ({ cell: { value }, row }) => {
          return <React.Fragment>{value}</React.Fragment>;
        }
      },
      {
        Header: "Title",
        accessor: "title"
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ cell: { value } }) => {
          return <React.Fragment>{value || "N/A"}</React.Fragment>;
        }
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
        Cell: ({ cell: { value } }) => {
          return <React.Fragment>{value || "N/A"}</React.Fragment>;
        }
      },
      {
        Header: "Action",
        accessor: "userId",
        Cell: ({ cell: { value }, row }) => {
          return (
            <SPProviderActionCell
              viewProvider={() => {
                handleGetSingleBillersProvider(row?.original);
                setIsProviderDetailsView(true);
              }}
              removeProvider={() => {
                removeProviderFromBillersList({ id: value });
              }}
            />
          );
        }
      }
    ],
    []
  );

  return (
    <SPDashboardLayout headerTitle="Providers">
      {isProviderDetailsView ? (
        <div className="w-full h-full px-4 mt-6">
          <div className="flex items-center justify-between w-full">
            <div
              onClick={() => setIsProviderDetailsView(false)}
              className="w-full cursor-pointer pl-4 flex justify-start items-center"
            >
              <ArrowBackIcon /> <div className="ml-3">Go back to Providers</div>
            </div>
            <div className="w-full flex items-center justify-end">
              <div className="w-[140px]">
                <Button
                  label="Remove Provider"
                  size="medium"
                  variant="secondary"
                  onClick={() => {
                    removeProviderFromBillersList({
                      id: singleBillerProviderInfo?.userId
                    });
                    setIsProviderDetailsView(false);
                  }}
                />
              </div>
            </div>
          </div>
          <SingleProviderDetails
            providerInfo={singleBillerProviderInfo && singleBillerProviderInfo}
          />
        </div>
      ) : (
        <>
          {isAddProviderView ? (
            <>
              <div className="w-full h-full px-4 mt-6">
                <div className="flex items-center justify-between w-full">
                  <div
                    onClick={() => setIsAddProviderView(false)}
                    className="w-full cursor-pointer pl-2 flex justify-start items-center"
                  >
                    <ArrowBackIcon /> <div className="ml-2">Go back</div>
                  </div>
                  <div className="w-full flex justify-end">
                    <Button
                      label="Add a new provider manually"
                      size="large"
                      variant="primary"
                        onClick={() =>

                          setIsAddProviderModalView(true)

                        }
                    />
                  </div>
                </div>
                <div className="my-6 w-full flex justify-between items-baseline">
                  <div className="w-[435px]">
                    <p className="text-[40px] text-[#103C1B] font-[500] py-4">
                      Find a provider
                    </p>
                    <p className="text-[18px] text-[#6E8877]">
                      By adding providers to your billing portal, you will be
                      able to manage their billing information efficiently and
                      accurately process their claims
                    </p>
                  </div>
                  <div>
                      {
                        searchedProvidersList?.map((value) => (
                          <div className="w-full h-full flex flex-col gap-4 py-1" key={value?.userId}>
                          <SingleSearchedProvider
                              addAction={() => {
                                addProviderToBillersList({ providerId: value?.userId });
                              setIsAddProviderView(false);
                            }}
                            providerInfo={value}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <SPNewProviderModal
                isVisible={isAddProviderModalView}
                closeModal={() => setIsAddProviderModalView(false)}
                saveAction={() => {
                  // addProviderToBillersList({ providerId: value?.userId });
                  setIsAddProviderModalView(false);
                  setIsAddProviderView(false);

                }}
              />
            </>
          ) : (
            <div className="w-full">
              <div className="flex w-full justify-end pr-4">
                <div className="w-[188px]">
                  <Button
                    variant="primary"
                    label="Add Provider"
                    size="medium"
                    onClick={() => {
                      refetch();
                      setIsAddProviderView(true);
                    }}
                  />
                </div>
              </div>
              <ReusableTable
                tableColumns={TABLE_COLUMNS}
                    tableData={allProviders ?? []}
                    onClick={data => { }}
                pageSizeCount={10}
                searchFieldPlaceholder="Search providers"
              />
            </div>
          )}
        </>
      )}
    </SPDashboardLayout>
  );
}

export default SPProvidersPage;
