import React from "react";
import CustomDrawer from "../drawer";

interface ProviderProfileDrawerProps {
  userDetails: any | null;
  drawerTitle: string;
  onClose: () => void;
  open: boolean;
}

const ProviderProfileDrawer: React.FC<ProviderProfileDrawerProps> = ({
  userDetails,
  drawerTitle,
  onClose,
  open
}) => {
  return (
    <CustomDrawer
      open={open}
      onClose={onClose}
      drawerTitle={
        userDetails?.firstName
          ? `${userDetails?.firstName} ${userDetails?.lastName}`
          : userDetails?.name
      }
      noWidth
    >
      <div className="z-9999 max-w-[301px] min-w-[300px] lg:min-w-[482px] max-h-[100vh]">
        <div className="p-2 ml-0 mr-0">
          <div className="py-4 flex flex-col justify-start mx-auto text-[12px] font-[500]">
            <div className="w-full flex justify-center items-center">
              <img
                className="w-[100px] h-[100px] rounded-full cursor-pointer"
                src={userDetails?.avatarUrl || "/assets/images/avatar.png"}
                alt=""
              />
            </div>
            <p className="text-[12px] font-[400] text-[#103C1B] pb-2 text-left">
              {userDetails && userDetails?.about !== "" && userDetails?.about}
            </p>

            <p className="text-[12px] font-[400] text-[#87928A] pb-2 text-left">
              Languages
            </p>
            <div className="flex items-center justify-start">
              {userDetails?.languages &&
                userDetails?.languages.length &&
                userDetails?.languages?.map((language: string, idx: number) => (
                  <p
                    key={idx}
                    className="text-[12px] font-[400] text-[#103C1B] pb-4 text-left pr-2"
                  >
                    {language},
                  </p>
                ))}
            </div>

            <p className="text-[12px] font-[400] text-[#87928A] pb-2 text-left">
              Expertise
            </p>
            <div className="flex items-center justify-start">
              {userDetails?.expertise &&
                userDetails?.expertise.length &&
                userDetails?.expertise?.map((e: string, idx: number) => (
                  <p
                    key={idx}
                    className="text-[12px] font-[400] text-[#103C1B] pb-4 text-left pr-2"
                  >
                    {e},
                  </p>
                ))}
            </div>
            <p className="text-[12px] font-[400] text-[#87928A] pb-2 text-left">
              Qualifications
            </p>
            <div className="flex items-center justify-start">
              {userDetails?.qualifications &&
                userDetails?.qualifications.length &&
                userDetails?.qualifications?.map((q: string, idx: number) => (
                  <p
                    key={idx}
                    className="text-[12px] font-[400] text-[#103C1B] pb-4 text-left pr-2"
                  >
                    {q},
                  </p>
                ))}
            </div>
          </div>
        </div>
      </div>
    </CustomDrawer>
  );
};

export default ProviderProfileDrawer;
