import clsx from "clsx";

import Spacer from "../Spacer";

interface MenuItemProps {
  isActive: boolean;
  icon: React.ReactNode;
  label: string;
  onClick?: (arg: any) => void;
}

const MenuItem = ({
  isActive,
  icon,
  label,
  onClick
}: MenuItemProps) => (
  <li
    className={clsx({
      "flex items-center text-black lg:text-white justify-start pl-4  mx-auto mb-[20px] w-full h-[40px] cursor-pointer ":
        true,
      "text-[#71B882]": isActive
    })}
    onClick={onClick}
  >
    {icon}
    <Spacer width={10} />
    <div
      className={clsx({
        "text-medium font-normal leading-normal text-black lg:text-white":
          true,
        "text-[#71B882] font-[500] lg:text-[#ABE9BA]": isActive
      })}
      data-toggle="menu"
    >
      {label}
    </div>
  </li>
);

export default MenuItem;
