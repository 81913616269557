import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { FormTypes } from "../../../provider/pages/Settings/Templates";
import http from "../../../utils/http";


type Payload = {
  formType: FormTypes;
  data: any;
}

export const useCreateForm = () => {
  const [noteType, setNoteType] = useState<string>('')
  const queryClient = useQueryClient();
  const { mutate, data, variables, reset, ...rest } = useMutation({
    mutationKey: ['create template forms'],
    mutationFn: async ({ data, formType }: Payload) => {
      setNoteType(formType)
      const response = await http.post(`notes?noteType=${formType}`, data)
      return response.data;
    },

    onMutate: async () => {
      const toastId = toast.loading("creating form...");
      return { toastId }
    },

    onSuccess: (_data, _variables, context) => {
      const { toastId } = context;
      toast.update(toastId, {
        render: "form successfully created!",
        isLoading: false,
        autoClose: 5000,
        type: "success"
      })
      queryClient.invalidateQueries({
        queryKey: [`get provider ${noteType}`]
      });
    },

    onError: (error, _variables, context) => {
      const toastId = context?.toastId as number;

      if (axios.isAxiosError(error)) {
        toast.update(toastId, {
          render: error?.response?.data?.error || error.response?.statusText,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        })
      } else {
        toast.update(toastId, {
          render: error?.message || "Error creating template form",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        })
      }
    },
  });

  return {
    createForm: mutate,
    data,
    variables,
    ...rest,
  };
}
