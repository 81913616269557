import ModalPortal from "../../components/shared/ModalPortal";
import { CancelIcon } from "../../svgs/CancelIcon";

interface IProps {
  closeModal: () => void;
  isVisible: boolean;
}
const SPWelcomeModal: React.FC<IProps> = ({
  closeModal,
  isVisible = false
}) => {
  return isVisible ? (
    <ModalPortal>
      <div
        style={{
          backgroundColor: "rgba(38, 38, 38, 0.75)",
          zIndex: 100,
          display: "flex",
          justifyContent: "center"
        }}
        className="w-screen h-screen fixed flex flex-col justify-center items-center top-0 bg-opacity-20 backdrop-blur-[5px]"
      >
        <div className="flex justify-end  w-[90%] lg:w-[780px] mx-auto py-2">
          <div
            onClick={() => {
              closeModal();
            }}
            className="p-2 bg-white rounded-full cursor-pointer"
          >
            <CancelIcon className="h-full w-full" />
          </div>
        </div>
        <div className="bg-white h-[624px] w-[90%] lg:w-[780px] p-10 shadow-lg rounded-[16px] border-box">
          <p className="text-[#2E3011] text-[24px] font-[600] py-6">
            Welcome to TempleHS!
          </p>
          <p className="py-6 text-[#2E3011] text-[18px]">
            We are excited to have you as a user and to provide you with the
            tools you need to streamline your medical billing process. Our
            platform is designed to make it easy for you to manage your billing
            and claims, and to stay compliant with the latest regulations.
          </p>
          <p className="py-2 text-[#2E3011] text-[18px]">
            With our platform, you can:
          </p>
          <ul className="text-[#2E3011] text-[18px] list-disc">
            <li>Submit claims and track their status</li>
            <li>Generate detailed reports and analytics</li>
            <li>Manage patient information and billing history</li>
            <li>Access a library of coding resources and guidelines</li>
            <li>
              Stay up-to-date with the latest medical billing and coding news
            </li>
            <li>And more</li>
          </ul>
        </div>
      </div>
    </ModalPortal>
  ) : null;
};

export default SPWelcomeModal;
