import axios, {
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig
} from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../constants/base_url";

const adminHttp: AxiosInstance = axios.create({
  baseURL: API_URL
});

adminHttp.interceptors.request.use(
  (config: InternalAxiosRequestConfig<any>) => {
    const token = localStorage.getItem("temple_admin_token");
    if (token) {
      config.headers = config.headers || {};
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: any) => {
    if (error.response && error.response.status === 401) {
      toast.error("Your sessions has expired, please login again", {
        toastId: "customId"
      });
      localStorage.removeItem("temple_admin_token");
      window.location.href = `/admin/login?authFail=true`;
    }

    return Promise.reject(error);
  }
);

adminHttp.interceptors.response.use(
  function (response: AxiosResponse<any, any>) {
    return response;
  },
  function (error: any) {
    if (error.response.status === 401) {
      toast.error("Your sessions has expired, please login again", {
        toastId: "customId"
      });
      localStorage.removeItem("temple_admin_token");
      window.location.href = `/admin/login?authFail=true`;
    }

    return Promise.reject(error);
  }
);

export const setAdminToken = (token: string) => {
  if (!token) {
    delete adminHttp.defaults.headers.common.Authorization;
  }
  localStorage.setItem("temple_admin_token", token);
  adminHttp.defaults.headers["temple_admin_token"] = token;
  adminHttp.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export default adminHttp;
