import { useQuery } from '@tanstack/react-query';
import { BillerResponseData, ErrorResponse } from '../../types';
import billerHttp from './../../../utils/billerHttp';

export function useGeBillerProfileDetails() {
  const { data, isLoading, ...rest } = useQuery<BillerResponseData, ErrorResponse>({
    queryKey: ["get biller profile details"],
    queryFn: async () => {
      const response = await billerHttp.get(`billers/details`);
      return response.data;
    },
    staleTime: Infinity,
  });
  return {
    billerProfileData: data as BillerResponseData,
    isLoading,
    ...rest,
  }
}