import { useFormik } from "formik";
import React from "react";
import DatePicker from "react-datepicker";
import { useCreateEmergencyContact } from "../../../api/hooks/accounts/createEmergencyContact";
import { removeEmptyObjectValues } from "../../../utils/helpers";
import CustomDrawer from "../../drawer";
import RadioField from "../../form/RadioField";
import TextField from "../../form/TextField";
import Button from "../../shared/Button";
import { emergencyContactSchema, initialEmergencyContactValues } from "../schema/data.schema";

interface AddEmergencyContactDrawerProps {
  drawerTitle: string;
  onClose: () => void;
  open: boolean;
}

const AddEmergencyContactDrawer: React.FC<AddEmergencyContactDrawerProps> = ({
  drawerTitle,
  onClose,
  open,
}) => {

  const { createEmergencyContact, isPending } = useCreateEmergencyContact();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialEmergencyContactValues,
    validationSchema: emergencyContactSchema,
    onSubmit: async () => {
      const fullName = formik?.values?.firstName.concat(
        " ",
        formik?.values?.lastName
      );
      const formattedData = removeEmptyObjectValues({
        name: fullName,
        email: formik.values.email,
        phoneNumber: formik.values.phoneNumber,
        gender: formik.values.gender,
        dateOfBirth: formik.values.dateOfBirth,
        relationship: formik.values.relationship
      });
      createEmergencyContact({
        name: fullName,
        email: formattedData?.email,
        phoneNumber: formattedData?.phoneNumber,
        gender: formattedData?.gender,
        dateOfBirth: formattedData?.dateOfBirth,
        relationship: formattedData?.relationship
      }, {
        onSuccess: () => {
          formik.resetForm();
          onClose();
        }
      });
    }
  });

  return (
    <div className="w-full lg:w-[600px]">
      <CustomDrawer
        noWidth
        open={open}
        onClose={onClose}
        drawerTitle={drawerTitle}
      >
        <div className="max-h-[100vh] flex flex-col overflow-y-auto px-8">
          <form onSubmit={formik.handleSubmit}>
            <div className="w-full flex flex-row items-center justify-between my-4">
              <div className="form-item-data">
                <TextField
                  name="firstName"
                  type="text"
                  placeholder="First name"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  label="First Name"
                  error={
                    formik.touched.firstName ? formik.errors.firstName : ""
                  }
                />
              </div>
              <div className="w-2" />
              <div className="form-item-data">
                <TextField
                  name="lastName"
                  type="text"
                  placeholder="Last name"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  label="Last Name"
                  error={formik.touched.lastName ? formik.errors.lastName : ""}
                />
              </div>
            </div>
            <div className="form-item-grid">
              <div className="form-item-text">Gender</div>
              <div className="flex flex-row items-center justify-start">
                <div className="form-item-data">
                  <RadioField
                    name="gender"
                    placeholder="Gender"
                    display="inline"
                    options={[
                      { item: "Male", value: "male" },
                      { item: "Female", value: "female" }
                    ]}
                    hasOther={true}
                    isRequired={true}
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="my-4">
              <div className="form-item-text">Email</div>
              <div className="flex flex-row items-center justify-end">
                <div className="w-full">
                  <TextField
                    name="email"
                    type="email"
                    placeholder="Enter email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email ? formik.errors.email : ""}
                  />
                </div>
              </div>
            </div>
            <div className="my-4">
              <div className="form-item-text">Phone Number</div>
              <div className="flex flex-row items-center justify-end">
                <div className="w-full">
                  <TextField
                    name="phoneNumber"
                    type="tel"
                    placeholder="Enter phone"
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.phoneNumber
                        ? formik.errors.phoneNumber
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
            <div className="my-4">
              <div className="form-item-text">Date Of Birth</div>
              <div className="flex flex-row items-center justify-end">
                <div className="w-full bg-white border border-[#CECFC5]">
                  <DatePicker
                    selected={formik.values.dateOfBirth || ""}
                    name="dateOfBirth"
                    dateFormat="MM/dd/yyyy"
                    onChange={(date: Date) => {
                      formik.setFieldValue("dateOfBirth", date);
                    }}
                    placeholderText="MM/DD/YYYY"
                    className="date-picker"
                    forceShowMonthNavigation={true}
                    showYearDropdown={true}
                    showMonthDropdown={true}
                    scrollableMonthYearDropdown={true}
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown={true}
                    shouldCloseOnSelect={true}
                    required
                  />
                  <>
                    {formik.touched.dateOfBirth &&
                      formik.errors.dateOfBirth && (
                        <p className="mt-2 text-xs text-red-600 capitalize">
                          Date of birth is required
                        </p>
                      )}
                  </>
                </div>
              </div>
            </div>
            <div className="my-4">
              <div className="form-item-text">Relationship</div>
              <div className="flex flex-row items-center justify-end">
                <div className="w-full">
                  <TextField
                    name="relationship"
                    type="text"
                    placeholder="Relationship"
                    value={formik.values.relationship}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.relationship
                        ? formik.errors.relationship
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex justify-end my-4">
              <Button
                height={60}
                loading={isPending}
                onClick={formik.submitForm}
                variant="primary"
                label="Save"
                size="medium"
                disabled={isPending}
              />
            </div>
          </form>
        </div>
      </CustomDrawer>
    </div>
  );
};

export default AddEmergencyContactDrawer;
