import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { io } from "socket.io-client";
import AdminBusinessOffice from "./admin/pages/AdminBusiness_Office";
import AdminCompliants from "./admin/pages/AdminCompliants";
import AdminLogin from "./admin/pages/AdminLogin";
import AdminOverview from "./admin/pages/AdminOverview";
import AdminPatients from "./admin/pages/AdminPatients";
import AdminProviders from "./admin/pages/AdminProviders";
import AdminReports from "./admin/pages/AdminReports";
import AdminServiceProviders from "./admin/pages/AdminServiceProviders";
import AdminSetPassword from "./admin/pages/AdminSetPassword";
import AdminUsers from "./admin/pages/AdminUsers";
import { RequireBillerAuth } from './biller/components/auth/RequireBillerAuth';
import SPAllClaims from "./biller/pages/SPAllClaims";
import SPClaimsReview from "./biller/pages/SPClaimsReview";
import SPERAs from "./biller/pages/SPERAs";
import SPForgotPassword from "./biller/pages/SPForgotPassword";
import SPLoginPage from "./biller/pages/SPLoginPage";
import SPMainDashboard from "./biller/pages/SPMainDashboard";
import SPMainRegistrationPage from "./biller/pages/SPMainRegistrationPage";
import SPProvidersPage from "./biller/pages/SPProvidersPage";
import SPResetPassword from "./biller/pages/SPResetPassword";
import SPSettings from "./biller/pages/SPSettings";
import SPSignupPage from "./biller/pages/SPSignupPage";
import SPVerifyEmail from "./biller/pages/SPVerifyEmail";
import { RequireAuth } from "./components/auth/RequireAuth";
import SelectMentalhealthProvider from "./components/dashboard/scheduleAppointment/SelectMentalHealthProvider";
import { API_URL, SOCKET_API_URL } from "./constants/base_url";
import FallBackError from "./errors/FallbackError";
import Appointments from "./patient/Appointments";
import Checkout from "./patient/Checkout";
import Dashboard from "./patient/Dashboard";
import ForgotPassword from "./patient/ForgotPassword";
import LoginPage from "./patient/LoginPage";
import MentalHealthVisit from "./patient/MentalHealthVisit";
import Messages from "./patient/Messages";
import MinorsRegistration from "./patient/MinorsRegistration";
import MyAccount from "./patient/MyAccount";
import NotFound from "./patient/NotFound";
import PersonalRegistrationPage from "./patient/PersonalRegistrationPage";
import Prescriptions from "./patient/Prescriptions";
import Signup from "./patient/RegisterUser";
import ResetPassword from "./patient/ResetPassword";
import { ScheduleDepVisit } from "./patient/ScheduleDepVisit";
import { ScheduleVisitInsurance } from "./patient/ScheduleVisitInsurance";
import SignupSuccess from "./patient/SignupSuccess";
import VerifyEmail from "./patient/VerifyEmail";
import PracticeRegCheckout from './provider/components/PracticeRegCheckout';
import { default as ProfessionalDetails, default as ProviderCheckout } from "./provider/components/ProfessionalDetails";
import ProviderForgotPassword from "./provider/components/ProviderForgotPassword";
import ProviderResetPassword from "./provider/components/ProviderResetPassword";
import { RequireProviderAuth } from './provider/components/auth/RequireProviderAuth';
import ProviderDashboardOverview from "./provider/components/overview";
import SignInPage from "./provider/components/signin";
import ProviderCreateAccount from "./provider/components/signup";
import ProviderAppointments from "./provider/pages/Appointments";
import ProviderMessages from "./provider/pages/Messages";
import ProviderPatientsDetail from "./provider/pages/PatientDetail";
import ProviderPatients from "./provider/pages/Patients";
import PaymentAndBilling from "./provider/pages/PaymentAndBilling/PaymentAndBilling";
import ProviderSubscription from "./provider/pages/ProviderSubscription";
import ProviderSettings from "./provider/pages/Settings";
import ProviderTemplates from "./provider/pages/Templates";
import BusinessOffice from "./provider/pages/business-office/BusinessOffice";
import AdminAuthRoute from "./utils/AdminAuthRoute";

const socket = io(SOCKET_API_URL);

const App = () => {
  const { reset } = useQueryErrorResetBoundary();

  useEffect(() => {
    const savedPayers = localStorage.getItem("insurancePayers");

    if (!savedPayers || savedPayers === "undefined") {
      const rawData = fetch(`${API_URL}/billers/payers`);
      rawData.then(data =>
        data.json().then(res => {
          localStorage.setItem(
            "insurancePayers",
            JSON.stringify(res?.payers?.payer)
          );
        })
      );
    }
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/patient/login" />} />
      <Route path="patient/login" element={<LoginPage />} />
      {/* <Route 
        path='/'
        hasErrorBoundary
        element={
          <ErrorBoundary FallbackComponent={FallBackError}>
            {/* <ProtectedRoute element={<Layout />} /> */}
      {/* <Layout />
          </ErrorBoundary> */}
      {/* } */}
      {/* >  */}
      <Route
        path="/dashboard"
        element={
          <RequireAuth >
            <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
              <Dashboard />
            </ErrorBoundary>
          </RequireAuth>
        }
      />

      <Route
        path="/mental-health-provider"
        element={
          <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
            <SelectMentalhealthProvider />
          </ErrorBoundary>
        }
      />
      <Route
        path="/appointments"
        element={
          <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
            <RequireAuth>
              <Appointments />
            </RequireAuth>
          </ErrorBoundary>
        }
      />
      <Route path="/checkout/success" element={<Checkout />} />
      <Route
        path="/provider/checkout/business-office/success"
        element={
          <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
            <PracticeRegCheckout />
          </ErrorBoundary>
        }
      />
      <Route
        path="/messages"
        element={
          <RequireAuth >
            <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
              <Messages socket={socket} />
            </ErrorBoundary>
          </RequireAuth>
        }
      />
      <Route
        path="/prescriptions"
        element={
          <RequireAuth >
            <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
              <Prescriptions />
            </ErrorBoundary>
          </RequireAuth>

        }
      />
      <Route
        path="/my-account"
        element={
          <RequireAuth >
            <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
              <MyAccount />
            </ErrorBoundary>
          </RequireAuth>
        }
      />

      <Route path="/patient/signup" element={<Signup />} />
      <Route
        path="/:userType/verify-email/:userId/:email"
        element={<VerifyEmail />}
      />
      <Route path="/signup-success" element={<SignupSuccess />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route
        path="/patient/reset-password/:id/:token"
        element={<ResetPassword />}
      />
      <Route path="/signup-minor" element={<MinorsRegistration />} />
      <Route
        path="/registration/personal"
        element={<PersonalRegistrationPage />}
      />
      <Route
        path="/dashboard/dependent/visit/schedule"
        element={
          <RequireAuth>
            <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
              <ScheduleDepVisit />
            </ErrorBoundary>
          </RequireAuth>
        }
      />
      <Route
        path="/dashboard/dependent/visit/schedule/:depId"
        element={
          <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
            <ScheduleVisitInsurance />
          </ErrorBoundary>
        }
      />
      <Route
        path="/dashboard/dependent/visit/schedule/health"
        element={
          <RequireAuth>
            <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
              <MentalHealthVisit />
            </ErrorBoundary>
          </RequireAuth>
        }
      />

      <Route path="/provider/signup" element={<ProviderCreateAccount />} />
      <Route path="/provider/login" element={<SignInPage />} />
      <Route
        path="/provider/forgot-password"
        element={<ProviderForgotPassword />}
      />
      <Route
        path="/provider/reset-password/:id/:token"
        element={<ProviderResetPassword />}
      />
      <Route
        path="/provider/professionalDetails"
        element={
          <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
            <ProfessionalDetails />
          </ErrorBoundary>
        }
      />
      <Route
        path="/provider/dashboard"
        element={
          <RequireProviderAuth>
            <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
              <ProviderDashboardOverview />
            </ErrorBoundary>
          </RequireProviderAuth>
        }
      />
      <Route
        path="/provider/subscription"
        element={
          <RequireProviderAuth>
            <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
              <ProviderSubscription />
            </ErrorBoundary>
          </RequireProviderAuth>

        }
      />
      <Route
        path="/provider/subscription/success"
        element={
          <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
            <ProviderCheckout />
          </ErrorBoundary>
        }
      />
      <Route
        path="/provider/messages"
        element={
          <RequireProviderAuth>
            <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
              <ProviderMessages socket={socket} />
            </ErrorBoundary>
          </RequireProviderAuth>
        }
      >
        <Route path=":patientId" element={
          <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
            <ProviderMessages socket={socket} />
          </ErrorBoundary>
        } />
      </Route>
      <Route
        path="/provider/appointments"
        element={
          <RequireProviderAuth>
            <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
              <ProviderAppointments />
            </ErrorBoundary>
          </RequireProviderAuth>
        }
      />
      <Route
        path="/provider/templates"
        element={
          <RequireProviderAuth>
            <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
              <ProviderTemplates />
            </ErrorBoundary>
          </RequireProviderAuth>
        }
      />
      <Route
        path="/provider/settings"
        element={
          <RequireProviderAuth>
            <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
              <ProviderSettings />
            </ErrorBoundary>
          </RequireProviderAuth>
        }
      />
      <Route
        path="/provider/business-office"
        element={
          <RequireProviderAuth>
            <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
              <BusinessOffice />
            </ErrorBoundary>
          </RequireProviderAuth>
        }
      />
      {/* ADMIN ROUTES STARTS*/}
      <Route path="/admin/login" element={
        <ErrorBoundary FallbackComponent={FallBackError}>

          <AdminLogin />
        </ErrorBoundary>
      } />
      <Route path="/admin/set-password" element={
        <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
          <AdminSetPassword />
        </ErrorBoundary>
      } />
      <Route
        path="/admin/overview"
        element={
          <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
            <AdminAuthRoute element={
              <AdminOverview />
            } />
          </ErrorBoundary>

        }
      />
      <Route
        path="/admin/providers"
        element={
          <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
            <AdminAuthRoute element={<AdminProviders />} />
          </ErrorBoundary>
        }
      />
      <Route
        path="/admin/patients"
        element={<AdminAuthRoute element={
          <ErrorBoundary FallbackComponent={FallBackError}>
            <AdminPatients />
          </ErrorBoundary>
        } />}
      />
      <Route
        path="/admin/complaints"
        element={<AdminAuthRoute element={
          <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
            <AdminCompliants />
          </ErrorBoundary>
        } />}
      />
      <Route
        path="/admin/business-office"
        element={<AdminAuthRoute element={
          <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
            <AdminBusinessOffice />
          </ErrorBoundary>
        } />}
      />
      <Route
        path="/admin/billers"
        element={
          <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
            <AdminAuthRoute element={<AdminServiceProviders />} />
          </ErrorBoundary>}
      />
      <Route
        path="/admin/reports"
        element={<AdminAuthRoute element={
          <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
            <AdminReports />
          </ErrorBoundary>
        } />}
      />
      <Route
        path="/admin/users"
        element={<AdminAuthRoute element={<AdminUsers />} />}
      />
      <Route path="/admin/set-password/:token" element={<AdminSetPassword />} />

      {/* ADMIN ROUTES ENDS*/}

      <Route
        path="/provider/patients"
        element={
          <RequireProviderAuth>
            <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
              <ProviderPatients />
            </ErrorBoundary>
          </RequireProviderAuth>

        }
      />
      <Route
        path="/provider/patients/:userId/details"
        element={
          <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
            <RequireProviderAuth>
              <ProviderPatientsDetail />
            </RequireProviderAuth>
          </ErrorBoundary>
        }
      />
      <Route
        path="/provider/payment-billing"
        element={
          <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
            <RequireProviderAuth>
              <PaymentAndBilling />
            </RequireProviderAuth>
          </ErrorBoundary>}
      />

      {/* SERVICE PROVIDER ROUTES STARTS */}
      <Route path="/biller/login" element={<SPLoginPage />} />
      <Route path="/biller/signup" element={<SPSignupPage />} />
      <Route
        path="/biller/verify-email/:userType/:email/:userId"
        element={<SPVerifyEmail />}
      />
      <Route
        path="/biller/register"
        element={
          <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
            <RequireBillerAuth>
              <SPMainRegistrationPage />
            </RequireBillerAuth>
          </ErrorBoundary>
        }
      />
      <Route path="/biller/forgot-password" element={<SPForgotPassword />} />
      <Route
        path="/biller/reset-password/:id/:token"
        element={<SPResetPassword />}
      />
      <Route
        path="/biller/dashboard"
        element={
          <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
            <RequireBillerAuth><SPMainDashboard /> </RequireBillerAuth>
          </ErrorBoundary>
        }
      />
      <Route
        path="/biller/providers"
        element={
          <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
            <RequireBillerAuth><SPProvidersPage /> </RequireBillerAuth>
          </ErrorBoundary>
        }
      />
      <Route
        path="/biller/claims-for-review"
        element={
          <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
            <RequireBillerAuth><SPClaimsReview /> </RequireBillerAuth>
          </ErrorBoundary>
        }
      />
      <Route
        path="/biller/all-claims"
        element={
          <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
            <RequireBillerAuth><SPAllClaims /> </RequireBillerAuth>
          </ErrorBoundary>
        }
      />
      <Route
        path="/biller/eras"
        element={
          <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
            <RequireBillerAuth><SPERAs /> </RequireBillerAuth>
          </ErrorBoundary>
        }
      />
      <Route
        path="/biller/settings"
        element={
          <ErrorBoundary onReset={reset} FallbackComponent={FallBackError}>
            <RequireBillerAuth><SPSettings /> </RequireBillerAuth>
          </ErrorBoundary>
        }
      />

      {/* SERVICE PROVIDER ROUTES ENDS */}
      {/* </Route> */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
