import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import http from "../../../utils/http";

type Payload = {
  oldId?: string;
  date?: string;
  time?: string;
};

export const useRescheduleAppointment = () => {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation({
    mutationKey: ['appointments reschedule'],
    mutationFn: async ({ ...data }: Payload) => {
      const response = await http.patch("appointments/reschedule", {
        oldAppointmentId: data.oldId,
        appointmentDate: data.date,
        appointmentStartTime: data.time
      });
      return response.data;
    },
    onMutate: () => {
      const toastId = toast.loading("Please wait...");
      return { toastId };
    },

    onSuccess: (_data, _variables, context) => {
      const { toastId } = context;
      toast.update(toastId, {
        render: "Appointment recheduled successfully",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });
      queryClient.invalidateQueries({ queryKey: ['provider upcoming appointments'] })
    },

    onError: (error, _variables, context) => {
      const toastId = context?.toastId as number;

      if (axios.isAxiosError(error)) {
        toast.update(toastId, {
          render: error?.response?.data?.error || error.response?.statusText,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        })
      } else {
        toast.update(toastId, {
          render: error?.message || "Error rescheduling appointment",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        });
      }
    },
  });

  return {
    rescheduleAppointment: mutate,
    ...rest,
  };
}