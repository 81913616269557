import * as Yup from "yup";

export const sp_new_validation_schema = Yup.object().shape({
  firstName: Yup.string().required("Provider Name is required"),
  lastName: Yup.string().required("Provider Title is required"),
  middleName: Yup.string().optional(),
  email: Yup.string().email("Invalid email").required("Email is required"),
  title: Yup.string().required("Provider Title is required"),
  phoneNumber: Yup.string().required("Contact Number is required"),
  providerNPI: Yup.string().required("Provider NPI is required"),
  providerTaxId: Yup.string().required("Provider Tax ID is required")
});

interface FormValues {
  firstName: string;
  lastName: string;
  middleName: string;
  title: string;
  email: string;
  phoneNumber: string;
  providerNPI: string;
  providerTaxId: string;
}

export const sp_new_initialValues: FormValues = {
  firstName: "",
  lastName: "",
  middleName: "",
  title: "",
  email: "",
  phoneNumber: "",
  providerNPI: "",
  providerTaxId: ""
};
