import { useMemo } from 'react';
import { getBillerDecodedJwt, getBillerToken, isBillerAuthenticated } from '../../../utils/helpers';
import { BillerUser } from '../../types';

export function useBillerAuthState() {
	const token = getBillerToken();

	const user = useMemo<BillerUser | null>(() => {
		if (token) {
			const decoded = getBillerDecodedJwt<BillerUser>(token);
			return decoded?.data || null;
		}
		return null;
	}, [token]);

	return {
		user,
		isAuthenticated: () => isBillerAuthenticated<BillerUser>(),
	};
}
