import clsx from "clsx";

interface MenuItemProps {
  isActive: boolean;
  icon: React.ReactNode;
  label: string;
  onClick?: (arg: any) => void;
}

const MenuItem: React.FC<MenuItemProps> = ({
  isActive,
  icon,
  label,
  onClick
}) => {
  return (
    <li
      className={clsx({
        "flex items-center justify-start mb-[10px] pl-[25px] mx-auto w-full cursor-pointer h-[50px]":
          true,
        "text-[#3D874E] bg-[#E7FBEC]": isActive
      })}
      onClick={onClick}
    >
      {icon}
      <div className="w-[10px]" />
      <div data-toggle="menu">
        <span
          className={clsx({
            "text-[.9rem] leading-normal": true,
            "text-[.9rem] text-[#3D874E] font-bold": isActive
          })}
        >
          {label}
        </span>
      </div>
    </li>
  );
};

export default MenuItem;
