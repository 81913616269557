import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { useModal } from '../../../context/ModalContext';
import { NoteValue } from "../../../provider/pages/PatientDetail/interfaces";
import { FormTypes } from "../../../provider/pages/Settings/Templates";
import http from "../../../utils/http";

type Payload = {
  noteType: FormTypes;
  noteFields: Array<NoteValue>;
  appointmentId?: string;
  patientId: string;
}

export const useSaveFormResponse = () => {
  const [patientid, setPatientId] = useState<string>("")
  const { openModal } = useModal();
  const queryClient = useQueryClient()
  const { mutate, ...rest } = useMutation({
    mutationKey: ['save form notes'],
    mutationFn: async ({ patientId, noteType, appointmentId, noteFields }: Payload) => {
      setPatientId(patientId)
      const response = await http.post(`documentation/${patientId}?noteType=${noteType}`, { appointmentId, values: noteFields })
      return response.data;
    },

    onSuccess: () => {
      openModal();
      toast.success("Note saved!", {
        toastId: "note"
      });

      queryClient.invalidateQueries({
        queryKey: ['get provider patients', patientid],
      });

    },

    onError: (error) => {
      if (axios.isAxiosError(error)) {
        toast.error(
          error?.response?.data?.error || error.response?.statusText,
        );
      } else {
        toast.error(
          error?.message
          || "Error saving note",
        );
      }
    }
  });

  return {
    saveFormResponse: mutate,
    ...rest,
  };
}
