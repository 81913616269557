import { Clear } from "@mui/icons-material";
import { Box, Drawer, styled } from "@mui/material";
import { ReactNode } from "react";

interface CustomDrawerProps {
  open: boolean;
  drawerTitle?: string;
  onClose: (e: any) => any;
  children: ReactNode;
  width?: number;
  noWidth?: boolean;
}

const CustomDrawer = (props: CustomDrawerProps) => {
  const { open, onClose, width, noWidth } = props;
  return (
    <StyledCustomDrawer
      open={open}
      onClose={onClose}
      anchor="right"
      style={{ width: "100%" }}
    >
      {noWidth ? (
        <Box sx={{ width: "100%" }}>
          <DrawerHeader title={props.drawerTitle ?? "---"} onClose={onClose} />
          <div className="drawer-content">{props.children}</div>
        </Box>
      ) : (
        <Box sx={{ width: width ? width : 600 }}>
          <DrawerHeader title={props.drawerTitle ?? "---"} onClose={onClose} />
          <div className="drawer-content">{props.children}</div>
        </Box>
      )}
    </StyledCustomDrawer>
  );
};

const DrawerHeader = ({
  title,
  onClose
}: {
  title: string;
  onClose: (e: any) => any;
}) => (
  <div className="drawer-header">
    <p className="drawer-title">{title}</p>
    <Clear
      onClick={onClose}
      sx={{
        fontSize: "1.5rem",
        cursor: "pointer"
      }}
    />
  </div>
);

const StyledCustomDrawer = styled(Drawer)(() => ({
  position: "relative",
  "& .drawer-content": {
    width: "100%",
    height: "90vh",
    overflowY: "auto"
  },
  "& .drawer-header": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    width: "100%",
    padding: ".8rem .9rem",

    "& .drawer-title": {
      fontSize: "1.5rem",
      fontWeight: 500,
      color: "#324705"
    }
  }
}));

export default CustomDrawer;
