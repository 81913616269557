import {
  Card,
  CircularProgress,
  TextField as MuiTextField
} from "@mui/material";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import axios from "axios";
import { addMinutes, format } from "date-fns";
import { Form, Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  bookAppointmentsForPatients,
  checkInsuranceEligibility,
  setUpPatientPayment
} from "../api/appointments";
import { useGetMedicalRecord } from "../api/hooks/accounts/useGetMedicalRecord";
import { useAuthState } from "../api/hooks/patient/auth/useAuthState";
import { useGetProviderAppointment } from '../api/hooks/provider/useGetProviderAppointment';
import { useNoAuthGetProviderForm } from '../api/hooks/provider/useNoAuthGetProviderForm';
import { getUsedProvidersBasedOnSpecialty } from "../api/patients";
import Spacer from "../components/Spacer";
import AppointmentConfirmCard from "../components/cards/AppointmentConfirmCard";
import SingleProviderCard from "../components/cards/SingleProviderCard";
import DashboardLayout from "../components/dashboard/DashboardLayout";
import EnterPharmacy from "../components/dashboard/scheduleAppointment/EnterPharmacy";
import FormBuilder from "../components/form/FormBuilder";
import TextField from "../components/form/TextField";
import { getInsurancePayers } from "../components/lib/utils/utils";
import Button from "../components/shared/Button";
import { Loading } from '../components/shared/Loading';
import useWindowWidth from "../hooks/useWindowWidth";
import { NoteValue } from "../provider/pages/PatientDetail/interfaces";
import { FormTypes } from "../provider/pages/Settings/Templates";
import { ArrowBackIcon } from "../svgs/ArrowBackIcon";
import { ConfirmAppointmentIcon } from "../svgs/ConfirmAppointmentIcon";
import { DeleteIcon } from "../svgs/DeleteIcon";
import { PlusIcon } from "../svgs/PlusIcon";
import { ReactComponent as EmptySMG } from "../svgs/empty-smg.svg";
import { getLoggedInUser } from "../utils/loggedInUser";
import { AllergiesFormInputInitialState, AllergiesFormInputType } from "./MyAccount";
import { formatTitle, handleGetSideTitle, handleRemoveEmptyArrayValue } from "./utils/RemoveEmptyArrayValue";

export function ScheduleVisitInsurance() {
  const { user } = useAuthState()

  const width = useWindowWidth();
  const { depId } = useParams();
  const search = useSearchParams()[0];
  const userType = search.get("user");
  const [hasPreviousProvider, setHasPreviousProvider] = useState<Array<any>>(
    []
  );
  const [hasFetchedPP, setHasFetchePP] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [showPersonalIdInput, setShowPersonalIdInput] = useState(false);
  const [, setDoctorsForAppointment] = useState<
    any | null
    >(null);
  const [fetchingDFAQuery, setFetchingDFAQuery] = useState(false);
  const [medications, setMedications] = useState<string[]>([]);
  const [allergies, setAllergies] = useState<any[]>([]);
  const navigate = useNavigate();
  const location: any = useLocation();
  const [formData, setFormData] = useState<any>({
    dependentId: depId
  });
  const [selectedAppointmentDetails, setSelectedAppointmentDetails] = useState<any>({});
  const [userReminderEmail, setUserReminderEmail] = useState(user?.email ?? "");
  const [isLoading, setIsLoading] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [hasInsurance, setHasInsurance] = useState(false);
  const [intakeFormDetails, setIntakeFormDetails] = useState<any>();
  const [intakeFormAnswers, setIntakeFormAnswers] = useState<NoteValue>({});
  const [consentFormDetails, setConsentFormDetails] = useState<any>();
  const [consentFormAnswers, setConsentFormAnswers] = useState<NoteValue>({});
  const [allergiesList, setAllergiesList] = useState<AllergiesFormInputType[]>(
    AllergiesFormInputInitialState
  );

  const referrer = search.get("referrer");
  const signupReferrer = search.get("signupRef");

  const handleAllergyChange = (e: any, index: number) => {
    const newInputs = [...allergiesList];
    newInputs[index].allergy = e.target.value;
    // If they dont add a new field, we still have to set the array with the current field values
    return setAllergiesList(newInputs);
  };

  const insuranceCompaniesFromLocalStorage = getInsurancePayers()
  const [insuranceName, setInsuranceName] = useState<{
    payer_id: string;
    payer_name: string;
  }>({ payer_id: "", payer_name: "" });
  const [insuranceData, setInsuranceData] = useState<Array<any>>([]);

  const handleSearchInsuranceName = useCallback((val: string) => {
    const filteredData = insuranceCompaniesFromLocalStorage?.filter(
      (e: any, i: number) =>
        e.payer_name.toLowerCase().includes(val.toLowerCase())
    );
    setInsuranceData(filteredData);
  }, [insuranceCompaniesFromLocalStorage]);

  useEffect(() => {
    if (insuranceName.payer_name.length > 2) {
      handleSearchInsuranceName(insuranceName.payer_name);
    }
    return () => { };
  }, [insuranceName.payer_name, handleSearchInsuranceName]);

  const handleReactionChange = (e: any, index: number) => {
    const newInputs = [...allergiesList];
    newInputs[index].reaction = e.target.value;
    // If they dont add a new field, we still have to set the array with the current field values
    return setAllergiesList(newInputs);
  };

  const fetchUserInfo = () => {
    const { data: user } = getLoggedInUser({ user: "patient" });
    if (user?.insuranceName && user?.insuranceNumber) {
      setCurrentPage(1);
      setHasInsurance(true);
    }
  };

  const removeItem = (
    idx: number,
    arr: Array<string>,
    cb: (item: Array<string>) => void
  ) => {
    if (!!arr.length) {
      const newArray = arr.filter(item => item !== arr[idx]);
      cb(newArray);
    }
  };
  const getFeetInches = (height: number) => {
    if (height) {
      const heightInInches = height / 2.54;
      const feet = Math.floor(heightInInches / 12);
      const inches = Math.round(heightInInches - feet * 12);
      return {
        feet,
        inches
      };
    } else {
      return {
        feet: 0,
        inches: 0
      };
    }
  };

  const dependentId = referrer === "appointment" ? user?.userId : depId;

  const { medicalRecords, refetchMedicalRecords, isLoading: isFetchingMedicalRecords } = useGetMedicalRecord(dependentId)

  useEffect(() => {
    if (!isFetchingMedicalRecords) {
      setMedications(medicalRecords?.medications);
    }
    if (!isFetchingMedicalRecords) {
      setAllergies(medicalRecords?.allergies);
    }
  }, [isFetchingMedicalRecords, medicalRecords])

  const handleCheckPrevProvider = async () => {
    try {
      const specialty = location?.state?.data?.visiting ?? "pcp";
      setIsLoading(true);
      if (depId) {
        const response = await getUsedProvidersBasedOnSpecialty(
          specialty,
          userType === "self" ? undefined : depId
        );
        setIsLoading(false);
        setHasPreviousProvider(
          response?.patientPreviousProviderAppointments ?? []
        );
        setHasFetchePP(true);
        if (response?.patientPreviousProviderAppointments?.length === 0) {
          fetchUserInfo();
        }
      }
    } catch (e) {
      fetchUserInfo();
      setIsLoading(false);
    }
  };

  const query: {
    specialty?: string;
    zipCode?: string;
    date?: string;
    time?: string;
    insurance?: string;
  } = {};
  if (referrer === "landingPage") {
    query.specialty = search.get("specialty") || "pcp";
    const otherValues = {
      ...(search.get("zipCode") && {
        zipCode: search.get("zipCode"),
        radius: 0
      }),
      ...(search.get("date") && { date: search.get("date") }),
      ...(search.get("time") && {
        time: JSON.stringify(search.get("time"))
      }),
      ...(search.get("isPediatric") && {
        isPediatric: search.get("isPediatric")
      }),
      ...(search.get("insurance") && {
        insurance: search.get("insurance")
      }),
      ...(search.get("isGeriatric") && {
        isGeriatric: search.get("isGeriatric")
      })
    };
    Object.assign(query, otherValues);
  } else {
    query.specialty = location.state?.data?.visiting ?? "pcp";
  }

  const { providerAppointmentData, isError, isLoading: loading, error } = useGetProviderAppointment(query)


  if (isError && axios.isAxiosError(error)) {
    toast.error(error?.response?.data?.error, { toastId: "customId" });
  }

  const handleSkip = useCallback(() => {
    setCurrentPage(currentPage + 1);
  }, []);

  useEffect(() => {
    if (referrer === "landingPage") {
      handleSkip();
    }
    if (referrer === "appointment") {
      refetchMedicalRecords();
      const jsonBooking = localStorage.getItem("bookingDetails");
      if (jsonBooking) {
        const formValues = JSON.parse(jsonBooking);
        if (formValues.formData) {
          setFormData({
            ...formValues.formData
          });
        }
        if (formValues.intakeFormAnswers) {
          setIntakeFormAnswers({
            ...formValues.intakeFormAnswers
          });
        }

        if (formValues.selectedAppointmentDetails) {

          setSelectedAppointmentDetails({
            ...formValues.selectedAppointmentDetails
          });
        }
        if (signupReferrer === "personalDetails") {
          const jsonRecords = localStorage.getItem("medRecords");
          const medRecords = jsonRecords && JSON.parse(jsonRecords);
          if (medRecords) {
            setFormData({
              ...formValues.formData,
              ...medRecords
            });
          }
          setCurrentPage(6);
        } else {
          setCurrentPage(5);
        }
      }
    }
    if (!hasFetchedPP && referrer !== "landingPage") {
      handleCheckPrevProvider();
    }
    // if (doctorsForAppointment === null) {
    //   handleFetchAllProviders();
    // }
  }, [referrer, hasFetchedPP, signupReferrer, handleSkip]);


  const handleScheduleTime = (value: any) => {
    setFormData({
      ...formData,
      appointmentStartTime: value.selectedTime,
      appointmentDate: value.selectedDate,
      providerId: value.providerId
    });
    setCurrentPage(currentPage + 1);
  };

  const formTypes: FormTypes[] = ["consentForm", "intakeForm"];
  const { providerFormData } = useNoAuthGetProviderForm({
    formType: formTypes,
    providerId: selectedAppointmentDetails?.providerId
  })

  const { data: dataForintakeForm, isError: isErr, error: err } = providerFormData[0]
  const { data: dataForConsentForm, isError: isErr2, error: err2 } = providerFormData[1]


  if (isErr && axios.isAxiosError(err)) {
    toast.error(dataForintakeForm?.error?.message, { toastId: "customId" })
  } else if (dataForintakeForm?.error !== null) {
    toast.error(dataForintakeForm?.error, { toastId: "customId" })
  } else {
    toast.error(dataForintakeForm?.error?.message || 'Unknown error occured, refresh page', { toastId: "customId" })
  }

  if (isErr2 && axios.isAxiosError(err2)) {
    toast.error(dataForConsentForm?.error?.message, { toastId: "customId" })
  } else if (dataForConsentForm?.error !== null) {
    toast.error(dataForConsentForm?.error, { toastId: "customId" })
  } else {
    toast.error(dataForConsentForm?.error?.message || 'Unknown error occured, refresh page', { toastId: "customId" })
  }

  const handleBackClick = () => {
    if (currentPage !== 0) {
      if (currentPage === 6 && !intakeFormDetails && !consentFormDetails) {
        setCurrentPage(3);
      } else if (currentPage === 6 && !intakeFormDetails) {
        setCurrentPage(4);
      } else if (
        currentPage === 7 &&
        !selectedAppointmentDetails.isInitialAppointment
      ) {
        setCurrentPage(2);
      } else {
        setCurrentPage(currentPage - 1);
      }
    } else {
      navigate("/dashboard/dependent/visit/schedule", {
        replace: true,
        state: location.state
      });
    }
  };

  useEffect(() => {
    if (providerFormData[0]?.data?.noteType === "intakeForm") {
      setIntakeFormDetails(dataForintakeForm?.intakeForm);
    }
    if (providerFormData[1]?.data?.noteType === "consentForm") {
      setConsentFormDetails(dataForConsentForm?.consentForm);
    }

  }, [intakeFormDetails?.value, dataForintakeForm?.intakeForm, dataForConsentForm?.consentForm])

  const handleRenderFormContainer = () => {
    if (loading || isLoading) {
      return (
        <div className="w-full h-[100vh] flex items-center justify-center">
          <Loading />
        </div>
      );
    } else {
      switch (currentPage) {
        case 0:
          return (
            <Formik
              initialValues={{
                insuranceName: "",
                insuranceNumber: ""
              }}
              validationSchema={Yup.object().shape({
                insuranceName: Yup.string().required(
                  "Insuance type is required"
                ),
                insuranceNumber: Yup.string().required(
                  "Insurance Id is required "
                )
              })}
              onSubmit={(values: any) => {
                setFormData({
                  ...formData,
                  ...values
                });
                setCurrentPage(currentPage + 1);
              }}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                handleSubmit,
                setFieldValue
              }) => (
                <Form className="flex flex-col mx-auto r-0 items-center w-full lg:w-[550px] overflow-scroll h-full pb-[50px]">
                  <div className="flex flex-col items-center mx-auto w-full">
                    <div className="mt-4 w-full">
                      <label
                        className="text-[#000] font-medium mb-2 text-[16px] block"
                        htmlFor={`insuranceName`}
                      >
                        Insurance
                      </label>
                      <div>
                        <div className="">
                          <TextField
                            name=""
                            type="text"
                            placeholder="Enter the insurance name here "
                            value={insuranceName.payer_name}
                            onChange={e => {
                              setIsSelected(false);
                              setInsuranceData([]);
                              setInsuranceName({
                                ...insuranceName,
                                payer_name: e.target.value
                              });
                            }}
                          />
                        </div>
                        {/* {touched.insuranceName && errors.insuranceName && (
                          <div className="text-[red] text-xs">
                            {errors.insuranceName}
                          </div>
                        )} */}
                        <div className="flex flex-col gap-2">
                          {!isSelected &&
                            insuranceData.map((item, index) => (
                              <div
                                key={index}
                                className=""
                                onClick={() => {
                                  setFieldValue(
                                    "insuranceName",
                                    item.payer_name
                                  );
                                  setInsuranceName({
                                    payer_name: item.payer_name,
                                    payer_id: item.payerid
                                  });
                                  setIsSelected(true);
                                  setShowPersonalIdInput(true);
                                }}
                              >
                                {item.payer_name}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    {showPersonalIdInput ? (
                      <div className="mt-4 w-full">
                        <TextField
                          name="insuranceNumber"
                          type="text"
                          placeholder="Personal ID"
                          value={values.insuranceNumber}
                          onChange={handleChange}
                          label="Insurance ID"
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="mt-4 w-full flex flex-row justify-end item-center">
                      <Button
                        label="Skip"
                        variant="secondary"
                        width="100px"
                        height="40px"
                        onClick={() => handleSkip()}
                      />
                      <Spacer width={10} />
                      <Button
                        label="Continue"
                        variant="primary"
                        width="100px"
                        height="40px"
                        onClick={() => handleSubmit()}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          );
        case 1:
          return (
            <div className="w-full">
              <>
                <div className="h-[24px]" />
                <Formik
                  initialValues={{
                    date: "",
                    time: "",
                    distance: ""
                  }}
                  onSubmit={async v => {
                    try {
                      const queryParams: any = {
                        specialty: location.state?.data?.visiting ?? "pcp"
                      };
                      if (v.date) queryParams["date"] = v.date.toString();
                      if (v.time) {
                        queryParams["time"] = JSON.stringify(
                          new Date(v.time).toLocaleTimeString().slice(0, 5)
                        );
                      }
                      if (v.distance) queryParams["radius"] = v.distance;
                      setFetchingDFAQuery(true);
                      //const data = await getProviderAppointment(queryParams); //TODO: check this out
                      setFetchingDFAQuery(false);
                      //setDoctorsForAppointment(data?.providersAvailability); //TODO: check this out
                    } catch (err) {
                      setFetchingDFAQuery(false);
                    }
                  }}
                >
                  {({ errors, values, handleSubmit, setFieldValue }) => (
                    <Form className="flex flex-col items-center w-full lg:w-[600px] pr-2">
                      <div className="hidden lg:flex items-center justify-end lg:w-full mb-5">
                        <div className="w-full pr-2">
                          <p className="mb-2 text-[#6E8877]">Date</p>
                          {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                            {/* <DatePicker
                              value={new Date(values.date) || null}
                              minDate={new Date()}
                              onChange={(v: any) => {
                                setFieldValue(
                                  "date",
                                  format(new Date(v), "MM/dd/yyyy")
                                );
                                handleSubmit();
                              }}
                              renderInput={(params: any) => (
                                <MuiTextField
                                  className="c-red"
                                  {...params}
                                  required={false}
                                />
                              )}
                            /> */}
                          {/* </LocalizationProvider> */}
                        </div>
                        <div className="w-full ">
                          <p className="mb-2 text-[#6E8877]">Time</p>
                          {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                            {/* <TimePicker
                              value={new Date(values.time) || null}
                              ampm={false}
                              onChange={(v: any) => {
                                setFieldValue("time", v);
                                handleSubmit();
                              }}
                              renderInput={(params: any) => (
                                <MuiTextField {...params} />
                              )}
                            /> */}
                          {/* </LocalizationProvider> */}
                        </div>
                        <div className="w-full ml-2">
                          <p className="text-[#6E8877]">Distance from you</p>
                          <div className="w-full mt-3 ">
                            <input
                              className="w-full py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded"
                              value={values.distance}
                              placeholder="Select distance"
                              type="number"
                              onChange={v => {
                                setFieldValue("distance", v.target.value);
                                handleSubmit();
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="w-full mt-12 block lg:hidden mb-5">
                        <div className="w-full flex items-center">
                          <div className="w-1/2 pr-2">
                            <p className="mb-2 text-[#6E8877]">Date</p>
                            {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                              {/* <DatePicker
                                value={new Date(values.date) || null}
                                minDate={new Date()}
                                onChange={(v: any) => {
                                  setFieldValue(
                                    "date",
                                    format(new Date(v), "MM/dd/yyyy")
                                  );
                                  handleSubmit();
                                }}
                                renderInput={(params: any) => (
                                  <MuiTextField {...params} required={false} />
                                )}
                              /> */}
                            {/* </LocalizationProvider> */}
                          </div>
                          <div className="w-1/2 ">
                            <p className="mb-2 text-[#6E8877]">Time</p>
                            {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                              {/* <TimePicker
                                value={new Date(values.time) || null}
                                ampm={false}
                                onChange={(v: any) => {
                                  setFieldValue("time", v);
                                  handleSubmit();
                                }}
                                renderInput={(params: any) => (
                                  <MuiTextField {...params} />
                                )}
                              /> */}
                            {/* </LocalizationProvider> */}
                          </div>
                        </div>
                        <div className="w-full mt-3">
                          <p className="text-[#6E8877]">Distance from you</p>
                          <div className="w-full mt-3 ">
                            <input
                              className="w-full py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded"
                              value={values.distance}
                              placeholder="Select distance"
                              type="number"
                              onChange={v => {
                                setFieldValue("distance", v.target.value);
                                handleSubmit();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
                <div className="w-full">
                  {fetchingDFAQuery ? (
                    <div className="w-full h-full flex items-center justify-center">
                      <CircularProgress></CircularProgress>
                    </div>
                  ) : (
                    <>

                      {Object.keys(providerAppointmentData?.providersAvailability ?? {})?.length <= 0 ? (
                        <div className="flex flex-col items-center justify-center h-full w-full">
                          <EmptySMG />
                          <Spacer height={10} />
                          <h2 className="text-[#103C1B] text-[18px] font-bold">
                            No doctor available at this time
                          </h2>
                          <Spacer height={10} />
                          <p className="text-center text-[#103C1B] text-[16px]">
                            We are sorry, please choose another time
                            prefererence.
                          </p>
                        </div>
                      ) : (
                        <div className="w-full mb-3 sm:mb-0 flex flex-col items-end justify-end lg:w-[606px]">
                          {/* providerAppointmentData?.providersAvailability ?? {} */}
                          {Object.keys(providerAppointmentData?.providersAvailability ?? {})?.map(
                            (key: any, idx: any) => (
                              <SingleProviderCard
                                key={idx}
                                onChipClick={v => {
                                  setSelectedAppointmentDetails({
                                    ...providerAppointmentData?.providersAvailability[key]?.details,
                                    ...v,
                                    isInitialAppointment: true
                                  });
                                  handleScheduleTime(v);
                                }}
                                providerObject={{
                                  ...providerAppointmentData?.providersAvailability[key]?.details,
                                  availableSlots:
                                    providerAppointmentData?.providersAvailability[key]?.availability,
                                  isInitialAppointment: true
                                }}
                              />
                            )
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </>
            </div>
          );
        case 2:
          const {
            selectedDate,
            selectedTime,
            firstCallDuration,
            followUpCallDuration,
            isInitialAppointment
          } = selectedAppointmentDetails;
          const addDuration = addMinutes(
            new Date(`${selectedDate?.slice(0, 11)}${selectedTime}`),
            isInitialAppointment ? firstCallDuration : followUpCallDuration
          );
          return (
            <div className=" w-full">
              <div className="w-full flex justify-end">
                <Card>
                  <div className="p-2 w-full lg:w-[550px]">
                    <p className="text-[#324705] text-[20px] w-full lg:w-[550px]">
                      Your appointment will start by{" "}
                      {format(new Date(`02/05/2023 ${selectedTime}`), "p")} and
                      end by {format(addDuration, "p")} on{" "}
                      <span className="mr-1"></span>
                      {format(new Date(selectedDate), "eeee, MMMM do")}
                    </p>
                  </div>
                </Card>
              </div>
              <div className="w-full flex justify-end item-center mt-3">
                <Button
                  label="Continue"
                  variant="primary"
                  onClick={() => {
                    setFormData({
                      ...formData
                    });
                    // getProviderForms();
                    if (!isInitialAppointment) {
                      setCurrentPage(8);
                    } else {
                      setCurrentPage(currentPage + 1);
                    }
                  }}
                  height="50px"
                  width="100px"
                />
              </div>
            </div>
          );
        case 3:
          return (
            <Formik
              initialValues={{
                appointmentReason: ""
              }}
              validationSchema={Yup.object().shape({
                appointmentReason: Yup.string().required(
                  "Visit reason is required"
                )
              })}
              onSubmit={values => {
                setFormData({
                  ...formData,
                  ...values
                });

                if (!medicalRecords) {
                  refetchMedicalRecords();
                }
                if (consentFormDetails?.noteFields) {
                  setCurrentPage(currentPage + 1);
                } else if (intakeFormDetails?.noteFields) {
                  setCurrentPage(currentPage + 2);
                } else {
                  setCurrentPage(6);
                }
              }}
            >
              {({ errors, touched, values, handleChange, handleSubmit }) => (
                <Form className="flex flex-col mx-auto mr-0 items-center w-full lg:w-[550px] overflow-scroll h-full pb-[50px]">
                  <div className="flex flex-col items-center mx-auto mr-0 w-full">
                    <div className="mt-4 w-full">
                      <TextField
                        name="appointmentReason"
                        type="text"
                        placeholder="The reason for this visit"
                        value={values.appointmentReason}
                        onChange={handleChange}
                        label="Visit Reason"
                        error={
                          touched.appointmentReason
                            ? errors.appointmentReason
                            : ""
                        }
                      />
                    </div>
                    <div className="mt-4 w-full flex flex-row justify-end item-center">
                      <Button
                        label="Continue"
                        variant="primary"
                        width="100px"
                        height="40px"
                        onClick={() => handleSubmit()}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          );
        case 4:
          return (
            <FormBuilder
              formParams={consentFormDetails?.noteFields}
              formType="consentForm"
              submitAction={() => {
                if (intakeFormDetails?.noteFields) {
                  setCurrentPage(currentPage + 1);
                } else {
                  setCurrentPage(currentPage + 2);
                }
              }}
              isPreview={false}
              setFormAnswers={setConsentFormAnswers}
              formAnswers={consentFormAnswers}
            />
          );
        case 5:
          return (
            <FormBuilder
              formParams={intakeFormDetails?.noteFields}
              formType="intakeForm"
              submitAction={() => {
                if (referrer === "landingPage" && !user) {
                  localStorage.setItem(
                    "bookingDetails",
                    JSON.stringify({
                      consentFormAnswers,
                      intakeFormAnswers,
                      formData,
                      selectedAppointmentDetails
                    })
                  );
                  navigate("/patient/signup?referrer=appointment");
                } else {
                  setCurrentPage(currentPage + 1);
                }
              }}
              isPreview={false}
              setFormAnswers={setIntakeFormAnswers}
              formAnswers={intakeFormAnswers}
            />
          );
        case 6:
          return (
            <>
              {isFetchingMedicalRecords ? (
                <div className="flex justify-center items-center h-1/2"><Loading /></div>
              ) : (
                <Formik
                  initialValues={{
                    isTakingMedication: medicalRecords?.isTakingMedication
                      ? "yes"
                      : "no",
                      medications: medications ?? [],
                    hasAllergies: medicalRecords?.hasAllergies ? "yes" : "no",
                      allergies: allergies ?? [],
                    feet: medicalRecords?.height
                      ? getFeetInches(Number(medicalRecords?.height)).feet
                      : 0,
                    inches: medicalRecords?.height
                      ? getFeetInches(Number(medicalRecords?.height)).inches
                      : 0,
                    weight: Number(medicalRecords?.weight),
                    height: Number(medicalRecords?.height),
                    currentMedication: "",
                    currentAllergy: ""
                  }}
                  onSubmit={values => {
                    const v = Object.assign({}, values);
                    if (v.hasAllergies === "yes" && allergies?.length > 0) {
                      v.allergies = handleRemoveEmptyArrayValue(allergies);
                    } else {
                      v.allergies = [];
                    }

                    if (
                      v.isTakingMedication === "yes" &&
                      medications.length > 0
                    ) {
                      v.medications =
                        handleRemoveEmptyArrayValue(medications);
                    } else {
                      v.medications = [];
                    }
                    if (v.feet && v.inches) {
                      v.height =
                        (Number(values?.feet ?? "0") * 12 +
                          Number(values?.inches ?? "0")) *
                        2.54;
                    }

                    values.currentAllergy = "";
                    values.currentMedication = "";

                    setFormData({
                      ...formData,
                      ...v
                    });
                    if (selectedAppointmentDetails.specialty === "therapist") {
                      setCurrentPage(currentPage + 2);
                    } else {
                      setCurrentPage(currentPage + 1);
                    }
                  }}
                  validationSchema={Yup.object().shape({
                    isTakingMedication: Yup.string()
                      .oneOf(["yes", "no"])
                      .required("Currently taking meds is required"),
                    hasAllergies: Yup.string()
                      .oneOf(["yes", "no"])
                      .required("Has allergies is required"),
                    feet: Yup.number().required("Feet is required"),
                    inches: Yup.number().required("Inches is required"),
                    weight: Yup.number().required("Weight is required")
                  })}
                >
                  {({
                    errors,
                    touched,
                    values,
                    handleChange,
                    handleSubmit,
                    handleReset,
                    setFieldValue
                  }) => (
                    <Form className="flex flex-col mx-auto lg:w-[500px] overflow-y-scroll h-auto pb-[50px]">
                      <div className="border-0 border-b border-solid pb-4 mb-5 w-full">
                        <label className="text-[#103C1B]">
                          Are you currently taking any medication?
                        </label>
                        <div className="flex flex-row items-center mt-2">
                          <input
                            type="radio"
                            name="isTakingMedication"
                            value="yes"
                            checked={values.isTakingMedication === "yes"}
                            onChange={handleChange}
                          />
                          <span className="ml-3 text-[#103C1B]">Yes</span>
                        </div>
                        <div className="flex flex-row items-center mt-2">
                          <input
                            type="radio"
                            name="isTakingMedication"
                            value="no"
                            checked={values.isTakingMedication === "no"}
                            onChange={handleChange}
                          />
                          <span className="ml-3 text-[#103C1B]">No</span>
                        </div>
                        {values.isTakingMedication === "yes" && (
                          <div>
                              {medications.length > 0 &&
                                medications.map((medication, i: number) => (
                                <div className="border border-grey focus:outline-none flex flex-row items-center justify-between">
                                  <div
                                    key={i}
                                    className=" h-[50px] py-3.5 px-4 my-2 text-[#6E8877] text-s rounded resize-none"
                                  >
                                    {medication}
                                  </div>
                                  <p
                                    onClick={() =>
                                        removeItem(i, medications, items => setMedications(items))
                                    }
                                    className="text-[red] cursor-pointer mx-2"
                                  >
                                    <DeleteIcon />
                                  </p>
                                </div>
                              ))}
                            <div className="mt-4 ">
                              <textarea
                                value={values.currentMedication}
                                name="currentMedication"
                                placeholder="Enter Medication"
                                className="w-full py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded resize-none"
                                onChange={handleChange}
                                rows={1}
                              />
                            </div>
                            <div
                              className="cursor-pointer flex flex-row"
                              onClick={() => {
                                if (values.currentMedication) {
                                  setMedications([
                                    ...medications,
                                    values.currentMedication
                                  ]);
                                  values.currentMedication = "";
                                } else {
                                  toast.error("Please enter medication");
                                }
                              }}
                            >
                              <PlusIcon />
                              <span className="text-[#1A9D39] text-[18px] ml-3">
                                Add medication
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="border-0 border-b border-solid pb-4 mb-5 w-full">
                        <label className="text-[#103C1B]">
                          Do you have any allergies or drug sensitivities?
                        </label>
                        <div className="flex flex-row items-center mt-2">
                          <input
                            type="radio"
                            name="hasAllergies"
                            value="yes"
                            checked={values.hasAllergies === "yes"}
                            onChange={handleChange}
                          />
                          <span className="ml-3 text-[#103C1B]">Yes</span>
                        </div>
                        <div className="flex flex-row items-center mt-2">
                          <input
                            type="radio"
                            name="hasAllergies"
                            value="no"
                            checked={values.hasAllergies === "no"}
                            onChange={handleChange}
                          />
                          <span className="ml-3 text-[#103C1B]">No</span>
                        </div>
                        {values.hasAllergies === "yes" && (
                          <div className="w-full">
                            {allergiesList &&
                              allergiesList?.map((item, index) => (
                                <div
                                  className="w-full p-2 flex flex-wrap lg:flex-nowrap justify-start items-center mb-4"
                                  key={index}
                                >
                                  <div className="mr-2">
                                    <TextField
                                      name="allergy"
                                      type="text"
                                      placeholder="Allergy"
                                      value={item?.allergy}
                                      onChange={e =>
                                        handleAllergyChange(e, index)
                                      }
                                    />
                                  </div>

                                  <div className="lg:w-[240px] mr-2">
                                    <select
                                      className="w-full py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded h-[47px]"
                                      // placeholder="Select severity"
                                      name="severity"
                                      value={item?.severity}
                                      onChange={e => {
                                        const newInputs = [...allergiesList];
                                        newInputs[index].severity =
                                          e.target.value;
                                        // If they dont add a new field, we still have to set the array with the current field values
                                        setAllergiesList(newInputs);
                                      }}
                                    >
                                      <option value="" disabled>
                                        {item?.severity || "Severity"}
                                      </option>
                                      {["Severe", "Mild", "Moderate"].map(
                                        value => (
                                          <option
                                            value={value.toLowerCase()}
                                            key={value}
                                          >
                                            {value}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                  <div className="">
                                    <TextField
                                      name="reaction"
                                      type="text"
                                      placeholder="Reaction"
                                      value={item?.reaction}
                                      onChange={e =>
                                        handleReactionChange(e, index)
                                      }
                                    />
                                  </div>

                                  <button
                                    className="ml-2"
                                    onClick={() => {
                                      const newFields = [...allergiesList];
                                      newFields.splice(index, 1);
                                      setAllergiesList(newFields);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </button>
                                </div>
                              ))}

                            <div
                              className="cursor-pointer flex flex-row"
                              onClick={() => {
                                setAllergiesList([
                                  ...allergiesList,
                                  {
                                    allergy: "",
                                    severity: "",
                                    reaction: ""
                                  }
                                ]);
                              }}
                            >
                              <PlusIcon />
                              <span className="text-[#1A9D39] text-[16px] ml-3">
                                Add another allergy
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="w-full pb-4 mb-5">
                        <label className="text-[#103C1B]">
                          Enter your body measurement
                        </label>
                        <div className="flex flex-row items-center mt-2">
                          <span className="text-[#103C1B]">Height:</span>
                          <div className="ml-3 flex flex-row items-center">
                            <input
                              placeholder="Feet"
                              type="number"
                              name="feet"
                              max={10}
                              value={values.feet}
                              className="p-2 w-[119px] border border-solid rounded outline-none mr-2"
                              onChange={v => {
                                if (Number(v.target.value) > 10) return;
                                handleChange(v);
                              }}
                            />
                            <input
                              placeholder="Inch"
                              type="number"
                              name="inches"
                              max={12}
                              value={values.inches}
                              className="p-2 w-[119px] border border-solid rounded outline-none mr-2"
                              onChange={v => {
                                if (Number(v.target.value) > 12) return;
                                handleChange(v);
                              }}
                            />
                          </div>
                        </div>
                        <div className="flex flex-row items-center mt-2">
                          <span className="text-[#103C1B]">Weight:</span>
                          <div className="ml-3 flex flex-row items-center">
                            <input
                              placeholder="Lbs"
                              type="number"
                              name="weight"
                              max={600}
                              value={values.weight}
                              className="p-2 w-[119px] border border-solid rounded outline-none mr-2"
                              onChange={v => {
                                if (Number(v.target.value) > 600) return;
                                handleChange(v);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="h-[20px] lg:h-[50px]" />
                      <div className="lg:mt-4 w-full flex flex-row justify-end item-center">
                        <Button
                          label="Continue"
                          variant="primary"
                          width="100px"
                          height="40px"
                          onClick={() => {
                            setMedications([
                              ...medications,
                              values.currentMedication
                            ]);
                            setAllergies([
                              ...allergies,
                              values.currentAllergy
                            ]);
                            handleSubmit();
                          }}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </>
          );
        case 7:
          return (
            <EnterPharmacy
              toast={toast}
              setCurrentPage={setCurrentPage}
              handleSkip={handleSkip}
              setFormData={setFormData}
              formData={formData}
            />
          );
        case 8:
          return (
            <Formik
              initialValues={{
                acceptTerms: false
              }}
              onSubmit={async values => {
                setIsLoading(true);
                let data: any = {
                  acceptTerms: values.acceptTerms,
                  ...formData
                };
                const { isInitialAppointment } = selectedAppointmentDetails;
                if (isInitialAppointment) {
                  data.isTakingMedication =
                    data.isTakingMedication === "yes" ? true : false;
                  data.hasAllergies =
                    data.hasAllergies === "yes" ? true : false;

                  if (!data.isTakingMedication) {
                    delete data.medications;
                  }
                  if (!data.hasAllergies) {
                    delete data.allergies;
                  }

                  data = {
                    ...data,
                    height: data.height ? data.height.toString() : "0",
                    weight: data.weight ? data.weight.toString() : "0"
                  };
                }

                data.currentAllergy = allergiesList;
                const {
                  feet,
                  inches,
                  currentAllergy,
                  currentMedication,
                  acceptTerms,
                  ...submitData
                } = data;

                if (insuranceName.payer_id && insuranceName.payer_name) {
                  const response = await checkInsuranceEligibility({
                    insured: {
                      firstName: user?.firstName as string,
                      lastName: user?.lastName as string,
                      payerId: insuranceName.payer_id
                    },
                    providerNPI: selectedAppointmentDetails?.providerIndentifier
                  });

                  if (response.status === 200) {
                    const res = await bookAppointmentsForPatients({
                      ...submitData,
                      dependentId:
                        referrer !== null ? user?.userId : depId,
                      isInitialAppointment,
                      ...(intakeFormAnswers && {
                        intakeFormAnswers: [intakeFormAnswers]
                      }),
                      ...(consentFormAnswers && {
                        consentFormAnswers: [consentFormAnswers]
                      })
                    });

                    if (res?.appointmentDetails) {
                      localStorage.removeItem("bookingDetails");
                      localStorage.removeItem("medRecords");
                      toast.success("Appointment booked successfully");
                      navigate("/appointments");
                    } else {
                      toast.error(res.error);
                    }
                    setIsLoading(false);
                  } else {
                    toast.error(
                      "You are not eligible for this service under this insurance provider"
                    );
                    setIsLoading(false);
                  }
                } else {
                  await setUpPatientPayment(
                    {
                      ...submitData,
                      dependentId:
                        referrer !== null ? user?.userId : depId,
                      isInitialAppointment,
                      ...(intakeFormAnswers && {
                        intakeFormAnswers: [intakeFormAnswers]
                      }),
                      ...(consentFormAnswers && {
                        consentFormAnswers: [consentFormAnswers]
                      }),
                      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
                    },
                    user?.stripeId as string,
                    location.pathname
                  );
                }

                setIsLoading(false);
              }}
            >
              {({ values, handleSubmit, setFieldValue }) => (
                <div className="w-full lg:w-[550px] mx-0">
                  <AppointmentConfirmCard
                    providerFirstName={selectedAppointmentDetails?.firstName}
                    providerLastName={selectedAppointmentDetails?.lastName}
                    providerType={formatTitle(
                      selectedAppointmentDetails?.title
                    )}
                    providerImageUrl={
                      selectedAppointmentDetails?.avatarUrl
                        ? selectedAppointmentDetails?.avatarUrl
                        : "/assets/images/avatar.png"
                    }
                    appointmentDate={`${format(
                      new Date(selectedAppointmentDetails.selectedDate),
                      "EEE, LLLL d yyyy"
                    )}, ${format(
                      new Date(
                        `02/05/2023 ${selectedAppointmentDetails.selectedTime}`
                      ),
                      "p"
                    )}`}
                    appointmentDuration={`${selectedAppointmentDetails.isInitialAppointment
                      ? selectedAppointmentDetails.firstCallDuration
                      : selectedAppointmentDetails.followUpCallDuration
                      } minutes`}
                    reminders={userReminderEmail ?? "---"}
                  // paymentDetails="Mastercard ****6427 - Exp 02/25"
                  />
                  <div className="w-full flex flex-row items-center mt-2">
                    {/* TODO CREAGE GENERIC CHECKBOX INUT WIRTH SVG ICONS BECAUSE CHECKBOX DEFAULT COLOR CANNOT CHANGE */}
                    <input
                      type="checkbox"
                      name="acceptTerms"
                      checked={values.acceptTerms}
                      onChange={event => {
                        setFieldValue("acceptTerms", event.target.checked);
                      }}
                    />
                    <span className="ml-3 text-[#103C1B]">
                      I certify that I have read and accept the terms of
                      TempleHS.
                    </span>
                  </div>
                  <div className="h-4" />
                  <div className="my-4 lg:my-2 w-full flex flex-row justify-end item-center">
                    <Button
                      label="Schedule Appointment"
                      variant="primary"
                      width="200px"
                      height="40px"
                      disabled={!values.acceptTerms}
                      onClick={handleSubmit}
                    />
                  </div>
                  <div className="h-8  block lg:hidden" />
                </div>
              )}
            </Formik>
          );
        case 9:
          return (
            <div className="text-center text-[#103C1B] w-full lg:w-[50%] lg:m-auto lg:mr-[130px] flex flex-col items-center justify-center">
              <div className="w-full flex justify-center items-center m-auto ">
                <ConfirmAppointmentIcon />
              </div>
              <div className="w-full flex justify-center items-center text-[40px] font-400">
                Your appointment has been booked!
              </div>
              <div className="w-full flex justify-center items-center text-[18px] font-400">
                We sent you a mail with all the details of your appointment.
              </div>
              <div className="mt-4 w-full flex justify-center item-center">
                <Button
                  label="View Appointment"
                  variant="secondary"
                  onClick={() => navigate("/appointments")}
                />
                <Spacer width={10} />
                <Button
                  label="Go back home"
                  variant="primary"
                  onClick={() => {
                    navigate("/dashboard");
                  }}
                />
              </div>
            </div>
          );
        default:
          return <div />;
      }
    }
  };
  const handleFindNewProvider = () => {
    try {
      const prevP = hasPreviousProvider;
      const dfa = Object.keys(providerAppointmentData?.providersAvailability);
      const filtered = {} as any;

      for (const dfsKey of dfa) {
        const found = prevP.findIndex(pp => {
          const ppKey = Object.entries(pp)[0];
          return ppKey[0] === dfsKey;
        });
        if (found === -1) {
          filtered[dfsKey] = providerAppointmentData?.providersAvailability[dfsKey];
        }
      }
      setDoctorsForAppointment(filtered);
      setHasPreviousProvider([]);
      setHasFetchePP(true);
      fetchUserInfo();
    } catch (e) {
      toast.error("Invalid action");
    }
  };

  if (hasPreviousProvider?.length > 0) {
    const HandleSetScreenTitle = () => {
      const component = (value: string) => (
        <p className="text-[1.2rem] font-normal text-[#103C1B]">{value}</p>
      );
      const subComponent = (value: string) => (
        <div className="mt-3 text-[#6E8877] text-[19px]">
          <p className="text-[16px]">{value}</p>
        </div>
      );
      return (
        <div>
          {component("Continue with your previous provider")}
          {subComponent(
            "This is recommended because continuing with your previous provider is likely to lead to higher quality outcomes."
          )}
        </div>
      );
    };
    return (
      <DashboardLayout headerTitle="Schedule Appointment">
        <div className="flex justify-between items-center pr-0 pl-0 w-full mt-[20px] mb-8 lg:mb-0">
          <div className="flex flex-col items-center w-full">
            <div className="w-full">
              <div className="w-fit flex">
                <Button
                  onClick={() => handleBackClick()}
                  icon={<ArrowBackIcon />}
                  iconPosition="left"
                  size="medium"
                  label="Go back"
                  variant="tertiary"
                  additionalClassname="text-[#103C1B]"
                ></Button>
              </div>
              <div className="ment-m">
                <div className="w-[90%]">
                  <HandleSetScreenTitle />
                </div>
                <div className="w-full flex flex-col h-full relative">
                  <div className="w-full relative sm:h-[75vh]">
                    <div className="w-full flex flex-col justify-between h-full">
                      <div className="w-full h-full overflow-auto mb-4">
                        {hasPreviousProvider?.map((item: any, idx: any) => {
                          const { availability, details } =
                            item[Object.keys(item)[0]];
                          return (
                            <SingleProviderCard
                              key={idx}
                              onChipClick={v => {
                                setHasPreviousProvider([]);
                                setHasFetchePP(true);
                                setSelectedAppointmentDetails({
                                  ...details,
                                  ...v,
                                  isInitialAppointment: false
                                });
                                handleScheduleTime(v);
                                setCurrentPage(2);
                              }}
                              availabilityText="Next Available Slots"
                              providerObject={{
                                ...details,
                                availableSlots: availability,
                                isInitialAppointment: false
                              }}
                            />
                          );
                        })}
                      </div>
                      <div className="flex flex-col items-center justify-center w-full relative">
                        <p className="text-[#6E8877] text-[15px] text-center">
                          OR
                        </p>
                        <p className="text-[#324705] font-bold text-center text-[20px] mt-2">
                          Do you want to find a new provider?
                        </p>
                        <Button
                          additionalClassname={"text-[18px] font-[400] mt-2"}
                          type="submit"
                          variant="primary"
                          onClick={handleFindNewProvider}
                          label="Find new provider"
                          size="large"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout headerTitle="Schedule Appointment">
      <div className="w-full h-full overflow-auto">
        <div className="flex justify-between items-center px-0 mx-0 w-full h-full mt-[20px]">
          {currentPage === 10 ? (
            <div className="w-full flex flex-col">
              {handleRenderFormContainer()}
            </div>
          ) : (
            <div className="w-full h-full flex flex-col items-center">
              <div className="w-full h-full">
                <div className="w-fit flex">
                  <Button
                    onClick={() => {
                      if (currentPage === 1 && hasInsurance) {
                        navigate("/dashboard/dependent/visit/schedule", {
                          replace: true,
                          state: location.state
                        });
                      }
                      handleBackClick();
                    }}
                    icon={<ArrowBackIcon />}
                    iconPosition="left"
                    size="medium"
                    label="Go back"
                    variant="tertiary"
                    additionalClassname="text-[#103C1B]"
                  ></Button>
                </div>
                <div
                  className={`w-full flex flex-col ${width > 820 && width < 1280
                    ? "md:flex-col mx-4"
                    : "flex-row mx-5"
                    } md:flex-row`}
                >
                  <div className="mb-2 md:flex-1 lg:mb-0 lg:mr-[50px]">
                    <p className="text-[1.5rem] font-normal text-[#103C1B]">
                      {handleGetSideTitle(currentPage).header}
                    </p>
                    <p className="mb-2 text-[.8rem] font-xs text-[#6E8877]">
                      {handleGetSideTitle(currentPage).subBody}
                    </p>
                  </div>
                  <div className="w-full md:flex-1 flex flex-col mx-auto">
                    {handleRenderFormContainer()}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}
