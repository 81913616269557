import { capitalize } from "@mui/material";
import axios from "axios";
import clsx from "clsx";
import { format } from "date-fns";
import { useState } from "react";
import { toast } from "react-toastify";
import { useDeleteAdminComplaint } from "../../api/hooks/admin/delete_admin_complaint";
import { useGetAdminComplaints } from "../../api/hooks/admin/get_admin_complaint_list";
import { useGetSingleAdminComplaint } from "../../api/hooks/admin/get_single_admin_complaint_list";
import { useUpdateAdminComplaints } from "../../api/hooks/admin/update_admin_complaint";
import ReusableTable from "../../components/ReusableTable";
import { Loading } from "../../components/shared/Loading";
import SecondaryListTabsWidget from "../../components/shared/SecondaryListTabsWidget";
import { ArrowBackIcon } from "../../svgs/ArrowBackIcon";
import AdminComlaintActionCell from "../components/AdminComplaintActionCell";
import AdminDashboardLayout from "../components/AdminDashboardLayout";
import { useAdminComplaintTableCols } from "../tables/hooks/admin_complaints_cols";


const AdminCompliants = () => {
  const [isComplaintDetailsView, setIsComplaintDetailsView] =
    useState<boolean>(false);
  const [activeTabLabel, setActiveTabLabel] = useState("All Complaint");
  const [userId, setUserId] = useState<string>("");

  const handleTabClick = (tabName: string) => {
    setActiveTabLabel(tabName);
  };

  const { complaintList, error, isError, isLoading } = useGetAdminComplaints()
  const { deleteAdminComplaint } = useDeleteAdminComplaint()
  const { oneComplaintList, refetch, isLoading: isLoadingSingleData, isError: isOneError, error: oneError } = useGetSingleAdminComplaint({ userId })
  const { updateAdminComplaint } = useUpdateAdminComplaints()
  const { TABLE_COLUMNS } = useAdminComplaintTableCols({ setIsComplaintDetailsView, setUserId })


  if (isError) {
    const message = axios.isAxiosError(error) ? error?.response?.data?.error : "";
    toast.error(message || error?.message, { toastId: "customId" })
  }
  if (isOneError) {
    const message = axios.isAxiosError(isOneError) ? isOneError?.response?.data?.error : "";
    toast.error(message || oneError?.message, { toastId: "customId" })
  }

  return (
    <AdminDashboardLayout headerTitle="Complaints">

      {isLoadingSingleData ? (<div className="flex justify-center items-center h-1/2">
        <Loading />
      </div>) : isComplaintDetailsView ? (
        <div className="w-full h-full px-4 mt-8">
          <div className="w-full">
            <div
              onClick={() => setIsComplaintDetailsView(false)}
              className="cursor-pointer flex justify-start items-center"
            >
              <ArrowBackIcon />{" "}
              <div className="ml-3">Go back to Complaints</div>
            </div>
          </div>
          <div className="h-8" />

          {oneComplaintList?.status === "active" && (
            <div className="w-full flex justify-center items-center">
              <div className="w-[24px] h-[24px] rounded-full bg-[#34623F] text-[#fff] text-center">
                1
              </div>
              <div className="border border-[#CECFC5] w-[305px] bg-[#CECFC5]" />

              <div className="w-[24px] h-[24px] rounded-full  text-[#AAA] border border-[#AAA] text-center">
                2
              </div>
              <div className="border border-[#CECFC5] w-[305px] bg-[#CECFC5]" />
              <div className="w-[24px] h-[24px] rounded-full text-[#AAA] border border-[#AAA] text-center">
                3
              </div>
            </div>
          )}

          {oneComplaintList?.status === "inProgress" && (
            <div className="w-full flex justify-center items-center">
              <div className="w-[24px] h-[24px] rounded-full bg-[#34623F] text-[#fff] text-center">
                1
              </div>
              <div className="border w-[305px] border-[#34623f] bg-[#34623f]" />
              <div className="w-[24px] h-[24px] rounded-full bg-[#34623F] text-[#fff] text-center">
                2
              </div>
              <div className="border border-[#CECFC5] w-[305px] bg-[#CECFC5]" />
              <div className="w-[24px] h-[24px] rounded-full text-[#AAA] border border-[#AAA] text-center">
                3
              </div>
            </div>
          )}

          {oneComplaintList?.status === "resolved" && (
            <div className="w-full flex justify-center items-center">
              <div className="w-[24px] h-[24px] rounded-full bg-[#34623F] text-[#fff] text-center">
                1
              </div>
              <div className="border w-[305px] border-[#34623f] bg-[#34623f]" />
              <div className="w-[24px] h-[24px] rounded-full bg-[#34623F] text-[#fff] text-center">
                2
              </div>
              <div className="border w-[305px] border-[#34623f] bg-[#34623f]" />
              <div className="w-[24px] h-[24px] rounded-full bg-[#34623F] text-[#fff] text-center">
                3
              </div>
            </div>
          )}

          <div className="w-full flex justify-evenly items-center mx-auto">
            <div className="text-[14] font-[400] text-[#000814 ml-3">
              Active
            </div>
            <div className="text-[14] font-[400] text-[#949589]">
              In Progress
            </div>
            <div className="text-[14] font-[400] text-[#949589]">Resolved</div>
          </div>
          <div className="h-6" />
          <div className="w-full min-h-[503px] bg-white p-6 my-6 shadow">
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center">
                <p className="text-[20px] font-[600]">
                  Complaint #
                  {oneComplaintList?.complaintId &&
                    oneComplaintList?.complaintId?.slice(0, 7)}
                </p>
                <div
                  className={clsx({
                    "h-[32px] rounded-[32px]  text-center border ml-4 px-4":
                      true,
                    "bg-[#EEF6FD] border-[#B8D3EC]":
                      oneComplaintList?.status === "active",
                    "bg-[#FEF4E2] text-[#F1A512] border-[#FBE1AE]":
                      oneComplaintList?.status === "inProgress",
                    "bg-[#E7FBEC] border-[#8BDF9F] text-[#34623F]":
                      oneComplaintList?.status === "resolved"
                  })}
                >
                  {oneComplaintList?.status &&
                    capitalize(oneComplaintList?.status)}
                </div>
              </div>
              <div className="">
                <AdminComlaintActionCell
                  viewComplaint={() => {
                    setUserId(oneComplaintList?.complaintId)
                    refetch()
                    setIsComplaintDetailsView(true);
                  }}
                  markAsInProgress={() => {
                    updateAdminComplaint(
                      {
                        userId: oneComplaintList?.complaintId,

                        status: "inProgress"
                      }
                    );

                  }}
                  markAsResolved={() => {
                    updateAdminComplaint(
                      {
                        userId: oneComplaintList?.complaintId,

                        status: "resolved"
                      }
                    );

                  }}
                  deleteComplaint={() => {
                    deleteAdminComplaint({ userId: oneComplaintList?.complaintId });

                  }}
                  status={oneComplaintList?.status}
                />

              </div>
            </div>
            <div className="h-14" />
            <div className="w-full">
              <div className="w-full flex items-center my-6">
                <p className="text-[18px] font-[500] text-[#87928A]">
                  Created by
                </p>
                <div className="w-[250px]" />
                <p className="text-[20px] font-[500] text-[#324705]">
                  {oneComplaintList?.createdBy}
                </p>
              </div>
              <div className="w-full flex items-center my-6">
                <p className="text-[18px] font-[500] text-[#87928A]">
                  Created On
                </p>
                <div className="w-[250px]" />
                <p className="text-[20px] font-[500] text-[#324705]">
                  {oneComplaintList?.created_at
                    ? format(
                      new Date(oneComplaintList?.created_at),
                      "MM/dd/yyyy"
                    )
                    : ""}
                </p>
              </div>
              <div className="w-full flex items-baseline my-6">
                <p className="text-[18px] font-[500] text-[#87928A]">
                  Description
                </p>
                <div className="w-[250px]" />
                <p className="w-[684px] text-[20px] font-[500] text-[#324705]">
                  {oneComplaintList?.description}
                </p>
              </div>
              <div className="w-full flex items-center my-6">
                <p className="text-[18px] font-[500] text-[#87928A]">
                  Account Type
                </p>
                <div className="w-[230px]" />
                <p className="text-[20px] font-[500] text-[#324705]">
                  {oneComplaintList?.accountType &&
                    capitalize(oneComplaintList?.accountType)}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-full px-4 mt-8">
          <div className="px-4">
            <SecondaryListTabsWidget
              tabs={[
                    { label: "Active", value: complaintList?.activeCount ?? 0 },
                    { label: "In Progress", value: complaintList?.inProgressCount ?? 0 },
                    { label: "Resolved", value: complaintList?.resolvedCount ?? 0 },
                    { label: "All Complaint", value: complaintList?.complaints?.length ?? 0 }
              ]}
              handleTabClick={label => {
                handleTabClick(label);
              }}
              activeTabLabel={activeTabLabel}
            />
          </div>
          <div className="w-full mt-8">
            {activeTabLabel === "Active" && (
                  isLoading ? <Loading /> : <ReusableTable
                tableColumns={TABLE_COLUMNS}
                    tableData={complaintList?.complaints ?? []}
                    onClick={data => { }}
                pageSizeCount={10}
                searchFieldPlaceholder="Search Complaint"
              />
            )}

            {activeTabLabel === "In Progress" && (
                  isLoading ? <Loading /> : <ReusableTable
                tableColumns={TABLE_COLUMNS}
                    tableData={complaintList?.complaints ?? []}
                    onClick={data => { }}
                pageSizeCount={10}
                searchFieldPlaceholder="Search Complaint"
              />
            )}
            {activeTabLabel === "Resolved" && (
                  isLoading ? <Loading /> : <ReusableTable
                tableColumns={TABLE_COLUMNS}
                    tableData={complaintList?.complaints ?? []}
                    onClick={data => { }}
                pageSizeCount={10}
                searchFieldPlaceholder="Search Complaint"
              />
            )}
            {activeTabLabel === "All Complaint" && (
                  isLoading ? <Loading /> : <ReusableTable
                tableColumns={TABLE_COLUMNS}
                    tableData={complaintList?.complaints ?? []}
                    onClick={data => { }}
                pageSizeCount={10}
                searchFieldPlaceholder="Search Complaint"
              />
            )}
          </div>
        </div>
      )}
    </AdminDashboardLayout>
  );
};

export default AdminCompliants;
