import React, { useState } from "react";
import { AdminTableActionIcon } from "../../svgs/AdminTableActionIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

interface IProps {
  viewComplaint: () => void;
  markAsInProgress: () => void;
  markAsResolved: () => void;
  deleteComplaint: () => void;
  status: string;
}

const AdminComplaintActionCell = ({
  viewComplaint,
  markAsInProgress,
  markAsResolved,
  deleteComplaint,
  status
}: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="flex items-center gap-x-4">
      <button
        type="button"
        className="border-none outline-none cursor-pointer"
        onClick={event => {
          event.stopPropagation();
          handleClick(event);
        }}
      >
        <AdminTableActionIcon />
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
      >
        <MenuItem onClick={viewComplaint}>View</MenuItem>
        {status === "resolved" && (
          <>
            <MenuItem onClick={markAsInProgress}>Mark as in progress</MenuItem>
          </>
        )}
        {status === "inProgress" && (
          <MenuItem onClick={markAsResolved}>Mark as resolved</MenuItem>
        )}
        {status === "active" && (
          <>
            <MenuItem onClick={markAsResolved}>Mark as resolved</MenuItem>
            <MenuItem onClick={markAsInProgress}>Mark as in progress</MenuItem>
          </>
        )}
        <MenuItem onClick={deleteComplaint}>Delete</MenuItem>
      </Menu>
    </div>
  );
};

export default AdminComplaintActionCell;
