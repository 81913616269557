import React from "react";
import { ReactComponent as CheckboxChecked } from "../../svgs/checkbox-checked.svg";
import { ReactComponent as CheckboxUnchecked } from "../../svgs/checkbox-unchecked.svg";

interface IProps {
  label: string;
  isSelected: boolean;
  toggleSelection: () => void; // Make icon selected or not
}

const CheckWithLabelToggle: React.FC<IProps> = ({
  label,
  isSelected,
  toggleSelection
}) => {
  return (
    <div className="flex justify-between items-center w-full rounded pl-3 mb-4">
      <div className="flex justify-start">
        {isSelected ? (
          <CheckboxChecked
            className="cursor-pointer"
            onClick={() => {
              toggleSelection();
            }}
          />
        ) : (
          <CheckboxUnchecked
            className="cursor-pointer"
            onClick={() => {
              toggleSelection();
            }}
          />
        )}
        <div className="w-2" />
        <p className="font-normal text-[#434343] ml-2 text-[16px]">{label}</p>
      </div>
    </div>
  );
};

export default CheckWithLabelToggle;
