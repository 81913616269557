import { format } from "date-fns";
import * as Yup from "yup";
import { getCurrentUser } from "../../../../api/auth";
import { phoneRegExp } from "../../../../utils/consts";
import { FormValues } from "../Credentialing";

export const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  title: Yup.string(),
  practicePhoneNumber: Yup.string().required(
    "Practice Phone Number is required"
  ),
  fax: Yup.string(),
  practiceName: Yup.string().required("Practice Name is required"),
  providerTaxId: Yup.string(),
  deaNumber: Yup.string(),
  npi: Yup.string().required("NPI is required"),
  providerType: Yup.string().required("Provider Type is required"),
  licenseNumber: Yup.string().required("License number is required"),
  stateOfLicense: Yup.string().required("State of license is required"),
  comments: Yup.string(),
  firstName: Yup.string().required("First Name is required"),
  dateOfBirth: Yup.string().required("Date Of Birth is required"),
  effectiveDate: Yup.string().required("Effective Date is required"),
  expirationDate: Yup.string().required("Policy Number is required"),
  gender: Yup.string().required("Gender is required"),
  groupNumber: Yup.string(),
  ssn: Yup.string().required("Social security Number is required"),
  insuranceProvider: Yup.string().required("Insurance Provider is required"),
  policyNumber: Yup.string().required("Policy Number is required"),
  middleName: Yup.string(),
  lastName: Yup.string(),
  address1: Yup.string(),
  address2: Yup.string(),
  businessDescription: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  zipCode: Yup.string(),
  businessAddress1: Yup.string(),
  businessAddress2: Yup.string(),
  businessCity: Yup.string(),
  businessState: Yup.string(),
  businessZipCode: Yup.string(),
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .test("is-valid-us-phone-number", "Invalid US phone number", value => {
      return phoneRegExp.test(value as string);
    })
});

export const initialValues: FormValues = {
  title: getCurrentUser()?.title || "",
  firstName: getCurrentUser()?.firstName || "",
  practicePhoneNumber: "",
  fax: "",
  practiceName: getCurrentUser()?.practiceName || "",
  providerTaxId: "",
  dateOfBirth: getCurrentUser()?.dateOfBirth
    ? format(new Date(getCurrentUser()?.dateOfBirth), "mm/dd/yyyy")
    : "",
  effectiveDate: "",
  expirationDate: "",
  gender: getCurrentUser()?.gender || "",
  groupNumber: "",
  deaNumber: getCurrentUser()?.deaNumber || "",
  ssn: getCurrentUser()?.ssn || "",
  insuranceProvider: getCurrentUser()?.insuranceProviders
    ? getCurrentUser()?.insuranceProviders[0]
    : "",
  policyNumber: "",
  middleName: getCurrentUser()?.middleName || "",
  lastName: getCurrentUser()?.lastName || "",
  address1: getCurrentUser()?.address || "",
  address2: "",
  city: getCurrentUser()?.city || "",
  state: getCurrentUser()?.state || "",
  zipCode: getCurrentUser()?.zipCode || "",
  email: getCurrentUser()?.email || "",
  phoneNumber: getCurrentUser()?.phoneNumber || "",
  businessAddress1: "",
  businessAddress2: "",
  businessCity: "",
  businessState: "",
  businessZipCode: "",
  comments: "",
  npi: getCurrentUser()?.practiceNPI || "",
  providerType: getCurrentUser()?.specialty || "",
  licenseNumber: getCurrentUser()?.licenseNumbers
    ? getCurrentUser()?.licenseNumbers[0]?.id
    : "",
  stateOfLicense: getCurrentUser()?.licenseNumbers
    ? getCurrentUser()?.licenseNumbers[0]?.state
    : ""
};
