export const AdminTableActionIcon = ({
  width = 38,
  height = 38,
  color = "#1C1B1E",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="38" height="38" rx="6.57692" fill="#EEEEEE" />
      <path
        d="M13.1538 20.4617C13.961 20.4617 14.6154 19.8073 14.6154 19.0001C14.6154 18.1929 13.961 17.5386 13.1538 17.5386C12.3467 17.5386 11.6923 18.1929 11.6923 19.0001C11.6923 19.8073 12.3467 20.4617 13.1538 20.4617Z"
        fill="#1C1B1E"
      />
      <path
        d="M19 20.4617C19.8072 20.4617 20.4615 19.8073 20.4615 19.0001C20.4615 18.1929 19.8072 17.5386 19 17.5386C18.1928 17.5386 17.5385 18.1929 17.5385 19.0001C17.5385 19.8073 18.1928 20.4617 19 20.4617Z"
        fill="#1C1B1E"
      />
      <path
        d="M24.8462 20.4617C25.6533 20.4617 26.3077 19.8073 26.3077 19.0001C26.3077 18.1929 25.6533 17.5386 24.8462 17.5386C24.039 17.5386 23.3846 18.1929 23.3846 19.0001C23.3846 19.8073 24.039 20.4617 24.8462 20.4617Z"
        fill={color}
      />
    </svg>
  );
};
