import AdminLoginForm from "../components/AdminLoginForm";
import { Link } from "react-router-dom";

const AdminLogin: React.FC = () => {
  return (
    <div className="bg-[#CAD2CB] overflow-hidden w-full h-[100vh]">
      <div className="flex">
        <div className="flex flex-col items-center justify-center w-full px-4 overflow-scroll h-[90vh]">
          <p className="text-[40px] font-[600] text-[#34623F] font-[gentiumBasic]">
            <Link to="#">TempleHS Admin</Link>
          </p>
          <div className="h-6" />
          <AdminLoginForm />
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
