export const OverviewComplaintIcon = ({
  width = 90,
  height = 90,
  color = "#E45B3D",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="45" cy="45" r="45" fill="#FEF0ED" />
      <path
        d="M43.3333 50H46.6667V53.3334H43.3333V50ZM43.3333 36.6667H46.6667V46.6667H43.3333V36.6667ZM45 28.3334C35.7833 28.3334 28.3333 35.8334 28.3333 45C28.3333 49.4203 30.0893 53.6595 33.2149 56.7852C34.7625 58.3328 36.5999 59.5605 38.622 60.398C40.644 61.2356 42.8113 61.6667 45 61.6667C49.4203 61.6667 53.6595 59.9108 56.7851 56.7852C59.9107 53.6595 61.6667 49.4203 61.6667 45C61.6667 42.8113 61.2356 40.6441 60.398 38.622C59.5604 36.5999 58.3328 34.7626 56.7851 33.2149C55.2375 31.6673 53.4002 30.4396 51.3781 29.602C49.356 28.7645 47.1887 28.3334 45 28.3334ZM45 58.3334C41.4638 58.3334 38.0724 56.9286 35.5719 54.4281C33.0714 51.9276 31.6667 48.5363 31.6667 45C31.6667 41.4638 33.0714 38.0724 35.5719 35.572C38.0724 33.0715 41.4638 31.6667 45 31.6667C48.5362 31.6667 51.9276 33.0715 54.4281 35.572C56.9286 38.0724 58.3333 41.4638 58.3333 45C58.3333 48.5363 56.9286 51.9276 54.4281 54.4281C51.9276 56.9286 48.5362 58.3334 45 58.3334Z"
        fill={color}
      />
    </svg>
  );
};
