import { useQuery } from '@tanstack/react-query';
import http from '../../../utils/http';
import { DataSourceType, ErrorResponse } from '../../types';

export function useVerifyDiagnosisCode(code: string) {
  const newCode = code.toLocaleUpperCase();
  const { data, isError, error, isLoading, ...rest } = useQuery<DataSourceType[], ErrorResponse>({
    queryKey: ["verify diagnosis code", newCode],
    queryFn: async () => {
      const response = await http.get(`/charge-sheet/icd/lookup/${newCode}`);
      return response?.data?.value;
    },
    enabled: false,
    // refetchOnMount: false,
  });
  return {
    diagnosisData: data,
    isLoading,
    isError,
    error,
    ...rest,
  }
}