import { styled } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { usePostProviderLogin } from "../../api/hooks/provider/auth/usePostProviderLogin";
import Spacer from "../../components/Spacer";
import AuthLayout from "../../components/auth/AuthLayout";
import PasswordField from "../../components/form/PasswordField";
import TextField from "../../components/form/TextField";
import Button from "../../components/shared/Button";
import { MainModal } from "../../reuseables/mainModal";
import { providerInitialValues, providerLoginSchema } from "./schema/providerSchema";

const SignInPage = () => {
  const navigate = useNavigate();
  const [showEmailVerification, setShowEmailVerification] = useState(false);
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const [urlStatus, setUrlStatus] = useState(urlParams.get("status"));
  const [loginError, setLoginError] = useState("");
  const [statusMessage, setStatusMessage] = useState<string>("");
  const authFail = urlParams.get("authFail");

  const handleCloseEmailVerification = () => {
    setUrlStatus("");
    setShowEmailVerification(false);
  };

  useEffect(() => {
    if (urlStatus) {
      setShowEmailVerification(true);
      if (urlStatus === "email-verified") {
        setStatusMessage("Email Verified Successfully, Please log in");
      }
      if (urlStatus === "already-verified") {
        setStatusMessage("Your email address has already been verified");
      }
      if (urlStatus === "bad-request") {
        setStatusMessage("Error verifying this email address.");
      }
    }
  }, [urlStatus, showEmailVerification]);

  const { providerLogin, isPending: isLoading } = usePostProviderLogin({ setLoginError })

  return (
    <AuthLayout>
      <SignInPageContainer>
        <div className="w-full sm:w-[70%] md:w-[60%] lg:w-[50%] xl:w-[40%] bg-white px-5 lg:px-0 text-center">
          <div className="head-text">Log in to your account</div>
          {authFail === "true" && (
            <section className="bg-[#fdf6f6] text-[#c20707] border-[#E45B3D] border-[1px] p-[10px] text-[18px] rounded-md my-10">
              Your session has expired. Please, log in again
            </section>
          )}
          {loginError && (
            <p className="bg-[#fdf6f6] border-[#E45B3D] border-[1px] p-[10px] rounded-md text-[18px] text-[#c20707] my-4">
              Login failed: {loginError}
            </p>
          )}
          <Formik
            initialValues={providerInitialValues}
            validationSchema={providerLoginSchema}
            onSubmit={({ email, password }) => {
              setLoginError("");
              providerLogin({
                email,
                password
              });
            }}
          >
            {({ values, handleChange, touched, errors, handleSubmit }) => (
              <div className="page-form-container">
                <TextField
                  name="email"
                  type="email"
                  placeholder="johnddoe@xyz.com"
                  value={values?.email}
                  label="Email Address"
                  error={touched.email ? errors.email : ""}
                  onChange={handleChange}
                />
                <Spacer height={10} />
                <PasswordField
                  name="password"
                  placeholder="At least 8 characters"
                  value={values?.password}
                  onChange={handleChange}
                  label="Password"
                  showPasswordToggler={true}
                  error={touched.password ? errors.password : ""}
                />
                <Spacer height={10} />
                <div className="w-full flex flex-row items-center justify-between lg:justify-end ">
                  <div
                    className="mr-9 text-[14px] text-[#3D874E] cursor-pointer"
                    onClick={() => navigate("/provider/forgot-password")}
                  >
                    Forgot password?
                  </div>
                  <Button
                    onClick={handleSubmit}
                    height={60}
                    type="submit"
                    variant="primary"
                    label={"Sign In"}
                    disabled={isLoading}
                    size="small"
                  />
                </div>
              </div>
            )}
          </Formik>
          <section className="mt-10">
            <span className="text-[1rem]">Is this your first time here?</span>
            <span
              className="text-[#3D874E] ml-2 cursor-pointer text-[1rem]"
              onClick={() => navigate("/provider/signup")}
            >
              Create an account instead
            </span>
          </section>
          <MainModal
            open={showEmailVerification}
            onClose={() => setShowEmailVerification(false)}
            closeAfterTransition={false}
            title={statusMessage}
            onOk={() => handleCloseEmailVerification()}
          />
        </div>
      </SignInPageContainer>
    </AuthLayout>
  );
};

const SignInPageContainer = styled("div")(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& .head-text": {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#2E3011",
    paddingBottom: "20px"
  },
  "& .page-form-container": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContents: "flex-start",
    marginTop: "20px"
  }
}));

export default SignInPage;
