import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import http from '../../../utils/http';
import { RegisterPayload } from '../../types';


export const useRegisterProvider = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation({
    mutationKey: ['create Provider'],
    mutationFn: async ({ ...data }: RegisterPayload) => {
      const response = await http.post('/providers/register', data);
      return response;
    },

    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({ queryKey: ['create Provider'] });
      navigate(
        `/providers/verify-email/${data?.userId}/${data.email}`
      );
    },

    onError: (error) => {
      if (axios.isAxiosError(error)) {
        toast.error(error?.response?.data?.error, { toastId: "customId" });
      } else {
        toast.error('An unexpected error occurred');
      }
    }
  });

  return {
    createProvider: mutate,
    ...rest,
  };
}