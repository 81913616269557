import React from "react";
import SecondaryListTabItem from "./SecondaryListTabItem";

interface SecondaryListTabsWidgetProps {
  tabs: { label: string; value: any; urlParam?: string }[];
  activeTabLabel: string;
  handleTabClick: (label: string, urlParam?: string) => void;
}

const SecondaryListTabsWidget: React.FC<SecondaryListTabsWidgetProps> = ({
  tabs,
  activeTabLabel,
  handleTabClick
}) => {
  return (
    <div className="text-sm font-medium text-center text-gray-500 border-b dark:text-gray-400">
      <ul className="flex h-[70px] gap-x-[33px]">
        {tabs.map((tab, idx) => (
          <SecondaryListTabItem
            key={`${tab.label}_${idx}`}
            isActive={tab.label === activeTabLabel}
            onClick={() => {
              handleTabClick(tab.label, tab.urlParam);
            }}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </ul>
    </div>
  );
};

export default SecondaryListTabsWidget;
