import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import { CreateMedicalRecord } from "../../../types/medicalRecord";
import http from "../../../utils/http";


export const useCreateMedicalRecord = () => {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation({
    mutationKey: ['create medical record'],
    mutationFn: async ({ ...data }: CreateMedicalRecord) => {
      const response = await http.post("medical-records/patient/latest", data)
      return response.data;
    },
    onMutate: () => {
      const toastId = toast.loading("Please wait...");
      return { toastId };
    },
    onSuccess: (_data, _variables, context) => {
      const { toastId } = context;
      toast.update(toastId, {
        render: "Medical record created successfully",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      })
      queryClient.invalidateQueries({ queryKey: ['medical record'] });
    },

    onError: (error, _variables, context) => {
      const toastId = context?.toastId as number

      if (axios.isAxiosError(error)) {
        toast.update(toastId, {
          render: error?.response?.data?.error || "Error creating medical record",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        })

      } else {
        toast.update(toastId, {
          render: error?.message || "Error creating medical record",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        });

      }
    }
  });

  return {
    createMedicalRecord: mutate,
    ...rest,
  };
}
