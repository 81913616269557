import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import http from "../../../../utils/http";


type Payload = {
  locationState?: any
  emancipationDecisionKey?: boolean;
  isEmancipatedMinor?: boolean
  email?: string;
  password?: string;
  dateOfBirth?: string;
}

export const usePatientSignup = () => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation({
    mutationKey: ['patient signup'],
    mutationFn: async ({ ...credentials }: Payload) => {

      const referrer = credentials.locationState?.referrer;

      const response = await http.post(
        `patients/register${referrer ? "?referrer=appointment" : ""}`,
        credentials, {
        headers: {
          "Content-Type": "application/json",
          Accept: "multipart/form-data"
        }
      })
      return response.data;
    },
    onMutate: () => {
      const toastId = toast.loading("Please wait...");
      return { toastId };
    },
    onSuccess: (data, variables, context) => {
      const referrer = variables.locationState?.referrer;


      const { toastId } = context;
      toast.update(toastId, {
        render: "Successfully registered. Please check your email to verify your account.",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      })
      queryClient.invalidateQueries({ queryKey: ['provider profile details'] });
      navigate(
        `/patients/verify-email/${data.data.userId}/${data.data.email}${referrer === "appointment" ? "?referrer=appointment" : ""
        }`
      )

    },

    onError: (error, _variables, context) => {
      const toastId = context?.toastId as number

      if (axios.isAxiosError(error)) {
        toast.update(toastId, {
          render: error?.response?.data?.error || "Error registering patient",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        })

      } else {
        toast.update(toastId, {
          render: error?.message || "Error registering patient",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        });

      }
    }
  });

  return {
    patientSignup: mutate,
    ...rest,
  };
}
