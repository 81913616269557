import { useNavigate } from "react-router-dom";
import MentalHealthProviderCard from "../components/cards/MentalHealthProviderTypes";
import { ReactComponent as TherapistIcon } from "../svgs/therapist-icon.svg";
import { ReactComponent as PsychiatristIcon } from "../svgs/psychiatrist-icon.svg";

import DashboardLayout from "../components/dashboard/DashboardLayout";
import Button from "../components/shared/Button";
import { ArrowBackIcon } from "../svgs/ArrowBackIcon";

const MentalHealthVisit = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/dashboard");
  };

  const RenderPages = () => {
    return (
      <div className="w-full relative">
        <MentalHealthProviderCard
          icon={<PsychiatristIcon />}
          providerType="Psychiatrist"
          description="This includes psychiatrists and licensed advanced practice professionals like Nurse Practitioners and Physician Assistants who can diagnose and prescribe medication to treat
          mental health disorders."
          onClick={() => {
            return navigate("/dashboard/dependent/visit/schedule", {
              replace: true,
              state: {
                pageFrom: "/dashboard/dependent/visit/schedule/health",
                hasPreviousProvider: false,
                data: {
                  visiting: "psychiatrist"
                }
              }
            });
          }}
        />
        <div className="h-5" />
        <MentalHealthProviderCard
          icon={<TherapistIcon />}
          providerType="Therapist"
          description="A licensed counsellor who can identify your goals, aspirations, potential and come up with viable solutions to problems that cause emotional distress."
          onClick={() => {
            return navigate("/dashboard/dependent/visit/schedule", {
              replace: true,
              state: {
                pageFrom: "/dashboard/dependent/visit/schedule/health",
                hasPreviousProvider: false,
                data: {
                  visiting: "therapist"
                }
              }
            });
          }}
        />
      </div>
    );
  };

  return (
    <DashboardLayout headerTitle="Schedule Appointment">
      <div className="flex justify-between items-center pr-0 pl-0 w-full mt-[20px] mb-8 lg:mb-0">
        <div className="flex flex-col items-center w-full">
          <div className="w-full">
            <div className="w-fit flex">
              <Button
                onClick={() => handleBackClick()}
                icon={<ArrowBackIcon />}
                iconPosition="left"
                size="medium"
                label="Go back"
                variant="tertiary"
                additionalClassname="text-[#103C1B]"
              ></Button>
            </div>
            <div className="ment-m">
              <div className="w-[90%]">
                <p className="text-[1.2rem] font-normal text-[#103C1B]">
                  Who do you want to visit?
                </p>
              </div>
              <div className="w-full flex flex-col -z-9 h-full relative">
                <RenderPages />
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default MentalHealthVisit;
