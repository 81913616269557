import React from "react";
import ListTabsItem from "./ListTabItem";

interface ListTabsWidgetProps {
  tabs: { label: string; urlParam?: string }[];
  activeTabLabel: string;
  handleTabClick: (label: string, urlParam?: string) => void;
}

const ListTabsWidget: React.FC<ListTabsWidgetProps> = ({
  tabs,
  activeTabLabel,
  handleTabClick
}) => {
  return (
    <div className="text-sm font-medium text-center w-full text-gray-500 border-b dark:text-gray-400">
      <ul className="flex h-[46px] gap-x-[5px] min-[821px]:gap-x-[51px]">
        {tabs.map((tab, idx) => (
          <ListTabsItem
            key={`${tab.label}_${idx}`}
            isActive={tab.label === activeTabLabel}
            onClick={() => handleTabClick(tab.label)}
            label={tab.label}
          />
        ))}
      </ul>
    </div>
  );
};

export default ListTabsWidget;
