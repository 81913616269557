import { useFormik } from "formik";
import { claims_initialValues, claims_validationSchema } from "../../schema/claims_for_review.schema";

export const useFormikWithInitialValues = () => {
  const formik = useFormik({
    initialValues: claims_initialValues,
    validationSchema: claims_validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: () => { }
  });
  return formik;
};