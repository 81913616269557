import { useQuery } from '@tanstack/react-query';
import http from '../../../utils/http';
import { ErrorResponse, ProviderPatient } from '../../types';

export function useGeProviderPatient() {
  const { data, isLoading, refetch, ...rest } = useQuery<ProviderPatient, ErrorResponse>({
    queryKey: ["providers patients"],
    queryFn: async () => {
      const response = await http.get(`providers/patients`);
      return response.data;
    },
    staleTime: Infinity,
  });
  return {
    providerPatientsData: data as ProviderPatient,
    isLoading,
    refetchPPatient: refetch,
    ...rest,
  }
}