import { PatientDetailsType } from "./patientDetails";

export const prescriptionTabs = [
  {
    label: "Current",
    urlParam: "/current"
  },
  {
    label: "Previous",
    urlParam: "/previous"
  }
];

export const mobileDrugBodyParams = [
  {
    fieldName: "dosage",
    displayName: "frequency"
  },
  {
    fieldName: "providerName",
    displayName: "provider"
  },
  {
    fieldName: "pharmacy",
    displayName: "pharmacy"
  }
];

export interface LocationState {
  activePrescriptionTab: string;
}

export interface PrescriptionType {
  drug: string;
  dosage: string;
  duration: string;
  status: string;
  providerName: string;
  pharmacy: string;
}

export interface PrescriptionProps {
  activePatient: PatientDetailsType | undefined;
  updatePrescriptionList: (
    data: PatientDetailsType["patientDetails"]["prescriptions"]
  ) => void;
}
