import { useQuery } from '@tanstack/react-query';
import http from '../../../utils/http';


interface QueryData {
  specialty?: string;
  zipCode?: string;
  date?: string;
  time?: string;
  insurance?: string;
  [key: string]: any;
}

const cleanQueryData = (queryData: QueryData) => {
  return Object.fromEntries(
    Object.entries(queryData).filter(([_, value]) => value !== undefined)
  );
};

export function useGetProviderAppointment(queryData: QueryData) {
  const { data, isLoading, refetch, ...rest } = useQuery({
    queryKey: ["provider appointment ", queryData],
    queryFn: async () => {
      const cleanedQueryData = cleanQueryData(queryData);
      const response = await http.get("appointments/providers", {
        params: cleanedQueryData,
        paramsSerializer: function paramsSerializer(params: QueryData) {
          return Object.entries(params)
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join('&');
        },
      });
      return response.data.patientsProviders ?? [];
    },
    staleTime: 1000 * 60 * 60, // data is stale every 1 hour
    refetchInterval: 1000 * 60 * 60, // refetch every 1 hour
  });
  return {
    providerAppointmentData: data,
    isLoading,
    refetchPatientProvider: refetch,
    ...rest,
  }
}