export const HeightIcon = ({
  width = 16,
  height = 17,
  color = "#87928A",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.833374 2.83398C0.833374 2.00556 1.50495 1.33398 2.33337 1.33398H13.6667C14.4952 1.33398 15.1667 2.00556 15.1667 2.83398V14.1673C15.1667 14.9958 14.4952 15.6673 13.6667 15.6673H2.33337C1.50495 15.6673 0.833374 14.9958 0.833374 14.1673V2.83398ZM2.33337 2.33398C2.05723 2.33398 1.83337 2.55784 1.83337 2.83398V14.1673C1.83337 14.4435 2.05723 14.6673 2.33337 14.6673H13.6667C13.9429 14.6673 14.1667 14.4435 14.1667 14.1673V2.83398C14.1667 2.55784 13.9429 2.33398 13.6667 2.33398H2.33337Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00001 5.35156C6.60817 5.35156 5.40991 5.93759 4.38452 7.17118C4.208 7.38353 3.89276 7.41259 3.6804 7.23607C3.46804 7.05955 3.43899 6.74431 3.6155 6.53195C4.80672 5.09886 6.27512 4.35156 8.00001 4.35156C9.7249 4.35156 11.1933 5.09886 12.3845 6.53195C12.561 6.74431 12.532 7.05955 12.3196 7.23607C12.1073 7.41259 11.792 7.38353 11.6155 7.17118C10.5901 5.93759 9.39186 5.35156 8.00001 5.35156Z"
        fill={color}
      />
      <path
        d="M8 10.834C8.5523 10.834 9 10.3863 9 9.83398C9 9.28168 8.5523 8.83398 8 8.83398C7.4477 8.83398 7 9.28168 7 9.83398C7 10.3863 7.4477 10.834 8 10.834Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.04235 7.0934C6.26693 6.93272 6.57925 6.98452 6.73993 7.2091L8.40936 9.54243C8.57004 9.76701 8.51824 10.0793 8.29366 10.24C8.06908 10.4007 7.75677 10.3489 7.59609 10.1243L5.92665 7.79098C5.76597 7.5664 5.81777 7.25408 6.04235 7.0934Z"
        fill={color}
      />
    </svg>
  );
};
