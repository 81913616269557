export const CalendarIcon = ({
  width = 20,
  height = 21,
  color = "#103C1B",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg height={height} width={width} viewBox="0 0 20 21" fill="none" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.04175 3.8335C1.04175 3.48832 1.32157 3.2085 1.66675 3.2085H18.3334C18.6786 3.2085 18.9584 3.48832 18.9584 3.8335V18.8335C18.9584 19.1787 18.6786 19.4585 18.3334 19.4585H1.66675C1.32157 19.4585 1.04175 19.1787 1.04175 18.8335V3.8335ZM2.29175 4.4585V18.2085H17.7084V4.4585H2.29175Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0417 8.8335C11.0417 8.48832 11.3216 8.2085 11.6667 8.2085H15.0001C15.3453 8.2085 15.6251 8.48832 15.6251 8.8335V14.6668C15.6251 15.012 15.3453 15.2918 15.0001 15.2918H11.6667C11.3216 15.2918 11.0417 15.012 11.0417 14.6668V8.8335ZM12.2917 9.4585V14.0418H14.3751V9.4585H12.2917Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.08325 1.5415C7.42843 1.5415 7.70825 1.82133 7.70825 2.1665V5.49984C7.70825 5.84502 7.42843 6.12484 7.08325 6.12484C6.73807 6.12484 6.45825 5.84502 6.45825 5.49984V2.1665C6.45825 1.82133 6.73807 1.5415 7.08325 1.5415Z"
        fill="#1C1B1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9167 1.5415C13.2619 1.5415 13.5417 1.82133 13.5417 2.1665V5.49984C13.5417 5.84502 13.2619 6.12484 12.9167 6.12484C12.5716 6.12484 12.2917 5.84502 12.2917 5.49984V2.1665C12.2917 1.82133 12.5716 1.5415 12.9167 1.5415Z"
        fill="#1C1B1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.375 8.8335C4.375 8.48832 4.65482 8.2085 5 8.2085H8.33333C8.67851 8.2085 8.95833 8.48832 8.95833 8.8335V14.6668C8.95833 15.012 8.67851 15.2918 8.33333 15.2918H5C4.65482 15.2918 4.375 15.012 4.375 14.6668C4.375 14.3217 4.65482 14.0418 5 14.0418H7.70833V9.4585H5C4.65482 9.4585 4.375 9.17867 4.375 8.8335Z"
        fill="#1C1B1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.20825 11.75C5.20825 11.4048 5.48807 11.125 5.83325 11.125H8.33325C8.67843 11.125 8.95825 11.4048 8.95825 11.75C8.95825 12.0952 8.67843 12.375 8.33325 12.375H5.83325C5.48807 12.375 5.20825 12.0952 5.20825 11.75Z"
        fill="#1C1B1E"
      />
    </svg>
  );
};
