export const VideoIcon = ({
  width = 24,
  height = 25,
  color = "#3D874E",
  className
}: {
  width?: string | number;
  height?: string | number;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      className={className}
      viewBox="0 0 64 64"
      aria-labelledby="title"
      aria-describedby="desc"
      role="img"
    >
      <path
        data-name="layer1"
        fill="#8fa9d8"
        d="M62 19L42 29V17H2v32h40V37l20 10V19z"
      ></path>
      <path
        data-name="opacity"
        fill="#fff"
        opacity=".25"
        d="M42 29v4l20-10v-4L42 29zM2 17h40v4H2z"
      ></path>
      <path
        data-name="opacity"
        fill="#000064"
        opacity=".16"
        d="M2 45h40v4H2zm40-8l20 10v-4L42 33v4z"
      ></path>
      <path
        data-name="stroke"
        fill="none"
        stroke="#2e4369"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M62 19L42 29V17H2v32h40V37l20 10V19z"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};
