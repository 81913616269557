import { ReactComponent as GreenArrowSlant } from "../../svgs/green-arrow-slant.svg";
import Spacer from "../Spacer";
import Button from "../shared/ButtonSecondary";

interface HealthTipCardProps {
  imageUrl?: string;
  description?: string;
  onClick?: (arg: any) => void;
}

const HealthTipCard = ({
  description,
  imageUrl,
  onClick = () => { }
}: HealthTipCardProps) => (
  <div className="flex lg:flex-nowrap flex-wrap justify-start items-center border border-[#EBEBEB] rounded-lg bg-[#fff] w-full min:h-[175px] bg-gradient-to-b-[rgba(0, 0, 0, .36), rgba(0, 0, 0, .36)] cursor-pointer grow shadow-md pb-4 lg:py-0 box-border">
    <div className="w-[30%] h-[175px] flex lg:justify-start">
      <img src={imageUrl} id="tip-img" alt="Home img" />
    </div>
    <div className="hidden lg:block w-6" />
    <div className="text-[12px] w-[70%] font-normal flex flex-col flex-1 lg:justify-end px-4 lg:p-0">
      <Spacer height={12} />
      <div className="w-full flex justify-start text-[#324705] text-[20px] font-[400] text-left  break-words">
        {description}
      </div>

      <Spacer height={12} />
      <div className="w-full flex justify-between items-center">
        <div className="flex justify-end">
          <Button
            onClick={onClick}
            variant="secondary"
            label="Read More"
            icon={<GreenArrowSlant />}
            iconPosition="right"
            size="medium"
          />
        </div>
      </div>
    </div>
  </div>
);

export default HealthTipCard;
