import clsx from "clsx";

import Spacer from "../Spacer";

interface ButtonProps {
  variant?: "primary" | "secondary" | "tertiary";
  size?: "large" | "small" | "medium" | "extra-large";
  label?: string;
  onClick?: (arg: any) => void;
  type?: "button" | "submit";
  icon?: React.ReactNode;
  width?: number | string;
  height?: number | string;
  loading?: boolean;
  disabled?: boolean;
  textColor?: string;
  capitalized?: boolean;
  additionalClassname?: any;
  iconPosition?: "left" | "right";
  children?: React.ReactNode;
  iconSpace?: number;
  textSizeProp?: string;
}

const BtnButton: React.FC<ButtonProps> = ({
  variant,
  label,
  onClick = () => {},
  type = "button",
  icon,
  width,
  height,
  loading = false,
  disabled = false,
  textColor,
  capitalized,
  additionalClassname,
  size = "large",
  iconPosition = "left",
  iconSpace = 16,
  textSizeProp
}) => {
  const computedStyle = () => {
    const style: { width?: string; height?: string; color?: string } = {};

    if (width) {
      style.width = `${width}`;
    }

    if (height) {
      style.height = `${height}`;
    }

    if (textColor) {
      style.color = textColor;
    }

    return style;
  };

  return (
    <button
      className={clsx({
        "flex items-center text-sm justify-center rounded-[8px] font-[500] w-full":
          true,
        "bg-[#34623F] text-[#fff]": variant === "primary",
        "bg-[#fdfdfd]": variant && ["secondary", "tertiary"].includes(variant),
        "text-[#34623F] border-[1px] border-solid border-[#34623F]":
          variant === "secondary",
        "border-[rgba(51, 51, 51, 0.1)]": variant === "tertiary",
        "opacity-50": disabled,
        "w-[260px] h-[40px]": size === "large",
        "!w-[130px] h-[40px]": size === "medium",
        "!w-[90px] h-[32px]": size === "small",
        "w-full h-[60px]": size === "extra-large",
        capitalize: capitalized,
        [additionalClassname]: additionalClassname
      })}
      onClick={onClick}
      type={type}
      style={computedStyle()}
      disabled={disabled}
    >
      {icon && iconPosition === "left" && (
        <>
          {icon}
          <Spacer width={iconSpace} />
        </>
      )}
      {!loading ? <p className={textSizeProp}>{label}</p> : "Loading..."}
      {icon && iconPosition === "right" && (
        <>
          <Spacer width={iconSpace} />
          {icon}
        </>
      )}
    </button>
  );
};

export default BtnButton;
