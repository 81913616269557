import React from "react";
import { useLocation } from "react-router-dom";
import { useProviderAuthState } from '../../../api/hooks/provider/auth/useProviderAuthState';
import { ReactComponent as MenuToggler } from "../../../svgs/menu-toggler.svg";

interface IProps {
  openRetractableMenu: () => void;
  headerTitle?: string;
}

const ProviderMobileHeader: React.FC<IProps> = ({ openRetractableMenu, headerTitle }) => {
  const location = useLocation();
  const { user } = useProviderAuthState()

  const getHeaderTitle = () => {
    if (location.pathname === "/provider/dashboard") {
      return "Dashboard";
    }

    if (location.pathname.includes("/provider/patients")) {
      return "Patients";
    }

    if (location.pathname.includes("/provider/appointments")) {
      return "Appointments";
    }

    if (location.pathname.includes("/provider/messages")) {
      return "Messages";
    }

    if (location.pathname.includes("/provider/payment-billing")) {
      return "Payment & Billing";
    }

    if (location.pathname.includes("/provider/settings")) {
      return "Settings";
    }
  };

  return (
    <div className="flex justify-between px-3 lg:hidden bg-white w-full h-[60px] items-center top-0 z-100 relative m-auto mt-0 shadow-md">
      <button
        type="button"
        className="cursor-pointer border-none outline-none"
        onClick={openRetractableMenu}
      >
        <MenuToggler />
      </button>
      <p className="font-semibold text-sm text-[#141921]">{headerTitle || getHeaderTitle()}</p>
      <div className="mr-1 rounded-full flex relative flex-col items-center justify-center">
        <img
          src={user?.avatarUrl || "/assets/images/avatar.png"}
          className="h-[40px] w-[40px] rounded-full"
          alt="provider profile"
        />
      </div>
    </div>
  );
};

export default ProviderMobileHeader;
