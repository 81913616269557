import { CertifiedBackgroundIcon } from "../../svgs/CertifiedBackgroundIcon";
import { useNavigate } from "react-router-dom";
import Spacer from "../Spacer";
import Button from "../shared/Button";
import { UserType } from "../../types/common";

const PasswordResetSuccess = ({ userType }: { userType: UserType }) => {
  const navigate = useNavigate();

  return (
    <div className="w-[90%] box-border min-[821px]:w-[614px] bg-white">
      <div className="text-center">
        <div className="w-full mx-auto flex justify-center">
          <CertifiedBackgroundIcon color="white" />
        </div>
        <Spacer height={20} />
        <p className="text-[34px] font-[800] text-[#103C1B]">
          You have a new password
        </p>
        <Spacer height={10} />
        <p className="font-[400] text-[18px] text-[#103C1B]">
          Your password has been reset successfully.
        </p>
      </div>
      <Spacer height={30} />
      <div className="flex  justify-center items-center w-[100%]">
        <Button
          additionalClassname={"text-[18px] font-[400] w-[150px]"}
          width={98}
          height={60}
          type="submit"
          variant="primary"
          label="Sign in now"
          onClick={() => navigate(`/${userType}/login`)}
        />
      </div>
    </div>
  );
};

export default PasswordResetSuccess;
