import { useQuery } from '@tanstack/react-query';
import { ComplaintObject } from '../../../admin/types/admin_complaints';
import adminHttp from '../../../utils/adminHttp';

type Props = {
  userId: string
}
export function useGetSingleAdminComplaint({ userId }: Props) {
  const { data, isLoading, ...rest } = useQuery({
    queryKey: ["get single admin complaint", userId],
    queryFn: async () => {
      const response = await adminHttp.get(`complaint/id/${userId}`);
      return response.data;
    },
    staleTime: Infinity,
    enabled: !!userId, // only fetch data if userId is provided
  });
  return {
    oneComplaintList: data as ComplaintObject,
    isLoading,
    ...rest,
  }
}