import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useState } from "react";

export interface AccordionProps {
  data: IAccordionComponentData[];
  heading?: string;
}
interface IAccordionComponentData {
  id: string;
  title?: string;
  subtitle?: string;
  body: {
    field: string;
    value: string;
  }[];
}

const AccordionComponent = ({ data, heading }: AccordionProps) => {
  const [expanded, setExpanded] = useState<string | null>(null);

  const handleChange = (id: string) => {
    setExpanded(prev => (prev === id ? null : id));
  };

  return (
    <div className="shadow-accordion">
      {heading && (
        <div className="bg-[#EEEEE9] py-3 pl-3 text-[#34623F] font-medium">
          {heading}
        </div>
      )}
      {data?.map((item, index) => (
        <Accordion
          key={index}
          elevation={0}
          expanded={expanded === item.id}
          onChange={() => handleChange(item.id)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            key={index}
          >
            <div className="flex row justify-between w-full items-center text-[#2E3011]">
              <div>
                <h2>{item.title}</h2>
                {expanded !== item.id && <p>{item.subtitle}</p>}
              </div>
              <span className="text-xs w-[37px]">
                {expanded === item.id ? "Hide Details" : "Show Details"}
              </span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {item?.body?.map(({ field, value }, index) => (
              <div
                key={index}
                className="capitalize text-sm mb-3 last:mb-0 text-[#2E3011]"
              >
                <h3>{field}</h3>
                <div>
                  {value[0] && Array.isArray(value)
                    ? Object.keys(value[0])?.map((item, index) => (
                        <div key={index}>
                          <input
                            className="accent-[#3D874E]"
                            type="checkbox"
                            checked={value[0][item]}
                            readOnly={true}
                          />{" "}
                          <span>{item}</span>
                        </div>
                      ))
                    : value}
                </div>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default AccordionComponent;
