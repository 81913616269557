import { format } from "date-fns";
import { Fragment, useMemo } from "react";
import { Column } from "react-table";
import { formTitleMapper } from "../../../pages/PatientDetail/interfaces";
import { DocumentationTableParams } from "../../../pages/PatientDetail/interfaces/documentation";


export function useDocsTableCols() {
  const TABLE_COLUMNS = useMemo<Column<DocumentationTableParams>[]>(
    () => [
      {
        Header: "Date",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => {
          return (
            <span className="capitalize">
              {format(new Date(value), "MM/dd/yyyy") || "-"}
            </span>
          );
        }
      },
      {
        Header: "Time",
        accessor: "updated_at",
        Cell: ({ cell: { value } }) => {
          return (
            <Fragment>
              {format(new Date(value), "p") || "-"}
            </Fragment>
          );
        }
      },
      {
        Header: "Note Title",
        accessor: "noteType",
        Cell: ({ cell: { value } }) => {
          return (
            <Fragment>
              {value ? formTitleMapper[value] : "-"}
            </Fragment>
          );
        }
      }
    ],
    []
  );

  return {
    TABLE_COLUMNS
  }
}