import { format } from "date-fns";
import { Fragment, useMemo } from "react";
import { Column } from "react-table";
import { InsuranceCredentialing } from "../../types/insurance_credentialing";

export function useCredentialingRequestsTableCols() {
  const INSUR_CREDENTIALING_COLUMNS = useMemo<Column<InsuranceCredentialing>[]>(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Credential ID",
        accessor: "credentialId",
      },
      {
        Header: "Provider ID",
        accessor: "providerId",
      },
      {
        Header: "Insurance Provider",
        accessor: "insuranceProvider",
      },
      {
        Header: "Policy Number",
        accessor: "policyNumber",
      },
      {
        Header: "Effective Date",
        accessor: "effectiveDate",
        Cell: ({ cell: { value } }) => {
          return <Fragment>{value ? format(new Date(value), "yyyy-MM-dd") : "N/A"}</Fragment>;
        },
      },
      {
        Header: "Expiration Date",
        accessor: "expirationDate",
        Cell: ({ cell: { value } }) => {
          return <Fragment>{value ? format(new Date(value), "yyyy-MM-dd") : "N/A"}</Fragment>;
        },
      },
      {
        Header: "Created At",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => {
          return <Fragment>{value ? format(new Date(value), "yyyy-MM-dd") : "N/A"}</Fragment>;
        },
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
        Cell: ({ cell: { value } }) => {
          return <Fragment>{value ? format(new Date(value), "yyyy-MM-dd") : "N/A"}</Fragment>;
        },
      },
    ],
    []
  );

  return {
    INSUR_CREDENTIALING_COLUMNS,
  };
}
