import styled from "@emotion/styled";
import axios from "axios";
import { useFormik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { getS3SignedUrl } from "../api/hooks/common/fetchers.common";
import { useCreateComplaint } from "../api/hooks/complaints/createComplaints";
import { CancelIcon } from "../svgs/CancelIcon";
import { generateUUID } from "../utils/helpers";
import TextAreaField from "./form/TextAreaField";
import BtnButton from "./shared/BtnButton";
import { Loading } from "./shared/Loading";
import ModalPortal from "./shared/ModalPortal";

interface IProps {
  actionTrigger: () => void;
  closeModal: () => void;
  isVisible: boolean;
}

type ReportDocType = {
  file?: any;
};

const ReportProblemModal = ({
  actionTrigger,
  closeModal,
  isVisible = false
}: IProps) => {
  const [isShownSuccess, setIsShownSuccess] = useState(false);
  const [reportDoc, setReportDoc] = useState({} as ReportDocType);
  const [documentUrl, setDocumentUrl] = useState<string>("");
  const [s3SignedUrl, setS3SignedUrl] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);


  const { createComplaint, isPending: isCreateComplaintPending } = useCreateComplaint()


  const formik = useFormik({
    initialValues: {
      description: "",
    },
    validationSchema: Yup.object({
      description: Yup.string().required("Description is required"),
    }),
    onSubmit: () => { }
  });

  const handleFileUpload = async (e: any) => {
    const file = e?.target?.files[0];
    const maxAllowedSize = 2 * 1024 * 1024;
    if (file.size > maxAllowedSize) {
      toast.error("File size should be less than 2MB");
      return;
    } else {
      setReportDoc({ file });
      const key = generateUUID() + file.name.slice(file.name.lastIndexOf("."));
      (async () => {
        const response = await getS3SignedUrl({
          operation: "putObject",
          key,
          object: "account"
        });
        setS3SignedUrl(response.signedUrlParams.signedUrl);
        setDocumentUrl(`${response.signedUrlParams.host}/${key}`);
      })();
    }
  };

  const handleReportDocDelete = () => {
    setReportDoc({});
  };

  if (isLoading) {
    return <div className="w-1/2 h-1/2 flex items-center justify-center">
      <Loading />
    </div>
  }

  return isVisible ? (
    <ModalPortal>
      <div
        style={{
          backgroundColor: "rgba(38, 38, 38, 0.75)",
          zIndex: 100,
          display: "flex",
          justifyContent: "center"
        }}
        className="w-screen h-screen fixed flex flex-col justify-center items-center top-0 bg-opacity-20 backdrop-blur-[5px]"
      >
        <div className="flex justify-end  w-[90%] lg:w-[462px] mx-auto py-2">
          <div
            onClick={() => {
              closeModal();
            }}
            className="p-2 bg-white rounded-full cursor-pointer"
          >
            <CancelIcon className="h-full w-full" />
          </div>
        </div>
        <div className="bg-white w-[90%] lg:w-[462px] px-6 pt-6 pb-4 lg:p-6 shadow-lg rounded-[16px] border-box">
          <p className="text-[#2E3011] text-[24px] font-[600]">
            Report an issue
          </p>
          {isShownSuccess ? (
            <>
              <p className="text-center my-2 font-[500]">
                Thank you for reporting this issue.
              </p>
              <div className="flex justify-end mt-8 w-full">
                <div className="flex w-[383px] h-[60px]">
                  <BtnButton
                    loading={isLoading}
                    type="button"
                    variant="primary"
                    label={isShownSuccess ? "Done" : "Submit"}
                    onClick={() => {
                      setIsLoading(true);
                      // IF SUCCESSFUL API CALL
                      setIsShownSuccess(true);
                      setIsLoading(false);
                    }}
                    size="extra-large"
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="flex flex-col  items-center justify-center h-full">
              <form className="w-full h-full p-2">
                <div className="w-full my-4">
                  <TextAreaField
                    name="description"
                    rows={3}
                    placeholder="Please include as much info as possible..."
                    label="Issue"
                    value={formik.values?.description}
                    onChange={formik.handleChange}
                  />
                </div>

                <div className="w-full">
                  <ReportContainer>
                    <input
                      id="report_file_upload"
                      className="invisible_file_upload"
                      onChange={handleFileUpload}
                      type="file"
                      accept=".pdf, image/*"
                    />
                    <p className="text-[#103C1B] text-[18px]">Add file</p>
                    <div
                      className="file_upload_cont"
                      onClick={() =>
                        document.getElementById("report_file_upload")?.click()
                      }
                    >
                      <div className="trigger-text-cont">
                        <span className="text-[#103C1B] text-[18px]">
                          Click to upload file (max-file-size: 2MB.)
                        </span>
                      </div>
                    </div>
                    {reportDoc?.file && (
                      <div className="report-name-cont">
                        <span>{reportDoc?.file?.name}</span>
                        <span
                          className="remove-report-doc"
                          onClick={handleReportDocDelete}
                        >
                          Delete
                        </span>
                      </div>
                    )}
                  </ReportContainer>
                </div>

                <div className="flex justify-end mt-8 w-full">
                  <div className="flex w-[383px] h-[60px]">
                      <BtnButton
                      type="button"
                        disabled={isLoading}
                      variant="primary"
                        label={isShownSuccess ? "Done" : "Submit"}
                      onClick={async () => {
                        createComplaint({
                          description: formik.values.description,
                          documentUrl: documentUrl ?? '',
                        });
                        if (documentUrl) {
                          axios.put(s3SignedUrl, reportDoc.file);
                        }

                        formik.resetForm();
                        actionTrigger();
                      }}
                      size="extra-large"
                    />
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </ModalPortal>
  ) : null;
};

const ReportContainer = styled.div`
  marginTop: ".9rem",
  "& .invisible_file_upload": {
    opacity: 0,
    position: "absolute",
    left: "-100%"
  },
  "& .file_upload_cont": {
    border: " 1px dashed #34623F",
    borderColor: "#103C1B",
    cursor: "pointer",
    background: "rgba(52, 98, 63, 0.07)",
    borderRadius: "4px",
    padding: "2rem",
    maxWidth: "300px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    marginTop: ".4rem",

    "& .trigger-text-cont": {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center"
    }
  },

  "& .report-name-cont": {
    marginTop: ".4rem",
    fontSize: ".76rem",
    position: "relative",
    width: "70%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",

    "& .remove-report-doc": {
      cursor: "pointer",
      color: "#F83535"
    }
  }
  `;

export default ReportProblemModal;
