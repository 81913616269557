import { useQuery } from '@tanstack/react-query';
import billerHttp from '../../../utils/billerHttp';
import { BillerChargeSheet, BillerChargeSheetsResponse, ErrorResponse } from '../../types';

export function useGeBillerChargeSheet() {
  const { data, isLoading, ...rest } = useQuery<BillerChargeSheetsResponse, ErrorResponse>({
    queryKey: ["get all biller charge-sheet"],
    queryFn: async () => {
      const response = await billerHttp.get(`billers/charge-sheets/all`);
      return response.data;
    },
    staleTime: Infinity,
  });
  return {
    allBillerChargeSheet: data?.billerChargeSheets as BillerChargeSheet[],
    isLoading,
    ...rest,
  }
}