import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { useGetProviderTemplates } from '../../../api/hooks/provider/useGetProviderTemplate';
import { Loading } from "../../../components/shared/Loading";
import { ReactComponent as EmptyMsgIcon } from "../../../svgs/empty-smg.svg";
import { Template } from "../../../types/template";
import ProviderDashboardLayout from "../../components/dashboard/ProviderDashboardLayout";
import PreviewPage from "./PreviewPage";
import TemplateList from "./TemplateList";

const Templates = () => {
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);

  const { templates, isError, error, isLoading } = useGetProviderTemplates()

  if (isError) {
    const message = axios.isAxiosError(error) ? error?.response?.data?.error : "Error fetching templates";
    toast.error(message, { toastId: "customId" })
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <Loading />
      </div>
    )
  }

  if (!isLoading && templates.length === 0) {
    return (
      <ProviderDashboardLayout headerTitle="Templates">
        <div className="h-full w-full m-auto -mt-[40px] flex flex-col justify-center items-center">
          <EmptyMsgIcon />
          <div className="font-semibold text-[#34623f] mt-4 text-center">
            Nothing here
          </div>
          <div className="text-[#103C1B] pt-2 lg:w-[322px] text-center">
            You do not have any templates yet.
          </div>
        </div>
      </ProviderDashboardLayout>
    );
  }

  return (
    <ProviderDashboardLayout headerTitle="Templates">
      {selectedTemplate === null ? (
        <TemplateList
          templates={templates}
          previewTemplate={template => setSelectedTemplate(template)}
        />
      ) : (
        <PreviewPage
          closePreview={() => setSelectedTemplate(null)}
          template={selectedTemplate}
        />
      )}
    </ProviderDashboardLayout>
  );
};

export default Templates;
