import { useQuery } from '@tanstack/react-query';
import { AdminOverView } from '../../../admin/types/dashboard';
import adminHttp from '../../../utils/adminHttp';

export function useGetAdminDashboard() {
  const { data, isLoading, ...rest } = useQuery({
    queryKey: ["get admin dashboard"],
    queryFn: async () => {
      const response = await adminHttp.get("admins/dashboard");
      return response.data;
    },
    staleTime: Infinity,
  });
  return {
    adminOverView: data as AdminOverView,
    isLoading,
    ...rest,
  }
}