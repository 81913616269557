export const OverviewSProviderIcon = ({
  width = 90,
  height = 90,
  color = "#EF5DA8",
  className
}: {
  height?: number;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={height/2} cy={height/2} r={height/2} fill="#FFEEF6" />
      <path
        d="M43.95 52.8H47.05V48.15H51.7V45.05H47.05V40.4H43.95V45.05H39.3V48.15H43.95V52.8ZM33.1 59C32.2475 59 31.518 58.6967 30.9114 58.0901C30.3038 57.4825 30 56.7525 30 55.9V37.3C30 36.4475 30.3038 35.718 30.9114 35.1114C31.518 34.5038 32.2475 34.2 33.1 34.2H39.3V31.1C39.3 30.2475 39.6038 29.5174 40.2114 28.9098C40.818 28.3033 41.5475 28 42.4 28H48.6C49.4525 28 50.1826 28.3033 50.7901 28.9098C51.3967 29.5174 51.7 30.2475 51.7 31.1V34.2H57.9C58.7525 34.2 59.4825 34.5038 60.0901 35.1114C60.6967 35.718 61 36.4475 61 37.3V55.9C61 56.7525 60.6967 57.4825 60.0901 58.0901C59.4825 58.6967 58.7525 59 57.9 59H33.1ZM33.1 55.9H57.9V37.3H33.1V55.9ZM42.4 34.2H48.6V31.1H42.4V34.2ZM33.1 55.9V37.3V55.9Z"
        fill={color}
      />
    </svg>
  );
};
