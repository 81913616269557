import { format } from "date-fns";
import { CalendarDateIcon } from "../../svgs/CalendarDateIcon";
import { ReactComponent as DurationIcon } from "../../svgs/duration-icon.svg";
import Button from "../shared/Button";

interface UpcomingEventCardProps {
  title: string;
  name: string;
  type: string;
  startTime: string;
  endTime: string;
  appointmentDate: Date;
  handleJoinCallClick: () => void;
}

const UpcomingEventCard = ({
  title,
  name,
  type,
  startTime,
  endTime,
  appointmentDate,
  handleJoinCallClick
}: UpcomingEventCardProps) => {
  return (
    <div className="rounded-[8px] drop-shadow bg-[#fff] w-full min-h-auto p-4 mb-2">
      {new Date().getTime() >= new Date(appointmentDate).getTime() && (
        <p className="text-left text-[#324705] font-semibood text-[24px] py-2">
          Happening Now!
        </p>
      )}
      <div
        className="text-left text-[#324705] font-normal text-[14px]"
        data-toggle="menu"
      >
        Appointment with {name}, {type || ""}, {title || ""}
      </div>
      <div className="w-full flex justify-between items-center py-2">
        <>
          <div className="text-[#7E954B] text-[14px] flex items-center justify-start">
            <DurationIcon />{" "}
            <p className="ml-2">
              {startTime} - {endTime}
            </p>
          </div>
          {/* // check if its 30mins before call, show button
          /// list all the lists */}
          {new Date().getTime() >= new Date(appointmentDate).getTime() ? (
            <Button
              onClick={handleJoinCallClick}
              height={60}
              width={97}
              variant="primary"
              label="Join Call"
              size="medium"
            />
          ) : (
            <div className="text-[#7E954B] text-[14px] flex items-center justify-end">
              <CalendarDateIcon />
              <p className="ml-2">
                {format(new Date(appointmentDate), "EEEE, dd LLL")}
              </p>
            </div>
          )}
        </>
      </div>
      {new Date().getTime() <= new Date(appointmentDate).getTime() && (
        <div className="text-left text-[#324705] font-normal text-[16px]">
          Please join the call a few minutes early to test your device microphone
          and video. Your provider will join by {startTime}
        </div>
      )}
    </div>
  );
};

export default UpcomingEventCard;
