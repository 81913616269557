import { ReactComponent as GreenArrowSlant } from "../../svgs/green-arrow-slant.svg";
import Button from "../shared/ButtonSecondary";

interface SinglePhamarcyCardProps {
  name: string;
  address: string;
  city: string;
  state: string;
  postcode: string;
  distance: number;
  onClick: (arg: any) => void;
}

const SinglePhamarcyCard = ({
  name,
  // city,
  address,
  // state,
  // postcode,
  distance,
  onClick = () => { }
}: SinglePhamarcyCardProps) => (
  <div className="flex flex-col justify-center border border-[#EBEBEB] rounded-lg bg-[#fff] w-full min-h-[243px] bg-gradient-to-b-[rgba(0, 0, 0, .36), rgba(0, 0, 0, .36)] cursor-pointer grow shadow-md mb-4 p-4">
    <div className="py-4 text-[18px]">
      <p className="font-medium">{name}</p>
      <p className="">{address}</p>
      {/* <p className="">{city}</p>
        <p className="">{state}</p>
        <p className="">{postcode}</p> */}
      <p className="py-2">
        Distance from you: {Number(distance * 0.000621371).toFixed(2)} miles
      </p>
    </div>

    <div className="w-full flex justify-center">
      <Button
        onClick={onClick}
        variant="secondary"
        label="Select Pharmacy"
        icon={<GreenArrowSlant />}
        iconPosition="right"
      />
    </div>
  </div>
);

export default SinglePhamarcyCard;
