import { useQuery } from '@tanstack/react-query';
import http from '../../../utils/http';


export function useGetProviderAppointmentCount() {
  const { data, isLoading, refetch, ...rest } = useQuery({
    queryKey: ["provider appointment count today"],
    queryFn: async () => {
      const response = await http.get("appointments/providers/today");
      return response.data;
    },
    staleTime: 1000 * 60 * 60, // data is stale every 1 hour
    refetchInterval: 1000 * 60 * 60, // refetch every 1 hour
  });
  return {
    getProviderAppointmentCount: data,
    isLoading,
    refetchProviderAppointmentCount: refetch,
    ...rest,
  }
}