import { useQuery } from '@tanstack/react-query';
import adminHttp from '../../../utils/adminHttp';


export function useGetAdminProviderDetails() {
  const { data, isLoading, ...rest } = useQuery({
    queryKey: ["get admin provider list"],
    queryFn: async () => {
      const response = await adminHttp.get("admins/providers/list");
      return response.data;
    },
    staleTime: Infinity,
  });
  return {
    data,
    isLoading,
    ...rest,
  }
}