export type FormDetail = {
  [x: string]: {
    header: string;
    description: string;
  };
};

export enum FormDetailsEnum {
  INTAKEFORM = "intakeForm",
  INITIALVISITNOTE = "initialVisitNote",
  PROGRESSNOTE = "progressNote",
  CONSENTFORM = "consentForm"
}

export const FORMDETAILS = {
  [FormDetailsEnum.INTAKEFORM]: {
    header: "Intake Form",
    description:
      "By completing the intake form, patients provide valuable information that enables healthcare professionals to understand their condition and provide appropriate care."
  },
  [FormDetailsEnum.INITIALVISITNOTE]: {
    header: "Initial Visit Note",
    description:
      "By signing the consent form, patients indicate their understanding of the treatment and grant their informed consent, ensuring transparency and patient autonomy in the healthcare process."
  },
  [FormDetailsEnum.PROGRESSNOTE]: {
    header: "Progress Note",
    description:
      "Progress notes provide a comprehensive overview of the patient's healthcare status, facilitating effective communication among healthcare providers and ensuring continuity of care."
  },
  [FormDetailsEnum.CONSENTFORM]: {
    header: "Consent Form",
    description:
      "By signing the consent form, patients indicate their understanding of the treatment and grant their informed consent, ensuring transparency and patient autonomy in the healthcare process."
  }
};
