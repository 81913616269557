import { styled } from "@mui/material";
import { format } from "date-fns";
import React, { useState } from "react";
import DeleteEmergencyContactModal from "../../../components/MyAccount/Patient/DeleteEmergencyContactModal";
import { DeleteIcon } from "../../../svgs/DeleteIcon";
import { ReactComponent as EditIcon } from "../../../svgs/edit-pen.svg";

interface EmergencyContactListItemProps {
  onClick: (...arg: any) => any;
  cardData: any;
  index: number;
  //  TODO: TAKE OUT TYPE ANY
}

const EmergencyContactListItem: React.FC<EmergencyContactListItemProps> = (
  props: EmergencyContactListItemProps
) => {
  const [showDeleteEmergencyContactModal, setShowDeleteEmergencyContactModal] =
    useState(false);

  return (
    <EmergencyContactListItemContainer
      className="lg-dependent"
      style={{ cursor: "pointer", boxShadow: "-2px 4px 25px #EEEEE9" }}
    >
      <>
        <div className="flex flex-col">
          <div className="card-br">
            <p className="card-title-head">Contact {props?.index + 1}</p>
            <p className="user-name break-all">{props?.cardData?.name}</p>
          </div>
          <div className="card-br">
            <p className="card-title-head">Relationship</p>
            <p className="user-name break-all">
              {props?.cardData?.relationship}
            </p>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="card-br">
            <p className="card-title-head">Email Address</p>
            <p className="card-title-body break-all">
              {props?.cardData?.email}
            </p>
          </div>
          <div className="pt-[10px] mb-[10px]">
            <p className="card-title-head">Date of birth</p>

            <p className="card-title-body">
              {" "}
              {props?.cardData?.dateOfBirth &&
                format(new Date(props.cardData.dateOfBirth), "MM/dd/yyyy")}
            </p>
          </div>
        </div>
        <div className="h-[70%] mb-[10px]">
          <p className="card-title-head">Phone Number</p>
          <p className="card-title-body break-all">
            {props?.cardData?.phoneNumber}
          </p>
        </div>
        <div className="h-[70%] mb-[10px]">
          <p className="card-title-head">Gender</p>
          <p className="card-title-body">{props?.cardData?.gender}</p>
        </div>
        <div className="h-[70%] flex items-start text-[.7rem]">
          <button
            onClick={() =>
              props?.onClick(props?.cardData?.contctId, props?.cardData)
            }
            className="mr-4"
          >
            <EditIcon />
          </button>
          <button
            onClick={() => {
              setShowDeleteEmergencyContactModal(true);
            }}
          >
            <DeleteIcon className="arrow-icon" />
          </button>
        </div>
        <DeleteEmergencyContactModal
          isVisible={showDeleteEmergencyContactModal}
          closeModal={() => setShowDeleteEmergencyContactModal(false)}
          contactId={props?.cardData?.contactId}
          emergencyContactName={props?.cardData?.name}
        />
      </>
    </EmergencyContactListItemContainer>
  );
};

export default EmergencyContactListItem;

const EmergencyContactListItemContainer = styled("div")(() => ({
  position: "relative",
  width: "100%",
  height: "150px",
  backgroundColor: "#fff",
  boxShadow: "0px 2px 3px 0 rgba(0,0,0,.1)",
  padding: ".8rem",
  borderRadius: "4px",
  display: "grid",
  gridTemplateColumns: "20% 30% 15% 15% auto",
  gridTemplateRows: "auto autos",
  gap: "1%",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "14px",

  "& .card-br": {
    borderRight: "1px solid #EEEEE9",
    marginBottom: "10px",
    paddingTop: "10px",
    height: "70%"
  },
  "& .card-brlast": {
    borderRight: "1px solid #EEEEE9"
  },
  "& .arrow-icon": {
    fontSize: ".7rem",
    color: "#87928A"
  },
  "& .card-title-head": {
    fontSize: ".74rem",
    color: "#87928A"
  },

  "& .card-title-body": {
    fontSize: ".75rem",
    color: "#324705",
    fontWeight: "bold"
  },

  "& .card-user-profile": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    position: "relative",
    width: "100%",
    overflowX: "auto",
    borderRight: "1px solid #EEEEE9",

    "& .user-name": {
      color: "#103C1B",
      fontWeight: "bold",
      fontSize: "1.12rem"
    },
    "& .user-desc": {
      color: "#103C1B",
      fontSize: ".78rem"
    }
  }
}));
