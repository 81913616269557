import { useQuery } from '@tanstack/react-query';
import http from '../../../utils/http';


export function useGetMedicalRecord(depId?: string) {
  const query = depId
    ? `medical-records/patient/latest?dependentId=${depId}`
    : `medical-records/patient/latest`;
  const { data, refetch, ...rest } = useQuery({
    queryKey: ['patient medical record', depId ?? ''],
    queryFn: async () => {
      const response = await http.get(query);
      return response.data;
    },
    // enabled: !!depId,
  });
  return {
    medicalRecords: data,
    refetchMedicalRecords: refetch,
    ...rest,
  }
}