
interface ToolTipProps {
  children: React.ReactNode;
}
const ToolTip: React.FC<ToolTipProps> = ({ children }) => {
  return (
    <div
      className="font-medium"
      style={{
        backgroundColor: "#103C1B",
        color: "#fff",
        fontSize: ".7rem",
        width: "fit-content",
        textAlign: "center",
        padding: "5px",
        borderRadius: "6px",
        bottom: "20px",
        zIndex: "1"
      }}
    >
      {children}
      <div
        className="tooltip-arrow"
        style={{
          content: " ",
          top: "100%",
          left: "50%",
          marginLeft: "-5px",
          borderWidth: "5px",
          borderStyle: "solid",
          borderColor: "#103C1B transparent transparent transparent"
        }}
      ></div>
    </div>
  );
};

export default ToolTip;
