import React, {
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Socket } from "socket.io-client";

import axios from "axios";
import { toast } from "react-toastify";
import { getCurrentUser } from "../api/auth";
import { useGetPatientProviderList } from '../api/hooks/patient/useGetPatientProviders';
import MessageChat from "../components/chat/MessageChat";
import MessagesList from "../components/chat/MessageList";
import DashboardLayout from "../components/dashboard/DashboardLayout";
import { Loading } from '../components/shared/Loading';
import SearchInput from "../components/shared/SearchInput";
import { ReactComponent as EmptyMsgIcon } from "../svgs/empty-smg.svg";

interface IProps {
  socket: Socket;
}

const Messages: React.FC<IProps> = ({ socket }) => {
  const [messagesList, setMessagesList] = useState<any>([]);
  const [recentMessages, setRecentMessages] = useState<any[]>([]);
  const [selectedChatDetails, setSelectedChatDetails] = useState<null | any>(
    null
  );
  const pageTop = useRef<null | HTMLDivElement>(null);
  const [touchStart, setTouchStart] = useState<null | any>(null);
  const [touchEnd, setTouchEnd] = useState<null | any>(null);
  const minSwipeDistance = 50;
  const currentUser = getCurrentUser();

  const senderId = currentUser?.userId;

  const receiverId = selectedChatDetails?.userId;

  const onBackSwipe = () => {
    setSelectedChatDetails(null);
    scrollToTop();
  };

  const scrollToTop = () => {
    pageTop.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest"
    });
  };

  const onTouchStart = (e: any) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (touchStart !== null && touchEnd !== null) {
      //eslint-disable-next-line
      const distance = touchStart - touchEnd;
      const isLeftSwipe = distance < -minSwipeDistance;
      if (isLeftSwipe) {
        setSelectedChatDetails(null);
        scrollToTop();
      }
    }
    return;
  };

  const { getPatientProviderList, isError, isLoading, error } = useGetPatientProviderList()

  if (isError && axios.isAxiosError(error)) {
    toast.error(error?.response?.data?.error, { toastId: "customId" });
  }

  useEffect(() => {
    scrollToTop();
  }, [selectedChatDetails]);



  const senderIds = useMemo(() => {
    return getPatientProviderList ? getPatientProviderList?.map((l: any) => l?.userId) : [];
  }, [getPatientProviderList]);

  const userRooms = senderIds.map((providerId: string) => `${senderId}_${providerId}`);

  useEffect(() => {
    if (userRooms.length) {
      socket.emit("PATIENT_USERS", { userRooms });
    }
  }, [socket, userRooms]);

  useEffect(() => {
    socket.on("SEND_MESSAGE_SUCCESS", (message: any) => {
      const newMessage: any = Object.values(message)[0];
      const unreadMessagesCount = Object.values(message)[1];
      const newMessagesList = [...messagesList, newMessage];
      const { receiverId: newReceiver, senderId: newSender } = newMessage;
      const id =
        newReceiver === receiverId
          ? `${newReceiver}_${newSender}`
          : `${newSender}_${newReceiver}`;
      setMessagesList(newMessagesList);
      setRecentMessages({
        ...recentMessages,
        [id]: { message: newMessage, unreadMessagesCount }
      });
    });
  }, [messagesList, receiverId, recentMessages, socket]);

  useEffect(() => {
    if (senderId && receiverId) {
      socket?.emit("GET_SINGLE_CHAT_MESSAGES", {
        senderId,
        receiverId
      });
    }
  }, [socket, receiverId, senderId]);

  useEffect(() => {
    socket?.emit("GET_SINGLE_CHAT_MESSAGES_ALL_SENDERS", {
      senderIds,
      receiverId: currentUser.userId,
      isReceiverPatient: false
    });
  }, [currentUser.userId, socket, senderIds]);

  useEffect(() => {
    socket?.on("GET_SINGLE_CHAT_MESSAGES_ALL_SENDERS_SUCCESS", (m: any) => {
      setRecentMessages(m.messages);
    });
  }, [receiverId, senderId, socket]);

  useEffect(() => {
    socket?.on("GET_SINGLE_CHAT_MESSAGES_SUCCESS", (messages: any) => {
      if (messages?.messages) {
        setMessagesList(messages.messages);
      }
    });
  }, [socket, receiverId, senderId]);


  if (isLoading) {
    <div className="w-full h-full flex items-center justify-center">
      <Loading />
    </div>
  }


  return (
    <>
      <div ref={pageTop} />
      <DashboardLayout
        reff={pageTop}
        headerTitle="Messages"
        defaultLeftMargin
        noGrid
      >
        <div className="w-full h-full">
          <div className="relative flex justify-between items-start max-h-[calc(100%-120px] w-full mr-0 mt-0 pt-0 overflow-x-hidden">
            <div className="flex flex-col justify-start bg-none lg:bg-[#F5FAF5] fixed w-full lg:w-[330px] h-full p-2 ml-0 pl-0 lg:pt-[27px] overflow-y-scroll lg:overflow-x-hidden">
              {selectedChatDetails && (
                <div className="hidden lg:block mx-auto mb-4">
                  <SearchInput
                    value=""
                    placeholder="Search through your messages"
                    additionalClassname="w-full lg:w-[300px] h-[40px]"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.value.trim() === "") {
                      }
                      if (event.target.value.trim()) {
                      }
                    }}
                  />
                </div>
              )}
              {!selectedChatDetails && (
                <div className="mx-auto mb-4">
                  <SearchInput
                    value=""
                    placeholder="Search through your messages"
                    additionalClassname="w-[300px] h-[40px]"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.value.trim() === "") {
                      }
                      if (event.target.value.trim()) {
                      }
                    }}
                  />
                </div>
              )}

              {/* SHOWN WHEN NO CHAT IS NOT SELECTED */}
              {!selectedChatDetails && (
                <MessagesList
                  socket={socket}
                  expandMessage={selectedMessage => {

                    setSelectedChatDetails(selectedMessage);
                  }}
                  list={getPatientProviderList ?? []}
                  recentMessages={recentMessages}
                  selectedChat={selectedChatDetails}
                  setRecentMessages={setRecentMessages}
                />
              )}

              {/*  HIDDEN ON MOBILE */}
              {selectedChatDetails && (
                <div className="hidden lg:block">
                  <MessagesList
                    socket={socket}
                    expandMessage={selectedMessage => {

                      setSelectedChatDetails(selectedMessage);
                    }}
                    list={getPatientProviderList ?? []}
                    recentMessages={recentMessages}
                    selectedChat={selectedChatDetails}
                    setRecentMessages={setRecentMessages}
                  />
                </div>
              )}
            </div>
            <div>
              <div
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
                className="overflow-y-auto overflow-hidden w-full h-full max-w-full lg:max-w-[calc(100%-330px)] lg:min-w-[calc(100%-330px)] items-end lg:ml-auto mr-0 flex flex-col justify-end"
              >
                {selectedChatDetails ? (
                  <MessageChat
                    socket={socket}
                    selectedUserDetails={selectedChatDetails}
                    firstName={selectedChatDetails?.providerName}
                    imageUrl={selectedChatDetails?.providerDetails?.imageUrl}
                    messageOwnerIndex={selectedChatDetails?.index}
                    oidx={selectedChatDetails?.oIdx}
                    onBackClick={onBackSwipe}
                    messagesList={messagesList}
                    userType="patient"
                  />
                ) : (
                  <div className="h-full w-full m-auto mt-[200px] hidden lg:flex flex-col justify-center items-center">
                    <EmptyMsgIcon />
                    <div className="font-semibold text-[#34623f] mt-4 text-center">
                      No chats selected
                    </div>
                    <div className="text-[#103C1B] pt-2 w-[322px] text-center">
                      Select a chat on the left to see the details here
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default Messages;
