import AuthLayout from "../../components/auth/AuthLayout";
import SPResetPasswordForm from "../components/SPResetPassword";

const SPResetPassword = () => {
  return (
    <AuthLayout>
      <SPResetPasswordForm />
    </AuthLayout>
  );
};

export default SPResetPassword;
