import { useQuery } from '@tanstack/react-query';
import http from '../../../utils/http';
import { AppointmentApiResponse, ErrorResponse } from '../../types';


export function useGetPatientUpcomingAppointments() {
  const { data, isLoading, refetch, ...rest } = useQuery<AppointmentApiResponse, ErrorResponse>({
    queryKey: ["provider upcoming appointments"],
    queryFn: async () => {
      const response = await http.get("appointments/providers/upcoming");
      return response.data;
    },
    refetchInterval: 1000 * 60 * 30, // refech every 30 minutes,
    staleTime: 1000 * 60 * 30   // 30 mins

  });

  return {
    providerAppointments: data,
    isLoading,
    refetchProviderAppointments: refetch,
    ...rest,
  }
}