import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { Column } from "react-table";
import { useDeleteAdminProvider } from "../../api/hooks/admin/delete_provider";
import { useGetAdminProviderDetails } from "../../api/hooks/admin/get_admin_provider_list";
import { useGetSingleAdminData } from "../../api/hooks/admin/get_single_admin_data";
import { useUpdateAdminProviderStatus } from "../../api/hooks/admin/update_provider_status";
import ReusableTable from "../../components/ReusableTable";
import BtnButton from "../../components/shared/BtnButton";
import { ArrowBackIcon } from "../../svgs/ArrowBackIcon";
import AdminDashboardLayout from "../components/AdminDashboardLayout";
import AdminDataInfoCard from "../components/AdminDataInfoCard";
import AdminProvidersActionCell from "../components/AdminProvidersActionCell";

interface ProvidersType {
  join_date: Date;
  experience: string;
  firstName: string;
  lastName: string;
  specialty: string;
  created_at: Date;
  title: number;
  userId: string;
  isActivated: boolean;
}

interface ProviderPatientsType {
  firstName: string;
  lastName: string;
  name: string;
  age: number;
  email: string;
  diagnosis: string;
  last_session: Date;
  id: number;
}


const AdminProviders = () => {
  const [isProviderDetailsView, setIsProviderDetailsView] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>("");

  const { data } = useGetAdminProviderDetails()
  const { deleteProvider } = useDeleteAdminProvider()
  const { updateProviderUserStatus } = useUpdateAdminProviderStatus()
  const { singleAdminDetails, isLoading, isError, error, refetch } = useGetSingleAdminData({ userId })

  useEffect(() => {
    if (userId) {
      refetch();
    }
  }, [userId])

  const TABLE_COLUMNS = useMemo<Column<ProvidersType>[]>(
    () => [
      {
        Header: "Name",
        accessor: "firstName",
        Cell: ({ cell: { value }, row }) => {
          return (
            <React.Fragment>
              {" "}
              {`${row.original.firstName}
          ${row.original.lastName}`}
            </React.Fragment>
          );
        }
      },
      {
        Header: "Specialty",
        accessor: "specialty",
        Cell: ({ cell: { value }, row }) => {
          return <React.Fragment>{value}</React.Fragment>;
        }
      },
      {
        Header: "Title",
        accessor: "title"
      },
      {
        Header: "Experience",
        accessor: "experience",
        Cell: ({ cell: { value } }) => {
          return <React.Fragment>{value || "N/A"}</React.Fragment>;
        }
      },
      {
        Header: "Join Date",
        accessor: "created_at",
        Cell: ({ cell: { value }, row }) => {
          return (
            <React.Fragment>
              {value ? format(new Date(value), "MM/dd/yyyy") : "N/A"}
            </React.Fragment>
          );
        }
      },
      {
        Header: "Action",
        accessor: "userId",
        Cell: ({ cell: { value }, row }) => {
          return (
            <AdminProvidersActionCell
              viewProvider={() => {
                setUserId(value);
                refetch();
                setIsProviderDetailsView(true);
              }}
              deactivateProvider={() => {
                updateProviderUserStatus({ userId: value });
              }}
              deleteProvider={() => { }}
              status={row.original?.isActivated}
            />
          );
        }
      }
    ],
    [updateProviderUserStatus]
  );

  const PROVIDER_PATIENTS_TABLE_COLUMNS = useMemo<
    Column<ProviderPatientsType>[]
  >(
    () => [
      {
        Header: "Name",
        accessor: "firstName",
        Cell: ({ cell: { value }, row }) => {
          return (
            <React.Fragment>
              {" "}
              {`${row.original?.firstName}
      ${row.original?.lastName}`}
            </React.Fragment>
          );
        }
      },
      {
        Header: "Age",
        accessor: "age",
        Cell: ({ cell: { value }, row }) => {
          return <React.Fragment>{value}</React.Fragment>;
        }
      },
      {
        Header: "Email",
        accessor: "email"
      },
      {
        Header: "Diagnosis",
        accessor: "diagnosis",
        Cell: ({ cell: { value } }) => {
          return <React.Fragment>{value || "N/A"}</React.Fragment>;
        }
      },
      {
        Header: "Last Session",
        accessor: "last_session",
        Cell: ({ cell: { value }, row }) => {
          return (
            <React.Fragment>
              {value ? format(new Date(value), "MM/dd/yyyy") : "N/A"}
            </React.Fragment>
          );
        }
      }
    ],
    []
  );

  const mentalHealthCount = useMemo(() => {
    if (data?.psychiatristCount > 0 || data?.therapistCount > 0) {
      return Number(data?.psychiatristCount) + Number(data?.therapistCount);
    } else return 0;
  }, [data?.psychiatristCount, data?.therapistCount]);

  return (
    <AdminDashboardLayout
      headerTitle={isProviderDetailsView ? "Provider Details" : "Providers"}
    >

      {isProviderDetailsView ? (
        <div className="w-full h-full px-4 mt-8">
          <div className="w-full">
            <div
              onClick={() => setIsProviderDetailsView(false)}
              className="cursor-pointer pl-4 flex justify-start items-center"
            >
              <ArrowBackIcon /> <div className="ml-3">Go back to Providers</div>
            </div>
          </div>

          <div className="my-6 mx-4">
            <div className="flex items-center">
              <div className="w-[932px] h-[157px] bg-white rounded-[8px] shadow-[-2px_4px_25px_#EEEEE9] p-4 flex items-center justify-between">
                <div className="flex items-center">
                  <div className="h-[96px] w-[96px]">
                    <img
                      className="w-full h-full rounded-full mr-8"
                      src="/assets/images/avatar.png"
                      alt="Profile img"
                    />
                  </div>
                  <div className="flex flex-col ml-4">
                    <p className="text-[20px] text-[#324705] font-[600]">
                      {`${singleAdminDetails?.providerDetails?.firstName} ${singleAdminDetails?.providerDetails?.lastName} (${singleAdminDetails?.providerDetails?.title})`}
                    </p>
                    <p className="text-[16px] text-[#324705] font-[400]">
                      {singleAdminDetails?.providerDetails?.email}
                    </p>
                    <p className="text-[16px] text-[#324705] font-[400]">
                      {singleAdminDetails?.providerDetails?.deaNumber}
                    </p>
                  </div>
                </div>
                <div
                  onClick={() => {
                    updateProviderUserStatus({ userId: singleAdminDetails?.providerDetails?.userId });
                  }}
                  className="text-[16px] font-[500] text-[#3B3D24] w-[186px] h-[54px] bg-white shadow-[-2px_4px_25px_#EEEEE9] rounded-[8px] flex items-center justify-center cursor-pointer"
                >
                  {singleAdminDetails?.providerDetails?.isActivated
                    ? "Deactivate Provider"
                    : "Activate Provider"}
                </div>
                <div
                  onClick={() => {
                    deleteProvider({ userId: singleAdminDetails?.providerDetails?.userId });
                    setIsProviderDetailsView(false);
                  }}
                  className="text-[16px] font-[500] text-[#E45B3D] w-[186px] h-[54px] bg-white shadow-[-2px_4px_25px_#EEEEE9] rounded-[8px] flex items-center justify-center cursor-pointer"
                >
                  Delete Provider
                </div>
              </div>
              <div className="w-6" />
              <div className="w-[398px] h-[157px] bg-white rounded-[8px] shadow-[-2px_4px_25px_#EEEEE9] p-6">
                <div className="text-[20px] font-[500] text-[#324705]">
                  Total Appointment this month
                </div>
                <div className="text-[40px] font-[600] text-[#324705]">0</div>
              </div>
            </div>
            <div className="h-8" />
            <div className="flex items-center">
              <div className="w-[932px] h-[157px] bg-white rounded-[8px] shadow-[-2px_4px_25px_#EEEEE9] p-4">
                <div className="flex items-center justify-between">
                  <div className="">
                    <p className="text-[15px] font-[500] text-[#87928A]">
                      Specialty
                    </p>
                    <p className="text-[15px] font-[500] text-[#324705]">
                      {singleAdminDetails?.providerDetails?.specialty}
                    </p>
                  </div>
                  <div>
                    <p className="text-[15px] font-[500] text-[#87928A]">
                      Provider NPI
                    </p>
                    <p className="text-[15px] font-[500] text-[#324705]">
                      {singleAdminDetails?.providerDetails?.providerNPI}
                    </p>
                  </div>
                  <div>
                    <p className="text-[15px] font-[500] text-[#87928A]">
                      License Number
                    </p>
                    <p className="text-[15px] font-[500] text-[#324705]">
                      {singleAdminDetails?.providerDetails?.licenseNumber}
                    </p>
                  </div>
                  <div>
                    {" "}
                    <p className="text-[15px] font-[500] text-[#87928A]">
                      License State
                    </p>
                    <p className="text-[15px] font-[500] text-[#324705]">
                      {singleAdminDetails?.providerDetails?.licenseState}
                    </p>
                  </div>
                </div>

                <div className="w-full flex items-center justify-start mt-4">
                  <div>
                    <p className="text-[15px] font-[500] text-[#87928A]">
                      Languages
                    </p>
                    <p className="text-[15px] font-[500] text-[#324705]">
                      {singleAdminDetails?.providerDetails.languages?.map((value: any, index: number) => (
                        <span key={index}>{value}</span>
                      ))}
                    </p>
                  </div>
                  <div>
                    <p className="text-[15px] font-[500] text-[#87928A]">
                      Practice Address
                    </p>
                    <p className="text-[15px] font-[500] text-[#324705]">
                      {singleAdminDetails?.providerDetails?.address}
                    </p>
                  </div>
                  <div>
                    <p className="text-[15px] font-[500] text-[#87928A]">
                      Join Date
                    </p>
                    <p className="text-[15px] font-[500] text-[#324705]">
                      {singleAdminDetails?.providerDetails?.created_at
                        ? format(
                          new Date(singleAdminDetails?.providerDetails?.created_at),
                          "MM/dd/yyyy"
                        )
                        : ""}
                    </p>
                  </div>
                  <div>
                    <p className="text-[15px] font-[500] text-[#87928A] justify-end">
                      Phone Number
                    </p>
                    <p className="text-[15px] font-[500] text-[#324705]">
                      {singleAdminDetails?.providerDetails?.phoneNumber}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-6" />
              <div className="w-[398px] h-[157px] bg-white rounded-[8px] shadow-[-2px_4px_25px_#EEEEE9] p-6">
                <div className="text-[20px] font-[500] text-[#324705]">
                  Payment to TempleHS
                </div>
                <div className="text-[40px] font-[600] text-[#324705]">$0</div>
              </div>
            </div>
          </div>

          <div>
            <p className="text-[40px] font-[600] text-[#324705] mt-6">
              Patients
            </p>
            <div className="w-full mt-6">
              <ReusableTable
                tableColumns={PROVIDER_PATIENTS_TABLE_COLUMNS}
                tableData={singleAdminDetails?.providerPatientDetails ?? []}
                onClick={data => { }}
                pageSizeCount={10}
                searchFieldPlaceholder="Search Patients"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-full px-4 mt-8">
          <div className="flex items-center w-full">
            <div className="w-[374px]">
              <AdminDataInfoCard
                title="Total no. of  Providers"
                  dataCount={data?.providerCount || 0}
                blue
              />
            </div>
            <div className="w-4" />

            <div className="w-[374px]">
              <AdminDataInfoCard
                title="Providers in General Medicine"
                  dataCount={data?.pcpCount || 0}
                green
              />
            </div>
            <div className="w-4" />

            <div className="w-[374px]">
              <AdminDataInfoCard
                title="Providers in Mental Health"
                dataCount={mentalHealthCount}
                yellow
              />
            </div>
          </div>
          <div className="w-full flex items-center justify-between mt-8 mb-4 px-4">
            <div className="text-[24px] font-[600] text-[#324705]">
              All Providers
            </div>
            <div>
                {(data?.allProviders || [])?.length > 0 ? (
                <CSVLink
                    data={data?.allProviders ?? []}
                  filename="providers-list.csv"
                  uFEFF={true}
                >
                  <BtnButton variant="secondary" label="Export" size="small" />
                </CSVLink>
              ) : (
                <BtnButton
                  variant="secondary"
                  label="Export"
                  size="small"
                  disabled={true}
                />
              )}
            </div>
          </div>
          <div className="w-full mt-6">
            <ReusableTable
              tableColumns={TABLE_COLUMNS}
                tableData={data?.allProviders || []}
                onClick={data => { }}
              pageSizeCount={10}
              searchFieldPlaceholder="Search Provider"
            />
          </div>
        </div>
      )}
    </AdminDashboardLayout>
  );
};

export default AdminProviders;
