import clsx from "clsx";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { InfoIcon2 } from "../../svgs/InfoIcon2";
import { TempleHSLogo } from "../../svgs/TempleHSLogo";
import { ReactComponent as CloseIcon } from "../../svgs/close-icon.svg";
import { ReactComponent as AccountIcon } from "../../svgs/temple-account.svg";
import { ReactComponent as AppointmentsIcon } from "../../svgs/temple-appointments.svg";
import { ReactComponent as DashboardIcon } from "../../svgs/temple-dashboard.svg";
import { ReactComponent as LogoutIcon } from "../../svgs/temple-logout.svg";
import { ReactComponent as MessageIcon } from "../../svgs/temple-messages.svg";
import { ReactComponent as PillsIcon } from "../../svgs/temple-pills.svg";
import ReportProblemModal from "../ReportProblemModal";
import Spacer from "../Spacer";
import MenuItem from "./MenuItem";

type Props = {
  isMenuOpen: boolean;
  dismiss: () => void;
}

const RetractibleSidebar = ({ isMenuOpen, dismiss }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isReportModalVisible, setIsReportModalVisible] = useState(false);

  const handleMenuClick = (path: string) => {
    navigate(`${path}`);
  };

  const menuItems = [
    {
      path: "/dashboard",
      label: "Overview",
      icon: <DashboardIcon stroke="#000" />,
      activeIcon: <DashboardIcon stroke="#71B882" />
    },
    {
      path: "/appointments",
      label: "Appointments",
      icon: <AppointmentsIcon stroke="#000" />,
      activeIcon: <AppointmentsIcon stroke="#71B882" />
    },
    {
      path: "/messages",
      label: "Messages",
      icon: <MessageIcon stroke="#000" />,
      activeIcon: <MessageIcon stroke="#71B882" />
    },
    {
      path: "/prescriptions",
      label: "Prescriptions",
      icon: <PillsIcon stroke="#000" />,
      activeIcon: <PillsIcon stroke="#71B882" />
    },
    {
      path: "/my-account",
      label: "My Account",
      icon: <AccountIcon stroke="#000" />,
      activeIcon: <AccountIcon stroke="#71B882" />
    }
  ];

  const bottomMenus = [
    {
      path: "/",
      label: "Log out",
      icon: <LogoutIcon stroke="#000" />,
      activeIcon: <LogoutIcon stroke="#fff" />
    }
  ];
  return (
    <aside
      className={clsx({
        "sidebar2 z-9999 w-full h-screen fixed left-0 ease-in-out duration-500 block lg:hidden bg-black bg-opacity-50":
          true,
        "-translate-x-0": isMenuOpen,
        "-translate-x-full": !isMenuOpen
      })}
    >
      <div className="w-1/2 h-full bg-white relative pt-[47px] text-sm font-semibold border border-t-0 border-[#d1d2d4]-500 shadow shadow-[4px 4px 15px 4px rgba(51, 51, 51, 0.05)] content-center lg:content-start text-left flex flex-col justify-between overflow-y-auto min-h-[100vh]">
        <div className="w-full flex items-center justify-between px-4 mb-4 lg:mb-0">
          <div className="font-[600] text-[#7CDC93] text-[28px] flex justify-start items-center pb-0 w-full">
            <TempleHSLogo width="110px" />
          </div>
          <button
            type="button"
            className=" border-none outline-none cursor-pointer"
            onClick={dismiss}
          >
            <CloseIcon />
          </button>
        </div>
        <div className="h-[50px]" />
        <div className="h-[100vh] flex flex-col justify-between">
          <div>
            {menuItems.map(item => (
              <MenuItem
                key={item.label}
                isActive={location.pathname.startsWith(item.path)}
                icon={
                  location.pathname === item.path ? item.activeIcon : item.icon
                }
                label={item.label}
                onClick={() => {
                  handleMenuClick(item.path);
                }}
              />
            ))}
          </div>

          <div className="w-full mt-[1.375rem]">
            <div
              onClick={() => {
                setIsReportModalVisible(true);
              }}
              className="w-full cursor-pointer text-white bg-[#34623F] border-y border-white flex justify-center items-center h-[50px]"
            >
              <InfoIcon2 color="white" />
              <p className="ml-2">Support Center</p>
            </div>
          </div>
          <div className="bottom-0">
            {bottomMenus.map(item => (
              <MenuItem
                key={item.label}
                isActive={location.pathname === item.path}
                icon={
                  location.pathname === item.path ? item.activeIcon : item.icon
                }
                label={item.label}
                onClick={() => {
                  if (item.label === "Log out") {
                    localStorage.removeItem("temple_user_token");
                    window.location.href = `${process.env.REACT_APP_LANDING_PAGE_URL}`;
                  } else {
                    handleMenuClick(item.path);
                  }
                }}
              />
            ))}
            <Spacer height={150} />
          </div>
        </div>
      </div>
      <ReportProblemModal
        isVisible={isReportModalVisible}
        closeModal={() => setIsReportModalVisible(false)}
        actionTrigger={() => {
          setIsReportModalVisible(false);
        }}
      />
    </aside>
  );
};

export default RetractibleSidebar;
