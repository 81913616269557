import * as Yup from "yup";

export const validationSchema = Yup.object({
  patientId: Yup.string().required("Patient name is required"),
  appointmentDate: Yup.string().required("Start date is required"),
  appointmentStartTime: Yup.string().required("Start time is required"),
  appointmentType: Yup.string().required("Location is required"),
  isInitialAppointment: Yup.string().required(
    "Select whether this is an initial or follow-up appointment"
  )
});

export const initialValues = {
  patientId: "",
  appointmentDate: "",
  appointmentStartTime: "",
  isInitialAppointment: "no",
  appointmentType: ""
};
