import { useQuery } from '@tanstack/react-query';
import { Template } from '../../../types/template';
import http from '../../../utils/http';


export function useGetProviderTemplates() {
  const { data, isLoading, refetch, ...rest } = useQuery({
    queryKey: ["get provider templates"],
    queryFn: async () => {
      const response = await http.get(`templates`);
      return response.data;
    },
    staleTime: Infinity, // data is never stale
  });
  return {
    templates: data?.templates as Template[],
    isLoading,
    refetchProviderTemplate: refetch,
    ...rest,
  }
}