import { capitalize } from "@mui/material";
import clsx from "clsx";
import { format } from "date-fns";
import React, { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import { Column } from "react-table";
import ReusableTable from "../../components/ReusableTable";
import BtnButton from "../../components/shared/BtnButton";
import { ArrowBackIcon } from "../../svgs/ArrowBackIcon";
import { CalendarIcon } from "../../svgs/CalendarIcon";
import AdminDashboardLayout from "../components/AdminDashboardLayout";
import AdminDataInfoCard from "../components/AdminDataInfoCard";

interface ReportsType {
  paymentId: string;
  provider: string;
  charges: string;
  payment_date: Date;
  id: number;
  status: string;
}

const AdminStatusEl = ({ status }: { status: string }) => (
  <div className="flex items-center">
    <p
      className={clsx({
        "text-[#E45B3D]": status === "Unsuccessful",
        "text-[#3D874E]": status === "Successful",
        "text-[#F1A512]": status === "Pending"
      })}
    >
      {status && status === "inProgress" ? "In Progress" : capitalize(status)}
    </p>
  </div>
);

const AdminReports = () => {
  const [isDetailsView, setIsDetailsView] = useState(false);
  const REPORTS_TABLE_COLUMNS = useMemo<Column<ReportsType>[]>(
    () => [
      {
        Header: "Payment ID",
        accessor: "paymentId"
      },
      {
        Header: "Provider",
        accessor: "provider",
        Cell: ({ cell: { value } }) => {
          return <React.Fragment>{value}</React.Fragment>;
        }
      },
      {
        Header: "Charges",
        accessor: "charges"
      },
      {
        Header: "Payment Date",
        accessor: "payment_date",
        Cell: ({ cell: { value }, row }) => {
          return (
            <React.Fragment>
              {value ? format(new Date(value), "MM/dd/yyyy") : "N/A"}
            </React.Fragment>
          );
        }
      },
      {
        Header: "Payment Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => {
          return (
            <React.Fragment>
              <AdminStatusEl status={value} />
            </React.Fragment>
          );
        }
      }
    ],
    []
  );
  return (
    <AdminDashboardLayout headerTitle="Report">
      {isDetailsView ? (
        <div className="w-full h-full px-4 mt-6">
          <div className="flex items-center justify-between w-full">
            <div
              onClick={() => setIsDetailsView(false)}
              className="cursor-pointer pl-2 flex justify-start items-center my-6"
            >
              <ArrowBackIcon /> <div className="ml-3">Go back</div>
            </div>
            {/* <div className="w-auto flex items-center justify-end">
              <Button
                label="Action"
                size="medium"
                variant="secondary"
              />
              <div className="w-4" />
              <Button label="Action" size="small" variant="primary" />
            </div> */}
          </div>
          <div className="w-full my-6">
            <div className="w-full flex flex-col items-center justify-start">
              <div className="w-full flex justify-start">
                <div className="flex flex-col mr-auto text-left w-[200px]">
                  <p className="text-[#87928A] pb-2">Payment ID</p>
                  <p className="text-[#324705] break-all max-w-full">PAY-001</p>
                </div>
                <div className="flex flex-col mr-auto text-left w-[200px]">
                  <p className="text-[#87928A] pb-2">Provider</p>
                  <p className="text-[#324705] break-all max-w-full">
                    Courtney Henry
                  </p>
                </div>
                <div className="flex flex-col mr-auto text-left w-[200px]">
                  <p className="text-[#87928A] pb-2">Patient</p>
                  <p className="text-[#324705] break-all max-w-full">
                    Aluka Johnson
                  </p>
                </div>
                <div className="flex flex-col mr-auto text-left w-[200px]">
                  <p className="text-[#87928A] pb-2">Charges</p>
                  <p className="text-[#324705]  break-all max-w-full">$200</p>
                </div>
              </div>
              <div className="h-6" />
              <div className="w-full flex justify-start">
                <div className="flex flex-col mr-auto text-left w-[200px]">
                  <p className="text-[#87928A] pb-2">Payment Status</p>
                  <p className="break-all max-w-full text-[#3D874E]">
                    Successful
                  </p>
                </div>
                <div className="flex flex-col mr-auto text-left w-[200px]">
                  <p className="text-[#87928A] pb-2">Payment Date</p>
                  <p className="text-[#324705] break-all w-auto">10/04/2022</p>
                </div>
                <div className="flex flex-col mr-auto text-left w-[200px]">
                  <p className="text-[#87928A] pb-2">Payment Type</p>
                  <p className="text-[#324705] break-all max-w-full">
                    Insurance
                  </p>
                </div>
                <div className="flex flex-col mr-auto text-left w-[200px]">
                  <p className="text-[#87928A] pb-2">Biller</p>
                  <p className="text-[#324705] break-all max-w-full">
                    Logan Mitchel
                  </p>
                </div>
              </div>
              <div className="h-6" />
              <div className="w-full flex justify-start">
                <div className="flex flex-col mr-auto text-left w-[200px]">
                  <p className="text-[#87928A] pb-2">Insurance Name</p>
                  <p className="text-[#324705] break-all max-w-full">AFFE</p>
                </div>
                <div className="flex flex-col mr-auto text-left w-[200px]">
                  <p className="text-[#87928A] pb-2">Insurance State</p>
                  <p className="text-[#324705] break-all max-w-full">
                    California
                  </p>
                </div>
                <div className="flex flex-col mr-auto text-left w-[200px]">
                  <p className="text-[#87928A] pb-2">Insurance Number</p>
                  <p className="text-[#324705] break-all max-w-full">
                    878657899
                  </p>
                </div>
                <div className="flex flex-col mr-auto text-left w-[200px]">
                  <p className="text-[#87928A] pb-2">Card/Account charged</p>
                  <p className="text-[#324705] break-all max-w-full">
                    543355434343234
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-full px-4 mt-8">
          <div className="w-full h-[88px] bg-[#EEEEE9] mb-8">
            <div className="w-full h-full flex items-center justify-between  px-4">
              <div className="flex items-center justify-start">
                <div className="w-[280px] h-[46px] flex items-center bg-white border border-[#CECFC5] rounded-[6px] rounded-r-none rounded-b-none rounded-l-[6px]">
                  <DatePicker
                    selected={new Date()}
                    name="dateOfBirth"
                    dateFormat="MM/dd/yyyy"
                    onChange={(date: Date) => {}}
                    placeholderText="MM/DD/YYYY"
                    className="date-picker"
                    forceShowMonthNavigation={true}
                    showYearDropdown={true}
                    showMonthDropdown={true}
                    scrollableMonthYearDropdown={true}
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown={true}
                    shouldCloseOnSelect={true}
                  />
                  <span className="pr-2">
                    <CalendarIcon />
                  </span>
                </div>
                <div className="w-[280px] h-[46px] flex items-center bg-white border border-[#CECFC5] rounded-none rounded-r-[6px] rounded-b-[6px] rounded-l-none">
                  <DatePicker
                    selected={new Date()}
                    name="dateOfBirth"
                    dateFormat="MM/dd/yyyy"
                    onChange={(date: Date) => {}}
                    placeholderText="MM/DD/YYYY"
                    className="date-picker"
                    forceShowMonthNavigation={true}
                    showYearDropdown={true}
                    showMonthDropdown={true}
                    scrollableMonthYearDropdown={true}
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown={true}
                    shouldCloseOnSelect={true}
                  />
                  <span className="pr-2">
                    <CalendarIcon />
                  </span>
                </div>
              </div>
              <div>
                <BtnButton
                  variant="primary"
                  label="Update Report"
                  size="medium"
                />
              </div>
            </div>
          </div>
          <div className="flex items-center w-full">
            {" "}
            <div className="w-[374px]">
              <AdminDataInfoCard
                title="Total Payment"
                  dataCount={0} // TODO: add live data here
                green
              />
            </div>
            <div className="w-4" />
            <div className="w-[374px]">
              <AdminDataInfoCard
                title="No. of  Payments"
                  dataCount={0} // TODO: add live data here
                green
              />
            </div>
            </div> 
          <div className="w-full flex items-center justify-between mt-8 mb-4 px-4">
            <div className="text-[24px] font-[600] text-[#324705]">
              Providers Payment History
            </div>
            <div>
              <CSVLink
                  data={[]} //TODO: add live data here
                filename="reports-list.csv"
                uFEFF={true}
              >
                <BtnButton variant="secondary" label="Export" size="small" />
              </CSVLink>
            </div>
          </div>
          <div className="w-full mt-8">
            <ReusableTable
              tableColumns={REPORTS_TABLE_COLUMNS}
                tableData={[]}//TODO: add live data here
              onClick={data => {
                setIsDetailsView(true);
              }}
              pageSizeCount={10}
              searchFieldPlaceholder="Search Payment"
            />
          </div>
        </div>
      )}
    </AdminDashboardLayout>
  );
};

export default AdminReports;
