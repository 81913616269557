export const Lifebuoy = ({
  width = 24,
  height = 25,
  color = "#fff"
}: {
  width?: number;
  height?: number;
  color?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 12.5c0-.663.148-1.293.41-1.863.204-.441.17-.978-.174-1.321L5.159 6.739c-.41-.41-1.087-.389-1.418.087A9.904 9.904 0 001.97 12.5c0 2.104.65 4.059 1.763 5.67.33.478 1.007.5 1.418.09l2.583-2.576c.344-.343.38-.88.176-1.322-.262-.57-.41-1.2-.41-1.862zM12 8c.663 0 1.293.148 1.863.41.441.204.978.17 1.321-.174l2.55-2.55c.409-.409.388-1.083-.086-1.415A9.883 9.883 0 0011.97 2.5c-2.104 0-4.051.65-5.66 1.762-.478.33-.501 1.008-.09 1.42l2.56 2.567c.346.346.888.38 1.332.172A4.438 4.438 0 0112 8zM19.89 6.406a.48.48 0 00-.72-.036l-3.22 3.21a.54.54 0 00-.069.656 4.455 4.455 0 010 4.528.54.54 0 00.069.656l3.22 3.22a.48.48 0 00.72-.037 9.945 9.945 0 002.08-6.103 9.913 9.913 0 00-2.08-6.094zM12 17a4.455 4.455 0 01-2.265-.619.54.54 0 00-.655.069L5.83 19.7a.48.48 0 00.037.72 9.945 9.945 0 006.103 2.08 9.873 9.873 0 006.108-2.09.48.48 0 00.034-.718l-3.225-3.225a.54.54 0 00-.651-.071A4.458 4.458 0 0112 17z"
        fill={color}
      />
    </svg>
  );
};
