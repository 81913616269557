import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useGeProviderPatient } from "../../../api/hooks/provider/useGetProviderPatients";
import { useGetSinglePatientRecord } from '../../../api/hooks/provider/useGetSinglePatientRecord';
import { Loading } from "../../../components/shared/Loading";
import ProviderDashboardLayout from "../../components/dashboard/ProviderDashboardLayout";
import PatientDetail from "../PatientDetail";
import { PatientDetailsType } from "../PatientDetail/interfaces/patientDetails";
import PatientComponent from "./Patients";


const ProviderPatients = () => {
  const [selectedPatient, setSelectedPatient] = useState<any>({});
  const [activePatient, setActivePatient] = useState<PatientDetailsType>({} as PatientDetailsType);
  const [isPatientSelected, setPatientSelected] = useState<boolean>(false);

  const { providerPatientsData: patients, isLoading: isFetchingPatients, isError, error } = useGeProviderPatient()

  if (isError && axios.isAxiosError(error)) {
    toast.error(error?.response?.data?.error, { toastId: "customId" });
  }

  const { data, isLoading, isError: isPatientError, error: patientError } = useGetSinglePatientRecord(selectedPatient?.userId)

  useEffect(() => {
    if (isLoading && data) {
      setActivePatient(data)
      setPatientSelected(true);
    }
  }, [data, isLoading]);

  if (isPatientError) {
    const message = patientError?.message
    toast.error(message, { toastId: "customId" })
  }

  const getCurrentView = () => {
    if (isPatientSelected) {
      if (isFetchingPatients || isLoading) {
        return (
          <ProviderDashboardLayout headerTitle="Patients">
            <div className="w-[100%] h-[80vh] flex items-center justify-center">
              <Loading />
            </div>
          </ProviderDashboardLayout>
        );
      }
      return (
        <PatientDetail
          activePatientData={activePatient}
          goBack={() => setPatientSelected(false)}
          diagnosis={selectedPatient?.diagnosis}
          patientId={selectedPatient.userId}
        />
      );
    }
    return (
      <ProviderDashboardLayout headerTitle="Patients">
        <PatientComponent
          patients={patients}
          setSelectedPatient={value => {
            setSelectedPatient(value);
            setPatientSelected(true);
          }}
        />
      </ProviderDashboardLayout>
    );
  };

  return <>{getCurrentView()}</>;
};

export default ProviderPatients;
