import { useQuery } from '@tanstack/react-query';
import adminHttp from '../../../utils/adminHttp';

export function useGetAllPatientsList() {
  const { data, isLoading, ...rest } = useQuery({
    queryKey: ["get all admin patients list"],
    queryFn: async () => {
      const response = await adminHttp.get("admins/patients/list");
      return response.data;
    },
    staleTime: Infinity,
  });
  return {
    patientData: data,
    isLoading,
    ...rest,
  }
}