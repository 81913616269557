import React, { useState } from "react";
import { toast } from "react-toastify";
import { useGetEmergencyContacts } from "../../../api/hooks/accounts/getEmergencyContacts";
import { Loading } from "../../shared/Loading";
import EditEmergencyContactDrawer from "./EditEmergencyContactDrawer";
import EmergencyContactItem from "./EmergencyContactItem";
import MobileEmergencyContactItem from "./MobileEmergencyContactItem";


const EmergencyContactsLists = () => {
  const [singleContactData, setSingleContactData] = useState<any>();
  const [isEditContactFormVisible, setIsEditContactFormVisible] =
    useState(false);

  const openContactDetails = (data: any) => {
    setIsEditContactFormVisible(true);
    setSingleContactData(data);
  };

  const { emergencyContactsData, isLoading, isError, error } = useGetEmergencyContacts();

  if (isLoading) {
    return <div className="w-1/2 h-1/2 flex items-center justify-center">
      <Loading />
    </div>
  }

  if (isError) {
    toast.error(error?.error)
  }
  return (
    <div className="flex flex-col justify-start lg:min-w-full lg:h-full border-none rounded-lg px-0 py-2">
      <EditEmergencyContactDrawer
        open={isEditContactFormVisible}
        onClose={() => setIsEditContactFormVisible(false)}
        drawerTitle="Edit Emergency Contact"
        contactDetails={singleContactData}
      />
      {emergencyContactsData?.contacts?.map((listItem, index) => {
        return (
          <React.Fragment key={listItem?.id}>
            <EmergencyContactItem
              cardData={listItem}
              onClick={() => {
                openContactDetails(listItem);
              }}
              index={index}
            />

            <MobileEmergencyContactItem
              cardData={listItem}
              onclick={() => {
                openContactDetails(listItem);
              }}
              index={index}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default EmergencyContactsLists;
