import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import Button from "../components/shared/Button";
import { MainModalProps } from "./mainModal";

const AgreementModal = (props: MainModalProps) => {
  return (
    <Dialog
      open={props.open}
      closeAfterTransition={props.closeAfterTransition}
      onClose={props.onClose}
    >
      <DialogContent className="flex flex-col gap-3">
        <DialogTitle className="font-[500]">{props.title}</DialogTitle>
        <section className="flex flex-col space-y-2 py-4 px-5">
          <p className="text-[16px] font-[500]">HIPAA Reminders</p>
          <ul className="pl-4 list-disc">
            <li>
              I understand the rules and regulations of HIPAA, and how I should
              keep my client's records safe
            </li>
            <li>
              I attest that it's my responsibibilty to follow any requests in
              the HIPAA release form, including any redaction requests
            </li>
          </ul>
          <div className="flex flex-col space-y-2 py-4 px-5">
            <p className="font-[500]">Insurance company chart review</p>
            <p className="flex flex-row items-center gap-1 text-[13px]">
              <span>if this is for a payer please</span>{" "}
              <span className="underline">send us a request here</span>
            </p>
          </div>
        </section>
        <section className="flex gap-3 items-center justify-center">
          <DialogActions>
            <Button
              variant="primary"
              label="Agree and download"
              size="large"
              onClick={props.onOk}
            />
          </DialogActions>
          {props.onCancel && (
            <DialogActions>
              <Button
                variant="secondary"
                label="Cancel"
                size="medium"
                onClick={props.onCancel}
              />
            </DialogActions>
          )}
        </section>
      </DialogContent>
    </Dialog>
  );
};

export { AgreementModal };
