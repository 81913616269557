export const InfoIcon = ({
  width = 18,
  height = 18,
  color = "#5F91C0"
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00012 0.875C7.39315 0.875 5.82227 1.35152 4.48612 2.24431C3.14997 3.1371 2.10857 4.40605 1.4936 5.8907C0.878643 7.37535 0.71774 9.00901 1.03125 10.5851C1.34475 12.1612 2.11858 13.6089 3.25488 14.7452C4.39118 15.8815 5.83892 16.6554 7.41502 16.9689C8.99111 17.2824 10.6248 17.1215 12.1094 16.5065C13.5941 15.8916 14.863 14.8502 15.7558 13.514C16.6486 12.1779 17.1251 10.607 17.1251 9C17.1226 6.84588 16.2658 4.7807 14.7426 3.25751C13.2194 1.73431 11.1542 0.87749 9.00012 0.875ZM9.00005 4.625C9.18547 4.625 9.36672 4.67998 9.52089 4.783C9.67506 4.88601 9.79523 5.03243 9.86618 5.20373C9.93714 5.37504 9.95571 5.56354 9.91953 5.7454C9.88336 5.92725 9.79407 6.0943 9.66296 6.22541C9.53185 6.35652 9.3648 6.44581 9.18294 6.48199C9.00109 6.51816 8.81259 6.49959 8.64128 6.42864C8.46997 6.35768 8.32356 6.23752 8.22054 6.08335C8.11753 5.92918 8.06255 5.74792 8.06255 5.5625C8.06255 5.43939 8.0868 5.31748 8.13391 5.20373C8.18102 5.08999 8.25008 4.98664 8.33713 4.89959C8.42419 4.81253 8.52754 4.74348 8.64128 4.69636C8.75502 4.64925 8.87693 4.625 9.00005 4.625H9.00005ZM9.62512 13.375H9.00012C8.91804 13.375 8.83674 13.3589 8.76089 13.3275C8.68504 13.2961 8.61612 13.2501 8.55808 13.192C8.50003 13.134 8.454 13.0651 8.4226 12.9892C8.39121 12.9134 8.37508 12.8321 8.37512 12.75V9C8.20936 9 8.05039 8.93415 7.93318 8.81694C7.81597 8.69973 7.75012 8.54076 7.75012 8.375C7.75012 8.20924 7.81597 8.05027 7.93318 7.93306C8.05039 7.81585 8.20936 7.75 8.37512 7.75H9.00012C9.08221 7.74995 9.16351 7.76609 9.23936 7.79748C9.31521 7.82887 9.38413 7.87491 9.44217 7.93295C9.50022 7.991 9.54625 8.05992 9.57765 8.13577C9.60904 8.21162 9.62517 8.29291 9.62512 8.375V12.125C9.79088 12.125 9.94985 12.1908 10.0671 12.3081C10.1843 12.4253 10.2501 12.5842 10.2501 12.75C10.2501 12.9158 10.1843 13.0747 10.0671 13.1919C9.94985 13.3092 9.79088 13.375 9.62512 13.375Z"
        fill={color}
      />
    </svg>
  );
};
