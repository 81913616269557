import React, { useState } from "react";
import { AdminTableActionIcon } from "../../svgs/AdminTableActionIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

interface IProps {
  viewProvider: () => void;
  removeProvider: () => void;
}

const SPProviderActionCell = ({ viewProvider, removeProvider }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="flex items-center gap-x-4">
      <button
        type="button"
        className="border-none outline-none cursor-pointer"
        onClick={event => {
          event.stopPropagation();
          handleClick(event);
        }}
      >
        <AdminTableActionIcon />
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
      >
        <MenuItem onClick={viewProvider}>View</MenuItem>
        <MenuItem onClick={removeProvider}>Remove</MenuItem>
      </Menu>
    </div>
  );
};

export default SPProviderActionCell;
