import clsx from "clsx";
import Spacer from "../../../components/Spacer";

interface ProviderMenuItemProps {
  isActive: boolean;
  icon: React.ReactNode;
  label: string;
  onClick?: (arg: any) => void;
}

const ProviderMenuItem: React.FC<ProviderMenuItemProps> = ({
  isActive,
  icon,
  label,
  onClick
}) => {
  return (
    <li
      className={clsx({
        "flex items-center justify-start mb-[10px] pl-[25px] mx-auto w-full cursor-pointer h-[50px]":
          true,
        "text-[#3D874E] bg-[#E7FBEC]": isActive
      })}
      onClick={onClick}
    >
      {icon}
      <Spacer width={10} />
      <div
        // className={clsx({
        //   "text-medium font-normal leading-normal text-white": true,
        //   "text-[#ABE9B9] font-semibold": isActive
        // })}
        data-toggle="menu"
      >
        <span
          className={clsx({
            "text-[.9rem] leading-normal": true,
            "text-[.9rem] text-[#3D874E] font-bold": isActive
          })}
        >
          {label}
        </span>
      </div>
    </li>
  );
};

export default ProviderMenuItem;
