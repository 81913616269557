import Alert from "@mui/material/Alert";
import axios from "axios";
import { differenceInHours, format, formatRelative } from "date-fns";
import { useFormik } from "formik";
import React, {
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { toast } from "react-toastify";
import { Socket } from "socket.io-client";
import * as Yup from "yup";
import { getCurrentUser } from "../../api/auth";
import { useGetSinglePatientRecord } from '../../api/hooks/provider/useGetSinglePatientRecord';
import { InfoIcon } from "../../svgs/InfoIcon";
import { ReactComponent as EmptyMsgIcon } from "../../svgs/empty-smg.svg";
import { UserType } from "../../types/common";
import Spacer from "../Spacer";
import MessageInput from "./MessageInputField";
import ProviderProfileDrawer from "./ProviderProfileDrawer";

interface MessageChatProps {
  firstName: string;
  imageUrl: string;
  messages?: any[] | null;
  selectedUserDetails?: any | null;
  addNewMessage?: (...arg: any) => any[];
  newMessages?: any[];
  messageOwnerIndex: number;
  oidx: number;
  onBackClick: (arg?: any) => void;
  messagesList: any;
  socket: Socket;
  userType: UserType;
  // viewDetails: (arg: any) => void;
}

const MessageChat = ({
  // messages,
  // imageUrl = "",
  selectedUserDetails,
  // addNewMessage,
  // newMessages,
  // messageOwnerIndex,
  // oidx,
  // firstName,
  // onBackClick,
  messagesList,
  socket,
  userType
}: MessageChatProps) => {
  const [currentMessageText, setCurrentMessageText] = useState("");
  const [isProfileDrawerVisible, setIsProfileDrawerVisible] = useState(false);
  const hiddenFileInputRef = useRef<HTMLInputElement>(null);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [base64ImageSrc, setBase64ImageSrc] = useState<string | null>(null);
  const [chatWindowError, setChatWindowError] = useState<any>({});

  const chatWindowPage = useRef<null | HTMLDivElement>(null);
  const chatSize = useRef<number>(0);

  const currentUser = getCurrentUser();

  const scrollToBottom = () => {
    if (chatWindowPage.current) {
      chatWindowPage.current.scrollTop =
        chatWindowPage.current.scrollHeight + 200;
    }
  };

  useEffect(() => {
    if (chatSize.current !== messagesList.length) {
      chatSize.current = messagesList.length;
      scrollToBottom();
    }
  }, [messagesList]);

  const senderId = useMemo(() => {
    return currentUser?.userId;
  }, [currentUser]);

  const receiverId = useMemo(() => selectedUserDetails.userId, [selectedUserDetails]);

  const { data, isLoading, isError, error } = useGetSinglePatientRecord(receiverId)

  if (isError && axios.isAxiosError(error)) {
    toast.error(error?.response?.data?.error, { toastId: "customId" });
  }

  const formik = useFormik({
    initialValues: {
      message: ""
    },
    validationSchema: Yup.object({
      message: Yup.string().required("Message should not be empty")
    }),
    onSubmit: async () => { }
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setCurrentMessageText(e.target.value);
  };

  const createImageSource = (file: Blob) => {
    try {
      setIsUploadingImage(true);
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = async () => {
        if (reader.result) {
          setBase64ImageSrc(reader.result.toString());
          // setBase64ImageSrc(null);

          setIsUploadingImage(false);
        }
      };
    } catch (error) {
      setIsUploadingImage(false);
    }
  };

  const openFileLibrary = () => {
    hiddenFileInputRef.current?.click();
  };

  const selectFileFromLibrary = (event: any) => {
    const target = event.currentTarget as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    createImageSource(file);
  };

  useEffect(() => {
    scrollToBottom();
    // checkIfPatientHasAccount();
  }, [messagesList.length]);

  const ChatMessages = useMemo(() => {
    return messagesList?.map((message: any) => {
      return (
        <React.Fragment key={message?.messageId}>
          <div className="w-full">
            {message?.senderId === receiverId ? (
              <div className="flex flex-col justify-start">
                <Spacer height={14} />
                <div className="flex w-full justify-start relative items-baseline">
                  <div className="pl-0 flex justify-start absolute bottom-0 left-0 right-10">
                    <img
                      onClick={() => {
                        setIsProfileDrawerVisible(true);
                      }}
                      className="w-[30px] h-[30px] rounded-full cursor-pointer"
                      src={
                        selectedUserDetails.avatarUrl ||
                        "/assets/images/avatar.png"
                      }
                      alt=""
                    />
                  </div>
                  <Spacer width={25} />

                  <div className="min-h-auto w-[305px] ml-4 py-4 px-6 bg-[#F5F6F5] rounded rounded-tl-[25px] rounded-tr-[25px] rounded-br-[25px]">
                    <p className="font-[400] text-[#324705] text-[12px]">
                      {message?.message}
                    </p>
                  </div>
                </div>
                <p className="text-[12px] font-[500] pl-10 text-[#87928A]">
                  {differenceInHours(
                    new Date(),
                    new Date(message?.created_at)
                  ) < 24
                    ? format(new Date(message?.created_at), "h:mmb")
                    : formatRelative(new Date(message?.created_at), new Date())}
                </p>
              </div>
            ) : (
              <div className="flex w-full justify-end">
                <div className="w-full min-h-auto mr-0 flex flex-col">
                  <Spacer height={14} />
                  <div className="flex w-full relative items-end justify-end">
                    <div className="min-h-auto w-[305px] ml-4 py-4 px-6 border border-[#EBEBEB] bg-[#DEEDDE] rounded rounded-tl-[25px] rounded-tr-[25px] rounded-bl-[25px]">
                      <p className="font-[400] text-[#324705] text-[12px]">
                        {message?.message}
                      </p>
                    </div>
                    {chatWindowError[message.messageId] && !message.hasSent && (
                      <div className="flex justify-end items-center pl-3 h-full">
                        <InfoIcon color="red" />
                      </div>
                    )}
                  </div>

                  {chatWindowError[message.messageId] && !message.hasSent ? (
                    <>
                      <Spacer height={5} />
                      <p className="text-[12px] font-[500] flex justify-end w-full text-[#FC3333]">
                        Message not sent
                      </p>
                    </>
                  ) : (
                    <p className="text-[12px] font-[500] flex justify-end w-full text-[#87928A]">
                      {differenceInHours(
                        new Date(),
                        new Date(message?.created_at)
                      ) < 24
                        ? format(new Date(message?.created_at), "h:mmb")
                        : formatRelative(
                          new Date(message?.created_at),
                          new Date()
                        )}
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        </React.Fragment>
      );
    });
  }, [messagesList, chatWindowError, receiverId, selectedUserDetails]);

  if (selectedUserDetails === null) {
    return <div>No Messages</div>;
  }

  return messagesList?.length && messagesList.length > 0 ? (
    <div className="mb-10 lg:ml-auto mt-0 mr-0 border border-[#d1d2d4] border-b-0 border-t-0 border-r-0 bg-[#fff] w-full flex flex-col gap-4 items-start justify-end overflow-x-hidden">
      {!isLoading && data.patientDetails?.patient?.userSource === "provider" && (
        <div className="mt-5 mb-28 z-[9999] flex justify-start items-start">
          <Alert severity="info" sx={{
            width: '50%',
            fontSize: '12px',
          }}>
            This patient does not have an account with TempleHS, but the message
            will be delivered once the patient opens an account
          </Alert>
        </div>
      )}
      <div
        ref={chatWindowPage}
        className="w-full overflow-y-scroll fixed lg:max-w-[calc(80%-348px)]"
      >
        {messagesList?.length ? (
          <div className="w-full h-full px-4 ">{ChatMessages}</div>
        ) : (
          <div className="h-full w-full m-auto mt-[200px] hidden lg:flex flex-col justify-center items-center">
            <EmptyMsgIcon />
            <div className="font-semibold text-[#34623f] mt-4 text-center">
              No chat selected
            </div>
            <div className="text-[#103C1B] pt-2 w-[322px] text-center">
              Select a chat on the left to see the details here
            </div>
          </div>
        )}
        <Spacer height={50} />
        <div className="w-full bottom-0 z-9999">
          <MessageInput
            socket={socket}
            formik={formik}
            currentUserId={senderId}
            receiverId={receiverId}
            value={currentMessageText}
            placeholder="Type your message here.."
            name="message"
            onSend={() => {
              if (currentMessageText !== "") {
                setCurrentMessageText("");
              }
            }}
            onAttach={openFileLibrary}
            onChange={handleChange}
            userType={userType}
          />
        </div>
      </div>
      <input
        type="file"
        className="hidden"
        ref={hiddenFileInputRef}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          selectFileFromLibrary(event);
        }}
        accept="image/x-png,image/jpeg,image/jpg"
      />
      <Spacer height={20} />
      <div className="w-full">
        <ProviderProfileDrawer
          open={isProfileDrawerVisible}
          onClose={() => setIsProfileDrawerVisible(false)}
          drawerTitle=""
          userDetails={selectedUserDetails}
        />
      </div>
    </div>
  ) : (
      <>
      <Spacer height={20} />
      <div className="w-full bottom-0 z-9999 pt-[150px]">
        <MessageInput
          socket={socket}
          formik={formik}
          currentUserId={senderId}
          receiverId={receiverId}
          value={currentMessageText}
          placeholder="Type your message here.."
          name="message"
          onSend={() => {
            if (currentMessageText !== "") {
              setCurrentMessageText("");
            }
          }}
          onAttach={openFileLibrary}
          onChange={handleChange}
          userType={userType}
        />
      </div>
    </>
  );
};

export default MessageChat;
