import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import CustomDrawer from "../../drawer";
import AddDependantForm from "./AddDependantForm";

interface EditDependantDrawerProps {
  drawerTitle: string;
  onClose: () => void;
  open: boolean;
  dependantDetails: any;
}

const EditDependantDrawer: React.FC<EditDependantDrawerProps> = ({
  drawerTitle,
  onClose,
  open,
  dependantDetails
}) => {
  const [initialFormValues, setInitialFormValues] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    gender: "",
    dateOfBirth: new Date()
  });

  useEffect(() => {
    setInitialFormValues({
      firstName: dependantDetails?.firstName,
      lastName: dependantDetails?.lastName,
      email: dependantDetails?.email,
      gender: dependantDetails?.gender,
      dateOfBirth: dependantDetails?.dateOfBirth
        ? new Date(dependantDetails?.dateOfBirth)
        : new Date()
    });
  }, [dependantDetails]);

  const formik = useFormik({
    initialValues: {
      firstName: initialFormValues?.firstName,
      lastName: initialFormValues?.lastName,
      email: initialFormValues?.email,
      gender: initialFormValues?.gender,
      dateOfBirth: initialFormValues?.dateOfBirth
        ? new Date(initialFormValues?.dateOfBirth)
        : new Date()
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      email: Yup.string().email("Invalid Email"),
      phoneNumber: Yup.string().required("Phone number is required"),
      gender: Yup.string().required("Gender is required"),
      dateOfBirth: Yup.string().required("Date of birth is required")
    }),
    onSubmit: () => {}
  });

  return (
    <div className="w-full lg:w-[600px]">
      <CustomDrawer
        noWidth
        open={open}
        onClose={onClose}
        drawerTitle={drawerTitle}
      >
        <AddDependantForm formik={formik} />
      </CustomDrawer>
    </div>
  );
};

export default EditDependantDrawer;
