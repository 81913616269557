import React, { useState } from "react";
import { ArrowUpIcon } from "../../../svgs/ArrowUpIcon";
import { format } from "date-fns";
import { ArrowDownIcon } from "../../../svgs/ArrowDownIcon";
import clsx from "clsx";

interface MobileAppointmentCardProps {
  appointmentDetails?: any;
  onClick?: () => void;
}

const MobileAppointmentCard: React.FC<MobileAppointmentCardProps> = ({
  appointmentDetails,
  onClick
}) => {
  const [showFullView, setShowFullView] = useState(false);
  return (
    <div
      onClick={onClick}
      className="w-full block min-[821px]:hidden p-2 mb-2 border-b border-[#EEEEE9]"
    >
      <div className="w-full flex items-center justify-end">
        <img
          src={appointmentDetails?.avatarUrl || "/assets/images/avatar.png"}
          alt="provider-avatar"
          className="w-[40px] h-[40px] rounded-full mr-2"
        />
        <div className="w-full flex justify-start text-[#3D874E] font-[600]">
          {appointmentDetails?.patientName}
        </div>
      </div>

      <div className="w-full flex items-center justify-end">
        {showFullView ? (
          <>
            <p
              onClick={(e: any) => {
                e.stopPropagation();
                setShowFullView(false);
              }}
              className="text-[12px] font-[400] text-[#2E3011]"
            >
              Hide <br /> Details
            </p>
            <div
              onClick={(e: any) => {
                e.stopPropagation();
                setShowFullView(!showFullView);
              }}
            >
              <ArrowUpIcon />
            </div>
          </>
        ) : (
          <>
            <p
              onClick={(e: any) => {
                e.stopPropagation();
                setShowFullView(true);
              }}
              className="text-[12px] font-[400] text-[#2E3011]"
            >
              Show <br /> Details
            </p>
            <div
              onClick={(e: any) => {
                e.stopPropagation();
                setShowFullView(!showFullView);
              }}
            >
              <ArrowDownIcon />
            </div>
          </>
        )}
      </div>

      <div className="w-full">
        {showFullView && (
          <div className="my-4">
            <p>Date</p>
            <p>
              {format(
                new Date(appointmentDetails?.appointmentDate),
                "MM/dd/yyyy"
              )}
            </p>
          </div>
        )}
        <div className={clsx({ "my-4": showFullView, "my-0": !showFullView })}>
          <p>Time</p>
          <p>{`${appointmentDetails?.appointmentStartTime} - ${appointmentDetails?.appointmentEndTime}`}</p>
        </div>
        {showFullView && (
          <div className="my-4">
            <p>Reason for visit</p>
            <p>{appointmentDetails?.appointmentReason}</p>
          </div>
        )}
        {showFullView && (
          <div className="my-4">
            <p>Duration</p>
            <p>{appointmentDetails?.duration}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileAppointmentCard;
