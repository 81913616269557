import { useState } from "react";
import { CalendarIcon } from "../../svgs/CalendarIcon";
import { ReactComponent as DurationIcon } from "../../svgs/duration-icon.svg";
import { ReactComponent as NotificationIcon } from "../../svgs/notification-icon.svg";

interface AppointmentConfirmCardProps {
  providerImageUrl?: string;
  onClick?: (arg: any) => void;
  providerFirstName?: string;
  providerLastName?: string;
  providerType?: string;
  appointmentDate?: string;
  appointmentDuration?: string;
  reminders?: string;
  paymentDetails?: string;
}

const AppointmentConfirmCard = ({
  providerImageUrl,
  providerFirstName,
  providerLastName,
  providerType,
  appointmentDate,
  appointmentDuration,
  reminders,
  // paymentDetails,
  onClick = () => {}
}: AppointmentConfirmCardProps) => {
  const [isEditingEmail, setIsEditingEmail] = useState(false);

  const handleChangeItem = (key: any, value: any) => {
    switch (key) {
      case "isEditingEmail":
        setIsEditingEmail(value);
    }
  };

  return (
    <div className="flex flex-col justify-start items-start border border-[#EBEBEB] rounded-lg bg-[#fff] w-full h-[371px] bg-gradient-to-b-[rgba(0, 0, 0, .36), rgba(0, 0, 0, .36)] cursor-pointer grow shadow-md p-4 mb-4">
      <div className="w-full flex justify-start items-center">
        <div className="flex justify-start">
          <img
            src={providerImageUrl}
            alt="provider patient is about to book"
            className="w-[80px] h-[80px] rounded-full"
          />
        </div>
        <div className="w-8" />
        <div className="flex justify-end flex-col">
          <p className="text-[26px] font-[400] text-[#103C1B]">
            {`${providerFirstName} ${providerLastName}`}
          </p>
          <div className="h-1" />
          <p className="text-[15px] font-[400] text-[#103C1B]">
            {providerType}
          </p>
        </div>
      </div>
      <div className="h-8" />
      <div className="w-full">
        <div className="w-full flex items-center justify-start">
          <div className="flex justify-start items-center w-[35%] text-[#103C1B] text-[18px] font-[400]">
            <CalendarIcon /> <div className="w-4" /> Date:
          </div>
          <div className="flex text-left justify-start">{appointmentDate}</div>
        </div>
        <div className="h-4" />

        <div className="w-full flex items-center justify-start">
          <div className="flex justify-start items-center w-[35%] text-[#103C1B] text-[18px] font-[400]">
            <DurationIcon /> <div className="w-4" /> Duration:
          </div>
          <div className="flex text-left justify-start">
            {appointmentDuration}
          </div>
        </div>
        <div className="h-4" />

        <div className="w-full flex items-center lg:justify-start justify-between">
          <div className="flex justify-start items-center w-[35%] text-[#103C1B] text-[18px] font-[400]">
            <NotificationIcon /> <div className="w-4" />
            Reminders:
          </div>
          <div className="hidden lg:flex text-left justify-start ">
            {isEditingEmail ? (
              <input type="text" value={reminders} />
            ) : (
              <span>{reminders}</span>
            )}
            {isEditingEmail ? (
              <span className="pl-8 text-[#1A9D39]">Update</span>
            ) : (
              <div
                className="pl-8 text-[#1A9D39]"
                onClick={() => handleChangeItem("isEditingEmail", true)}
              >
                Change
              </div>
            )}
          </div>

          <div className="flex flex-col lg:hidden text-right justify-end ">
            {isEditingEmail ? (
              <input type="text" value={reminders} />
            ) : (
              <span>{reminders}</span>
            )}
            {isEditingEmail ? (
              <span className="text-[#1A9D39]">Update</span>
            ) : (
              <div
                className="text-[#1A9D39]"
                onClick={() => handleChangeItem("isEditingEmail", true)}
              >
                Change
              </div>
            )}
          </div>
        </div>
        <div className="h-4" />

        {/* <div className="w-full flex items-center lg:justify-start justify-between">
          <div className="flex justify-start items-center w-[35%] text-[#103C1B] text-[18px] font-[400]">
            {" "}
            <PaymentIcon /> <div className="w-4" />
            Card Details:
          </div>
          <div className="hidden lg:flex text-left justify-start">
            {paymentDetails}
            <span className="pl-8 text-[#1A9D39]"> Change</span>
          </div>

          <div className=" flex flex-col lg:hidden text-right justify-end">
            {paymentDetails}
            <span className="text-[#1A9D39]"> Change</span>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default AppointmentConfirmCard;
