import { useMemo } from 'react';
import { DecodedPatientData, getDecodedJwt, getToken, isAuthenticated } from '../../../../utils/helpers';

export function useAuthState() {
	const token = getToken();

	const user = useMemo<DecodedPatientData | null>(() => {
		if (token) {
			const decoded = getDecodedJwt<DecodedPatientData>(token);
			return decoded?.data || null;
		}
		return null;
	}, [token]);

	return {
		user,
		isAuthenticated: () => isAuthenticated<DecodedPatientData>(),
	};
}
