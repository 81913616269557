import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Spacer from "../../components/Spacer";
import Button from "../../components/shared/Button";
import TextField from "../../components/form/TextField";
import { forgotPassword } from "../../api/auth";
import ResendPasswordResetEmail from "../../components/auth/ResendPasswordResetEmail";

const SPForgotPassword = () => {
  const [isResendScreen, setResendScreen] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid Email").required("Email is required")
    }),
    onSubmit: async () => {
      await forgotPassword({ email: formik.values?.email, userType: "biller" });
      setResendScreen(true);
    }
  });

  return (
    <>
      {!isResendScreen ? (
        <div className="w-full box-border lg:w-[614px] bg-white">
          <div className="text-center">
            <p className="text-[34px] font-[800] text-[#103C1B]">
              Forgot your password?
            </p>
            <Spacer height={10} />
            <p className="font-[400] text-[20px] text-[#103C1B]">
              Enter your email to receive a link to reset your password.
            </p>
          </div>
          <Spacer height={40} />
          <div className="flex flex-col mx-auto items-center w-full lg:w-[478px]">
            <TextField
              name="email"
              type="email"
              placeholder="name@mail.com"
              value={formik.values.email}
              onChange={formik.handleChange}
              label="Email address"
              error={formik.touched.email ? formik.errors.email : ""}
            />

            <Spacer height={25} />

            <div className="w-[100%] flex justify-end items-center mt-8 text-xs text-normal">
              <Link to="/biller/login">
                <p className="text-[18px] font-[500] text-[#1A9D39]">
                  Go bck to login
                </p>{" "}
              </Link>

              <div className="flex flex-end ml-6">
                <Button
                  additionalClassname={"text-[18px] font-[400] w-[98px]"}
                  height={60}
                  type="submit"
                  variant="primary"
                  label="Submit"
                  onClick={formik.handleSubmit}
                  size="medium"
                />
              </div>
            </div>
            <div className="lg:h-[80px] h-[40px]" />
            <div className="lg:h-[80px] h-[40px]" />
          </div>
        </div>
      ) : (
        <ResendPasswordResetEmail
          email={formik.values.email}
          userType="biller"
        />
      )}
    </>
  );
};

export default SPForgotPassword;
