import * as Yup from "yup";
import { User } from "../../../../api/types";
import { phoneRegExp } from "../../../../utils/consts";

export function getInitialValues(user: Partial<User>) {
  return {
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    phoneNumber: user?.phoneNumber || "",
    providerNPI: user?.providerNPI || "",
    practiceNPI: user?.practiceNPI || "",
    licenseNumbers: user?.licenseNumbers || [],
    deaState: user?.deaState || "",
    deaNumber: user?.deaNumber || "",
    practiceName: user?.practiceName || "",
    address: user?.address || "",
    state: user?.state || "",
    city: user?.city || "",
    specialty: user?.specialty || "pcp",
    title: user?.title || "",
    pronoun: user?.pronoun || "",
    zipCode: user?.zipCode || "",
    bio: user?.bio || "",
    languages: user?.languages || [],
    avatarUrl: user?.avatarUrl || "",
    focusAreas: user?.focusAreas || []
  };
}

export const validationSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().required("Email is required"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .test("is-valid-us-phone-number", "Invalid US phone number", value => {
      return phoneRegExp.test(value as string);
    }),
  providerNPI: Yup.string().required("Provider NPI is required"),
  practiceNPI: Yup.string().required("Practice NPI is required"),
  licenseNumber: Yup.object().required("License Number is required"),
  address: Yup.string().required("Practice Address is required"),
  specialty: Yup.string().required("Practice Address is required"),
  title: Yup.string().required("Practice Address is required"),
  pronoun: Yup.string(),
  zipCode: Yup.string().required("Zipcode is required"),
  bio: Yup.string(),
  focusAreas: Yup.string().required("Focus Areas is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required")
});

export const passwordValidationSchema = Yup.object({
  currentPassword: Yup.string().required("Old password is required"),
  newPassword: Yup.string().required("New password is required"),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("You need to confirm your password")
});

export const passwordInitialValues = {
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: ""
};
