import AuthLayout from "../components/auth/AuthLayout";
import SignupFormOne from "../components/auth/SignupFormOne";

const RegisterUser = () => {
  return (
    <AuthLayout>
      <SignupFormOne />
    </AuthLayout>
  );
};

export default RegisterUser;
