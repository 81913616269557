import { format } from "date-fns";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Fragment } from 'react/jsx-runtime';
import { useGeInsuranceCredentialing } from "../../api/hooks/admin/get_insurance_credentialing";
import { useGeMalPracticeInsurance } from "../../api/hooks/admin/get_malPractice_insurance";
import { useGePracticeRegistration } from "../../api/hooks/admin/get_practice_reg";
import ReusableTable from "../../components/ReusableTable";
import ListTabsWidget from "../../components/shared/ListTabsWidget";
import { Loading } from "../../components/shared/Loading";
import { ArrowBackIcon } from "../../svgs/ArrowBackIcon";
import AdminDashboardLayout from "../components/AdminDashboardLayout";
import { useCredentialingRequestsTableCols } from "../tables/hooks/credentialing";
import { useMalpracticeInsuranceRequestsTableCols } from "../tables/hooks/map_practice";
import { usePracticeRegistrationsTableCols } from "../tables/hooks/practiceInsu";
import { InsuranceCredentialing } from '../types/insurance_credentialing';
import { MalpracticeInsuranceRequest } from '../types/mal_practice_insurance';
import { PracticeRegistration } from '../types/practice_reg';


const AdminBusinessOffice = () => {
  const search = useSearchParams()[0];
  const navigate = useNavigate();
  const activeTab = search.get("activeTab");
  const [activeTabLabel, setActiveTabLabel] = useState(
    activeTab || "Business Registration"
  );

  const handleTabClick = (tabName: string) => {
    navigate(`/admin/business-office?activeTab=${tabName}`);
    setActiveTabLabel(tabName);
  };

  const [isDetailsView, setIsDetailsView] = useState<boolean>(false);
  const [detailsData, setDetailsData] = useState<MalpracticeInsuranceRequest | InsuranceCredentialing | PracticeRegistration>({} as MalpracticeInsuranceRequest | InsuranceCredentialing | PracticeRegistration);

  const { malPracticeInsuranceData, isLoading: isLoadingMalPracticeInsurance } = useGeMalPracticeInsurance();
  const { insuranceCredelingData, isLoading: isLoadingInsuranceCredentialing } = useGeInsuranceCredentialing();
  const { allPracticeRegData, isLoading: isLoadingPracticeRegistration } = useGePracticeRegistration();

  const { MAL_PRACTICE_COLUMNS } = useMalpracticeInsuranceRequestsTableCols();
  const { INSUR_CREDENTIALING_COLUMNS } = useCredentialingRequestsTableCols();
  const { PRACTICE_INSUR_COLUMNS } = usePracticeRegistrationsTableCols();

  function showMoreDetails(data: MalpracticeInsuranceRequest | InsuranceCredentialing | PracticeRegistration) {
    setDetailsData(data);
  }

  const renderDetails = (data: MalpracticeInsuranceRequest | InsuranceCredentialing | PracticeRegistration) => {
    if ('claims' in data) {
      return (
        <Fragment>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Id: {' '}</span> {data.id}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Provider ID:</span> {data.providerId}</p>
          <p className="font-gentiumBasic capitalize"><span className="font-semibold">Insurance Provider:</span> {data.insuranceProvider}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Policy Number:{' '}</span> {data.policyNumber}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Deductible Amount:{' '}</span> {data.deductibleAmount}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Coverage Limit:{' '}</span> {data.coverageLimit}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Coverage Type:{' '} </span>{data.coverageType}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Effective Date: {' '}</span>{format(new Date(data.effectiveDate), "yyyy-MM-dd")}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Expiration Date: {' '}</span>{format(new Date(data.expirationDate), "yyyy-MM-dd")}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Fax: {' '}</span>{data.fax}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Insurance Response: {' '}</span>{data.insuranceResponse}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Current Status of Claim: {' '}</span>{data.currentStatusOfClaim}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Biz Entity Proof Key:{' '} </span>{data.bizEntityProofKey}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Medical License Key: {' '}</span>{data.medicalLicenseKey}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">CV Key: {' '}</span>{data.cvKey}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Has Had Malpractice Lawsuit: {' '}</span>{data.hasHadMalpracticeLawSuit ? 'Yes' : 'No'}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold"></span>Comments: {' '}{data.comments}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Date Created: {' '}</span>{format(new Date(data.created_at), "yyyy-MM-dd")}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Date Updated: {' '}</span>{format(new Date(data.updated_at), "yyyy-MM-dd")}</p>
          <p className="font-gentiumBasic">{data.claims.map((claim) => (
            <div key={claim.claimDate}>
              <div className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Claim Date: {' '}</span>{format(new Date(claim.claimDate), "yyyy-MM-dd")}</div>
              <div className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Claim Description: {' '}</span>{claim.claimDescription}</div>
              <div className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Claim Resolution: {' '}</span>{claim.claimResolution}</div>
              <div className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Claim Amount: {' '}</span>{claim.amount}</div>
            </div>
          ))}</p>
        </Fragment>
      );
    } else if ('resumeKey' in data) {
      return (
        <Fragment>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Id: {' '}</span>{data.id}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Credential ID: {' '}</span>{data.credentialId}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Provider ID: {' '}</span>{data.providerId}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Fax: {' '}</span>{data.fax}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Provider Tax ID: {' '}</span>{data.providerTaxId}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Practice Phone Number: {' '}</span>{data.practicePhoneNumber}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Insurance Provider:{' '}</span> {data.insuranceProvider}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Policy Number: {' '}</span>{data.policyNumber}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Resume Key: {' '}</span>{data.resumeKey}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Malpractice Cert Key: {' '}</span>{data.malpracticeCertKey}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Board Cert Key: {' '}</span>{data.boardCertKey}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Diploma Cert Key:{' '}</span> {data.diplomaCertKey}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">State License Key: {' '}</span>{data.stateLicenseKey}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">DEA Cert Key: {' '}</span>{data.deaCertKey}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Group Number: {' '}</span>{data.groupNumber}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Comments: {' '}</span>{data.comments}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Effective Date:{' '}</span> {format(new Date(data.effectiveDate), "yyyy-MM-dd")}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Expiration Date: {' '}</span>{format(new Date(data.expirationDate), "yyyy-MM-dd")}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Business Address 1:{' '}</span> {data.businessAddress1}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Business Address 2:{' '}</span> {data.businessAddress2}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Business City:{' '}</span> {data.businessCity}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Business State:{' '}</span> {data.businessState}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Business Zip Code: {' '}</span>{data.businessZipCode}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Date Created:{' '}</span> {format(new Date(data.created_at), "yyyy-MM-dd")}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Date Updated:{' '}</span> {format(new Date(data.updated_at), "yyyy-MM-dd")}</p>
        </ Fragment>
      );
    } else if ('regId' in data) {
      return (
        <Fragment>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Id: {' '}</span>{data.id}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Reg ID:{' '}</span> {data.regId}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Provider ID: {' '}</span>{data.providerId}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Business Address 1:{' '}</span> {data.businessAddress1}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Business Address 2: {' '}</span>{data.businessAddress2}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Business City: {' '}</span>{data.businessCity}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Business State: {' '}</span>{data.businessState}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Business Zip Code: {' '}</span>{data.businessZipCode}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Mailing Address 1: {' '}</span>{data.mailingAddress1}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Mailing Address 2: {' '}</span>{data.mailingAddress2}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Mailing City: {' '}</span>{data.mailingCity}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Mailing State: {' '}</span>{data.mailingState}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Mailing Zip Code: {' '}</span>{data.mailingZipCode}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Business Description: {' '}</span>{data.businessDescription}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Date Created:{' '}</span> {data.created_at}</p>
          <p className="font-gentiumBasic capitalize text-base"><span className="font-semibold">Date Updated: {' '}</span>{data.updated_at}</p>
        </Fragment>
      );
    }
  };

  return (
    <AdminDashboardLayout headerTitle="Business Office">
      {isDetailsView ? (
        <div className="w-full h-full px-4 mt-8">
          <div className="w-full">
            <div onClick={() => setIsDetailsView(false)} className="cursor-pointer flex justify-start items-center">
              <ArrowBackIcon />{" "}
              <div className="ml-3">Go back to Business Office</div>
            </div>
          </div>
          <div className="h-8" />
          <div className="my-6 mx-4">
            <div className="flex items-center">
              <div className="w-full bg-white rounded-[8px] shadow-[-2px_4px_25px_#EEEEE9] p-4">
                {renderDetails(detailsData)}
              </div>
            </div>
          </div>
        </div>
      ) :
        <Fragment>
          <div className="lg:mt-6 flex flex-col w-full h-full">
            <div className="overflow-y-hidden mb-6 px-4 w-full overflow-x-scroll lg:overflow-x-hidden py-4">
              <ListTabsWidget
                tabs={[
                  { label: "Business Registration" },
                  { label: "Insurance Credentialing" },
                  { label: "Malpractice Insurance" }
                ]}
                handleTabClick={label => {
                  handleTabClick(label);
                }}
                activeTabLabel={activeTabLabel}
              />
            </div>
            {isLoadingPracticeRegistration && (activeTab === "Business Registration" || activeTab === null) && <Loading />}
            {!isLoadingPracticeRegistration && (activeTab === "Business Registration" || activeTab === null) && (
              <ReusableTable
                tableColumns={PRACTICE_INSUR_COLUMNS}
                tableData={allPracticeRegData ?? []}
                onClick={data => {
                  showMoreDetails(data)
                  setIsDetailsView(true)
                  navigate(`/admin/business-office?activeTab=${activeTabLabel}`);
                }}
                pageSizeCount={10}
                searchFieldPlaceholder="Search Business Registration"
              />
            )}
            {isLoadingInsuranceCredentialing && activeTab === "Insurance Credentialing" && <Loading />}
            {!isLoadingInsuranceCredentialing && activeTab === "Insurance Credentialing" && (
              <ReusableTable
                tableColumns={INSUR_CREDENTIALING_COLUMNS}
                tableData={insuranceCredelingData ?? []}
                onClick={data => {
                  showMoreDetails(data)
                  setIsDetailsView(true)
                  navigate(`/admin/business-office?activeTab=${activeTabLabel}`);
                }}
                pageSizeCount={10}
                searchFieldPlaceholder="Search Insurance Credentialing"
              />
            )}
            {isLoadingMalPracticeInsurance && activeTab === "Malpractice Insurance" && <Loading />}
            {!isLoadingMalPracticeInsurance && activeTab === "Malpractice Insurance" && (
              <ReusableTable
                tableColumns={MAL_PRACTICE_COLUMNS}
                tableData={malPracticeInsuranceData ?? []}
                onClick={data => {
                  showMoreDetails(data)
                  setIsDetailsView(true)
                  navigate(`/admin/business-office?activeTab=${activeTabLabel}`);
                }}
                pageSizeCount={10}
                searchFieldPlaceholder="Search Malpractice Insurance"
              />
            )}
          </div>
        </Fragment>
      }
    </AdminDashboardLayout>
  );
};

export default AdminBusinessOffice;