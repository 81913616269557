import { UpcomingAppointmentType } from "../../../api/types";

export const getStartTimeOneHourAhead = (
  upcomingEvents: UpcomingAppointmentType[]
): string | null => {
  const currentDateTime = new Date();

  for (const event of upcomingEvents) {
    const appointmentStartTime = new Date(
      `2000-01-01T${event.appointmentStartTime}`
    );

    // Check if the appointment is at least 1 hour from the current time
    const oneHourLater = new Date(currentDateTime.getTime() + 60 * 60 * 1000);

    if (appointmentStartTime <= oneHourLater) {
      return event.appointmentStartTime;
    }
  }
  // If no suitable event is found, you can handle this case accordingly
  return null;
};
