import AuthLayout from "../components/auth/AuthLayout";
import PersonalRegistration from "../components/auth/PersonalRegistration";

const PersonalRegistrationPage = () => { 
  return (
    <AuthLayout>
      <PersonalRegistration/>
    </AuthLayout>
  );
};

export default PersonalRegistrationPage;