import { UserType } from "../types/common";
import http from "../utils/http";

// export const getS3SignedUrl = async ({
//   operation,
//   key,
//   object
// }: {
//   operation: "getObject" | "putObject";
//   key: string;
//   object: "emancipation" | "media" | "account";
// }) => {
//   try {
//     const response = await http.get(
//       `/common/signedUrl?operation=${operation}&key=${key}&object=${object}`
//     );
//     if (response.status === 200) {
//       return response.data;
//     }
//   } catch (error: any) {
//     return error.response.data;
//   }
// };

export const getImageSrc = async (key: string) => {
  try {
    const response = await http.get(
      `common/signedUrl?operation=getObject&key=${key}&object=account`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};

export const getNearbyPlaces = async ({
  latitude,
  longitude,
  place,
  name
}: {
  latitude: number;
  longitude: number;
  place: string;
  name?: string;
}) => {
  try {
    const response = await http.get(
      `common/nearbyPlaces/?latitude=${latitude}&longitude=${longitude}&place=${place}${
        name ? `&name=${name}` : ""
      }`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};

export const syncUserCalendar = async ({
  type,
  user
}: {
  type: "google" | "outlook" | "apple";
  user: UserType;
}) => {
  try {
    const response = await http.get(
      `/common/calendar?service=${type}&user=${user}`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};

// export const initiateOneTimePayment = async ({
//   productId,
//   cancelUrl,
//   successUrl
// }: {
//   productId: string;
//   cancelUrl: string;
//   successUrl: string;
// }) => {
//   try {
//     const response = await http.post("/stripe/payment-session", {
//       cancelUrl,
//       productId,
//       successUrl
//     });

//     if (response.status === 200) {
//       return response.data;
//     }
//   } catch (error) {
//     return error;
//   }
// };
