import React from "react";
import { MoneyMadeDashIcon } from "../../svgs/MoneyMadeDashIcon";

interface IProps {
  title: string;
  totalCount: number;
  todayCount: number;
}

const SPMoneyMadeCard: React.FC<IProps> = ({
  title,
  totalCount = 0,
  todayCount = 0
}) => {
  return (
    <div className="rounded-[8px] drop-shadow bg-[#fff] w-full h-full p-4 mb-2">
      <div className="flex items-center px-4 my-auto h-full">
        <div>
          <MoneyMadeDashIcon />
        </div>
        <div className="ml-4">
          <p className="text-[#324705] text-[16px] font-[500]">{title}</p>
          <p className="text-[#324705] text-[40px] font-[600]">{totalCount}</p>
          <p className="text-[#34623F] text-[16px] font-[500]">{`${todayCount} made today`}</p>
        </div>
      </div>
    </div>
  );
};

export default SPMoneyMadeCard;
