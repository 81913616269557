const PaymentDetailsForPaidStatus = ({ details }: any) => {
  return (
    <div className="w-full h-full">
      <div className="w-full flex flex-wrap lg:flex-nowrap items-center pl-4">
        <div className="w-full lg:w-[595px]">
          <div className="h-[53pxx] w-full bg-[#F8F8F7] text-[20px] text-[#324705] font-[500] p-2 mb-5">
            Payer Information
          </div>
          <div className="w-[80%] flex items-center justify-between">
            <p className="text-[16px] text-[#87928A] font-[500]">
              Name of Payer
            </p>
            <p className="w-1/2 text-[16px] text-[#324705] font-[500] text-left">
              ---
            </p>
          </div>
          <div className="h-4" />
          <div className="w-[80%] flex items-center justify-between">
            <p className="text-[16px] text-[#87928A] font-[500]">
              Identifier Address
            </p>
            <p className="w-1/2 text-[16px] text-[#324705] font-[500] text-left">
              ---
            </p>
          </div>
          <div className="h-4" />
          <div className="w-[80%] flex items-center justify-between">
            <p className="text-[16px] text-[#87928A] font-[500]">Claim ID</p>
            <p className="w-1/2 text-[16px] text-[#324705] font-[500] text-left">
              ---
            </p>
          </div>
          <div className="h-4" />
          <div className="w-[80%] flex items-center justify-between">
            <p className="text-[16px] text-[#87928A] font-[500]">
              Contact Number
            </p>
            <p className="w-1/2 text-[16px] text-[#324705] font-[500] text-left">
              ---
            </p>
          </div>
        </div>
        <div className="w-8" />
        <div className="w-full lg:w-[595px] my-4 lg:my-0">
          <div className="h-[53pxx] w-full bg-[#F8F8F7] text-[20px] text-[#324705] font-[500] p-2 mb-5">
            Payment Information
          </div>
          <div className="w-[80%] flex items-center justify-between">
            <p className="text-[16px] text-[#87928A] font-[500]">
              Total payment amount
            </p>
            <p className="w-1/2 text-[16px] text-[#324705] font-[500] text-left">
              {details?.charges}
            </p>
          </div>
          <div className="h-4" />
          <div className="w-[80%] flex items-center justify-between">
            <p className="text-[16px] text-[#87928A] font-[500]">
              Payment method
            </p>
            <p className="w-1/2 text-[16px] text-[#324705] font-[500] text-left">
              ---
            </p>
          </div>
          <div className="h-4" />
          <div className="w-[80%] flex items-center justify-between">
            <p className="text-[16px] text-[#87928A] font-[500]">Check ID</p>
            <p className="w-1/2 text-[16px] text-[#324705] font-[500] text-left">
              {details?.paymentId}
            </p>
          </div>
          <div className="h-4" />
          <div className="w-[80%] flex items-center justify-between">
            <p className="text-[16px] text-[#87928A] font-[500]">
              Payment Date
            </p>
            <p className="w-1/2 text-[16px] text-[#324705] font-[500] text-left">
              {details?.payment_date}
            </p>
          </div>
        </div>
      </div>
      <div className="w-full lg:pl-4 mt-0 lg:mt-10">
        <div className="w-full lg:w-[550px]">
          <div className="h-[53pxx] w-full bg-[#F8F8F7] text-[20px] text-[#324705] font-[500] p-2 mb-5">
            Transfer Information
          </div>
          <div className="w-[80%] flex items-center justify-between">
            <p className="text-[16px] text-[#87928A] font-[500]">
              Sender account number
            </p>
            <p className="w-1/2 text-[16px] text-[#324705] font-[500] text-left">
              ---
            </p>
          </div>
          <div className="h-4" />
          <div className="w-[80%] flex items-center justify-between">
            <p className="text-[16px] text-[#87928A] font-[500]">
              Receiver identifier
            </p>
            <p className="w-1/2 text-[16px] text-[#324705] font-[500] text-left">
              ---
            </p>
          </div>
          <div className="h-4" />
          <div className="w-[80%] flex items-center justify-between">
            <p className="text-[16px] text-[#87928A] font-[500]">
              Receiver account number
            </p>
            <p className="w-1/2 text-[16px] text-[#324705] font-[500] text-left">
              ---
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetailsForPaidStatus;
