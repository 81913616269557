import { styled } from "@mui/material";
import { format } from "date-fns";
import React, { useState } from "react";
import { Dependent } from "../../../api/hooks/accounts/types/dependant.type";
import DeleteDependantModal from "../../../components/MyAccount/Patient/DeleteDependantModal";
import { DeleteIcon } from "../../../svgs/DeleteIcon";
import { ReactComponent as EditIcon } from "../../../svgs/edit-pen.svg";

interface DependantListItemProps {
  onClick: (...arg: any) => any;
  cardData: Dependent;
  index: number;
}

const DependantListItem: React.FC<DependantListItemProps> = (
  props: DependantListItemProps
) => {
  const [showDeleteDependantModal, setShowDeleteDependantModal] =
    useState(false);

  return (
    <DependantListItemContainer
      className="lg-dependent"
      style={{ cursor: "pointer", boxShadow: "-2px 4px 25px #EEEEE9" }}
    >
      <>
        <div className="card-br">
          <p className="card-title-head">Dependent {props?.index + 1}</p>
          <p className="user-name w-[200px] break-all">
            {`${props?.cardData?.firstName} ${props?.cardData?.lastName}`}{" "}
          </p>
        </div>
        <div className="card-br">
          <p className="card-title-head">Email Address</p>
          <p className="card-title-body break-all">{props?.cardData?.email}</p>
        </div>
        <div className="card-br">
          <p className="card-title-head">Date of birth</p>

          <p className="card-title-body">
            {" "}
            {format(new Date(props?.cardData?.dateOfBirth), "MM/dd/yyyy")}
          </p>
        </div>
        <div className="">
          <p className="card-title-head">Gender</p>
          <p className="card-title-body">{props?.cardData?.gender}</p>
        </div>
        <div className="flex justify-between items-center text-[.7rem]">
          <button
            onClick={() => {
              props?.onClick(props?.cardData?.userId, props?.cardData);
            }}
            className="mr-4"
          >
            <EditIcon />
          </button>

          <button
            onClick={() => {
              setShowDeleteDependantModal(true);
            }}
          >
            <DeleteIcon className="ml-4" />
          </button>
        </div>
        <DeleteDependantModal
          isVisible={showDeleteDependantModal} 
          closeModal={() => setShowDeleteDependantModal(false)}
          dependantId={props?.cardData?.userId}
          dependantName={`${props?.cardData?.firstName} ${props?.cardData?.lastName}`}
        />
      </>
    </DependantListItemContainer>
  );
};

export default DependantListItem;

const DependantListItemContainer = styled("div")(() => ({
  position: "relative",
  width: "100%",
  height: "fit-content",
  backgroundColor: "#fff",
  boxShadow: "0px 2px 3px 0 rgba(0,0,0,.1)",
  padding: ".8rem",
  borderRadius: "4px",
  display: "grid",
  gridTemplateColumns: "20% 30% 15% 15% auto",
  gridTemplateRows: "auto autos",
  gap: "1%",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "14px",

  "& .card-br": {
    borderRight: "1px solid #EEEEE9"
  },
  "& .card-brlast": {
    borderRight: "1px solid #EEEEE9"
  },
  "& .arrow-icon": {
    fontSize: ".7rem",
    color: "#87928A"
  },
  "& .card-title-head": {
    fontSize: ".74rem",
    color: "#87928A"
  },

  "& .card-title-body": {
    fontSize: ".75rem",
    color: "#324705",
    fontWeight: "bold"
  },

  "& .card-user-profile": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    position: "relative",
    width: "100%",
    overflowX: "auto",
    borderRight: "1px solid #EEEEE9",

    "& .user-image": {
      width: "50px",
      height: "50px",
      marginRight: "6px",
      borderRadius: "100%",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",

      "& .user-img-src": {
        width: "100%",
        height: "auto",
        position: "relative"
      }
    },
    "& .user-name": {
      color: "#103C1B",
      fontWeight: "bold",
      fontSize: "1.12rem",
      boxSizing: "border-box"
    },
    "& .user-desc": {
      color: "#103C1B",
      fontSize: ".78rem"
    }
  }
}));
