import styled from "@emotion/styled";
import { useFormik } from "formik";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { getS3SignedUrl } from "../../../api/hooks/common/fetchers.common";
import { useInitialOneTimePayment } from "../../../api/hooks/common/initiateOneTimePayment";
import { GovtIdDoc } from "../../../components/auth/PersonalRegistration";
import RadioField from "../../../components/form/RadioField";
import TextField from "../../../components/form/TextField";
import Button from "../../../components/shared/Button";
import CheckWithLabelToggle from "../../../components/shared/CheckWithLabelToggle";
import { statesInTheUS } from "../../../utils/consts";
import { generateUUID, removeEmptyObjectValues } from "../../../utils/helpers";
import FeedbackModal from "../../components/FeedbackModal";
import { initialValues, validationSchema } from './schemas/validation';

export interface FormValues {
  title: string | undefined;
  insuranceProvider: string;
  npi: string;
  practicePhoneNumber: string;
  fax: string | undefined;
  policyNumber: string;
  providerType: string;
  practiceName: string;
  providerTaxId?: string;
  licenseNumber: string;
  stateOfLicense: string;
  deaNumber: string | undefined;
  middleName: string | undefined;
  lastName?: string;
  firstName?: string;
  dateOfBirth: string;
  effectiveDate: string;
  expirationDate: string;
  gender: string;
  address1?: string;
  address2: string | undefined;
  groupNumber: string | undefined;
  ssn: string;
  city?: string;
  state?: string;
  zipCode?: string;
  businessAddress1: string | undefined;
  businessAddress2: string | undefined;
  businessCity: string | undefined;
  businessState: string | undefined;
  businessZipCode: string | undefined;
  comments: string | undefined;
  email: string;
  phoneNumber: string;
}

const Credentialing = ({
  // setInitialSettingsFormValues,
  // initialSettingsFormValues,
  // triggerUserRefresh,
  toast
}: {
  setInitialSettingsFormValues: any;
  initialSettingsFormValues: any;
  triggerUserRefresh: any;
  toast: any;
}) => {
  const urlLocation = useLocation();
  const [wantsToCredential, setWantsToCredential] = useState(false);

  const [isSameAsBusinessAddress, setIsSameAsBusinessAddress] = useState(false);
  const [isCertified, setIsCertified] = useState(false);
  const [resumeKey, setResumeKey] = useState<any>("");
  const [resumeId, setResumeId] = useState({} as GovtIdDoc);
  const [malpracticeCertId, setMalpracticeCertId] = useState({} as GovtIdDoc);
  const [malpracticeCertKey, setMapCertKey] = useState<any>("");
  const [boardCertId, setBoardCertId] = useState({} as GovtIdDoc);
  const [boardCertKey, setBoardKey] = useState<any>("");
  const [diplomaCertId, setDiplomaCertId] = useState({} as GovtIdDoc);
  const [diplomaCertKey, setNpiKey] = useState<any>("");
  const [deaCertId, setDeaCertId] = useState({} as GovtIdDoc);
  const [deaCertKey, setDeaKey] = useState<any>("");
  const [stateId, setStateId] = useState({} as GovtIdDoc);
  const [stateLicenseKey, setStateKey] = useState<any>("");
  const [showModal, setShowModal] = useState(false);



  const { initiateOneTimePayment, isPending, isSuccess } = useInitialOneTimePayment();

  const onSubmit = async (values: FormValues) => {
    const newValues = removeEmptyObjectValues({
      businessAddress1: isSameAsBusinessAddress
        ? values.address1
        : values.businessAddress1,
      businessAddress2: isSameAsBusinessAddress
        ? values.address2
        : values.businessAddress2,
      businessCity: isSameAsBusinessAddress ? values.city : values.businessCity,
      businessState: isSameAsBusinessAddress
        ? values.state
        : values.businessState,
      businessZipCode: isSameAsBusinessAddress
        ? values.zipCode
        : values.businessZipCode,
      insuranceProvider: values.insuranceProvider,
      practicePhoneNumber: values.practicePhoneNumber,
      fax: values.fax,
      policyNumber: values.policyNumber,
      providerTaxId: values.providerTaxId,
      effectiveDate: values.effectiveDate,
      expirationDate: values.expirationDate,
      groupNumber: values.groupNumber,
      comments: values.comments,
      resumeKey: resumeKey,
      malpracticeCertKey: malpracticeCertKey,
      boardCertKey: boardCertKey,
      diplomaCertKey: diplomaCertKey,
      deaCertKey: deaCertKey,
      stateLicenseKey: stateLicenseKey
    });

    if (
      !resumeKey ||
      !malpracticeCertKey ||
      !boardCertKey ||
      !diplomaCertKey ||
      !stateLicenseKey
    ) {
      toast.error("Please upload all the necessary documents");
    } else {
      const { REACT_APP_CREDENTIALING_PRODUCT_ID } = process.env;
      if (REACT_APP_CREDENTIALING_PRODUCT_ID) {
        initiateOneTimePayment({
          cancelUrl: urlLocation.pathname,
          productId: REACT_APP_CREDENTIALING_PRODUCT_ID,
          successUrl:
            "provider/checkout/business-office/success?session_id={CHECKOUT_SESSION_ID}&page=credentialReg"
        }, {
          onSuccess: (data) => {
            if (data.url) {
              localStorage.setItem("credentialingReg", JSON.stringify(newValues));
              window.location.href = data.url;
            }
          }
        });
      }
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    values,
    touched,
    // isValid,
    // setFieldValue,
    isSubmitting
  } = useFormik<FormValues>({
    initialValues,
    validationSchema,
    onSubmit
  });

  const handleFileUpload = async (e: any, setId: any, setKey: any) => {
    const file = e.target.files?.[0];
    if (!file) {
      toast.info("No file selected");
      return;
    }

    const maxAllowedSize = 2 * 1024 * 1024;
    if (file.size > maxAllowedSize) {
      toast.error("File size should be less than 2MB");
      return;
    } else {
      setId({ file });
      const key = generateUUID() + file.name.slice(file.name.lastIndexOf("."));
      (async () => {
        const response = await getS3SignedUrl({
          operation: "putObject",
          key,
          object: "account"
        });
        setKey(`${response.signedUrlParams.host}/${key}`);
      })();
    }

  };

  const handleDeleteFile = (setId: any, setKey: any) => {
    setId({});
    setKey("");
  };


  return (
    <>
      <div className="mb-5 px-5 md:px-10 lg:px-20">
        <h2 className="text-[18px]">
          Do you want to get credentialed with an insurance company?
        </h2>
        <RadioField
          name="seeRegistrationForm"
          placeholder="Register"
          display="block"
          options={[
            { item: "Yes", value: true },
            { item: "No", value: false }
          ]}
          value={wantsToCredential}
          onChange={e => {
            if (e.target.value === "true") {
              setWantsToCredential(true);
            } else {
              setWantsToCredential(false);
            }
          }}
        />
      </div>
      {wantsToCredential && (
        <div className="h-full w-full mb-10 overflow-y-auto sm:ml-10 mt-10">
          <h1 className="text-center font-[600] text-[28px] text-neutral-900">
            Insurance Credentialing
          </h1>
          <div className="w-full text-center flex flex-row items-center justify-center mt-4">
            <p className="text-center text-[14px] font-[500] text-neutral-900 w-[50%]">
              Credentialing is a crucial step in becoming an approved healthcare
              provider with insurance networks. Complete the Insurance
              Credentialing form to submit essential professional information,
              practice details, and required documents.
            </p>
          </div>
          <form
            className="mt-6 w-full flex items-center justify-center flex-wrap"
            onSubmit={e => handleSubmit(e)}
          >
            <div className="mt-6 w-full md:w-[80%]">
              <div className="text-primary-main text-[24px] font-[500] my-4">
                Insurance Information
              </div>
              <div className="flex flex-col sm:flex-row items-start justify-between gap-2">
                <div className="mt-4 flex-1">
                  <TextField
                    name="insuranceProvider"
                    type="text"
                    label="Insurance Provider"
                    placeholder="Enter Select Insurance Provider"
                    value={
                      values?.insuranceProvider ? values.insuranceProvider : ""
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.insuranceProvider && errors.insuranceProvider
                        ? errors.insuranceProvider
                        : ""
                    }
                  />
                </div>
                <div className="mt-4 flex-1">
                  <TextField
                    name="policyNumber"
                    type="text"
                    label="Insurance Policy Number"
                    placeholder="Enter Insurance Policy Number"
                    value={values.policyNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.policyNumber && errors.policyNumber
                        ? errors.policyNumber
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="mt-5">
                <div className="flex flex-col sm:flex-row items-start justify-between gap-2">
                  <div className="flex-1">
                    <TextField
                      name="groupNumber"
                      type="text"
                      label="Group Number (if applicable)"
                      placeholder="Enter Group Number"
                      value={values.groupNumber ? values.groupNumber : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.groupNumber && errors.groupNumber
                          ? errors.groupNumber
                          : ""
                      }
                    />
                  </div>
                  <div className="flex-1">
                    <TextField
                      name="effectiveDate"
                      type="date"
                      label="Effective Date"
                      placeholder="MM/DD/YYYY"
                      value={values.effectiveDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.effectiveDate && errors.effectiveDate
                          ? errors.effectiveDate
                          : ""
                      }
                    />
                  </div>
                  <div className="flex-1">
                    <TextField
                      name="expirationDate"
                      type="date"
                      label="Expiration Date"
                      placeholder="MM/DD/YYYY"
                      value={values.expirationDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.expirationDate && errors.expirationDate
                          ? errors.expirationDate
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center gap-1 mt-5">
                <div className="text-primary-main text-[24px] font-[500] my-4">
                  Personal Information
                </div>
              </div>
              <div className="flex flex-col sm:flex-row items-start justify-between flex-wrap gap-2">
                <div className="flex-1">
                  <TextField
                    name="title"
                    type="text"
                    label="Title"
                    placeholder="Enter title"
                    value={values.title ? values.title : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.title && errors.title ? errors.title : ""}
                  />
                </div>
                <div className="flex-1">
                  <TextField
                    name="firstName"
                    type="text"
                    label="First Name"
                    placeholder="Enter first name"
                    value={values.firstName || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.firstName && errors.firstName
                        ? errors.firstName
                        : ""
                    }
                  />
                </div>
                <div className="flex-1">
                  <TextField
                    name="middleName"
                    type="text"
                    label="Middle Name"
                    placeholder="Enter middle name"
                    value={values.middleName ? values.middleName : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.middleName && errors.middleName
                        ? errors.middleName
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col sm:flex-row items-start justify-between gap-2 mt-3">
                <div className="flex-1">
                  <TextField
                    name="lastName"
                    type="text"
                    label="Last Name"
                    placeholder="Enter last name"
                    value={values.lastName ? values.lastName : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.lastName && errors.lastName ? errors.lastName : ""
                    }
                  />
                </div>
                <div className="flex-1">
                  <TextField
                    name="dateOfBirth"
                    type="date"
                    label="Date of Birth"
                    placeholder="MM/DD/YYYY"
                    value={values.dateOfBirth}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.dateOfBirth && errors.dateOfBirth
                        ? errors.dateOfBirth
                        : ""
                    }
                  />
                </div>
                <div className="flex-1">
                  <TextField
                    name="ssn"
                    type="text"
                    label="Social Security Number"
                    placeholder="Enter Social Security Number"
                    value={values.ssn ? values.ssn : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.ssn && errors.ssn ? errors.ssn : ""}
                  />
                </div>
              </div>
              <div className="flex-1">
                <div className="my-2">
                  <div className="text-[18px] font-[500] text-left">Gender</div>
                  <RadioField
                    name="gender"
                    placeholder="Gender"
                    display="inline"
                    options={[
                      { item: "Male", value: "male" },
                      { item: "Female", value: "female" },
                      { item: "Other", value: "other" }
                    ]}
                    isRequired={true}
                    value={values.gender}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="flex flex-row items-center gap-1 mt-5">
                <div className="text-primary-main text-[24px] font-[500] my-4">
                  Contact Information
                </div>
              </div>
              <div className="">
                <div className="mt-4 w-full">
                  <TextField
                    name="address1"
                    type="text"
                    label="Address Line 1"
                    placeholder="Address Line 1"
                    value={values?.address1 ? values.address1 : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.address1 && errors.address1 ? errors.address1 : ""
                    }
                  />
                </div>
                <div className="mt-4 w-full">
                  <TextField
                    name="address2"
                    type="text"
                    label="Address Line 2"
                    placeholder="Address Line 2"
                    value={values?.address2 ? values.address2 : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.address2 && errors.address2 ? errors.address2 : ""
                    }
                  />
                </div>
                <div className="mt-5">
                  <div className="flex flex-col sm:flex-row items-start justify-between gap-2">
                    <TextField
                      name="city"
                      type="text"
                      label="City"
                      placeholder="city"
                      value={values?.city ? values.city : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.city && errors.city ? errors.city : ""}
                    />
                    <div className="flex-1">
                      <label
                        className="text-[#000] font-medium my-2 text-[16px]"
                        htmlFor={`stateOfLicense`}
                      >
                        State
                      </label>
                      <select
                        className="w-fit py-3 px-4 flex items-center bg-white justify-between border border-[#949589] text-[#103C1B] rounded leading-tight focus:outline-none focus:bg-none focus:border-gray-500 hover:border-black"
                        // placeholder="Select type"
                        name="state"
                        id="state"
                        value={values.state}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Select state
                        </option>
                        {statesInTheUS.map((state, index) => {
                          return (
                            <option key={index} value={state} id={state}>
                              {state}
                            </option>
                          );
                        })}
                      </select>
                      {touched.stateOfLicense && errors.stateOfLicense && (
                        <div className="text-[red] text-xs">
                          {errors.stateOfLicense}
                        </div>
                      )}
                    </div>
                    <TextField
                      name="zipCode"
                      type="text"
                      label="Zip Code"
                      placeholder="Zip Code"
                      value={values?.zipCode ? values.zipCode : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.zipCode && errors.zipCode ? errors.zipCode : ""
                      }
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <div className="flex flex-col sm:flex-row items-start justify-between gap-2">
                    <div className="flex-1">
                      <TextField
                        name="email"
                        type="text"
                        label="Email"
                        placeholder="Email"
                        value={values?.email ? values.email : ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.email && errors.email ? errors.email : ""
                        }
                      />
                    </div>
                    <div className="flex-1">
                      <TextField
                        name="phoneNumber"
                        type="text"
                        label="Phone Number"
                        placeholder="Phone Number"
                        value={values?.phoneNumber ? values.phoneNumber : ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.phoneNumber && errors.phoneNumber
                            ? errors.phoneNumber
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center gap-1 mt-5">
                <div className="text-primary-main text-[24px] font-[500] my-4">
                  Professional Information
                </div>
              </div>
              <div className="my-4 flex flex-col sm:flex-row items-start justify-between gap-2">
                <div className="flex-1">
                  <TextField
                    name="npi"
                    type="text"
                    label="NPI (National Provider Identifier)"
                    placeholder="Enter NPI"
                    value={values?.npi ? values.npi : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.npi && errors.npi ? errors.npi : ""}
                  />
                </div>
                <div className="flex-1">
                  <label
                    className="text-[#000] font-medium mb-8 text-[16px]"
                    htmlFor={`providerType`}
                  >
                    Provider Type
                  </label>
                  <select
                    className="w-[100%] py-3 px-4 flex items-center bg-white justify-between border border-[#949589] text-[#103C1B] rounded leading-tight focus:outline-none focus:bg-none focus:border-gray-500 hover:border-black"
                    name="providerType"
                    id="providerType"
                    value={values.providerType}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Select provider Type
                    </option>
                    {[
                      "Primary Care Physician",
                      "Therapist",
                      "Psychiatrist"
                    ].map((state, index) => {
                      return (
                        <option key={index} value={state} id={state}>
                          {state}
                        </option>
                      );
                    })}
                  </select>
                  {touched.providerType && errors.providerType && (
                    <div className="text-[red] text-xs">
                      {errors.providerType}
                    </div>
                  )}
                </div>
              </div>
              <div className="my-4 flex flex-col sm:flex-row items-start justify-between gap-2">
                <div className="flex-1">
                  <TextField
                    name="licenseNumber"
                    type="text"
                    label="License Number"
                    placeholder="Enter License Number"
                    value={values?.licenseNumber ? values.licenseNumber : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.licenseNumber && errors.licenseNumber
                        ? errors.licenseNumber
                        : ""
                    }
                  />
                </div>
                <div className="flex-1">
                  <label
                    className="text-[#000] font-medium mb-2 text-[16px]"
                    htmlFor={`stateOfLicense`}
                  >
                    State
                  </label>
                  <select
                    className="w-[100%] py-3 px-4 flex items-center bg-white justify-between border border-[#949589] text-[#103C1B] rounded leading-tight focus:outline-none focus:bg-none focus:border-gray-500 hover:border-black"
                    name="stateOfLicense"
                    id="stateOfLicense"
                    value={values.stateOfLicense}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Select state
                    </option>
                    {statesInTheUS.map((state, index) => {
                      return (
                        <option key={index} value={state} id={state}>
                          {state}
                        </option>
                      );
                    })}
                  </select>
                  {touched.stateOfLicense && errors.stateOfLicense && (
                    <div className="text-[red] text-xs">
                      {errors.stateOfLicense}
                    </div>
                  )}
                </div>
              </div>
              <div className="my-4 flex flex-col sm:flex-row items-start justify-between gap-2">
                <div className="flex-1">
                  <TextField
                    name="deaNumber"
                    type="text"
                    label="DEA Number (if applicable)"
                    placeholder="Enter DEA Number"
                    value={values?.deaNumber ? values.deaNumber : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.deaNumber && errors.deaNumber
                        ? errors.deaNumber
                        : ""
                    }
                  />
                </div>
                <div className="flex-1"></div>
              </div>
              <div className="flex flex-row items-center gap-1 mt-5">
                <div className="text-primary-main text-[24px] font-[500] my-4">
                  Practice Information
                </div>
              </div>
              <div className="flex flex-col sm:flex-row items-start justify-between gap-2">
                <div className="flex-1">
                  <TextField
                    name="practiceName"
                    type="text"
                    label="Practice Name"
                    placeholder="Practice Name"
                    value={values?.practiceName ? values.practiceName : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.practiceName && errors.practiceName
                        ? errors.practiceName
                        : ""
                    }
                  />
                </div>
                <div className="flex-1">
                  <TextField
                    name="providerTaxId"
                    type="text"
                    label="Provider Tax ID"
                    placeholder="Provider Tax ID"
                    value={values.providerTaxId || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.providerTaxId && errors.providerTaxId
                        ? errors.providerTaxId
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="flex flex-row items-center gap-1 mt-5">
                <span className="font-[500] text-[#000]">Practice address</span>
              </div>
              <div className="flex flex-row items-center gap-1 mt-5 font-bold">
                <CheckWithLabelToggle
                  label="Same as home address"
                  isSelected={isSameAsBusinessAddress}
                  toggleSelection={() =>
                    setIsSameAsBusinessAddress(prev => !prev)
                  }
                />
              </div>
              {!isSameAsBusinessAddress && (
                <>
                  {" "}
                  <div className="">
                    <div className="mt-4 w-full">
                      <TextField
                        name="businessAddress1"
                        type="text"
                        label="Address Line 1"
                        placeholder="Address Line 1"
                        value={
                          values?.businessAddress1
                            ? values.businessAddress1
                            : ""
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.businessAddress1 && errors.businessAddress1
                            ? errors.businessAddress1
                            : ""
                        }
                      />
                    </div>
                    <div className="mt-4 w-full">
                      <TextField
                        name="businessAddress2"
                        type="text"
                        label="Address Line 2"
                        placeholder="Address Line 2"
                        value={
                          values?.businessAddress2
                            ? values.businessAddress2
                            : ""
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.businessAddress2 && errors.businessAddress2
                            ? errors.businessAddress2
                            : ""
                        }
                      />
                    </div>
                    <div className="mt-5">
                      <div className="flex flex-col sm:flex-row items-start justify-between gap-2">
                        <TextField
                          name="businessCity"
                          type="text"
                          label="City"
                          placeholder="City"
                          value={
                            values?.businessCity ? values.businessCity : ""
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.businessCity && errors.businessCity
                              ? errors.businessCity
                              : ""
                          }
                        />

                        <div className="flex-1">
                          <label
                            className="text-[#000] font-medium my-2 text-[16px]"
                            htmlFor={`stateOfLicense`}
                          >
                            State
                          </label>
                          <select
                            className="w-fit py-3 px-4 flex items-center bg-white justify-between border border-[#949589] text-[#103C1B] rounded leading-tight focus:outline-none focus:bg-none focus:border-gray-500 hover:border-black"
                            name="businessState"
                            id="stateOfLicense"
                            value={values.businessState}
                            onChange={handleChange}
                          >
                            <option value="" disabled>
                              Select state
                            </option>
                            {statesInTheUS.map((state, index) => {
                              return (
                                <option key={index} value={state} id={state}>
                                  {state}
                                </option>
                              );
                            })}
                          </select>
                          {touched.businessState && errors.businessState && (
                            <div className="text-[red] text-xs">
                              {errors.businessState}
                            </div>
                          )}
                        </div>
                        <TextField
                          name="businessZipCode"
                          type="text"
                          label="Zip Code"
                          placeholder="Zip Code"
                          value={
                            values?.businessZipCode
                              ? values.businessZipCode
                              : ""
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.businessZipCode && errors.businessZipCode
                              ? errors.businessZipCode
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="mt-3">
                <div className="flex flex-col sm:flex-row items-start justify-between gap-2">
                  <div className="flex-1">
                    <TextField
                      name="practicePhoneNumber"
                      type="text"
                      label="Phone Number"
                      placeholder="Phone Number"
                      value={
                        values?.practicePhoneNumber
                          ? values.practicePhoneNumber
                          : ""
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.practicePhoneNumber &&
                          errors.practicePhoneNumber
                          ? errors.practicePhoneNumber
                          : ""
                      }
                    />
                  </div>
                  <div className="flex-1">
                    <TextField
                      name="fax"
                      type="text"
                      label="Fax"
                      placeholder="Fax"
                      value={values?.fax ? values.fax : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.fax && errors.fax ? errors.fax : ""}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center gap-1 mt-5">
                <div className="text-primary-main text-[24px] font-[500] my-4">
                  Document Upload
                </div>
              </div>
              <div className="flex flex-col sm:flex-row gap-3">
                <div className="flex-1">
                  <div className="flex flex-row items-start justify-between">
                    <div className="text-[#000] font-medium mb-2 text-[16px]">
                      Upload Resume
                    </div>
                  </div>
                  <UploadContainer className="">
                    <input
                      id="resume_file_upload"
                      name="resumeKey"
                      onBlur={handleBlur}
                      className="invisible_file_upload"
                      onChange={e =>
                        handleFileUpload(e, setResumeId, setResumeKey)
                      }
                      type="file"
                      accept=".pdf, image/*"
                    />
                    <div
                      className="file_upload_cont"
                      onClick={() =>
                        document.getElementById("resume_file_upload")?.click()
                      }
                    >
                      <div className="trigger-text-cont">
                        <span className="text-[#103C1B] text-[16px]">
                          Click to upload file (max-file-size: 2MB.)
                        </span>
                      </div>
                    </div>
                    {resumeId?.file && (
                      <div className="id-name-cont space-x-2">
                        <span>{resumeId?.file?.name}</span>
                        <span
                          className="remove-edoc"
                          onClick={() =>
                            handleDeleteFile(setResumeId, setResumeKey)
                          }
                        >
                          Delete
                        </span>
                      </div>
                    )}
                  </UploadContainer>
                </div>
                <div className="flex-1">
                  <div className="flex flex-row items-start justify-between">
                    <div className="text-[#000] font-medium mb-2 text-[16px]">
                      Upload State License
                    </div>
                  </div>
                  <UploadContainer className="">
                    <input
                      id="state_id_file_upload"
                      className="invisible_file_upload"
                      name="stateLicenseKey"
                      onChange={e =>
                        handleFileUpload(e, setStateId, setStateKey)
                      }
                      type="file"
                      accept=".pdf, image/*"
                    />
                    <div
                      className="file_upload_cont"
                      onClick={() =>
                        document.getElementById("state_id_file_upload")?.click()
                      }
                    >
                      <div className="trigger-text-cont">
                        <span className="text-[#103C1B] text-[16px]">
                          Click to upload file (max-file-size: 2MB.)
                        </span>
                      </div>
                    </div>
                    {stateId?.file && (
                      <div className="id-name-cont space-x-2">
                        <span>{stateId?.file?.name}</span>
                        <span
                          className="remove-edoc"
                          onClick={() =>
                            handleDeleteFile(setStateId, setStateKey)
                          }
                        >
                          Delete
                        </span>
                      </div>
                    )}
                  </UploadContainer>
                </div>
              </div>
              <div className="mt-4 flex flex-col sm:flex-row gap-3">
                <div className="flex-1">
                  <div className="flex flex-row items-start justify-between">
                    <div className="text-[#000] font-medium mb-2 text-[16px]">
                      Upload Board Certification
                    </div>
                  </div>
                  <UploadContainer className="govt_id_file_cont">
                    <input
                      id="board_id_file_upload"
                      className="invisible_file_upload"
                      name="boardCertKey"
                      onChange={e =>
                        handleFileUpload(e, setBoardCertId, setBoardKey)
                      }
                      type="file"
                      accept=".pdf, image/*"
                    />
                    <div
                      className="file_upload_cont"
                      onClick={() =>
                        document.getElementById("board_id_file_upload")?.click()
                      }
                    >
                      <div className="trigger-text-cont">
                        <span className="text-[#103C1B] text-[16px]">
                          Click to upload file (max-file-size: 2MB.)
                        </span>
                      </div>
                    </div>
                    {boardCertId?.file && (
                      <div className="id-name-cont space-x-2">
                        <span>{boardCertId?.file?.name}</span>
                        <span
                          className="remove-edoc"
                          onClick={() =>
                            handleDeleteFile(setBoardCertId, setBoardKey)
                          }
                        >
                          Delete
                        </span>
                      </div>
                    )}
                  </UploadContainer>
                </div>
                <div className="flex-1">
                  <div className="flex flex-row items-start justify-between">
                    <div className="text-[#000] font-medium mb-2 text-[16px]">
                      Upload Malpractice Insurance Certificate
                    </div>
                  </div>
                  <UploadContainer className="govt_id_file_cont">
                    <input
                      id="map_cert_id_file_upload"
                      className="invisible_file_upload"
                      name="malpracticeCertKey"
                      onChange={e =>
                        handleFileUpload(e, setMalpracticeCertId, setMapCertKey)
                      }
                      type="file"
                      accept=".pdf, image/*"
                    />
                    <div
                      className="file_upload_cont"
                      onClick={() =>
                        document
                          .getElementById("map_cert_id_file_upload")
                          ?.click()
                      }
                    >
                      <div className="trigger-text-cont">
                        <span className="text-[#103C1B] text-[16px]">
                          Click to upload file (max-file-size: 2MB.)
                        </span>
                      </div>
                    </div>
                    {malpracticeCertId?.file && (
                      <div className="id-name-cont space-x-2">
                        <span>{malpracticeCertId?.file?.name}</span>
                        <span
                          className="remove-edoc"
                          onClick={() =>
                            handleDeleteFile(
                              setMalpracticeCertId,
                              setMapCertKey
                            )
                          }
                        >
                          Delete
                        </span>
                      </div>
                    )}
                  </UploadContainer>
                </div>
              </div>
              <div className="mt-4 flex flex-col sm:flex-row gap-3">
                <div className="flex-1">
                  <div className="flex flex-row items-start justify-between">
                    <div className="text-[#000] font-medium mb-2 text-[16px]">
                      Upload Latest Educational Diploma
                    </div>
                  </div>
                  <UploadContainer className="govt_id_file_cont">
                    <input
                      id="npi_id_file_upload"
                      className="invisible_file_upload"
                      name="diplomaCertKey"
                      onChange={e =>
                        handleFileUpload(e, setDiplomaCertId, setNpiKey)
                      }
                      type="file"
                      accept=".pdf, image/*"
                    />
                    <div
                      className="file_upload_cont"
                      onClick={() =>
                        document.getElementById("npi_id_file_upload")?.click()
                      }
                    >
                      <div className="trigger-text-cont">
                        <span className="text-[#103C1B] text-[16px]">
                          Click to upload file (max-file-size: 2MB.)
                        </span>
                      </div>
                    </div>
                    {diplomaCertId?.file && (
                      <div className="id-name-cont space-x-2">
                        <span>{diplomaCertId?.file?.name}</span>
                        <span
                          className="remove-edoc"
                          onClick={() =>
                            handleDeleteFile(setDiplomaCertId, setNpiKey)
                          }
                        >
                          Delete
                        </span>
                      </div>
                    )}
                  </UploadContainer>
                </div>
                <div className="flex-1">
                  <div className="flex flex-row items-start justify-between">
                    <div className="text-[#000] font-medium mb-2 text-[16px]">
                      Upload DEA Certificate (if applicable)
                    </div>
                  </div>
                  <UploadContainer className="govt_id_file_cont">
                    <input
                      id="dea_id_file_upload"
                      className="invisible_file_upload"
                      name="deaCertKey"
                      onChange={e =>
                        handleFileUpload(e, setDeaCertId, setDeaKey)
                      }
                      type="file"
                      accept=".pdf, image/*"
                    />
                    <div
                      className="file_upload_cont"
                      onClick={() =>
                        document.getElementById("dea_id_file_upload")?.click()
                      }
                    >
                      <div className="trigger-text-cont">
                        <span className="text-[#103C1B] text-[16px]">
                          Click to upload file (max-file-size: 2MB.)
                        </span>
                      </div>
                    </div>
                    {deaCertId?.file && (
                      <div className="id-name-cont space-x-2">
                        <span>{deaCertId?.file?.name}</span>
                        <span
                          className="remove-edoc"
                          onClick={() =>
                            handleDeleteFile(setDeaCertId, setDeaKey)
                          }
                        >
                          Delete
                        </span>
                      </div>
                    )}
                  </UploadContainer>
                </div>
              </div>
              <div className="flex flex-row items-center gap-1 my-4 font-bold">
                <CheckWithLabelToggle
                  label="I certify that the information provided is accurate and complete"
                  isSelected={isCertified}
                  toggleSelection={() => {
                    setIsCertified(prev => !prev);
                  }}
                />
              </div>
              <div className="flex flex-row items-center gap-1 my-5">
                <div className="text-primary-main text-[24px] font-[500] my-4">
                  Additional Comments
                </div>
              </div>
              <div className="mt-4 w-full flex items-center justify-between border border-[#949589] text-[#103C1B] rounded py-3 px-4 leading-tight focus:outline-none focus:bg-none focus:border-gray-500 hover:border-black">
                <textarea
                  className="w-full appearance-none placeholder:text-[#6E8877] text-black bg-transparent border border-none focus:outline-none"
                  rows={5}
                  name="comments"
                  placeholder="Enter Additional Comments (if any)"
                  value={values?.comments ? values.comments : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></textarea>
              </div>
              <div className="mt-5 w-full flex flex-row items-center justify-center">
                <Button
                  disabled={isSubmitting || isPending || !isCertified}
                  onClick={() => onSubmit(values)}
                  label="Submit Application"
                  type="submit"
                  variant="primary"
                  size="large"
                />
              </div>
            </div>
          </form>
        </div>
      )}
      <FeedbackModal
        closeModal={handleCloseModal}
        isForSuccess={isSuccess}
        isVisible={showModal}
        message={
          isSuccess
            ? "Congratulations! Your Insurance Credentialing form has been successfully submitted. We appreciate your attention to detail."
            : "An error occurred, please try again"
        }
        title={
          isSuccess
            ? "Insurance Credentialing Form Submitted!"
            : "Could not submit your request"
        }
      />
    </>
  );
};

const UploadContainer = styled.div` 
  marginTop: ".9rem",
  "& .invisible_file_upload": {
    opacity: 0,
    position: "absolute",
    left: "-100%"
  },
  "& .file_upload_cont": {
    border: " 1px dashed #34623F",
    borderColor: "#103C1B",
    cursor: "pointer",
    background: "rgba(52, 98, 63, 0.07)",
    borderRadius: "4px",
    padding: "2rem",
    maxWidth: "300px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    marginTop: ".4rem",

    "& .trigger-text-cont": {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center"
    }
  },
  "& .id-name-cont": {
    marginTop: ".4rem",
    fontSize: ".76rem",
    position: "relative",
    width: "70%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",

    "& .remove-edoc": {
      cursor: "pointer",
      color: "#F83535"
    }
  }
  `;


export default Credentialing;
