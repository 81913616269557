export const handleRemoveEmptyArrayValue = (array: any[]) => {
  return array.filter(item => item);
};

export const formatTitle: any = (item: string) => {
  switch (item) {
    case "Dr":
      return "Medical Doctor";
    default:
      return "";
  }
};

export const handleGetSideTitle = (currentPage: number) => {
  switch (currentPage) {
    case 0:
      return {
        header: "What are your insurance details?",
        subBody: "Some providers only accept patients with insurance."
      };
    case 1:
      return {
        header: "Select your doctor and appointment time?",
        subBody: ""
      };
    case 2:
      return {
        header: "Confirm appointment time.",
        subBody: ""
      };
    case 3:
      return {
        header: "What is the reason for your visit?",
        subBody: ""
      };
    case 4:
      return {
        header: "Fill Consent Form",
        subBody:
          "Please complete the form to grant your provider consent to access your details."
      };
    case 5:
      return {
        header: "Fill Intake Form.",
        subBody:
          "Complete this form to provide essential information before your healthcare appointment."
      };
    case 6:
      return {
        header: "Tell us more about your health record.",
        subBody:
          "This information will help your provider prepare in advance, allowing you to spend less time in each session."
      };
    case 7:
      return {
        header:
          "Choose the pharmacy your provider should send your prescription to.",
        subBody: ""
      };
    case 8:
      return {
        header: "Confirm your appointment details.",
        subBody: ""
      };
    case 9:
      return {
        header: "",
        subBody: ""
      };
    default:
      return {
        header: "What are your insurance details?",
        subBody: "Some providers only accept patients with insurance."
      };
  }
};
