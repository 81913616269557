import { FC } from "react";
import { useTemplate } from '../../../api/hooks/provider/useTemplate';
import Button from "../../../components/shared/Button";
import { Loading } from "../../../components/shared/Loading";
import { ArrowBackIcon } from "../../../svgs/ArrowBackIcon";
import { Template } from "../../../types/template";
import { Form } from "../../components/templates";

const PreviewPage: FC<{ closePreview: () => void; template: Template }> = ({
  closePreview,
  template
}) => {
  const { enableTemplate, isPending } = useTemplate()
  return (
    <div className="pb-5">
      <div className="w-full h-full pl-4 pr-8 mt-8">
        <div className="w-full flex justify-between flex-col sm:flex-row">
          <div
            onClick={() => closePreview()}
            className="cursor-pointer flex justify-start items-center"
          >
            <ArrowBackIcon /> <div className="ml-3">Go back to Templates</div>
          </div>
          <div className="flex flex-row gap-4 self-end mt-3 sm:mt-0">
            <Button
              variant="primary"
              additionalClassname="w-[180px] min-w-max px-4 h-[50px]"
              label="Use Template"
              onClick={() => {
                enableTemplate({
                  data: { noteFields: template.templateValues },
                  noteType: template.noteType
                })
              }}
            /> 
          </div>
        </div>
        <div className="h-5" />
      </div>
      <div className="px-4">
        {
          isPending ? <div className="flex justify-center items-center h-full"><Loading /></div> : (
            <Form
              form={template.templateValues}
              formToView={template.noteType}
              fullScreen={true}
            />)
        }
      </div>
    </div>
  );
};

export default PreviewPage;
