import { ArrowForwardIos } from "@mui/icons-material";
import { format } from "date-fns";
import { ReactComponent as AnsweredIcon } from "../../svgs/correct-green.svg";

interface MobileAppointmentCardProps {
  onClick?: (arg: any) => any;
  imageUrl?: string;
  cardData?: any;
}

const MobileAppointmentCard = ({
  cardData,
  onClick
}: MobileAppointmentCardProps) => {
  return (
    <div
      className="block lg:hidden cursor-pointer w-full min-h-[210px] p-4 rounded-[8px] my-4"
      style={{ boxShadow: "-2px 4px 25px #EEEEE9" }}
    >
      <div className="w-full flex justify-between items-center">
        <div className="w-full flex items-center justify-start">
          <div className="mr-4">
            <img
              src={cardData?.avatarUrl ?? "/assets/images/avatar.png"}
              className="w-[40px] h-[40px] rounded-full"
              alt=""
            />
          </div>
          <div className="flex flex-col">
            <div className="">{`${cardData?.firstName} ${cardData?.lastName}`}</div>
            <div className="">{cardData?.title}</div>
          </div>
        </div>
        <div className="flex flex-col items-end">
          <ArrowForwardIos onClick={onClick} className="arrow-icon-m" />
        </div>
      </div>
      <div className="w-full flex justify-between items-center mt-4">
        <div className="py-2">
          <p className="text-[#87928A] text-[.74rem]">Date</p>
          <p className="text-[#324705] text-[.75rem] font-bold">
            {format(new Date(cardData?.appointmentDate), "EEEE, dd LLL")}
          </p>
        </div>
        <div className="">
          <p className="text-[#87928A] text-[.74rem]">Time</p>
          <p className="text-[#324705] text-[.75rem] font-bold">{`${cardData?.appointmentStartTime} - ${cardData?.appointmentEndTime}`}</p>
        </div>
      </div>
      <div className="mt-4">
        <p className="text-[#87928A] text-[.74rem]">Intake Form Status</p>
        <div className="flex items-center justify-start text-[#324705] text-[.77rem] font-[400]">
          {cardData?.intakeFormAnswers?.length ? (
            <>
              <AnsweredIcon className="mr-2 " />
              Answered
            </>
          ) : (
            <>Not Answered</>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileAppointmentCard;
