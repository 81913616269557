import * as Yup from "yup";

export const chargeSheetschema = Yup.object().shape({
  patientName: Yup.string().required("Provider name is required"),
  providerName: Yup.string().required("provider name is required"),
  dateOfBirth: Yup.string(),
  dateOfEncounter: Yup.string().required("date of appointment is required"),
  gender: Yup.string().default(undefined),
  copay: Yup.string(),
  locationOfEncounter: Yup.string(),
  modeOfEncounter: Yup.string(),
  patientId: Yup.string(),
  appointmentId: Yup.string(),
  status: Yup.string().default(null)
});
