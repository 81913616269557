import { useQuery } from '@tanstack/react-query';
import { Complaints } from '../../../admin/types/admin_complaints';
import adminHttp from '../../../utils/adminHttp';


export function useGetAdminComplaints() {
  const { data, isLoading, ...rest } = useQuery({
    queryKey: ["get all admin complaints list"],
    queryFn: async () => {
      const response = await adminHttp.get("complaint/list");
      return response.data;
    },
    staleTime: Infinity,
  });
  return {
    complaintList: data as Complaints,
    isLoading,
    ...rest,
  }
}