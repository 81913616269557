import clsx from "clsx";
import React, { useState } from "react";
interface RadioFieldProps {
  type?: string;
  display: "block" | "inline";
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<any, Element>) => void;
  placeholder: string;
  size?: string;
  disabled?: boolean;
  error?: string;
  height?: number;
  width?: number;
  label?: string;
  inputType?: number;
  isRequired?: boolean;
  inputValid?: boolean;
  hasOther?: boolean;
  value: any;
  name: string;
  options: Array<RadioOption>;
}

interface RadioOption {
  item: string;
  value: any;
}

const RadioField: React.FC<RadioFieldProps> = ({
  type = "text",
  onChange = () => {},
  onBlur = () => {},
  value,
  label,
  placeholder,
  size,
  name,
  disabled = false,
  error,
  height,
  width,
  isRequired = false,
  inputValid,
  options,
  hasOther,
  display
}) => {
  const computedRootStyle = () => {
    const style: { [key: string]: number | string } = {};

    if (width) {
      style.width = `${width}px`;
    }

    return style;
  };

  const [selectedValue, setSelectedValue] = useState(value);
  const [otherValue, setOtherValue] = useState(value);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled) {
      setSelectedValue(e.target.value);
      onChange(e);
    }
  };
  const handleSetOtherValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled) {
      setOtherValue(e.target.value);
      onChange(e);
    }
  };

  return (
    <div
      className={clsx({
        "w-full flex flex-col": true,
        "lg:w-1/2": size === "half"
      })}
      style={computedRootStyle()}
    >
      <div>
        <div className="w-[100%] mb-2">
          {label && (
            <>
              <label
                className="text-[#000] font-medium mb-2 text-[16px]"
                htmlFor={`grid-${name}`}
              >
                {label}
              </label>
              {isRequired ? (
                <span className="text-red-600"></span>
              ) : (
                <span className="ml-2 text-[13px] text-[#6E8877]">
                  (optional)
                </span>
              )}
            </>
          )}
        </div>
        <div
          className={
            display === "inline"
              ? "flex flex-row mr-2 ml-2 items-center"
              : "flex flex-col mr-2 ml-2 items-start flex-start"
          }
        >
          {options?.map((option, index) => (
            <span key={index} className="mr-4 flex flex-row items-center mb-2">
              <input
                className="mr-2 w-[18px] h-[18px] border border-slate-800"
                name={name}
                type="radio"
                onChange={e => {
                  handleInputChange(e);
                }}
                disabled={disabled}
                checked={value === option.value}
                value={option.value}
              />
              <label
                className="text-[#103C1B] font-medium text-[16px]"
                htmlFor={`${option.value}`}
              >
                {option.item}
              </label>
            </span>
          ))}
          {hasOther ? (
            <span className="flex flex-row items-center mb-2">
              <input
                className="mr-2 w-[18px] h-[18px]"
                name={name}
                type="radio"
                disabled={disabled}
                onChange={e => {
                  handleInputChange(e);
                }}
                value="other"
              />
              <label
                className="text-[#103C1B] font-medium text-[16px]"
                htmlFor="other"
              >
                Other
              </label>
            </span>
          ) : null}
        </div>
        {selectedValue === "other" ? (
          <div className="flex items-center justify-between border border-[gray]-300 text-[#103C1B] rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white mt-2 mb-2 focus:border-gray-500">
            <input
              className="w-full appearance-none text-[#6E8877] border border-none focus:outline-none text-s"
              placeholder={placeholder}
              type={type}
              name={name}
              disabled={disabled}
              value={otherValue}
              onChange={e => handleSetOtherValue(e)}
            />
          </div>
        ) : (
          <></>
        )}
        {error && (
          <p className="mt-2 text-xs text-red-600 capitalize">{error}</p>
        )}
      </div>
    </div>
  );
};

export default RadioField;
