export const ArrowLeftIcon2 = ({
  width = 14,
  height = 23,
  color = "#2E3011",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 14 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0509 0.616117C12.5627 0.127961 11.7713 0.127961 11.2831 0.616117L1.28311 10.6161C0.794953 11.1043 0.794953 11.8957 1.28311 12.3839L11.2831 22.3839C11.7713 22.872 12.5627 22.872 13.0509 22.3839C13.539 21.8957 13.539 21.1043 13.0509 20.6161L3.93476 11.5L13.0509 2.38388C13.539 1.89573 13.539 1.10427 13.0509 0.616117Z"
        fill={color}
      />
    </svg>
  );
};
