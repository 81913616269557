import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReportProblemModal from "../components/ReportProblemModal";
import Spacer from "../components/Spacer";
import MenuItem from "../components/dashboard/MenuItem";
import { InfoIcon2 } from "../svgs/InfoIcon2";
import { TempleHSLogo } from "../svgs/TempleHSLogo";
import { bottomMenus, menuItems } from "../types/constants";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isReportModalVisible, setIsReportModalVisible] = useState(false);

  // const handleMenuClick = (path: string) => {
  //   navigate(`${path}`);
  // };

  // text-sm font-semibold border border-t-0 border-[#d1d2d4]-500 shadow-md shadow-[rgba(51, 51, 51, 0.05)] bg-[#000] w-[230px] min-h-[100vh] lg:fixed lg:left-0 lg:top-0 content-center lg:content-start text-left justify-between ml-0 pl-0 relative
  return (
    <div className="hidden lg:block relative w-full bg-[#000] min-h-[100vh] text-sm font-semibold border border-t-0 border-[#d1d2d4]-500 min-w-[1024px]:left-0 lg:top-0 content-center lg:content-start text-left justify-between ml-0 pl-0">
      <div className="font-[600] text-[#ABE9B9] text-[28px] flex justify-start pl-8  items-center pt-[50px] pb-0 px-[20px] w-full">
        <TempleHSLogo color="#ABE9B9" />
      </div>
      <Spacer height={50} />
      <div className="h-[100vh] flex flex-col justify-between">
        <div>
          {menuItems.map(({ path, icon, activeIcon, label }) => (
            <MenuItem
              key={label}
              isActive={location.pathname.startsWith(path)}
              icon={
                location.pathname === path ? activeIcon : icon
              }
              label={label}
              onClick={() => navigate(`${path}`)}
            />
          ))}
        </div>

        <div className="w-full mt-[1.375rem]">
          <div
            onClick={() => {
              setIsReportModalVisible(true);
            }}
            className="w-full cursor-pointer text-[#34623F] bg-white border-y border-[#8BDF9F] flex justify-center items-center h-[50px]"
          >
            <InfoIcon2 />
            <p className="ml-2">Support Center</p>
          </div>
        </div>
        <div className="bottom-0">
          {bottomMenus.map(({ label, icon, path, activeIcon }) => (
            <MenuItem
              key={label}
              isActive={location.pathname === path}
              icon={
                location.pathname === path ? activeIcon : icon
              }
              label={label}
              onClick={() => {
                if (label === "Log out") {
                  localStorage.removeItem("temple_user_token");
                  window.location.href = `${process.env.REACT_APP_LANDING_PAGE_URL}`;
                } else {
                  navigate(`${path}`)
                }
              }}
            />
          ))}
          <Spacer height={150} />
        </div>
      </div>
      <ReportProblemModal
        isVisible={isReportModalVisible}
        closeModal={() => setIsReportModalVisible(false)}
        actionTrigger={() => {
          setIsReportModalVisible(false);
        }}
      />
    </div>
  );
};

export default Sidebar;
