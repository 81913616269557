import React, { useState } from "react";
import { CancelIcon } from "../../svgs/CancelIcon";
import { ReactComponent as InfoIcon } from "../../svgs/info-icon.svg";

interface InfoToolTipProps {
  width?: number;
  height?: number;
  message?: string;
  fillColor?: string;
}

const InfoToolTip: React.FC<InfoToolTipProps> = ({
  width = 151,
  height,
  message,
  fillColor
}) => {
  const [isInfoMessageVisible, setIsInfoMessageVisible] = useState(false);

  return (
    <div className="relative flex flex-col justify-start">
      {isInfoMessageVisible && (
        <div
          className="absolute right-0 bottom-2 h-auto flex justify-center items-center bg-[#BEDDF8] text-[#434343] m-auto rounded-[10px] p-[10px] text-[10px] font-[400] cursor-pointer z-9999"
          style={{
            width: `${width}px`,
            height: `${height}px`
          }}
        >
          <div className="">
            <div
              onClick={() => setIsInfoMessageVisible(false)}
              className="flex justify-end w-full"
            >
              <CancelIcon />
            </div>
            <div className="">{message}</div>
          </div>
        </div>
      )}
      <div
        className="cursor-pointer"
        onClick={() => {
          if (!isInfoMessageVisible) {
            setIsInfoMessageVisible(true);
          } else {
            setIsInfoMessageVisible(false);
          }
        }}
      >
        <InfoIcon fill={`${fillColor ? fillColor : "#010C15"}`} />
      </div>
    </div>
  );
};

export default InfoToolTip;
