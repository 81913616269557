import { useQuery } from '@tanstack/react-query';
import { MalpracticeInsuranceRequest, MalpracticeInsuranceResponse } from '../../../admin/types/mal_practice_insurance';
import adminHttp from '../../../utils/adminHttp';
import { ErrorResponse } from '../../types';

export function useGeMalPracticeInsurance() {
  const { data, isLoading, ...rest } = useQuery<MalpracticeInsuranceResponse, ErrorResponse>({
    queryKey: ["admin mal-practice insurance"],
    queryFn: async () => {
      const response = await adminHttp.get(`/malpractice-insurance/all`);
      return response.data;
    },
    staleTime: Infinity,
  });
  return {
    malPracticeInsuranceData: data?.malpracticeInsuranceRequests as MalpracticeInsuranceRequest[],
    isLoading,
    ...rest,
  }
}