import { useQuery } from '@tanstack/react-query';
import adminHttp from '../../../utils/adminHttp';

type Props = {
  userId: string
}

export function useGetSingleAdminData({ userId }: Props) {
  const { data, isLoading, ...rest } = useQuery({
    queryKey: ["get single admin data"],
    queryFn: async () => {
      const response = await adminHttp.get(`admins/provider/${userId}`);
      return response.data;
    },
    staleTime: Infinity,
  });
  return {
    singleAdminDetails: data,
    isLoading,
    ...rest,
  }
}