import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import http from "../../../utils/http";


type Payload = {
  currentPassword: string;
  newPassword: string;
}

export const useUpdateBillerPassword = () => {
  const queryClient = useQueryClient();
  const { mutate, data, variables, ...rest } = useMutation({
    mutationKey: ['update biller password'],
    mutationFn: async ({ ...data }: Payload) => {
      const response = await http.patch("billers/password", data)
      return response.data;
    },

    onSuccess: () => {
      toast.success(
        'Password Reset Successfully ',
        { toastId: "customId" }
      );
      queryClient.invalidateQueries({
        queryKey: ["update biller password"] // TODO: add the main invalidator
      })
    },

    onError: (error) => {
      if (axios.isAxiosError(error)) {
        toast.error(
          `Error ${error?.response?.data?.error || error.response?.statusText
          }`,
          { toastId: "customId" }
        );

      } else {
        toast.error(
          error?.message
          || "Error processing your request",
        );
      }
    },
  });

  return {
    updateBillerPassword: mutate,
    data,
    variables,
    ...rest,
  };
}