import Button from "../components/shared/Button";

interface Props {
	error: Error;
	resetErrorBoundary: () => void;
}

export default function FallBackError({ error, resetErrorBoundary }: Props) {

	return (
		<div
			role='alert'
			className='text-center mx-auto h-screen justify-center flex items-center flex-col'
		>
			<p>Something went wrong while processing, but don’t fret — let’s give it another shot.</p>
			<pre>
				Here's why: {process.env.MODE === 'development' ? error.stack : error.message}
			</pre>
			<Button
				variant="secondary"
				onClick={resetErrorBoundary}
				type='button'
				aria-label='Reset Error Boundary'
				size="small"
				label="Try again"
			/>
		</div>
	);
}
