import { FormikContextType } from "formik";
import React, { useState } from "react";
import { Socket } from "socket.io-client";
import FeedbackModal from "../../provider/components/FeedbackModal";
import { ReactComponent as EmojiIcon } from "../../svgs/emoji-icon.svg";
import { ReactComponent as SendChatIcon } from "../../svgs/send-chat.svg";
import { UserType } from "../../types/common";
import { generateUUID } from "../../utils/helpers";
import Spacer from "../Spacer";
import Button from "../shared/Button";

interface MessageInputFieldProps {
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<any, Element>) => void;
  value: string;
  placeholder: string;
  name: string;
  size?: string;
  disabled?: boolean;
  width?: number;
  onSend: () => void;
  onAttach: () => void;
  currentUserId: number;
  receiverId: number;
  socket: Socket;
  formik: FormikContextType<{ message: string }>;
  userType: UserType;
}

const MessageInputField: React.FC<MessageInputFieldProps> = ({
  onChange = () => {},
  onBlur = () => {},
  value,
  placeholder,
  size,
  name,
  disabled = false,
  width,
  onSend,
  onAttach,
  currentUserId,
  receiverId,
  socket,
  formik,
  userType
}) => {
  const [error, setError] = useState(false);

  const handleSubmit = async (e: any) => {
    if (!formik.isSubmitting) {
      e.preventDefault();

      if (value.length) {
        const to =
          userType === "patient"
            ? `${currentUserId}_${receiverId}`
            : `${receiverId}_${currentUserId}`;

        socket.emit("SEND_MESSAGE", {
          message: value,
          senderId: currentUserId,
          receiverId,
          userType,
          messageId: generateUUID(),
          isAudio: false,
          isImage: false,
          isVideo: false,
          to
        });
        onSend();
        formik.resetForm();
      }
    }
  };

  const computedRootStyle = () => {
    const style: { [key: string]: number | string } = {};

    if (width) {
      style.width = `${width}px`;
    }

    return style;
  };
  const handleOnChange = (e: any) => {
    onChange(e);
  };

  return (
    <div className="w-full h-[60px] flex flex-col" style={computedRootStyle()}>
      <div className="w-full lg:max-w-full lg:w-[calc(80%-348px)] flex items-center justify-between border-t border-gray-200 py-4 mx-0 leading-tight focus:outline-none focus:bg-white bg-white focus:border-gray-500 fixed bottom-0 z-9999">
        <input
          className="appearance-none text-[#103C1B] text-[16px] border border-none focus:outline-none lg:text-xs w-full px-2"
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleOnChange}
          onBlur={onBlur}
          disabled={disabled}
          onKeyDown={event => {
            if (event.key === "Enter" && value.length) {
              handleSubmit(event);
            }
          }}
        />
        <div className="w-full flex items-center justify-end mr-4 lg:mr-0">
          {/* <button onClick={onAttach}>
            <AttachIcon />
          </button>
          <Spacer width={12} /> */}
          <EmojiIcon />
          <Spacer width={12} />

          <Button
            type="submit"
            variant="primary"
            label="Send"
            icon={<SendChatIcon />}
            iconPosition="right"
            size="small"
            onClick={e => {
              handleSubmit(e);
            }}
          />
        </div>
      </div>
      <FeedbackModal
        closeModal={() => setError(false)}
        isVisible={error}
        message="This patient does not have an account with TempleHS, but the message will be delivered once the patient registers an account"
        title="Patient Not Registered"
      />
    </div>
  );
};

export default MessageInputField;
