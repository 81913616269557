import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import billerHttp from "../../../utils/billerHttp";

type Payload = {
  hasSeenLoginModal?: boolean;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  pronoun?: string;
  aapcLicenseNumber?: string;
  aapcLicenseExpiry?: string;
  cpbLicenseNumber?: string;
  cpbLicenseExpiry?: string;
  state?: string;
  city?: string;
  avatarUrl?: string;
  address?: string;
  paymentMethod?: string
};


export const useUpdateBillerProfileDetails = () => {
  const queryClient = useQueryClient();

  const { mutate, variables, reset, ...rest } = useMutation({
    mutationKey: ['update biller details'],
    mutationFn: async ({ ...rest }: Payload) => {
      const response = await billerHttp.patch('billers/details', { ...rest }
      )
      return response.data;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get biller profile details']
      });

      toast.success("Profile details updated!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        toastId: "update-details-p"
      });
    },

    onError: (error) => {
      if (axios.isAxiosError(error)) {
        toast.error(
          error?.response?.data?.error || error.response?.statusText,
        );
      } else {
        toast.error(
          error?.message
          || "Error tryng to login",
        );
      }
    },
  });

  return {
    updateBillerProfile: mutate,
    variables,
    ...rest,
  };
}
