import { useFormik } from "formik";
import { useState } from "react";
// import PasswordField from "../form/PasswordField";
import { useAdminLogin } from "../../api/hooks/admin/auth/adminLogin";
import PasswordField from "../../components/form/PasswordField";
import TextField from "../../components/form/TextField";
import BtnButton from "../../components/shared/BtnButton";
import Spacer from "../../components/Spacer";
import { adminValidationSchema, initialAdminValues } from "../schema/consts";

const AdminLoginForm = () => {
  // localStorage.clear();
  const [loginError, setLoginError] = useState("");
  const { adminLogin, isPending } = useAdminLogin({ setLoginError })

  const { values, handleChange, touched, errors, submitForm } = useFormik({
    initialValues: initialAdminValues,
    validationSchema: adminValidationSchema,
    onSubmit: async () => {
      adminLogin({
        email: values.email,
        password: values.password
      });

    }
  });

  return (
    <div className="w-full box-border lg:w-[614px] h-[497px] bg-white">
      <div className="text-center">
        <Spacer height={30} />
        <p className="text-[34px] font-[800] text-[#103C1B]">Log In</p>
      </div>
      <Spacer height={40} />
      <div className="flex flex-col mx-auto items-center w-full relative">
        {loginError?.length > 0 && (
          <p className="text-[18px] font-[700] text-red-500 font-[gentiumBasic] mb-4">
            Login failed: {loginError}
          </p>
        )}
        <form className="relative flex flex-col items-center w-full lg:w-[478px]">
          <TextField
            name="email"
            type="email"
            placeholder="name@mail.com"
            value={values.email}
            onChange={handleChange}
            label="Email address"
            error={touched.email ? errors.email : ""}
          />

          <Spacer height={25} />

          <PasswordField
            name="password"
            placeholder="At least 8 characters"
            value={values.password}
            onChange={handleChange}
            label="Password"
            showPasswordToggler={true}
            error={touched.password ? errors.password : ""}
          />
          <div className="w-full mt-6">
            <BtnButton
              onClick={submitForm}
              additionalClassname={
                "text-[18px] font-[400] w-full relative w-[478px] h-[50px] rounded-none"
              }
              width={478}
              height={60}
              variant="primary"
              label={isPending ? "Signing In..." : "Sign in"}
              disabled={isPending}
              size="extra-large"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminLoginForm;
