import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import http from "../../../utils/http";
import { status } from "../../types";

type ModeOfEncounter = "virtual" | "physical"

type Payload = {
  procedureCodes?: string | string[]
  diagnosisCodes?: string | string[];
  status: status;
  modeOfEncounter?: ModeOfEncounter;
  locationOfEncounter?: string;
  dateOfEncounter?: string;
  chargeId: string;
}

export const useUpdateChargeSheet = () => {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation({
    mutationKey: ['update charge-sheet'],
    mutationFn: async ({ chargeId, ...data }: Payload) => {
      const response = await http.patch(`charge-sheet/${chargeId}`, data)
      return response.data?.newCharge;
    },

    onSuccess: () => {
      toast.success("Sent to billing");
      queryClient.invalidateQueries({ queryKey: ['payment and billing table'] });
    },

    onError: (error) => {
      if (axios.isAxiosError(error)) {
        toast.error(
          error?.response?.data?.error || error.response?.statusText,
        );
      } else {
        toast.error(
          error?.message
          || "Error saving charge sheet",
        );
      }
    }
  });

  return {
    updateChargeSheet: mutate,
    ...rest,
  };
}
