import AuthLayout from "../../components/auth/AuthLayout";
import VerifyEmailForm from "../components/SPVerifyEmail";

const SPVerifyEmail = () => {
  return (
    <AuthLayout>
      <VerifyEmailForm />
    </AuthLayout>
  );
};

export default SPVerifyEmail;
