import { FC } from 'react'
import { styled } from '@mui/material';
import Button from '../../../../components/shared/Button';
import { ReactComponent as ExpandIcon } from "../../../../svgs/expand-icon.svg";
import { FormTypes } from '../../../pages/Settings/Templates';
import { FORMDETAILS } from '../../../pages/Templates/data';

interface IFormContainerProps {
  Form: FC;
  formType: FormTypes;
  onPreview: () => void;
}
const FormContainer: FC<IFormContainerProps> = ({
  Form,
  formType,
  onPreview
}) => {
  const getFormDetails = formType && FORMDETAILS[formType] ? FORMDETAILS[formType] : { header: "", description: "" };

  return (
    <Container>
      <FormWrapper>
        <Form />
      </FormWrapper>
      <FormDetailsWrapper>
        <h3>{getFormDetails.header}</h3>
        <p>{getFormDetails.description}</p>
        <Button onClick={onPreview} icon={<ExpandIcon />} variant='primary' label={"Preview"} backgroundColor='transparent' textColor='#1A9D39' additionalClassname={"!items-start !justify-start"} />
      </FormDetailsWrapper>
    </Container>
  )
}

const Container = styled("figure")(() => ({
  width: "100%",
  height: "100%",
  position: "relative",
  display: "flex",
  flexDirection: "column",
}));
const FormWrapper = styled("div")(() => ({
  height: "230px",
  width: "100%",
  borderBottom: "2px solid #3D874E",
  overflow: "hidden",
  "> div": {
    pointerEvents: "none",
    margin: "0 20px",
    "> form": {
      "> section > div": {
        fontSize: "16px",
      },
      "> div ": {
        flexDirection: "row",
        justifyContent: "space-between",
        // flexWrap: "wrap",
        "> span > label": {
          fontSize: "14px",
          textTransform: "capitalize",
          marginRight: "20px",
        },
        "> div>textarea": {
          marginLeft: "0",
          maxHeight: "40px",
          width: "150px"
        },
        "> div > div": {
          marginLeft: "0",
          height: "40px",
          width: "150px",
        },
        ">label": {
          fontSize: "14px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          textTransform: "capitalize",
          "input": {
            marginLeft: "0",
            maxHeight: "40px",
            width: "150px"
          },
        }
      },
      ".dropdown": {
        fontSize: "14px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",

      }
    }
  }
}));
const FormDetailsWrapper = styled("figcaption")(() => ({
  width: "100%",
  height: "250px",
  padding: "23px",
  color: "#324705",
  "> h3": {
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: "28.8px"
  },
  "> p": {
    fontSize: "14px",
    lineHeight: 1.4,
    marginTop: "22px",
    marginBottom: "22px"
  },
}));

export default FormContainer
