import { useQuery } from '@tanstack/react-query';
import http from '../../../utils/http';

export function useGetPatientProviderList() {
  const { data, isLoading, refetch, ...rest } = useQuery({
    queryKey: ["patient provider list"],
    queryFn: async () => {
      const response = await http.get("patients/providers");
      return response.data.patientsProviders;
    },
    staleTime: 1000 * 60 * 60, // data is stale every 1 hour
    refetchInterval: 1000 * 60 * 60, // refetch every 1 hour
  });
  return {
    getPatientProviderList: data,
    isLoading,
    refetchPatientProvider: refetch,
    ...rest,
  }
}