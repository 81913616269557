import { useState } from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as MenuToggler } from "../../svgs/menu-toggler.svg";
import { ReactComponent as NotificationIcon } from "../../svgs/notification-icon.svg";
import NotificationDrawer from "../NotificationDrawer";

type Props = {
  openRetractableMenu: () => void;
}

const notificationsList = [
  {
    providerName: "Appointment with Leo Stanton, MD",
    message: "You have an appointment for Tue, 24 Feb at 9:30AM ",
    time: "4:23PM"
  },
  {
    providerName: "Appointment with Marcelino Kindred, MD",
    message: "You have an appointment for Tue, 24 Feb at 9:30AM ",
    time: "24 Feb 2022"
  },
  {
    providerName: "Appointment with Pat Alexander, DO",
    message: "You have an appointment for Tue, 24 Jan at 9:30AM ",
    time: "24 Jan 2022"
  }
];

const MobileHeader = ({ openRetractableMenu }: Props) => {
  const [isNotificationDrawerVisible, setIsNotificationDrawerVisible] =
    useState(false);
  const location = useLocation();

  const getHeaderTitle = () => {
    if (location.pathname === "/dashboard") {
      return "Dashboard";
    }

    if (location.pathname.includes("messages")) {
      return "Messages";
    }

    if (
      location.pathname.includes("prescription") &&
      !location.pathname.includes("admin")
    ) {
      return "Prescription";
    }

    if (location.pathname.includes("my-account")) {
      return "My Account";
    }

    if (location.pathname.includes("appointments")) {
      return "Appointments";
    }
  };

  return (
    <div className="flex justify-between px-3 lg:hidden bg-white w-full h-[100px] items-center top-0 z-10 relative m-auto mt-0 shadow-md">
      <button
        type="button"
        className="cursor-pointer border-none outline-none"
        onClick={openRetractableMenu}
      >
        <MenuToggler />
      </button>
      <p className="font-semibold text-sm text-[#141921]">{getHeaderTitle()}</p>
      <div
        className="relative"
        onClick={() => setIsNotificationDrawerVisible(true)}
      >
        <NotificationIcon />
      </div>
      <NotificationDrawer
        open={isNotificationDrawerVisible}
        onClose={() => setIsNotificationDrawerVisible(false)}
        drawerTitle="Notifications"
        notificationsList={notificationsList}
      />
    </div>
  );
};

export default MobileHeader;
