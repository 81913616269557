import clsx, { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function mergeClassNames(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getInsurancePayers() {
  const savedPayers = localStorage.getItem("insurancePayers") || "";

  if (savedPayers !== null || savedPayers !== undefined) {
    return Array.isArray(savedPayers) ? JSON.parse(savedPayers) : [];
  }
  return [];
}
