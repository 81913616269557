

import { useQuery } from '@tanstack/react-query';
import http from '../../../utils/http';

export function useGetProviderJitsiToken(joinCall: boolean) {
  const { data, isLoading, refetch, ...rest } = useQuery({
    queryKey: ["provider jitsi token"],
    queryFn: async () => {
      const response = await http.get("/providers/jitsi/token");
      return response.data.token;
    },
    enabled: joinCall
  });
  return {
    getJitsiToken: data,
    isLoading,
    refetchGetJitsiToken: refetch,
    ...rest,
  }
}