import http from "../../../utils/http";

export const getS3SignedUrl = async ({
  operation,
  key,
  object
}: {
  operation: "getObject" | "putObject";
  key: string;
  object: "emancipation" | "media" | "account";
}) => {
  try {
    const response = await http.get(
      `/common/signedUrl?operation=${operation}&key=${key}&object=${object}`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    return error.response.data;
  }
};
