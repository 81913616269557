import clsx from "clsx";
import React from "react";
import { ReactComponent as SearchIcon } from "../../svgs/search-icon.svg";

interface SearchInputProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  value: string;
  placeholder: string;
  additionalClassname?: string;
  executeSearch?: () => void;
}

const SearchInput: React.FC<SearchInputProps> = ({
  onChange = () => {},
  onBlur = () => {},
  value,
  placeholder,
  additionalClassname
}) => {
  return (
    <div
      className={clsx({
        "flex items-center border border-gray-200 rounded-lg focus:outline-none focus:bg-[#F5FAF5] focus:border-gray-500 pl-[28px] pr-2 focus:ring-0":
          true,
        [`${additionalClassname}`]: additionalClassname
      })}
    >
      <SearchIcon className="flex-none" />
      <input
        className="bg-[#F5FAF5] focus:outline-none appearance-none pl-4 text-[#626465] font-medium border-none focus:ring-0 focus:border-none text-xs grow"
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};

export default SearchInput;
