import { useQuery } from '@tanstack/react-query';
import http from '../../../utils/http';


export function useProviderBookedPatients() {
  const { data, isLoading, refetch, ...rest } = useQuery({
    queryKey: ["providers booked patients"],
    queryFn: async () => {
      const response = await http.get(`providers/patients/booked`);
      return response.data;
    }, 
    staleTime: Infinity,
  });
  return {
    bookedPatients: data,
    isLoading,
    refetchPPatient: refetch,
    ...rest,
  }
}