export const AdminUsersIcon = ({
  width = 24,
  height = 25,
  color = "white",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 13.25C6.0335 13.25 5.25 14.0335 5.25 15C5.25 15.9665 6.0335 16.75 7 16.75C7.9665 16.75 8.75 15.9665 8.75 15C8.75 14.0335 7.9665 13.25 7 13.25ZM3.75 15C3.75 13.2051 5.20507 11.75 7 11.75C8.79493 11.75 10.25 13.2051 10.25 15C10.25 16.7949 8.79493 18.25 7 18.25C5.20507 18.25 3.75 16.7949 3.75 15Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 13.25C16.0335 13.25 15.25 14.0335 15.25 15C15.25 15.9665 16.0335 16.75 17 16.75C17.9665 16.75 18.75 15.9665 18.75 15C18.75 14.0335 17.9665 13.25 17 13.25ZM13.75 15C13.75 13.2051 15.2051 11.75 17 11.75C18.7949 11.75 20.25 13.2051 20.25 15C20.25 16.7949 18.7949 18.25 17 18.25C15.2051 18.25 13.75 16.7949 13.75 15Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.25C11.0335 3.25 10.25 4.0335 10.25 5C10.25 5.9665 11.0335 6.75 12 6.75C12.9665 6.75 13.75 5.9665 13.75 5C13.75 4.0335 12.9665 3.25 12 3.25ZM8.75 5C8.75 3.20507 10.2051 1.75 12 1.75C13.7949 1.75 15.25 3.20507 15.25 5C15.25 6.79493 13.7949 8.25 12 8.25C10.2051 8.25 8.75 6.79493 8.75 5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 22.5C1.25 19.3244 3.82436 16.75 7 16.75C10.1756 16.75 12.75 19.3244 12.75 22.5C12.75 22.9142 12.4142 23.25 12 23.25C11.5858 23.25 11.25 22.9142 11.25 22.5C11.25 20.1528 9.34719 18.25 7 18.25C4.65279 18.25 2.75 20.1528 2.75 22.5C2.75 22.9142 2.41421 23.25 2 23.25C1.58579 23.25 1.25 22.9142 1.25 22.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 22.5C11.25 19.3244 13.8244 16.75 17 16.75C20.1756 16.75 22.75 19.3244 22.75 22.5C22.75 22.9142 22.4142 23.25 22 23.25C21.5858 23.25 21.25 22.9142 21.25 22.5C21.25 20.1528 19.3472 18.25 17 18.25C14.6528 18.25 12.75 20.1528 12.75 22.5C12.75 22.9142 12.4142 23.25 12 23.25C11.5858 23.25 11.25 22.9142 11.25 22.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 12.5C6.25 9.32439 8.82439 6.75 12 6.75C15.1756 6.75 17.75 9.32439 17.75 12.5C17.75 12.9142 17.4142 13.25 17 13.25C16.5858 13.25 16.25 12.9142 16.25 12.5C16.25 10.1528 14.3472 8.25 12 8.25C9.65281 8.25 7.75 10.1528 7.75 12.5C7.75 12.9142 7.41421 13.25 7 13.25C6.58579 13.25 6.25 12.9142 6.25 12.5Z"
        fill={color}
      />
    </svg>
  );
};
