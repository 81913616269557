export const ArrowBackIcon = ({
  width = 22,
  height = 23,
	color="#103C1B",
  className
}: {
  height?: number | string;
  width?: number | string;
	color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 23"
      fill="none"
			className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6951 5.51386C14.9636 5.78235 14.9636 6.21765 14.6951 6.48614L9.68126 11.5L14.6951 16.5139C14.9636 16.7823 14.9636 17.2177 14.6951 17.4861C14.4266 17.7546 13.9913 17.7546 13.7228 17.4861L8.22285 11.9861C7.95436 11.7177 7.95436 11.2823 8.22285 11.0139L13.7228 5.51386C13.9913 5.24538 14.4266 5.24538 14.6951 5.51386Z"
        fill={color}
      />
    </svg>
  );
};
