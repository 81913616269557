import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FormTypes } from "../../../provider/pages/Settings/Templates";
import http from "../../../utils/http";


type Payload = {
  noteType: FormTypes;
  data: any;
}

export const useTemplate = () => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { mutate, variables, ...rest } = useMutation({
    mutationKey: ['use template'],
    mutationFn: async ({ data, noteType }: Payload) => {
      const response = await http.post(`notes?noteType=${noteType}`, data)
      return response.data;
    },

    onSuccess: ({ newForm }) => {
      toast.success("Template created successfully");
      queryClient.invalidateQueries({ queryKey: ['get provider form'] });
      navigate(
        `/provider/settings?activeTab=Template&activeNote=${newForm?.noteType}&referrer=templatesPreview`
      );
    },

    onError: (error) => {
      if (axios.isAxiosError(error)) {
        toast.error(
          error?.response?.data?.error || 'Error while creating template',
        );
      } else {
        toast.error(
          error?.message
          || "Error, something went wrong",
        );
      }
    }
  });

  return {
    enableTemplate: mutate,
    variables,
    ...rest,
  };
}
