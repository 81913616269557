import { useQuery } from '@tanstack/react-query';
import http from '../../../utils/http';


export function useGetProviderPreviousAppointments() {
  const { data, isLoading, refetch, isError, error, ...rest } = useQuery({
    queryKey: ["provider previous appointments"],
    queryFn: async () => {
      const response = await http.get("appointments/providers/previous");
      return response.data;
    },
    staleTime: 1000 * 60 * 30,   // 30 mins
    refetchInterval: 1000 * 60 * 60, // refetch every 1 hour

  });
  return {
    providerPrevAppointments: data,
    isLoadingPrevAptmnt: isLoading,
    refetchPrevProviderAppointments: refetch,
    isErrorPrevAptmnt: isError,
    errorPrevAptmnt: error,
    ...rest,
  }
}