import * as Yup from "yup";

export const emergencyContactSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().required("Email is required"),
  phoneNumber: Yup.string().required("Phone number is required"),
  relationship: Yup.string().required("Relationship is required"),
  gender: Yup.string().required("Gender is required"),
  dateOfBirth: Yup.date().required("Date of birth is required")
});

export const initialEmergencyContactValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  relationship: "",
  gender: "",
  dateOfBirth: new Date()
};
