import { format } from "date-fns";
import { Fragment, useMemo } from "react";
import { Column } from "react-table";
import { ClaimsType } from "../../../pages/SPAllClaims";

export function useSPAllClaimsTableRow() {
  const CLAIMS_REJECTED = useMemo<Column<ClaimsType>[]>(
    () => [
      {
        Header: "Claim ID",
        accessor: "claimId",
        Cell: ({ cell: { value } }) => {
          return (
            <Fragment>
              {value ? "#" + value.slice(0, 7) : ""}
            </Fragment>
          );
        }
      },
      {
        Header: "Reason for rejection",
        accessor: "reasonForRejection",
        Cell: ({ cell: { value } }) => {
          return <Fragment>{value}</Fragment>
        }
      },
      {
        Header: "Rejection date",
        accessor: "rejectionDate",
        Cell: ({ cell: { value }, row }) => {
          return (
            <Fragment>
              {value ? format(new Date(value), "MM/dd/yyyy") : "N/A"}
            </Fragment>
          );
        }
      },
      {
        Header: "Patient Name",
        accessor: "patientName",
        Cell: ({ cell: { value } }) => {
          return <Fragment>{value}</Fragment>;
        }
      },
      {
        Header: "Provider",
        accessor: "provider",
        Cell: ({ cell: { value } }) => {
          return <Fragment>{value}</Fragment>
        }
      },
      {
        Header: "Insurance",
        accessor: "insurance",
        Cell: ({ cell: { value }, row }) => {
          return <Fragment>{value}</Fragment>
        }
      },
      {
        Header: "Charge",
        accessor: "charge"
      }
    ],
    []
  );

  const CLAIMS_ACCEPTED = useMemo<Column<ClaimsType>[]>(
    () => [
      {
        Header: "Claim ID",
        accessor: "claimId",
        Cell: ({ cell: { value } }) => {
          return (
            <Fragment>
              {value ? "#" + value.slice(0, 7) : ""}
            </Fragment>
          );
        }
      },
      {
        Header: "Patient Name",
        accessor: "patientName",
        Cell: ({ cell: { value } }) => {
          return <Fragment>{value}</Fragment>;
        }
      },
      {
        Header: "Provider",
        accessor: "provider",
        Cell: ({ cell: { value } }) => {
          return <Fragment>{value}</Fragment>;
        }
      },
      {
        Header: "Insurance",
        accessor: "insurance",
        Cell: ({ cell: { value }, row }) => {
          return <Fragment>{value}</Fragment>
        }
      },
      {
        Header: "Charge",
        accessor: "charge"
      }
    ],
    []
  );

  return {
    CLAIMS_REJECTED,
    CLAIMS_ACCEPTED
  }
}