import { styled } from "@mui/material";
import { useEffect, useRef } from "react";
import { useBillerAuthState } from "../../api/hooks/biller/useBillerAuthState";
import { useGeBillerProfileDetails } from '../../api/hooks/biller/useGetBillerProfileDetails';
import SPHeader from "./SPHeader";
import SPSidebar from "./SPSidebar";

interface SPDashboardLayoutProps {
  children: React.ReactNode;
  headerTitle?: string;
}

const SPDashboardLayout: React.FC<SPDashboardLayoutProps> = ({
  children,
  headerTitle
}) => {
  const { isAuthenticated } = useBillerAuthState()

  const pageTop = useRef<null | HTMLDivElement>(null);

  const scrollToTop = () => {
    pageTop.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest"
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const { billerProfileData } = useGeBillerProfileDetails()
  const authenticated = isAuthenticated()

  return (
    <StyledProviderDashboardContainer>
      <div className="sidebar">
        <SPSidebar />
      </div>
      <div className="header">
        <SPHeader
          loggedIn={authenticated}
          currentUserInfo={billerProfileData}
          headerTitle={headerTitle}
        />
      </div>
      <div className="body pt-10" style={{ overflow: "auto" }}>
        {children}
      </div>
    </StyledProviderDashboardContainer>
  );
};
const StyledProviderDashboardContainer = styled("div")(() => ({
  width: "100%",
  height: "100%",
  position: "relative",
  display: "grid",
  gap: "5px",
  gridTemplateColumns: "20% 75%",
  gridTemplateRows: "10% 90%",
  gridTemplateAreas:
    "'sidebar header header header header' \n 'sidebar body  body body body'",
  "& .sidebar": {
    gridArea: "sidebar",
    width: "100%",
    height: "100%",
    position: "relative"
  },
  "& .header": {
    gridArea: "header",
    width: "100%",
    height: "110px",
    position: "relative"
  },
  "& .body": {
    gridArea: "body",
    width: "100%",
    height: "100%",
    marginBottom: "20px",
    position: "relative"
  }
}));

export default SPDashboardLayout;
