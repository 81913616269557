import { Divider, capitalize } from "@mui/material";
import clsx from "clsx";
import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { Column } from "react-table";
import { useGeBillerProfileDetails } from "../../api/hooks/biller/useGetBillerProfileDetails";
import { useUpdateBillerProfileDetails } from '../../api/hooks/biller/useUpdateBillerProfileDetails';
import ReusableTable from "../../components/ReusableTable";
import SPClaimsDashboardCard from "../components/SPClaimsDashboardCard";
import SPDashboardLayout from "../components/SPDashboardLayout";
import SPDashboardCard from "../components/SPDashboardProviderCard";
import SPMoneyMadeCard from "../components/SPMoneyMadeCard";
import SPWelcomeModal from "../components/SPWelcomeModal";

interface ClaimsType {
  claimId: string;
  date: Date;
  patientName: string;
  provider: string;
  insurance: string;
  charge: string;
  status: string;
}

const AdminStatusEl = ({ status }: { status: string }) => (
  <div className="flex items-center">
    <p
      className={clsx({
        "text-[#3D874E]": status === "approved",
        "text-[#F1A512]": status === "pending",
        "text-[#E45B3D]": status === "denied" || status === "rejected"
      })}
    >
      {status && capitalize(status)}
    </p>
  </div>
);

function SPMainDashboard() {
  const [isWelcomeModalVisible, setIsWelcomeModalVisible] = useState(false);
  const [successRateClaim, setSuccessRateClaim] = useState({
    totalCount: 0,
    todaysCount: 0
  });

  const [providers, setProviders] = useState({
    totalCount: 0,
    todaysCount: 0
  });

  const [moneyMadeFromTempleHs, setMoneyMadeFromTempleHs] = useState({
    totalCount: 0,
    todaysCount: 0
  });

  const { billerProfileData, refetch } = useGeBillerProfileDetails()
  const { updateBillerProfile } = useUpdateBillerProfileDetails()
  const { user } = billerProfileData || {}

  useEffect(() => {
    if (user?.hasSeenLoginModal) {
      setIsWelcomeModalVisible(false);
    } else {
      setIsWelcomeModalVisible(true);
    }
  }, [user?.hasSeenLoginModal]);

  const CLAIMS_SUBMISSION = useMemo<Column<ClaimsType>[]>(
    () => [
      {
        Header: "Claim ID",
        accessor: "claimId",
        Cell: ({ cell: { value } }) => {
          return (
            <React.Fragment>
              {value ? "#" + value.slice(0, 7) : ""}
            </React.Fragment>
          );
        }
      },
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ cell: { value }, row }) => {
          return (
            <React.Fragment>
              {value ? format(new Date(value), "MM/dd/yyyy") : "N/A"}
            </React.Fragment>
          );
        }
      },
      {
        Header: "Patient Name",
        accessor: "patientName",
        Cell: ({ cell: { value } }) => {
          return <React.Fragment>{value}</React.Fragment>;
        }
      },
      {
        Header: "Provider",
        accessor: "provider",
        Cell: ({ cell: { value } }) => {
          return <React.Fragment>{value}</React.Fragment>;
        }
      },
      {
        Header: "Insurance",
        accessor: "insurance",
        Cell: ({ cell: { value }, row }) => {
          return <React.Fragment>{value}</React.Fragment>;
        }
      },
      {
        Header: "Charge",
        accessor: "charge"
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => {
          return (
            <React.Fragment>
              <AdminStatusEl status={value} />
            </React.Fragment>
          );
        }
      }
    ],
    []
  );

  return (
    <SPDashboardLayout headerTitle="Dashboard">
      <div className="px-2">
        <div className="flex flex-wrap lg:flex-nowrap items-center">
          <div className="w-[422px] h-[198px]">
            <SPDashboardCard
              title=" Success rate of claims"
              todayCount={successRateClaim?.todaysCount}
              totalCount={successRateClaim?.totalCount}
            />
          </div>
          <div className="w-6" />
          <div className="w-[422px] h-[198px]">
            <SPClaimsDashboardCard
              title="Total no of Providers"
              todayCount={providers?.todaysCount}
              totalCount={providers?.totalCount}
            />
          </div>
          <div className="w-6" />
          <div className="w-[422px] h-[198px]">
            <SPMoneyMadeCard
              title="Money made from TempleHS"
              totalCount={moneyMadeFromTempleHs?.totalCount}
              todayCount={moneyMadeFromTempleHs?.todaysCount}
            />
          </div>
        </div>
        {providers?.totalCount > 0 ? (
          <div className="w-full mt-8">
            <ReusableTable
              tableColumns={CLAIMS_SUBMISSION}
              tableData={[]} //TODO: add live data here
              onClick={data => {}}
              pageSizeCount={10}
              title="Claims Submission"
              showTitleHideSearch
            />
          </div>
        ) : (
          <div className="mt-12 w-full h-[314px] rounded-[24px] shadow-[-2px_4px_25px_#EEEEE9] p-4 text-center">
            <p className="text-[24px] font-[600] text-[#324705] my-6">
              Things lined up for you
            </p>
            <Divider />
            <p className="text-[24px] font-[400] text-[#2E3011] my-6">
              Get started by{" "}
              <span className="text-[#3D874E]">adding a Provider</span>
            </p>
            <p className="text-[24px] font-[400] text-[#2E3011] my-6">
              Complete your <span className="text-[#3D874E]">Profile</span>
            </p>
          </div>
        )}
      </div>
      <SPWelcomeModal
        isVisible={isWelcomeModalVisible}
        closeModal={() => {
          updateBillerProfile({
            hasSeenLoginModal: true
          });
          setIsWelcomeModalVisible(false);
          refetch();
        }}
      />
    </SPDashboardLayout>
  );
}

export default SPMainDashboard;
