import clsx from "clsx";
import React, { useEffect, useMemo, useState } from "react";
import { Column } from "react-table";
import {
  getCurrentAdminUser
} from "../../api/admin";
import { useGetAdminUserDetails } from "../../api/hooks/admin/get_admin_user_details";
import { useGetAdminUsers } from "../../api/hooks/admin/get_admin_user_list";
import ReusableTable from "../../components/ReusableTable";
import BtnButton from "../../components/shared/BtnButton";
import AddAdminUserModal from "../components/AddAdminUserModal";
import AdminDashboardLayout from "../components/AdminDashboardLayout";
import AdminDataInfoCard from "../components/AdminDataInfoCard";
import AdminUsersActionCell from "../components/AdminUsersActionCell";
import EditAdminUserModal from "../components/EditAdminUserModal";

interface UsersType {
  userId: string;
  firstName: string;
  lastName: string;
  role: string;
  isActivated: boolean;
}

const UserStatusEl = ({ status }: { status: string }) => (
  <div className="flex items-center">
    <p
      className={clsx({
        "text-[#3D874E]": status === "Active",
        "text-[#E45B3D]": status === "Deactivated"
      })}
    >
      {status}
    </p>
  </div>
);

const AdminUsers = () => {
  const currentUser = getCurrentAdminUser();
  const [isAddUserModalView, setIsAddUserModalView] = useState<boolean>(false);
  const [singleAdminUserDetails, setSingleAdminUserDetails] =
    useState<any>(null);
  const [isEditUserModalView, setIsEditUserModalView] =
    useState<boolean>(false);
  const [userId, setUserId] = useState<string>("");

  const { data } = useGetAdminUsers()
  const { adminUserData, isLoading } = useGetAdminUserDetails({ userId })

  const TABLE_COLUMNS = useMemo<Column<UsersType>[]>(
    () => [
      {
        Header: "First Name",
        accessor: "firstName",
        Cell: ({ cell: { value } }) => {
          return <React.Fragment>{value}</React.Fragment>;
        }
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        Cell: ({ cell: { value } }) => {
          return <React.Fragment>{value}</React.Fragment>;
        }
      },
      {
        Header: "Role",
        accessor: "role"
      },
      {
        Header: "Status",
        accessor: "isActivated",
        Cell: ({ cell: { value } }) => {
          return (
            <React.Fragment>
              <UserStatusEl status={value ? "Active" : "Deactivated"} />
            </React.Fragment>
          );
        }
      },

      {
        Header: "Action",
        accessor: "userId",
        Cell: ({ cell: { value }, row }) => {
          return (
            <AdminUsersActionCell
              status={row.original?.isActivated}
              editUser={() => {
                setUserId(value);
                setIsEditUserModalView(true);
              }}
              removeUser={() => { }}
              deactivateUser={() => { }}
            />
          );
        }
      }
    ],
    []
  );

  useEffect(() => {
    if (!isLoading && adminUserData?.admin) {
      setSingleAdminUserDetails(adminUserData?.admin);
    }
  }, [isLoading, adminUserData?.admin]);


  return (
    <AdminDashboardLayout headerTitle="Admin Users">
      <div className="w-full h-full px-4 mt-8">
        <div className="flex items-center w-full">
          {" "}
          <div className="w-[374px]">
            <AdminDataInfoCard
              title="Total no. of  Users"
              dataCount={data?.length}
              red
            />
          </div>
        </div>
        <div className="w-full flex items-center justify-between mt-8 mb-4 px-4">
          <div className="text-[24px] font-[600] text-[#324705]">All Users</div>
          <div>
            {currentUser?.role === "superAdmin" && (
              <BtnButton
                onClick={() => setIsAddUserModalView(true)}
                variant="primary"
                label="Add User"
                size="medium"
              />
            )}
          </div>
        </div>
        <div className="w-full mt-8">
          <ReusableTable
            tableColumns={TABLE_COLUMNS}
            tableData={data || []}
            onClick={data => { }}
            pageSizeCount={10}
            searchFieldPlaceholder="Search Users"
          />
        </div>
      </div>
      <AddAdminUserModal
        isVisible={isAddUserModalView}
        closeModal={() => setIsAddUserModalView(false)}
        addUserAction={() => { }}
      />
      <EditAdminUserModal
        isVisible={isEditUserModalView}
        closeModal={() => setIsEditUserModalView(false)}
        editUserAction={() => { }}
        adminUserDetails={singleAdminUserDetails}
      />
    </AdminDashboardLayout>
  );
};

export default AdminUsers;
