import { styled } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useSPRessetPassword } from "../../api/hooks/biller/auth/useSPResetPassword";
import { initialValues, validationSchema } from "../../biller/schema/update_biller_password";
import Spacer from "../../components/Spacer";
import AuthLayout from "../../components/auth/AuthLayout";
import PasswordResetSuccess from "../../components/auth/PasswordResetSuccess";
import PasswordField from "../../components/form/PasswordField";
import Button from "../../components/shared/Button";

const PasswordResetPage = () => {
  const [resetSuccess, setResetSuccess] = useState<boolean>(false);
  const params = useParams();
  const { updatePassword, isSuccess } = useSPRessetPassword({});

  return (
    <AuthLayout>
      {!resetSuccess ? (
        <SignInPageContainer>
          <div className="w-[90%] lg:w-full flex flex-col items-center justify-start">
            <div className="head-text">Set your new password</div>
            <div>
              <span className="text-[1rem]">
                Enter your new password below. Ensure it is strong.
              </span>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                updatePassword({
                  password: values.password,
                  userType: "provider",
                  id: params.id,
                  token: params.token
                });
                if (isSuccess) {
                  setResetSuccess(true);
                }
              }}
            >
              {({ values, handleChange, touched, errors, handleSubmit }) => (
                <div className="page-form-container">
                  <PasswordField
                    name="password"
                    placeholder="At least 8 characters"
                    value={values?.password}
                    onChange={handleChange}
                    label="New Password"
                    showPasswordToggler={true}
                    error={touched.password ? errors.password : ""}
                  />
                  <Spacer height={10} />
                  <div className="w-[100%] flex flex-row items-center justify-end">
                    <Button
                      onClick={handleSubmit}
                      height={60}
                      type="submit"
                      variant="primary"
                      label={"Create new password"}
                      size="large"
                    />
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </SignInPageContainer>
      ) : (
        <PasswordResetSuccess userType="provider" />
      )}
    </AuthLayout>
  );
};

const SignInPageContainer = styled("div")(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "20px 0 15px 0",
  " @media (max-width: 820px)": {
    marginTop: "100px"
  },
  "& .head-text": {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#2E3011",
    margin: "8px 0"
  },
  "& .page-form-container": {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContents: "flex-start",
    marginTop: "20px",
    " @media (max-width: 820px)": {
      width: "100%"
    }
  }
}));

export default PasswordResetPage;
