export const DeleteIcon = ({
  height = 24,
  width = 24,
  color = "#D74E09",
	className,
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
	className?: string
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 5C3.75 4.58579 4.08579 4.25 4.5 4.25H19.5C19.9142 4.25 20.25 4.58579 20.25 5V22C20.25 22.4142 19.9142 22.75 19.5 22.75H4.5C4.08579 22.75 3.75 22.4142 3.75 22V5ZM5.25 5.75V21.25H18.75V5.75H5.25Z"
        fill={color}
        fillOpacity="0.76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 9.25C10.4142 9.25 10.75 9.58579 10.75 10V16.5C10.75 16.9142 10.4142 17.25 10 17.25C9.58579 17.25 9.25 16.9142 9.25 16.5V10C9.25 9.58579 9.58579 9.25 10 9.25Z"
        fill={color}
        fillOpacity="0.76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 9.25C14.4142 9.25 14.75 9.58579 14.75 10V16.5C14.75 16.9142 14.4142 17.25 14 17.25C13.5858 17.25 13.25 16.9142 13.25 16.5V10C13.25 9.58579 13.5858 9.25 14 9.25Z"
        fill={color}
        fillOpacity="0.76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 5C1.25 4.58579 1.58579 4.25 2 4.25H22C22.4142 4.25 22.75 4.58579 22.75 5C22.75 5.41421 22.4142 5.75 22 5.75H2C1.58579 5.75 1.25 5.41421 1.25 5Z"
        fill={color}
        fillOpacity="0.76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.98683 1.63949C9.11849 1.39931 9.3706 1.25 9.6445 1.25H14.3885C14.6647 1.25 14.9186 1.40179 15.0493 1.6451L16.6607 4.6451C16.7856 4.87753 16.7791 5.15847 16.6437 5.38492C16.5083 5.61136 16.2638 5.75 16 5.75H8C7.73502 5.75 7.4897 5.61017 7.35468 5.38217C7.21965 5.15418 7.21496 4.87185 7.34233 4.63949L8.98683 1.63949ZM10.0887 2.75L9.26642 4.25H14.7458L13.9401 2.75H10.0887Z"
        fill={color}
        fillOpacity="0.76"
      />
    </svg>
  );
};
