import { FC } from "react";
import DatePicker from "react-datepicker";
import { dependentRelationshipList } from "../../../utils/consts";
import Spacer from "../../Spacer";
import RadioField from "../../form/RadioField";
import TextField from "../../form/TextField";
import Button from "../../shared/Button";

interface AddDependantFormProps {
  formik: any;
}

const AddDependantForm: FC<AddDependantFormProps> = ({ formik }) => {
  return (
    <div className="max-h-[100vh] flex flex-col overflow-y-auto px-8">
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full flex flex-row items-center justify-between my-4">
          <div className="form-item-data">
            <TextField
              name="firstName"
              type="text"
              placeholder="First name"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              label="First Name"
              error={formik.touched.firstName ? formik.errors.firstName : ""}
            />
          </div>
          <div className="w-2" />
          <div className="form-item-data">
            <TextField
              name="lastName"
              type="text"
              placeholder="Last name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              label="Last Name"
              error={formik.touched.lastName ? formik.errors.lastName : ""}
            />
          </div>
        </div>
        <div className="my-4">
          <div className="flex flex-row items-center mx-auto w-[100%] gap-5">
            <div className="flex flex-col items-start w-full">
              <label
                htmlFor="dependentRelationship"
                className="text-[#000] font-medium mb-2 text-[16px]"
              >
                Dependent relationship
              </label>
              <select
                id="dependentRelationship"
                className="w-full py-3.5 px-4 border border-[#949589] text-[#6E8877] rounded leading-tight focus:outline-none focus:bg-none focus:border-gray-500 hover:border-black text-s"
                // placeholder="Select dependent relationship"
                name="dependentRelationship"
                value={formik.values.dependentRelationship}
                onChange={e => formik.handleChange(e)}
              >
                <option value="" disabled>
                  Select dependent relationship
                </option>
                {dependentRelationshipList.map((relationType, index) => {
                  return (
                    <option key={index} value={relationType} id={relationType}>
                      {relationType}
                    </option>
                  );
                })}
              </select>
              {formik.touched.dependentRelationship &&
                formik.errors.dependentRelationship && <Spacer height={10} />}
              {formik.touched.dependentRelationship &&
                formik.errors.dependentRelationship && (
                  <div className="text-[red] text-xs">
                    {formik.errors.dependentRelationship}
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="my-4">
          <div className="form-item-text">Email</div>
          <div className="flex flex-row items-center justify-end">
            <div className="w-full">
              <TextField
                name="email"
                type="email"
                placeholder="Enter email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email ? formik.errors.email : ""}
              />
            </div>
          </div>
        </div>
        <div className="my-4">
          <div className="form-item-text">Date Of Birth</div>
          <div className="flex flex-row items-center justify-end">
            <div className="w-full bg-white border border-[#CECFC5]">
              <DatePicker
                selected={formik.values.dateOfBirth || ""}
                name="dateOfBirth"
                dateFormat="MM/dd/yyyy"
                onChange={(date: Date) => {
                  formik.setFieldValue("dateOfBirth", date);
                }}
                placeholderText="MM/DD/YYYY"
                className="date-picker"
                forceShowMonthNavigation={true}
                showYearDropdown={true}
                showMonthDropdown={true}
                scrollableMonthYearDropdown={true}
                yearDropdownItemNumber={100}
                scrollableYearDropdown={true}
                shouldCloseOnSelect={true}
                required
              />
              <>
                {formik.touched.dateOfBirth && formik.errors.dateOfBirth && (
                  <p className="mt-2 text-xs text-red-600 capitalize">
                    Date of birth is required
                  </p>
                )}
              </>
            </div>
          </div>
        </div>
        <div className="form-item-grid">
          <div className="form-item-text">Gender</div>
          <div className="flex flex-row items-center justify-start">
            <div className="form-item-data">
              <RadioField
                name="gender"
                placeholder="Gender"
                display="block"
                options={[
                  { item: "Male", value: "male" },
                  { item: "Female", value: "female" }
                ]}
                hasOther={true}
                isRequired={true}
                value={formik.values.gender}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-end my-4">
          <Button
            height={60}
            onClick={formik.submitForm}
            // type="submit"
            variant="primary"
            label="Add Dependent"
            size="medium"
          />
        </div>
      </form>
    </div>
  );
};

export default AddDependantForm;
