import { format } from "date-fns";
import { Fragment, useMemo } from "react";
import { Column } from "react-table";
import { PatientAppointmentsType } from "../../pages/AdminPatients";

export function useAdminPatientAppointmentTableCols() {
  const APPOINTMENTS_TABLE_COLUMNS = useMemo<Column<PatientAppointmentsType>[]>(
    () => [
      {
        Header: "Doctor",
        accessor: "doctor",
        Cell: ({ cell: { value } }) => {
          return <Fragment>{value}</Fragment>;
        }
      },
      {
        Header: "Charges",
        accessor: "charges"
      },
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ cell: { value }, row }) => {
          return (
            <Fragment>
              {value ? format(new Date(value), "MM/dd/yyyy") : "N/A"}
            </Fragment>
          );
        }
      }
    ],
    []
  );

  return {
    APPOINTMENTS_TABLE_COLUMNS,
  };
}
