import { Form, Formik } from 'formik';
import { useAddExternalProvider } from '../../api/hooks/biller/useAddExternalProviders';
import TextField from "../../components/form/TextField";
import Button from "../../components/shared/Button";
import ModalPortal from "../../components/shared/ModalPortal";
import { CancelIcon } from "../../svgs/CancelIcon";
import { sp_new_initialValues, sp_new_validation_schema } from "../schema/sp_new_provder_modal.schema";


interface IProps {
  saveAction: () => void;
  closeModal: () => void;
  isVisible: boolean;
}
const SPNewProviderModal: React.FC<IProps> = ({
  saveAction,
  closeModal,
  isVisible = false
}) => {

  const { addExternalProvider, isPending } = useAddExternalProvider()
  return isVisible ? (
    <ModalPortal>
      <div
        style={{
          backgroundColor: "rgba(38, 38, 38, 0.75)",

        }}
        className="w-screen h-screen fixed flex flex-col justify-center items-center top-0 bg-opacity-20 backdrop-blur-[5px] z-[100]"
      >
        <div className="flex justify-end  w-[90%] lg:w-[865px] mx-auto py-2">
          <div
            onClick={() => {
              closeModal();
            }}
            className="p-2 bg-white rounded-full cursor-pointer"
          >
            <CancelIcon className="h-full w-full" />
          </div>
        </div>
        <div className="bg-white max-h-[750px] w-[90%] lg:w-[865px] px-4 pt-5 pb-4 lg:p-6 lg:pb-4 shadow-lg rounded-[16px]">
          <p className="text-[#2E3011] text-[24px] font-[600]">
            Add a Provider
          </p>
          <Formik
            initialValues={sp_new_initialValues}
            validationSchema={sp_new_validation_schema}

            onSubmit={({ firstName, lastName, middleName, title, email, providerNPI, providerTaxId, phoneNumber }) => {

              closeModal()
            }}
          >
            {
              ({ errors, touched, values, handleChange }) => (
                <Form className='flex flex-col'>
                  <div className="flex items-center mb-5 gap-2">
                    <div className="w-1/2">
                      <TextField
                        type="text"
                        placeholder="Winston Gill"
                        onChange={handleChange}
                        name="firstName"
                        value={values.firstName}
                        label="First Name"

                      />
                      {errors.firstName && touched.firstName ? <span className="text-red-500 text-sm">{errors.firstName}</span> : null}
                    </div>
                    <div className="w-1/2">
                      <TextField
                        type="text"
                        placeholder="middle name"
                        onChange={handleChange}
                        name="middleName"
                        value={values.middleName}
                        label="Middle Name (Optional)"

                      />
                      {errors.middleName && touched.middleName ? <span className="text-red-500 text-sm">{errors.middleName}</span> : null}
                    </div>

                    <div className="w-1/2">
                      <TextField
                        type="text"
                        placeholder="churchill"
                        onChange={handleChange}
                        name="lastName"
                        value={values.lastName}
                        label="Last Name"

                      />
                      {errors.lastName && touched.lastName ? <span className="text-red-500 text-sm">{errors.lastName}</span> : null}
                    </div>
                    <div className="w-1/2">
                      <TextField
                        type="text"
                        placeholder="Therapist MD"
                        onChange={handleChange}
                        name="title"
                        value={values.title}
                        label="Title"
                      />
                      {errors.title && touched.title ? <span className="text-red-500 text-sm">{errors.title}</span> : null}
                    </div>
                  </div>
                  <div className="flex items-center mb-6">
                    <div className="w-1/2 pr-4">
                      <TextField
                        type="email"
                        placeholder="winstongill@gmail.com"
                        onChange={handleChange}
                        name="email"
                        value={values.email}
                        label="Email address"

                      />
                      {errors.email && touched.email ? <span className="text-red-500 text-sm">{errors.email}</span> : null}
                    </div>
                    <div className="w-1/2">
                      <TextField
                        type="text"
                        placeholder="(555) 12345"
                        onChange={handleChange}
                        name="phoneNumber"
                        value={values.phoneNumber}
                        label="Phone Number"

                      />
                      {errors.phoneNumber && touched.phoneNumber ? <span className="text-red-500 text-sm">{errors.phoneNumber}</span> : null}
                    </div>
                  </div>
                  <div className="flex items-center mb-6">
                    <div className="w-1/2 pr-4">
                      <TextField
                        type="text"
                        placeholder="1245319599"
                        onChange={handleChange}
                        name="providerNPI"
                        value={values.providerNPI}
                        label="Provider NPI"

                      />
                      {errors.providerNPI && touched.providerNPI ? <span className="text-red-500 text-sm">{errors.providerNPI}</span> : null}
                    </div>
                    <div className="w-1/2">
                      <TextField
                        type="text"
                        placeholder="123456789123"
                        onChange={handleChange}
                        name="providerTaxId"
                        value={values.providerTaxId}
                        label="Provider Tax ID"

                      />
                      {errors.providerTaxId && touched.providerTaxId ? <span className="text-red-500 text-sm">{errors.providerTaxId}</span> : null}
                    </div>
                  </div>
                  <div className="flex justify-end w-full ">
                    <Button
                      additionalClassname={"text-[18px] font-[400] "}
                      type="button"
                      variant="primary"
                      onClick={() => {
                        addExternalProvider({
                          firstName: values.firstName,
                          lastName: values.lastName,
                          title: values.title,
                          middleName: values.middleName,
                          email: values.email,
                          providerNPI: values.providerNPI,
                          providerTaxId: values.providerTaxId,
                          phoneNumber: values.phoneNumber,
                        })
                        closeModal()
                      }}
                      label="Save"
                      size="small"
                      loading={isPending}
                      disabled={isPending}
                    />
                  </div>
                </Form>
              )
            }
          </Formik>
        </div>
      </div>
    </ModalPortal>
  ) : null;
};

export default SPNewProviderModal;
