import clsx from "clsx";

interface SecondaryListTabItemProps {
  isActive: boolean;
  label: string;
  value: any;
  onClick: () => void;
}

const SecondaryListTabsWithIconItem: React.FC<SecondaryListTabItemProps> = ({
  isActive,
  label,
  value,
  onClick
}) => {
  return (
    <li className="h-full">
      <button
        type="button"
        className={clsx({
          "inline-block rounded-t-lg text-[14px] lg:text-[20px] h-full lg:w-[200px]":
            true,
          "text-[#5e7163] hover:text-[#34623f] hover:border-b-[#34623f] border-b-[#5e7163] hover:border-b-[1px] font-[400]":
            !isActive,
          "text-[#34623F] active border-b-[1px] border-b-solid border-b-[#34623F] font-[400]":
            isActive
        })}
        onClick={() => {
          onClick();
        }}
      >
        <span className="flex items-center w-full h-full">
          {label}
          <div className="mr-3" />
          <b
            className={clsx({
              "h-[23px] min-w-[29px] text-[13px] font-[500] rounded-[82px]":
                true,
              "bg-[#CECFC5]": !isActive,
              "bg-[#34623f] text-white": isActive
            })}
          >
            {value}
          </b>
        </span>
      </button>
    </li>
  );
};

export default SecondaryListTabsWithIconItem;
