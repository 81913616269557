import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { format } from "date-fns";
import { Fragment, useMemo } from "react";
import { Column } from "react-table";
import { useDeleteAdminPatient } from "../../../api/hooks/admin/delete_patient";
import { useUpdateAdminPatientStatus } from "../../../api/hooks/admin/update_patient_status";
import AdminPatientsActionCell from "../../components/AdminPatientsActionCell";
import { ProviderPatientsType } from "../../pages/AdminPatients";


type Props = {
  setUserId: React.Dispatch<React.SetStateAction<string>>
  setIsPatientDetailsView: React.Dispatch<React.SetStateAction<boolean>>
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<any, Error>>
}
export function useAdminPatientTableCols({ setUserId, setIsPatientDetailsView, refetch }: Props) {
  const { deletePatient } = useDeleteAdminPatient()
  const { updatePatientStatus } = useUpdateAdminPatientStatus()

  const PATIENTS_TABLE_COLUMNS = useMemo<Column<ProviderPatientsType>[]>(
    () => [
      {
        Header: "Name",
        accessor: "firstName",
        Cell: ({ cell: { value }, row }) => {
          return (
            <Fragment>
              {" "}
              {`${row.original.firstName}
      ${row.original.lastName}`}
            </Fragment>
          );
        }
      },
      {
        Header: "Email",
        accessor: "email"
      },
      {
        Header: "Join Date",
        accessor: "created_at",
        Cell: ({ cell: { value }, row }) => {
          return (
            <Fragment>
              {value ? format(new Date(value), "MM/dd/yyyy") : "N/A"}
            </Fragment>
          );
        }
      },
      {
        Header: "Last Appointment",
        accessor: "lastAppointmentDate",
        Cell: ({ cell: { value }, row }) => {
          return (
            <Fragment>
              {value ? format(new Date(value), "MM/dd/yyyy") : "N/A"}
            </Fragment>
          );
        }
      },
      {
        Header: "Action",
        accessor: "userId",
        Cell: ({ cell: { value }, row }) => {
          return (
            <AdminPatientsActionCell
              viewPatient={() => {
                refetch()
                setUserId(value)
                setIsPatientDetailsView(true);
              }}
              deactivatePatient={() => {
                updatePatientStatus({ userId: value });
              }}
              deletePatient={() => {
                deletePatient({ userId: value });
              }}
              status={row.original?.isActivated}
            />
          );
        }
      }
    ],
    [updatePatientStatus, deletePatient, setIsPatientDetailsView, setUserId]
  );

  return {
    PATIENTS_TABLE_COLUMNS,
  };
}
