import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';

export type Reactchildren = {
	children: ReactNode;
};

interface TitleContextValue {
	title: string;
	setTitle: Dispatch<SetStateAction<string>>;
}

export const titleContext = createContext<TitleContextValue>(
	{} as TitleContextValue
);

const PageTitleProvider = ({ children }: Reactchildren) => {
	const [title, setTitle] = useState<string>('TempleHS');

	return (
		<titleContext.Provider value={{ title, setTitle }}>
			{children}
		</titleContext.Provider>
	);
};

export default PageTitleProvider;
