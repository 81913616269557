export const InfoIcon2 = ({
  width = 18,
  height = 18,
  color = "#3D874E"
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8272_4845)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.29895 3.29895C4.75738 1.84054 6.77377 0.9375 9 0.9375C11.2262 0.9375 13.2426 1.84054 14.701 3.29895L14.3033 3.6967L14.701 3.29895C16.1594 4.75738 17.0625 6.77377 17.0625 9C17.0625 11.2262 16.1594 13.2426 14.701 14.701C13.2426 16.1594 11.2262 17.0625 9 17.0625C6.77377 17.0625 4.75738 16.1594 3.29895 14.701L3.6967 14.3033L3.29895 14.701C1.84054 13.2426 0.9375 11.2262 0.9375 9C0.9375 6.77377 1.84054 4.75738 3.29895 3.29895L3.29895 3.29895ZM9 2.0625C7.08413 2.0625 5.35052 2.83839 4.09444 4.09445C2.83838 5.35053 2.0625 7.08413 2.0625 9C2.0625 10.9159 2.83839 12.6495 4.09445 13.9055C5.35052 15.1616 7.08413 15.9375 9 15.9375C10.9159 15.9375 12.6495 15.1616 13.9055 13.9055C15.1616 12.6495 15.9375 10.9159 15.9375 9C15.9375 7.08413 15.1616 5.35052 13.9055 4.09445C12.6495 2.83839 10.9159 2.0625 9 2.0625Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 4.125C9.51776 4.125 9.9375 4.54474 9.9375 5.0625C9.9375 5.58026 9.51776 6 9 6C8.48224 6 8.0625 5.58026 8.0625 5.0625C8.0625 4.54474 8.48224 4.125 9 4.125Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.875 7.5C7.875 7.18934 8.12684 6.9375 8.4375 6.9375H9.1875C9.49816 6.9375 9.75 7.18934 9.75 7.5V12.75C9.75 13.0607 9.49816 13.3125 9.1875 13.3125C8.87684 13.3125 8.625 13.0607 8.625 12.75V8.0625H8.4375C8.12684 8.0625 7.875 7.81066 7.875 7.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.3125 12.75C7.3125 12.4393 7.56434 12.1875 7.875 12.1875H10.5C10.8107 12.1875 11.0625 12.4393 11.0625 12.75C11.0625 13.0607 10.8107 13.3125 10.5 13.3125H7.875C7.56434 13.3125 7.3125 13.0607 7.3125 12.75Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_8272_4845">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
