import jwtDecode from "jwt-decode";
import { UserType } from "../types/common";

export const getLoggedInUser = ({ user }: { user: UserType }) => {
  try {
    const tokenName = `temple_${
      ["patient", "provider"].includes(user) ? "user" : user
    }_token`;
    const token = localStorage.getItem(tokenName);
    if (token) {
      const currentUser: any = jwtDecode(token);
      return currentUser;
    }
  } catch (error) {
    return error;
  }
};
