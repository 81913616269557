export const ArrowRightIcon2 = ({
  width = 14,
  height = 23,
  color = "#2E3011",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 14 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.949124 0.616117C1.43728 0.127961 2.22874 0.127961 2.71689 0.616117L12.7169 10.6161C13.205 11.1043 13.205 11.8957 12.7169 12.3839L2.71689 22.3839C2.22874 22.872 1.43728 22.872 0.949124 22.3839C0.460969 21.8957 0.460969 21.1043 0.949124 20.6161L10.0652 11.5L0.949124 2.38388C0.460969 1.89573 0.460969 1.10427 0.949124 0.616117Z"
        fill={color}
      />
    </svg>
  );
};
