import { Fragment } from "react";
import { ProviderCharge } from "../../api/types";
import { SimpleChargeSheet } from "./SimpleChargeSheet";
import { SaveAction } from "./types/chargeSheet";

interface Props {
  saveAction: ({
    patientId,
    appointmentId,
    status,
    modeOfEncounter,
    locationOfEncounter,
    dateOfEncounter,
  }: SaveAction) => void;
  sendToBillingAction: ({
    patientId,
    appointmentId,
    status,
    modeOfEncounter,
    locationOfEncounter,
    dateOfEncounter,
  }: SaveAction) => void;
  patientData?: any;
  chargeSheetData: ProviderCharge;
  isSendingToBilling: boolean;
  isSavingDraft: boolean;
  isFromNewBilling?: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SimpleChargeSheetModal = ({
  saveAction,
  chargeSheetData,
  sendToBillingAction,
  isSendingToBilling,
  isSavingDraft,
  isFromNewBilling,
  open,
  setOpen
}: Props) => (
  <Fragment>
    {open && (
      <SimpleChargeSheet
        open={open}
        setOpen={setOpen}
        isSendingToBilling={isSendingToBilling}
        isSavingDraft={isSavingDraft}
        saveAction={saveAction}
        chargeSheetData={chargeSheetData}
        sendToBillingAction={sendToBillingAction}
        isFromNewBilling={isFromNewBilling}
      />
    )}
  </Fragment>
)