import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import clsx from "clsx";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "../api/hooks/patient/auth/useAuthState";
import Spacer from "../components/Spacer";
import Button from "../components/shared/Button";
import { NotificationIcon } from "../svgs";
import { ArrowDownIcon } from "../svgs/ArrowDownIcon";
import { getGreeting } from "../utils/helpers";

interface HeaderProps {
  headerTitle?: string;
}

const Header = ({
  headerTitle
}: HeaderProps) => {
  const { user, isAuthenticated } = useAuthState();

  // const [isNotificationDrawerVisible, setIsNotificationDrawerVisible] =
  //   useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  // const notificationsList = [];
  const navigate = useNavigate();

  return (
    <div
      className={clsx({
        "hidden lg:flex top-0 items-center justify-between bg-[#FFFFFF] w-full h-[100px] m-auto mt-0 relative":
          true,
        "bg-[#FBFEF7] border-2 border-[#EEEEE9]": isAuthenticated
      })}
    >
      {user !== null ? (
        <>
          <div className="text-[24px] font-[600] text-[#324705] pl-0 mt-8 m-auto w-[100%] ml-3">
            {headerTitle ? (
              headerTitle
            ) : (
              <>
                  {getGreeting()} {user && user?.firstName}
              </>
            )}
          </div>
          <div className="flex items-center justify-end mr-[50px] ">
            <button
              className=""
            // onClick={() => setIsNotificationDrawerVisible(true)}
            >
              <NotificationIcon />
            </button>
            <Spacer width={8} />
            <img
              src={user?.avatarUrl ? user?.avatarUrl : "/assets/images/avatar.png"}
              alt="provider-avatar"
              className="w-[40px] h-[40px] rounded-full mr-2"
            />
            <Spacer width={8} />

            <div>
              <p className="text-xs font-bold text-[#324705] capitalize">
                {user && user?.firstName}{"  "}
                {user && user?.lastName}
              </p>
              <p className="text-xs font-normal text-[#324705]">
                {user && user?.email}
              </p>
            </div>

          </div>
        </>
      ) : (
        <div className="w-full px-[5vw] flex items-center justify-between text-black my-4">
          <p className="font-gentiumBasic font-[700] text-[34px]">TempleHS</p>
          <div>
            <ul className="flex justify-end items-center px-2 text-sm font-normal ">
              <li className="px-[1vw] text-[1.1vw]">
                <Button
                  width="12vw"
                  height="3.5vw"
                  variant="tertiary"
                  label=" Sign in"
                  additionalClassname="text-[1.1vw] font-[400]"
                  icon={<ArrowDownIcon color="#2E3011" />}
                  iconPosition="right"
                  iconSpace={10}
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                />
              </li>
              <li className="px-4">
                <Button
                  width="12vw"
                  height="3.5vw"
                  variant="primary"
                  label="Get started now"
                  additionalClassname="text-[1.1vw] font-[400]"
                  icon={<ArrowDownIcon color="white" />}
                  iconPosition="right"
                  aria-controls={open2 ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open2 ? "true" : undefined}
                  onClick={handleClick2}
                />
              </li>
            </ul>
          </div>

          {/* LOGIN MENU */}
          {/* TODO: MAKE COMPONENT REUSABLE */}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button"
            }}
          >
            <MenuItem
              onClick={() => {
                navigate("/patient/login");
                handleClose();
              }}
            >
              As a Patient
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/provider/login");
                handleClose();
              }}
            >
              As a Provider
            </MenuItem>
            <MenuItem onClick={handleClose}>As a Service Provider</MenuItem>
          </Menu>

          {/* SIGNUP MENU */}
          {/* TODO: MAKE COMPONENT REUSABLE */}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl2}
            open={open2}
            onClose={handleClose2}
            MenuListProps={{
              "aria-labelledby": "basic-button"
            }}
          >
            <MenuItem
              onClick={() => {
                navigate("/patient/signup");
                handleClose2();
              }}
            >
              As a Patient
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/provider/intro");
                handleClose2();
              }}
            >
              As a Provider
            </MenuItem>
            <MenuItem onClick={handleClose2}>As a Service Provider</MenuItem>
          </Menu>
        </div>
      )}
      {/* <NotificationDrawer
        open={isNotificationDrawerVisible}
        onClose={() => setIsNotificationDrawerVisible(false)}
        drawerTitle="Notifications"
        notificationsList={notificationsList}
      /> */}
    </div>
  );
};

export default Header;
