import { useQuery } from '@tanstack/react-query';
import http from '../../../utils/http';


export function useGetDXScriptSSOLogin() {
  const { data, isLoading, refetch, ...rest } = useQuery({
    queryKey: ["getDXScriptSSOLogin"],
    queryFn: async () => {
      const response = await http.get(`prescriptions/sso/token`);
      return response.data?.token;
    },
    staleTime: Infinity, // never stale
  });
  return {
    token: data,
    isLoading,
    refetchDXScriptSSOLogin: refetch,
    ...rest,
  }
}