import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { useInviteNewAdminUser } from "../../api/hooks/admin/invite_new_users_admin";
import TextField from "../../components/form/TextField";
import BtnButton from "../../components/shared/BtnButton";
import CustomDropdown from "../../components/shared/CustomDropdown";
import ModalPortal from "../../components/shared/ModalPortal";
import { CancelIcon } from "../../svgs/CancelIcon";

interface IProps {
  addUserAction: () => void;
  closeModal: () => void;
  isVisible: boolean;
}

const AddAdminUserModal: React.FC<IProps> = ({
  addUserAction,
  closeModal,
  isVisible = false
}) => {
  const { inviteNewAdminUser, isPending } = useInviteNewAdminUser()

  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      role: ""
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid Email").required("Email is required"),
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("last name is required"),
      role: Yup.string().required("Role is required")
    }),
    onSubmit: () => { }
  });

  return isVisible ? (
    <ModalPortal>
      <div
        style={{
          backgroundColor: "rgba(38, 38, 38, 0.75)",
          zIndex: 100,
          display: "flex",
          justifyContent: "center"
        }}
        className="w-screen h-screen fixed flex flex-col justify-center items-center top-0 bg-opacity-20 backdrop-blur-[5px]"
      >
        <div className="flex justify-end  w-[90%] lg:w-[462px] mx-auto py-2">
          <div
            onClick={() => {
              closeModal();
            }}
            className="p-2 bg-white rounded-full cursor-pointer"
          >
            <CancelIcon className="h-full w-full" />
          </div>
        </div>
        <div className="bg-white h-[650px] w-[90%] lg:w-[462px] px-6 pt-6 pb-4 lg:p-6 shadow-lg rounded-[16px] border-box">
          <p className="text-[#2E3011] text-[24px] font-[600]">
            Add a new user
          </p>
          <div className="flex flex-col  items-center justify-center h-full">
            <form className="w-full h-full p-2">
              <div className="w-full my-4">
                <TextField
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                  label="First Name"
                  value={formik.values?.firstName}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="w-full my-8">
                <TextField
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  label="Last Name"
                  value={formik.values?.lastName}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="w-full my-8">
                <TextField
                  name="email"
                  type="email"
                  placeholder="Email Address"
                  label="Email Addresse"
                  value={formik.values?.email}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="w-full my-8">
                <div className="form-item-text pb-2">Role</div>
                <div className="w-full flex justify-start">
                  <CustomDropdown
                    name="role"
                    value={formik.values?.role}
                    optionsList={[
                      { name: "Admin", value: "admin" },
                      { name: "Super Admin", value: "superAdmin" }
                    ]}
                    onChange={event => {
                      formik.setFieldValue("role", event.target.value);
                    }}
                    placeholder="Role"
                    defaultValue="admin"
                  />
                </div>
              </div>
              <div className="flex justify-center mt-8 w-full">
                <div className="flex w-[383px] h-[60px]">
                  <BtnButton
                    loading={isPending}
                    type="button"
                    variant="primary"
                    label="Add User"
                    onClick={async () => {
                      inviteNewAdminUser({
                        email: formik.values.email,
                        firstName: formik.values.firstName,
                        lastName: formik.values.lastName,
                        role: formik.values.role
                      });

                    }}
                    size="extra-large"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ModalPortal>
  ) : null;
};

export default AddAdminUserModal;
