import { useQuery } from '@tanstack/react-query';
import http from '../../../utils/http';


export const saveCalendarAuthCode = async ({ code }: { code: string }) => {
  try {
    const response = await http.post("providers/calendar/code", { code });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};


export function useTriggerCalendarAuthCode(code: string) {
  const { data, isLoading, refetch, ...rest } = useQuery({
    queryKey: ["trigger calendar auth code", code],
    queryFn: () => saveCalendarAuthCode({ code }),
    refetchOnMount: true,
    staleTime: Infinity
  });
  return {
    calendarAuthToken: data,
    isLoading,
    refetch,
    ...rest,
  }
}