import { format } from "date-fns";
import { Fragment, useMemo } from "react";
import { Column } from "react-table";
import { MalpracticeInsuranceRequest } from "../../types/mal_practice_insurance";

export function useMalpracticeInsuranceRequestsTableCols() {
  const MAL_PRACTICE_COLUMNS = useMemo<Column<MalpracticeInsuranceRequest>[]>(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Provider ID",
        accessor: "providerId",
      },
      {
        Header: "Insurance Provider",
        accessor: "insuranceProvider",
      },
      {
        Header: "Policy Number",
        accessor: "policyNumber",
      },
      {
        Header: "Deductible Amount",
        accessor: "deductibleAmount",
      },
      {
        Header: "Coverage Limit",
        accessor: "coverageLimit",
      },
      {
        Header: "Coverage Type",
        accessor: "coverageType",
      },
      {
        Header: "Effective Date",
        accessor: "effectiveDate",
        Cell: ({ cell: { value } }) => {
          return <Fragment>{value ? format(new Date(value), "yyyy-MM-dd") : "N/A"}</Fragment>;
        },
      },
      {
        Header: "Expiration Date",
        accessor: "expirationDate",
        Cell: ({ cell: { value } }) => {
          return <Fragment>{value ? format(new Date(value), "yyyy-MM-dd") : "N/A"}</Fragment>;
        },
      },
      {
        Header: "Created At",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => {
          return <Fragment>{value ? format(new Date(value), "yyyy-MM-dd") : "N/A"}</Fragment>;
        },
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
        Cell: ({ cell: { value } }) => {
          return <Fragment>{value ? format(new Date(value), "yyyy-MM-dd") : "N/A"}</Fragment>;
        },
      },
    ],
    []
  );

  return {
    MAL_PRACTICE_COLUMNS,
  };
}
