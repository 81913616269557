export const EmailIconBox = ({
  width = 16,
  height = 17,
  color = "#87928A",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.833374 3.5C0.833374 3.22386 1.05723 3 1.33337 3H14.6667C14.9428 3 15.1667 3.22386 15.1667 3.5V13.5C15.1667 13.7761 14.9428 14 14.6667 14H1.33337C1.05723 14 0.833374 13.7761 0.833374 13.5V3.5ZM1.83337 4V13H14.1667V4H1.83337Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.933281 3.20003C1.09897 2.97912 1.41237 2.93434 1.63328 3.10003L7.99995 7.87503L14.3666 3.10003C14.5875 2.93434 14.9009 2.97912 15.0666 3.20003C15.2323 3.42094 15.1875 3.73434 14.9666 3.90003L8.29995 8.90003C8.12217 9.03336 7.87773 9.03336 7.69995 8.90003L1.03328 3.90003C0.812367 3.73434 0.767596 3.42094 0.933281 3.20003Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.833374 3.5C0.833374 3.22386 1.05723 3 1.33337 3H8.00004C8.27618 3 8.50004 3.22386 8.50004 3.5C8.50004 3.77614 8.27618 4 8.00004 4H1.83337V8.5C1.83337 8.77614 1.60952 9 1.33337 9C1.05723 9 0.833374 8.77614 0.833374 8.5V3.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 3.5C7.5 3.22386 7.72386 3 8 3H14.6667C14.9428 3 15.1667 3.22386 15.1667 3.5V8.5C15.1667 8.77614 14.9428 9 14.6667 9C14.3905 9 14.1667 8.77614 14.1667 8.5V4H8C7.72386 4 7.5 3.77614 7.5 3.5Z"
        fill={color}
      />
    </svg>
  );
};
