import { TempleHSLogo } from "../../svgs/TempleHSLogo";

interface AuthLayoutProps {
  children: React.ReactNode;
}

const AuthLayout = ({ children }: AuthLayoutProps) => (
  <div className="bg-white flex flex-col items-center justify-center">
    <a className="mt-20 mb-10" href={process.env.REACT_APP_LANDING_PAGE_URL}><TempleHSLogo /></a>
    {children}
  </div>
);

export default AuthLayout;
