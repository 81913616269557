export const ArrowUpIcon = ({
  width = 13,
  height = 8,
  color = "#2E3011",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.974724 6.94194C1.2188 7.18602 1.61453 7.18602 1.85861 6.94194L6.41667 2.38388L10.9747 6.94194C11.2188 7.18602 11.6145 7.18602 11.8586 6.94194C12.1027 6.69786 12.1027 6.30214 11.8586 6.05806L6.85861 1.05806C6.61453 0.813981 6.2188 0.813981 5.97472 1.05806L0.974724 6.05806C0.730647 6.30214 0.730647 6.69786 0.974724 6.94194Z"
        fill={color}
      />
    </svg>
  );
};
