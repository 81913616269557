import { useQuery } from '@tanstack/react-query';
import { PracticeRegistration, PracticeRegistrationResponse } from '../../../admin/types/practice_reg';
import adminHttp from '../../../utils/adminHttp';
import { ErrorResponse } from '../../types';

export function useGePracticeRegistration() {
  const { data, isLoading, ...rest } = useQuery<PracticeRegistrationResponse, ErrorResponse>({
    queryKey: ["admin practice-reg"],
    queryFn: async () => {
      const response = await adminHttp.get(`/practice-reg/all`);
      return response.data;
    },
    staleTime: Infinity,
  });
  return {
    allPracticeRegData: data?.practiceRegistrations as PracticeRegistration[],
    isLoading,
    ...rest,
  }
}