import { PatientDetailsType } from "./patientDetails";

export const tabs = [
  {
    label: "Overview",
    urlParam: "/overview"
  },
  {
    label: "Documentation",
    urlParam: "/documentation"
  },
  {
    label: "Prescription",
    urlParam: "/prescription"
  }
];

export const formTitleMapper: Record<string, string> = {
  intakeForm: "Intake Form",
  initialVisitNote: "Initial Visit Note",
  progressNote: "Progress Note",
  consentForm: "Consent Form"
};

export type PatientDetailProps = {
  activePatientData?: PatientDetailsType;
  goBack?: () => void;
  diagnosis?: string;
  patientId?: string;
};

export type NoteValue = {
  [key: string]: string | Array<string>;
};

type Note = {
  id: number;
  documentationId: string;
  providerId: string;
  patientId: string;
  appointmentId?: string;
  noteType: string;
  values: NoteValue[];
  isLocked: boolean;
  created_at: string;
  updated_at: string;
};

export type AllDocumentsProps = Note[];

type body = {
  field: string;
  value: string;
};
export type AllDocumentsWithMobile = {
  subtitle: string;
  title: string;
  body: body[];
  id: string;
};
