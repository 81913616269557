import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import http from "../../../utils/http";

type Payload = {
  patientId: string;
  appointmentDate: string;
  appointmentStartTime: string;
  isInitialAppointment: boolean | string;
  appointmentType: string;
}

export const useBookProviderAppointments = () => {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation({
    mutationKey: ['book provider appointment'],
    mutationFn: async ({ ...data }: Payload) => {
      const { patientId, ...theRest } = data;
      const response = await http.post(`appointments/patient?patientId=${patientId}`,
        {
          ...theRest
        })
      return response.data;
    },
    onMutate: () => {
      const toastId = toast.loading("Please wait...");
      return { toastId };
    },

    onSuccess: (_data, _variables, context) => {
      const { toastId } = context;
      toast.update(toastId, {
        render: "Appointment booked successfully",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });
      queryClient.invalidateQueries({ queryKey: ['provider upcoming appointments'] })
    },

    onError: (error, _variables, context) => {
      const toastId = context?.toastId as number;

      if (axios.isAxiosError(error)) {
        toast.update(toastId, {
          render: error?.response?.data?.error || error.response?.statusText,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        })
      } else {
        toast.update(toastId, {
          render: error?.message || "Error booking appointment",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        });
      }
    },
  });

  return {
    bookAppointmentsForProvider: mutate,
    ...rest,
  };
}