import { useFormik } from "formik";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { useSPRessetPassword } from "../../api/hooks/biller/auth/useSPResetPassword";
import Spacer from "../../components/Spacer";
import PasswordResetSuccess from "../../components/auth/PasswordResetSuccess";
import PasswordField from "../../components/form/PasswordField";
import Button from "../../components/shared/Button";
import { initialValues, validationSchema } from "../schema/update_biller_password";

const SPResetPassword = () => {
  const [screen, setScreen] = useState(0);
  const { updatePassword } = useSPRessetPassword({ setScreen });

  const params = useParams();
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: () => {
      updatePassword({
        password: formik.values?.password,
        userType: "biller",
        id: params?.id,
        token: params?.token
      });
    }
  });

  return (
    <>
      {screen === 0 ? (
        <div className="w-full box-border lg:w-[614px] bg-white">
          <div className="text-center">
            <p className="text-[34px] font-[800] text-[#103C1B]">
              Create new Password
            </p>
            <Spacer height={10} />
            <p className="font-[400] text-[20px] text-[#103C1B]">
              Enter a new and strong password.
            </p>
          </div>
          <Spacer height={40} />
          <div className="flex flex-col mx-auto items-center  w-full lg:w-[478px]">
            <PasswordField
              name="password"
              placeholder="At least 8 characters"
              value={formik.values.password}
              onChange={formik.handleChange}
              label="Password"
              showPasswordToggler={true}
              error={formik.touched.password ? formik.errors.password : ""}
            />

            <Spacer height={25} />

            <div className="w-[100%] flex justify-end items-center mt-8 text-xs text-normal">
              <div className="flex flex-end">
                <Button
                  additionalClassname={"text-[18px] font-[400]"}
                  width={98}
                  height={60}
                  type="submit"
                  variant="primary"
                  label="Create Password"
                  onClick={formik.handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <PasswordResetSuccess userType="biller" />
      )}
    </>
  );
};

export default SPResetPassword;
