import { JaaSMeeting } from "@jitsi/react-sdk";
import { ArrowForwardIos } from "@mui/icons-material";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import HealthTipCard from "../components/dashboard/HealthTipCard";
import MedicalCardOne from "../components/dashboard/MedicalCardOne";
import Button from "../components/shared/Button";

import { useAuthState } from "../api/hooks/patient/auth/useAuthState";
import { useGetPatientJitsiToken } from "../api/hooks/patient/usePatientJitsiToken";
import { getPatientAppointmentsList } from "../api/patients";
import DashboardLayout from "../components/dashboard/DashboardLayout";
import UpcomingEventCard from "../components/dashboard/UpcomingEventCard";
import { EmptyMsgIcon, GeneralMedIcon, MentalHealthIcon } from "../svgs";
import { AppointmentList } from "../types/appointmentList";

const Dashboard = () => {
  const { user } = useAuthState();
  const [appointmentsList, setAppointmentsList] = useState<AppointmentList[]>([]);
  const [joinCall, setJoinCall] = useState(false);
  const [jitsiToken, setJitsiToken] = useState<string>("");
  const [appointmentInfo, setAppointemntInfo] = useState<any | null>(null);

  const jaasAppId = process.env.REACT_APP_JAAS_APP_ID || "";

  const searchParams = useSearchParams()[0];
  const referrer = searchParams.get("referrer");

  const navigate = useNavigate();

  const fetchAppointmentsLists = async () => {
    const result = await getPatientAppointmentsList();
    const upcomingAppointments = result?.appointments?.filter(
      (item: any) => !item.didAppointmentHold
    );
    setAppointmentsList(upcomingAppointments);
  };

  useEffect(() => {
    if (referrer === "appointment") {
      navigate(
        "/dashboard/dependent/visit/schedule/landingpage?referrer=appointment"
      );
    }
    fetchAppointmentsLists();
  }, [navigate, referrer]);

  const { getJitsiToken, refetchGetJitsiToken } = useGetPatientJitsiToken(joinCall)

  useEffect(() => {
    if (getJitsiToken) {
      setJitsiToken(getJitsiToken);
    }
  }, [getJitsiToken]);

  const handleJoinCallClick = () => {
    setJoinCall(true)
  };

  useEffect(() => {
    if (appointmentsList && appointmentsList) {
      (appointmentsList || [])?.forEach(appointment => {
        const appointmentDate = new Date(appointment?.appointmentDate);
        const now = new Date();
        const timeDifference = appointmentDate.getTime() - now.getTime();
        const minutesDifference = timeDifference / (1000 * 60);
        // show toast if within 30 minutes
        if (minutesDifference <= 30) {
          toast('Your appointment is in less than 30 minutes!');
        }
      });
    }
  }, [appointmentsList]);

  useEffect(() => {
    if (joinCall) {
      refetchGetJitsiToken();
    }
  }, [joinCall, refetchGetJitsiToken]);

  return (
    <DashboardLayout>
      <div className="w-full px-2  mt-[20px] lg:mt-[30px]">
        <div className="w-full flex flex-col">
          <div className="flex lg:flex-nowrap flex-wrap w-full items-center justify-start">
            <div className="w-full">
              <MedicalCardOne
                icon={<GeneralMedIcon />}
                title="General Medicine"
                description="Get care for conditions like cold, cough,
            flu exposure, sore throat, minor injuries, pink eye, UTI and more."
                body={
                  <Button
                    width={350}
                    height={60}
                    onClick={() =>
                      navigate("/dashboard/dependent/visit/schedule", {
                        replace: true,
                        state: {
                          pageFrom: "/dashboard",
                          hasPreviousProvider: null
                        }
                      })
                    }
                    variant="primary"
                    label="Schedule an Appointment"
                  />
                }
              />
            </div>
            <div className="hidden lg:block w-4" />
            <div className="w-full mt-4 lg:mt-0">
              <MedicalCardOne
                icon={<MentalHealthIcon />}
                title="Mental Health"
                description="Speak to our licensed therapists & psychiatrists about addictions, anxiety, bipolar disorder, depression, grief, PTSD and more."
                body={
                  <Button
                    onClick={() =>
                      navigate("/dashboard/dependent/visit/schedule/health")
                    }
                    width={350}
                    height={60}
                    variant="primary"
                    label="Schedule an Appointment"
                  />
                }
                yellow={true}
              />
            </div>
            <div className="block lg:hidden h-2" />
            <div className="w-full lg:h-[50vh] flex flex-col items-start justify-start lg:ml-2">
              <div className="w-full flex justify-between items-center pb-4">
                <div className="text-[#324705] text-[28px] lg:text-[14px] font-[600]">
                  Upcoming events
                </div>
                <div className="flex justify-end items-center text-[#7E954B] text-[16px] font-[500]">
                  View all <ArrowForwardIos className="arrow" />
                </div>
              </div>
              {appointmentsList && appointmentsList?.length ? (
                appointmentsList?.map((eventItem) => (
                  <Fragment key={eventItem?.id}>
                    <UpcomingEventCard
                      handleJoinCallClick={() => {
                        handleJoinCallClick()
                        setAppointemntInfo(eventItem)
                      }}
                      name={`${eventItem?.firstName} ${eventItem?.lastName}`}
                      type={eventItem?.title}
                      title={
                        eventItem?.specialty === "psychiatrist"
                          ? "Psychiatrist"
                          : eventItem?.specialty === "pcp"
                            ? "Primary Care Physician"
                            : "Therapist"
                      }
                      startTime={eventItem?.appointmentStartTime}
                      endTime={eventItem?.appointmentEndTime}
                      appointmentDate={new Date(eventItem?.appointmentDate)}
                    />
                  </Fragment>
                ))
              ) : (
                <>
                  <div className="e-empty h-full w-full m-auto flex flex-col justify-center items-center rounded-[10px]">
                    <EmptyMsgIcon />
                    <div className="font-semibold text-[#34623f] mt-4 text-center">
                      Nothing here
                    </div>
                    <div className="text-[#103C1B] font-[400] pt-2 w-full text-center">
                      You don’t have any upcoming events 🙂
                    </div>
                  </div>
                </>
              )}

              {joinCall && (
                <JaaSMeeting
                  appId={jaasAppId}
                  roomName={`${appointmentInfo?.patientId}${user?.userId}`}
                  jwt={jitsiToken}
                  configOverwrite={{
                    disableThirdPartyRequests: true,
                    disableLocalVideoFlip: true,
                    backgroundAlpha: 0.5,
                    disableModeratorIndicator: true
                    // startWithAudioMuted: true,
                    // startScreenSharing: false,
                    // enableEmailInStats: false
                  }}
                  interfaceConfigOverwrite={{
                    VIDEO_LAYOUT_FIT: "nocrop",
                    MOBILE_APP_PROMO: false,
                    TILE_VIEW_MAX_COLUMNS: 4
                    // DISABLE_JOIN_LEAVE_NOTIFICATIONS: false
                    // SHOW_PROMOTIONAL_CLOSE_PAGE: true
                  }}
                  userInfo={{
                    displayName: `${user?.firstName} ${user?.lastName}`,
                    email: user?.email || ""
                  }}
                  onApiReady={(externalApi: any) => { }}
                  onReadyToClose={() => {
                    setJoinCall(false);
                  }}
                  getIFrameRef={(iframeRef: any) => {
                    iframeRef.style.height = "100%";
                    iframeRef.style.width = "60%";
                  }}
                />
              )}
            </div>
          </div>
          <div className="h-[24px]" />
          <div className="w-full">
            {/* TODO:  map health tips here when available */}
            <div className="w-full font-[600] text-[28px] text-[#103C1B] text-left flex justify-start ">
              Get more involved with your health
            </div>
            <div className="h-4" />
            <div className=" w-full  lg:w-[66.3%] flex flex-col items-start justify-start m-0 p-0">
              <HealthTipCard
                description="Why You Shouldn’t Worry About Ibuprofen And Heart Attacks."
                imageUrl="/assets/images/health-tip1.png"
              />
              <div className="h-4" />
              <HealthTipCard
                description="Eating Oats thrice a week can help lower your cholesterol level fast."
                imageUrl="/assets/images/health-tip2.png"
              />
              <div className="h-[40px]" />
            </div>
          </div>
        </div>
        <div className="h-[40px]" />
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
