export const ConfirmAppointmentIcon = ({
  height = 109,
  width = 113,
  className
}: {
  height?: number | string;
  width?: number | string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 113 109"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.833 41.1665H93.1663V86.6665C93.1663 89.0598 91.2263 90.9999 88.833 90.9999H15.1663C12.7731 90.9999 10.833 89.0598 10.833 86.6665V41.1665Z"
        fill="#34623F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.58301 41.1665C7.58301 39.3716 9.03808 37.9165 10.833 37.9165H93.1663C94.9613 37.9165 96.4163 39.3716 96.4163 41.1665V86.6665C96.4163 90.8548 93.0212 94.2499 88.833 94.2499H15.1663C10.9782 94.2499 7.58301 90.8548 7.58301 86.6665V41.1665ZM14.083 44.4165V86.6665C14.083 87.2649 14.568 87.7499 15.1663 87.7499H88.833C89.4314 87.7499 89.9163 87.2649 89.9163 86.6665V44.4165H14.083Z"
        fill="#34623F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1663 18.4165C14.568 18.4165 14.083 18.9016 14.083 19.4999V37.9165H89.9163V19.4999C89.9163 18.9015 89.4313 18.4165 88.833 18.4165H15.1663ZM7.58301 19.4999C7.58301 15.3117 10.9782 11.9165 15.1663 11.9165H88.833C93.0213 11.9165 96.4163 15.3118 96.4163 19.4999V41.1665C96.4163 42.9615 94.9613 44.4165 93.1663 44.4165H10.833C9.03808 44.4165 7.58301 42.9615 7.58301 41.1665V19.4999Z"
        fill="#34623F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.667 5.41699C36.4619 5.41699 37.917 6.87206 37.917 8.66699V26.0003C37.917 27.7952 36.4619 29.2503 34.667 29.2503C32.8721 29.2503 31.417 27.7952 31.417 26.0003V8.66699C31.417 6.87206 32.8721 5.41699 34.667 5.41699Z"
        fill="#34623F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.333 5.41699C71.1279 5.41699 72.583 6.87206 72.583 8.66699V26.0003C72.583 27.7952 71.1279 29.2503 69.333 29.2503C67.5381 29.2503 66.083 27.7952 66.083 26.0003V8.66699C66.083 6.87206 67.5381 5.41699 69.333 5.41699Z"
        fill="#34623F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.417 73.6665C57.417 71.8716 58.8721 70.4165 60.667 70.4165H73.667C75.4619 70.4165 76.917 71.8716 76.917 73.6665C76.917 75.4615 75.4619 76.9165 73.667 76.9165H60.667C58.8721 76.9165 57.417 75.4615 57.417 73.6665Z"
        fill="#D2E8D7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.083 73.6665C27.083 71.8716 28.5381 70.4165 30.333 70.4165H43.333C45.1279 70.4165 46.583 71.8716 46.583 73.6665C46.583 75.4615 45.1279 76.9165 43.333 76.9165H30.333C28.5381 76.9165 27.083 75.4615 27.083 73.6665Z"
        fill="#D2E8D7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.417 56.3335C57.417 54.5385 58.8721 53.0835 60.667 53.0835H73.667C75.4619 53.0835 76.917 54.5385 76.917 56.3335C76.917 58.1284 75.4619 59.5835 73.667 59.5835H60.667C58.8721 59.5835 57.417 58.1284 57.417 56.3335Z"
        fill="#D2E8D7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.083 56.3335C27.083 54.5385 28.5381 53.0835 30.333 53.0835H43.333C45.1279 53.0835 46.583 54.5385 46.583 56.3335C46.583 58.1284 45.1279 59.5835 43.333 59.5835H30.333C28.5381 59.5835 27.083 58.1284 27.083 56.3335Z"
        fill="#D2E8D7"
      />
      <path
        d="M112 84C112 97.2548 101.255 108 88 108C74.7452 108 64 97.2548 64 84C64 70.7452 74.7452 60 88 60C101.255 60 112 70.7452 112 84Z"
        fill="#34623F"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M101 74L85 92l-10-8"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
