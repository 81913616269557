export const PlusIcon = ({
  width = 22,
  height = 22,
  color = "#1A9D39"
}: {
  width?: number | string;
  height?: number | string;
  color?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0294 3.896C11.4091 3.89649 11.7165 4.2047 11.716 4.5844L11.6992 17.4177C11.6987 17.7974 11.3905 18.1048 11.0108 18.1043C10.6311 18.1038 10.3237 17.7956 10.3242 17.4159L10.341 4.5826C10.3415 4.2029 10.6497 3.8955 11.0294 3.896Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.89648 11C3.89648 10.6203 4.20429 10.3125 4.58398 10.3125H17.4173C17.797 10.3125 18.1048 10.6203 18.1048 11C18.1048 11.3797 17.797 11.6875 17.4173 11.6875H4.58398C4.20429 11.6875 3.89648 11.3797 3.89648 11Z"
        fill={color}
      />
    </svg>
  );
};
