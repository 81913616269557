import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { toast } from 'react-toastify';
import http from '../../../utils/http';

type Payload = {
  data: any;
}

export function useSaveProviderInsuranceCredentialing() {
  const queryClient = useQueryClient();
  const { mutate, data, variables, reset, ...rest } = useMutation({
    mutationKey: ['save Provider Insurance Credentialing'],
    mutationFn: async ({ data }: Payload) => {
      const response = await http.post("/credentialing/register", {
        ...data
      });
      return response.data;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['save Provider Insurance Credentialing'] });
    },

    onError: (error) => {
      if (axios.isAxiosError(error)) {
        toast.error(
          error?.response?.data?.error || error.response?.statusText,
        );
      } else {
        toast.error(
          error?.message
          || "Error creating template form",
        );
      }
    },
  });

  return {
    providerInsuranceCredential: mutate,
    providerInsuranceCredentialData: data,
    variables,
    ...rest,
  };
}
