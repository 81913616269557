export const CalendarDateIcon = ({
  width = 16,
  height = 17,
  color = "#87928A",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
			className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.16602 6.83398C1.16602 6.55784 1.38987 6.33398 1.66602 6.33398H14.3327C14.6088 6.33398 14.8327 6.55784 14.8327 6.83398V13.834C14.8327 14.4783 14.3104 15.0007 13.666 15.0007H2.33268C1.68835 15.0007 1.16602 14.4783 1.16602 13.834V6.83398ZM2.16602 7.33398V13.834C2.16602 13.926 2.24063 14.0007 2.33268 14.0007H13.666C13.7581 14.0007 13.8327 13.926 13.8327 13.834V7.33398H2.16602Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.33268 3.33398C2.24063 3.33398 2.16602 3.4086 2.16602 3.50065V6.33398H13.8327V3.50065C13.8327 3.4086 13.7581 3.33398 13.666 3.33398H2.33268ZM1.16602 3.50065C1.16602 2.85632 1.68835 2.33398 2.33268 2.33398H13.666C14.3104 2.33398 14.8327 2.85632 14.8327 3.50065V6.83398C14.8327 7.11013 14.6088 7.33398 14.3327 7.33398H1.66602C1.38987 7.33398 1.16602 7.11013 1.16602 6.83398V3.50065Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33398 1.33398C5.61013 1.33398 5.83398 1.55784 5.83398 1.83398V4.50065C5.83398 4.77679 5.61013 5.00065 5.33398 5.00065C5.05784 5.00065 4.83398 4.77679 4.83398 4.50065V1.83398C4.83398 1.55784 5.05784 1.33398 5.33398 1.33398Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.666 1.33398C10.9422 1.33398 11.166 1.55784 11.166 1.83398V4.50065C11.166 4.77679 10.9422 5.00065 10.666 5.00065C10.3899 5.00065 10.166 4.77679 10.166 4.50065V1.83398C10.166 1.55784 10.3899 1.33398 10.666 1.33398Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.83398 11.834C8.83398 11.5578 9.05784 11.334 9.33398 11.334H11.334C11.6101 11.334 11.834 11.5578 11.834 11.834C11.834 12.1101 11.6101 12.334 11.334 12.334H9.33398C9.05784 12.334 8.83398 12.1101 8.83398 11.834Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16602 11.834C4.16602 11.5578 4.38987 11.334 4.66602 11.334H6.66602C6.94216 11.334 7.16602 11.5578 7.16602 11.834C7.16602 12.1101 6.94216 12.334 6.66602 12.334H4.66602C4.38987 12.334 4.16602 12.1101 4.16602 11.834Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.83398 9.16602C8.83398 8.88987 9.05784 8.66602 9.33398 8.66602H11.334C11.6101 8.66602 11.834 8.88987 11.834 9.16602C11.834 9.44216 11.6101 9.66602 11.334 9.66602H9.33398C9.05784 9.66602 8.83398 9.44216 8.83398 9.16602Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16602 9.16602C4.16602 8.88987 4.38987 8.66602 4.66602 8.66602H6.66602C6.94216 8.66602 7.16602 8.88987 7.16602 9.16602C7.16602 9.44216 6.94216 9.66602 6.66602 9.66602H4.66602C4.38987 9.66602 4.16602 9.44216 4.16602 9.16602Z"
        fill={color}
      />
    </svg>
  );
};
