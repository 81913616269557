import React, { useState } from "react";
import TextField from "../../../components/form/TextField";
import Button from "../../../components/shared/Button";
import ModalPortal from "../../../components/shared/ModalPortal";
import { CancelIcon } from "../../../svgs/CancelIcon";
import { PlusIcon } from "../../../svgs/PlusIcon";

interface IProps {
  saveAction: (options: string[], id: string | number) => void;
  closeModal: () => void;
  title: string;
  questionId: string;
  options: string[];
}

const TextInput = (
  fieldListCount: number,
  newOptions: string[],
  optionItem: number,
  setNewOptions: (param: string[]) => void
) => (
  <div key={fieldListCount} className="w-full my-2">
    <TextField
      name="option"
      type="text"
      placeholder=""
      label={`Option ${fieldListCount + 1}`}
      value={newOptions[optionItem]}
      onChange={e => {
        const options = [...newOptions];
        options[fieldListCount] = e.target.value;
        setNewOptions(options);
      }}
    />
  </div>
);

const AddQuestionOptionsModal: React.FC<IProps> = ({
  saveAction,
  closeModal,
  title,
  questionId,
  options
}) => {
  const [fieldListCount, setFieldListCount] = useState<number>(
    options.length ? options.length : 1
  );
  const [newOptions, setNewOptions] = useState<string[]>(options);
  const [newInputField, setNewInputField] = useState<any[]>([]);

  return (
    <ModalPortal>
      <div
        style={{
          backgroundColor: "rgba(38, 38, 38, 0.75)",
          zIndex: 100,
          display: "flex",
          justifyContent: "center"
        }}
        className="w-screen h-screen fixed flex flex-col justify-center items-center top-0 bg-opacity-20 backdrop-blur-[5px]"
      >
        <div className="flex justify-end  w-[90%] lg:w-[462px] mx-auto py-2">
          <div
            onClick={() => {
              closeModal();
            }}
            className="p-2 bg-white rounded-full cursor-pointer"
          >
            <CancelIcon className="h-full w-full" />
          </div>
        </div>
        <div className="bg-white min-h-[409px] w-[90%] lg:w-[462px] px-4 pt-5 pb-4 lg:p-6 lg:pb-4 shadow-lg rounded-[16px] border-box">
          <p className="text-[#2E3011] text-[24px] font-[600]">{title}</p>
          {options?.length ? (
            <>
              {options.map((optionItem, idx) => (
                <div key={idx} className="w-full my-2">
                  <TextField
                    name="option"
                    type="text"
                    placeholder=""
                    label={`Option ${idx + 1}`}
                    value={newOptions[idx]}
                    onChange={e => {
                      const options = [...newOptions];
                      setNewOptions(
                        options.map((option, index) =>
                          index === idx ? e.target.value : option
                        )
                      );
                    }}
                  />
                </div>
              ))}
            </>
          ) : (
            <div>
              <div className="w-full my-2">
                <TextField
                  name="option"
                  type="text"
                  placeholder=""
                  label="Option 1"
                  value={newOptions[newOptions.length]}
                  onChange={e => {
                    const options = [...newOptions];
                    options[0] = e.target.value;
                    setNewOptions(options);
                  }}
                />
              </div>
            </div>
          )}
          <div>
            {newInputField}
            <div
              className="cursor-pointer flex flex-row pb-4 my-4"
              onClick={() => {
                setFieldListCount(fieldListCount + 1);
                setNewInputField([
                  ...newInputField,
                  TextInput(
                    fieldListCount,
                    newOptions,
                    newOptions.length,
                    setNewOptions
                  )
                ]);
              }}
            >
              <PlusIcon />
              <span className="text-[#1A9D39] text-[16px] ml-3">
                Add another option
              </span>
            </div>
          </div>
          <div className="w-full flex justify-end mt-4">
            <Button
              type="button"
              variant="primary"
              label="Save"
              onClick={() => saveAction(newOptions, questionId)}
              size="small"
            />
          </div>
        </div>
      </div>
    </ModalPortal>
  );
};

export default AddQuestionOptionsModal;
