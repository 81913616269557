import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import http from "../../../utils/http";


export const useDeleteEmergencyContact = () => {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation({
    mutationKey: ['delete emergency contact'],
    mutationFn: async ({ contactId }: { contactId: string }) => {
      const response = await http.delete(`emergency-contacts/contact/${contactId}`);
      return response.data;
    },
    onMutate: () => {
      const toastId = toast.loading("Please wait...");
      return { toastId };
    },
    onSuccess: (_data, _variables, context) => {
      const { toastId } = context;
      toast.update(toastId, {
        render: "Emergency contact deleted successfully",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      })
      queryClient.invalidateQueries({ queryKey: ['patient emergency contacts'] });
    },

    onError: (error, _variables, context) => {
      const toastId = context?.toastId as number

      if (axios.isAxiosError(error)) {
        toast.update(toastId, {
          render: error?.response?.data?.error || "Error deleting emergency contact",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        })

      } else {
        toast.update(toastId, {
          render: error?.message || "Error deleting emergency contact",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        });

      }
    }
  });

  return {
    deleteEmergencyContact: mutate,
    ...rest,
  };
}
