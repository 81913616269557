export const PatientSidebarIcon = ({
  width = 16,
  height = 15,
  color = "#103C1B",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      className={className}
      height={height}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.66089 8.18122C9.76544 8.18122 10.6609 9.09693 10.6609 10.2265L10.6598 11.2469C10.797 13.7512 8.91095 15 5.41349 15C1.92732 15 0 13.7675 0 11.2827V10.2265C0 9.09693 0.895414 8.18122 1.99996 8.18122H8.66089ZM13.9949 8.18122C15.0995 8.18122 15.9949 9.09693 15.9949 10.2265L15.9939 10.9445C16.1144 13.1909 14.448 14.3171 11.4019 14.3171C10.9884 14.3171 10.5997 14.2965 10.2367 14.2553C10.6242 13.8907 10.9129 13.4555 11.0968 12.95L11.4019 12.9536C13.7808 12.9536 14.7319 12.3108 14.6616 10.9819V10.2265C14.6616 9.84999 14.3631 9.54475 13.9949 9.54475L11.2435 9.54473C11.1085 9.00843 10.818 8.53598 10.4241 8.18065L13.9949 8.18122ZM8.66089 9.54475H1.99996C1.63178 9.54475 1.33331 9.84999 1.33331 10.2265V11.2827C1.33331 12.8453 2.57054 13.6365 5.41349 13.6365C8.24514 13.6365 9.41293 12.8632 9.32754 11.2851V10.2265C9.32754 9.84999 9.02907 9.54475 8.66089 9.54475ZM5.33323 0C7.17445 0 8.66705 1.52644 8.66705 3.40939C8.66705 5.29235 7.17445 6.81878 5.33323 6.81878C3.49202 6.81878 1.99942 5.29235 1.99942 3.40939C1.99942 1.52644 3.49202 0 5.33323 0ZM11.9998 1.36354C13.4725 1.36354 14.6664 2.58449 14.6664 4.09061C14.6664 5.59673 13.4725 6.81768 11.9998 6.81768C10.527 6.81768 9.33316 5.59673 9.33316 4.09061C9.33316 2.58449 10.527 1.36354 11.9998 1.36354ZM5.33323 1.36354C4.22839 1.36354 3.33273 2.2795 3.33273 3.40939C3.33273 4.53929 4.22839 5.45525 5.33323 5.45525C6.43808 5.45525 7.33374 4.53929 7.33374 3.40939C7.33374 2.2795 6.43808 1.36354 5.33323 1.36354ZM11.9998 2.72707C11.2634 2.72707 10.6665 3.33755 10.6665 4.09061C10.6665 4.84367 11.2634 5.45414 11.9998 5.45414C12.7361 5.45414 13.3331 4.84367 13.3331 4.09061C13.3331 3.33755 12.7361 2.72707 11.9998 2.72707Z"
        fill={color}
      />
    </svg>
  );
};
