import { useEffect } from 'react';
import SpeechRecognition from 'react-speech-recognition';

type Props = {
  field: string;
  activeVoiceField: string | null;
  onVoiceStart: (field: string) => void;
  onVoiceStop: () => void;
  onTranscriptChange: (field: string, transcript: string) => void;
  transcript: string;
  listening: boolean;
  resetTranscript: () => void;
  browserSupportsSpeechRecognition: boolean;
}

export function VoiceNote({
  field,
  activeVoiceField,
  onVoiceStart,
  onVoiceStop,
  onTranscriptChange,
  transcript,
  listening,
  resetTranscript,
  browserSupportsSpeechRecognition
}: Props) {

  useEffect(() => {
    if (listening && activeVoiceField === field) {
      onTranscriptChange(field, transcript);
    }
  }, [transcript, listening, activeVoiceField, field, onTranscriptChange]);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const startSpeech = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    resetTranscript();
    onVoiceStart(field);
    SpeechRecognition.startListening({ continuous: true });
  }

  const stopSpeech = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    SpeechRecognition.stopListening();
    onVoiceStop();
  }

  const onResetTranscript = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    resetTranscript();
  }

  return (
    <div className='flex justify-start items-start gap-3'>
      <p className="text-base">Microphone: {listening && activeVoiceField === field ? <span className="text-green-500">on</span> : <span className="text-red-500">off</span>}</p>
      <button className="text-base" onClick={startSpeech} >Start speaking</button>
      <button className="text-base" onClick={stopSpeech}>Stop</button>
      <button className="text-base" onClick={onResetTranscript}>Reset</button>
    </div>
  );
}

