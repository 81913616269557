import React from "react";
import MessagesListItem from "./MessageListItem";
import { Socket } from "socket.io-client";

interface IProps {
  list: any[];
  expandMessage: (arg: any) => void;
  socket: Socket;
  recentMessages: any[];
  selectedChat: any;
  setRecentMessages: any;
}

const MessagesList: React.FC<IProps> = ({
  list,
  expandMessage,
  socket,
  recentMessages,
  selectedChat,
  setRecentMessages
}) => {
  return (
    <div className="flex flex-col justify-start w-full lg:w-[330px] lg:h-full border-[1px] border-solid border-[#EBEBEB] rounded-lg lg:border-none px-0 py-2">
      {list && list?.length ? (
        list?.map(listItem => (
          <MessagesListItem
            socket={socket}
            key={listItem.userId}
            expandMessage={expandMessage}
            imageUrl={listItem.avatarUrl}
            providerName={
              listItem.name
                ? listItem.name
                : `${listItem.firstName} ${listItem.lastName}`
            }
            isSelected={selectedChat?.userId === listItem.userId}
            receiverDetails={listItem}
            recentMessages={recentMessages}
            setRecentMessages={setRecentMessages}
            selectedChat={selectedChat}
          />
        ))
      ) : (
        <></>
      )}
    </div>
  );
};

export default MessagesList;
