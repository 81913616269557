import { useFormik } from "formik";
import { useState } from "react";
import { Link } from "react-router-dom";
import { usePostBillerLogin } from "../../api/hooks/biller/usePostBillerLogin";
import Spacer from "../../components/Spacer";
import PasswordField from "../../components/form/PasswordField";
import TextField from "../../components/form/TextField";
import Button from "../../components/shared/Button";
import { billerInitialValues, billerLoginSchema } from "../schema/biller.schema";

const SPLoginForm = () => {
  localStorage.clear();
  const [loginError, setLoginError] = useState("");
  const { billerLogin, isPending: isLoading } = usePostBillerLogin({ setLoginError })

  const { values, errors, submitForm, touched, handleChange } = useFormik({
    initialValues: billerInitialValues,
    validationSchema: billerLoginSchema,
    onSubmit: async () => {
      billerLogin({
        email: values.email,
        password: values.password
      });
    }
  });

  return (
    <div className="w-full box-border lg:w-[614px] bg-white">
      <div className="text-center">
        <p className="text-[34px] font-[800] text-[#103C1B]">
          Sign in to your account
        </p>
      </div>
      <Spacer height={40} />
      <div className="flex flex-col mx-auto items-center w-full relative">
        {loginError?.length > 0 && (
          <p className="text-[18px] font-[700] text-red-500 font-[gentiumBasic] mb-4">
            Login failed: {loginError}
          </p>
        )}
        <form className="relative flex flex-col items-center w-full lg:w-[478px]">
          <TextField
            name="email"
            type="email"
            placeholder="name@mail.com"
            value={values.email}
            onChange={handleChange}
            label="Email address"
            error={touched.email ? errors.email : ""}
          />

          <Spacer height={25} />

          <PasswordField
            name="password"
            placeholder="At least 8 characters"
            value={values.password}
            onChange={handleChange}
            label="Password"
            showPasswordToggler={true}
            error={touched.password ? errors.password : ""}
          />
          <div className="w-[100%] flex justify-between items-center mt-8 text-xs text-normal">
            <Link
              to="/biller/forgot-password"
              className="text-[18px] font-[400] flex flex-start text-[#1A9D39]"
            >
              Forgot password?
            </Link>
            <div className="flex flex-end">
              <div className="w-[137px]">
                <Button
                  onClick={submitForm}
                  additionalClassname={
                    "text-[18px] font-[400] w-[108px] relative"
                  }
                  width={108}
                  height={60}
                  variant="primary"
                  label={isLoading ? "Signing In..." : "Sign in"}
                  disabled={isLoading}
                  size="medium"
                />
              </div>
            </div>
          </div>
          <div className="lg:h-[80px] h-[40px]" />
          <div className="border-[1px] border-gray-200 w-[300px]" />
          <div className="lg:h-[80px] h-[40px]" />
          <p className="text-center font-[400]">
            Are you a new member?{" "}
            <span className="text-[#1A9D39]">
              {" "}
              <Link to="/biller/signup"> Create an account</Link>
            </span>
          </p>
          <div className="lg:h-[20px] hidden lg:block" />
        </form>
      </div>
    </div>
  );
};

export default SPLoginForm;
