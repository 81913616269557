import axios from "axios";
import { format } from "date-fns";
import React, { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { Column } from "react-table";
import { toast } from "react-toastify";
import { useGetAdminDashboard } from "../../api/hooks/admin/get_admin_dashboard";
import ReusableTable from "../../components/ReusableTable";
import BtnButton from "../../components/shared/BtnButton";
import { Loading } from "../../components/shared/Loading";
import { ArrowBackIcon } from "../../svgs/ArrowBackIcon";
import AdminDashboardLayout from "../components/AdminDashboardLayout";
import AdminDataInfoCard from "../components/AdminDataInfoCard";

interface ServiceProviderType {
  name: string;
  providers: string[];
  join_date: Date;
  id: number;
}

interface ProvidersType {
  join_date: Date;
  name: string;
  specialty: string;
  created_at: Date;
  title: number;
  id: number;
}

const ProviderListEl = ({ providersList }: { providersList: string[] }) => (
  <div className="flex items-center">
    <p>{`${providersList}...`}</p>
    <b>{`(${providersList.length})`}</b>
  </div>
);

const AdminServiceProviders = () => {
  const [isServiceProviderDetailsView, setIsServiceProviderDetailsView] =
    useState<boolean>(false);

  const SERVICE_PROVIDERS_TABLE_COLUMNS = useMemo<
    Column<ServiceProviderType>[]
  >(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ cell: { value } }) => {
          return <React.Fragment>{value}</React.Fragment>;
        }
      },
      {
        Header: "Providers",
        accessor: "providers",
        Cell: ({ cell: { value }, row }) => {
          return (
            <React.Fragment>
              <ProviderListEl providersList={value} />
            </React.Fragment>
          );
        }
      },
      {
        Header: "Join Date",
        accessor: "join_date",
        Cell: ({ cell: { value }, row }) => {
          return (
            <React.Fragment>
              {value ? format(new Date(value), "MM/dd/yyyy") : "N/A"}
            </React.Fragment>
          );
        }
      }
    ],
    []
  );

  const PROVIDERS_TABLE_COLUMNS = useMemo<Column<ProvidersType>[]>(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ cell: { value } }) => {
          return <React.Fragment>{value}</React.Fragment>;
        }
      },
      {
        Header: "Specialty",
        accessor: "specialty",
        Cell: ({ cell: { value }, row }) => {
          return <React.Fragment>{value}</React.Fragment>;
        }
      },
      {
        Header: "Title",
        accessor: "title"
      },
      {
        Header: "Join Date",
        accessor: "join_date",
        Cell: ({ cell: { value }, row }) => {
          return (
            <React.Fragment>
              {value ? format(new Date(value), "MM/dd/yyyy") : "N/A"}
            </React.Fragment>
          );
        }
      }
    ],
    []
  );

  const { adminOverView, isLoading, isError, error } = useGetAdminDashboard()

  if (isError) {
    const message = axios.isAxiosError(error) ? error?.response?.data?.error : "Error";
    toast.error(message, { toastId: "customId" })
  }

  return (
    <AdminDashboardLayout
      headerTitle={
        isServiceProviderDetailsView ? "Tru-Valu Drugs" : "Service Providers"
      }
    >
      {isServiceProviderDetailsView ? (
        <div className="w-full h-full px-4 mt-8">
          <div className="w-full mb-6">
            <div
              onClick={() => setIsServiceProviderDetailsView(false)}
              className="cursor-pointer pl-4 flex justify-start items-center"
            >
              <ArrowBackIcon />{" "}
              <div className="ml-3">Go back to Service Providers</div>
            </div>
          </div>
          <div className="my-6 mx-4">
            <div className="flex items-center w-full">
              <div className="w-[374px]">
                <AdminDataInfoCard
                  title="Total no. of Providers"
                  dataCount={adminOverView?.providers?.allProviders?.count || 0}
                  green
                />
              </div>
            </div>
            <div className="w-full flex items-center justify-between mt-8 mb-4 px-4">
              <div className="text-[24px] font-[600] text-[#324705]">
                All Providers
              </div>
              <div>
                <CSVLink
                  data={[]}//TODO: add live data here
                  filename="admin-providers.csv"
                  uFEFF={true}
                >
                  <BtnButton variant="secondary" label="Export" size="small" />
                </CSVLink>
              </div>
            </div>
            <div className="w-full mt-8">
              <ReusableTable
                tableColumns={PROVIDERS_TABLE_COLUMNS}
                tableData={[]} //TODO: add live data here
                onClick={data => {
                  setIsServiceProviderDetailsView(true);
                }}
                pageSizeCount={10}
                searchFieldPlaceholder="Search Providers"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-full px-4 mt-8">
          <div className="flex items-center w-full">
            <div className="w-[374px]">
                {isLoading ? (<div className="flex justify-center items-center h-1/2">
                  <Loading />
                </div>) : <AdminDataInfoCard
                  title="Total no. of Service Providers"
                    dataCount={adminOverView?.serviceProviders?.allServiceProviders || 0}
                    green
                />}

            </div>
          </div>
          <div className="w-full flex items-center justify-between mt-8 mb-4 px-4">
            <div className="text-[24px] font-[600] text-[#324705]">
              All Service Providers
            </div>
            <div>
              <CSVLink
                  data={[]}//TODO: add live data here
                filename="service-providers-list.csv"
                uFEFF={true}
              >
                <BtnButton variant="secondary" label="Export" size="small" />
              </CSVLink>
            </div>
          </div>
          <div className="w-full mt-8">
            <ReusableTable
              tableColumns={SERVICE_PROVIDERS_TABLE_COLUMNS}
                tableData={[]} //TODO: add live data here
              onClick={data => {
                setIsServiceProviderDetailsView(true);
              }}
              pageSizeCount={10}
              searchFieldPlaceholder="Search Service Providers"
            />
          </div>
        </div>
      )}
    </AdminDashboardLayout>
  );
};

export default AdminServiceProviders;
