import { differenceInYears } from "date-fns";
import * as Yup from "yup";

export const signupFormOneSchema = Yup.object({
  email: Yup.string().email("Invalid Email").required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,40}$/,
      "Password must have at least one uppercase, one lowercase, one number, and be at least 8 characters long"
    ),
  dateOfBirth: Yup.date()
    .required("Date of birth is required")
    .test("DOB", "Patient must be older than 18 to register", value => {
      return value
        ? differenceInYears(new Date(), new Date(value)) >= 16
        : false;
    })
});

export const signupFormOneinitialValues = {
  email: "",
  password: "",
  dateOfBirth: new Date()
};
