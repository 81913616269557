import clsx from "clsx";
import { useFormik } from "formik";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useUpdateBillerProfileDetails } from "../../api/hooks/biller/useUpdateBillerProfileDetails";
import RadioField from "../../components/form/RadioField";
import TextField from "../../components/form/TextField";
import Button from "../../components/shared/Button";
import { SPCheckWhite } from "../../svgs/SPCheckWhite";

const SPMainRegistrationPage = () => {
  const [step, setStep] = useState(1);

  const navigate = useNavigate();

  const formik: any = useFormik({
    initialValues: {
      aapcLicenseNumber: "",
      cpbLicenseNumber: "",
      aapcLicenseExpiry: null,
      cpbLicenseExpiry: null,
      address: "",
      paymentType: ""
    },
    validationSchema: Yup.object({
      aapcLicenseNumber: Yup.string(),
      cpbLicenseNumber: Yup.string(),
      aapcLicenseExpiry: Yup.string(),
      cpbLicenseExpiry: Yup.string(),
      address: Yup.string(),
      paymentType: Yup.string()
    }),
    onSubmit: async () => { }
  });

  const { updateBillerProfile, isSuccess } = useUpdateBillerProfileDetails()


  return (
    <div className="overflow-hidden w-[100%] h-[100vh]">
      <div className="flex">
        <div className="flex flex-col items-center lg:w-[18%] w-full px-4 overflow-scroll h-[100vh] bg-[#243C29] text-white">
          <div className="text-[24px] font-[600] text-white mt-[100px]">
            TempleHS Billing
          </div>
          <div className="text-[16px] font-[500] mt-[170px]">
            <div className="flex items-center my-4 w-full text-white">
              <SPCheckWhite /> <div className="w-4" /> Licence
            </div>
            <div
              className={clsx({
                "flex items-center my-4 w-full": true,
                "text-[#646557]": step < 2,
                "text-white": step > 1
              })}
            >
              <SPCheckWhite
                color={step === 2 || step === 3 ? "white" : "#646557"}
              />{" "}
              <div className="w-4" /> Address
            </div>
            <div
              className={clsx({
                "flex items-center my-4 w-full": true,
                "text-[#646557]": step !== 3,
                "text-white": step === 3
              })}
            >
              <SPCheckWhite color={step === 3 ? "white" : "#646557"} />{" "}
              <div className="w-4" /> Payment Information
            </div>
          </div>
        </div>
        <div className="bg-white w-[80%] h-[100vh] flex justify-center mt-0 items-center">
          {step === 1 && (
            <div className="text-center">
              <div className="text-[34px] font-[800] text-[#103C1B] mb-6">
                Are you licensed?
              </div>
              <p className="text-[#103C1B] text-[20px] font-[400]">
                This is to verify your knowledge & skill in medical coding &
                billing
              </p>

              <div className="mt-8">
                <form>
                  <div className="w-full flex flex-row items-center justify-around my-4">
                    <div className="">
                      <TextField
                        name="aapcLicenseNumber"
                        type="text"
                        placeholder="AAPC license"
                        value={formik.values?.aapcLicenseNumber}
                        onChange={formik.handleChange}
                        label="AAPC license"
                        error={
                          formik.touched.aapcLicenseNumber
                            ? formik.errors.aapcLicenseNumber
                            : ""
                        }
                      />
                    </div>
                    <div className="">
                      <div className="text-[18px] font-[500] text-left">
                        Expiry Date
                      </div>
                      <div className="flex flex-row items-center justify-end">
                        <div className="w-full bg-white border border-[#CECFC5]">
                          <ReactDatePicker
                            selected={formik.values?.aapcLicenseExpiry}
                            name="aapcLicenseExpiry"
                            dateFormat="MM/dd/yyyy"
                            onChange={(date: Date) => {
                              formik.setFieldValue("aapcLicenseExpiry", date);
                            }}
                            placeholderText="MM/DD/YYYY"
                            className="date-picker"
                            shouldCloseOnSelect={true}
                            showYearDropdown={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex flex-row items-center justify-around my-4">
                    <div className="">
                      <TextField
                        name="cpbLicenseNumber"
                        type="text"
                        placeholder="CPB license"
                        value={formik.values?.cpbLicenseNumber}
                        onChange={formik.handleChange}
                        label="CPB license"
                        error={
                          formik.touched.cpbLicenseNumber
                            ? formik.errors.cpbLicenseNumber
                            : ""
                        }
                      />
                    </div>
                    <div className="">
                      <div className="text-[18px] font-[500] text-left">
                        Expiry Date
                      </div>
                      <div className="flex flex-row items-center justify-end">
                        <div className="w-full bg-white border border-[#CECFC5]">
                          <ReactDatePicker
                            selected={formik.values?.cpbLicenseExpiry}
                            name="cpbLicenseExpiry"
                            dateFormat="MM/dd/yyyy"
                            onChange={(date: Date) => {
                              formik.setFieldValue("cpbLicenseExpiry", date);
                            }}
                            placeholderText="MM/DD/YYYY"
                            className="date-picker"
                            shouldCloseOnSelect={true}
                            showYearDropdown={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex justify-end items-center mt-8">
                    <p
                      className="cursor-pointer"
                      onClick={() => {
                        setStep(2);
                      }}
                    >
                      Skip
                    </p>{" "}
                    <div className="w-6" />
                    <div className="w-[121px]">
                      <Button
                        height={60}
                        onClick={() => {
                          setStep(2);
                        }}
                        variant="primary"
                        label="Next"
                        size="medium"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="text-center">
              <div className="w-[500px] text-[34px] font-[800] text-[#103C1B] mb-6">
                Provide your address
              </div>
              <form>
                <div className="w-full flex flex-row items-center justify-around my-4">
                  <div className="w-full">
                    <TextField
                      name="address"
                      type="text"
                      placeholder="Enter your address"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      label="Address"
                      error={
                        formik.touched.address ? formik.errors.address : ""
                      }
                    />
                  </div>
                </div>
                <div className="w-full flex justify-between items-center mt-8">
                  <div className="w-[121px]">
                    <Button
                      height={60}
                      onClick={() => {
                        setStep(1);
                      }}
                      variant="secondary"
                      label="Back"
                      size="medium"
                    />
                  </div>
                  <div className="w-[121px]">
                    <Button
                      height={60}
                      onClick={() => {
                        setStep(3);
                      }}
                      variant="primary"
                      label="Next"
                      size="medium"
                    />
                  </div>
                </div>
              </form>
            </div>
          )}
          {step === 3 && (
            <div className="text-center w-[500px]">
              <div className="text-[34px] font-[800] text-[#103C1B] mb-6">
                Provide your payment details
              </div>
              <p className="text-[#103C1B] text-[20px] font-[400]">
                Providing your payment information is a crucial step in getting
                paid for your services
              </p>
              <form>
                <div className="my-4 ">
                  <div className="my-6">
                    <div className="text-[18px] font-[500] text-left">
                      Payment method
                    </div>
                    <RadioField
                      name="paymentType"
                      placeholder="Payment Method"
                      display="inline"
                      options={[
                        { item: "Direct Deposit", value: "directDeposit" },
                        { item: "Wire transfer", value: "wireTransfer" },
                        { item: "Check", value: "check" }
                      ]}
                      hasOther={true}
                      isRequired={true}
                      value={formik.values.paymentType}
                      onChange={formik.handleChange}
                    />
                  </div>

                  <div className="my-6">
                    <TextField
                      name="accountHoldersName"
                      type="text"
                      placeholder="Account holder's name"
                      value=""
                      onChange={() => { }}
                      label="Account holder's name"
                    />
                  </div>

                  <div className="my-6">
                    <TextField
                      name="accountNumber"
                      type="text"
                      placeholder="Account number"
                      value=""
                      onChange={() => { }}
                      label="Account Number"
                    />
                  </div>

                  <div className="my-6">
                    <TextField
                      name="routingNumber"
                      type="text"
                      placeholder="Routing number"
                      value=""
                      onChange={() => { }}
                      label="Routing Number"
                    />
                  </div>

                  <div className="my-6">
                    <TextField
                      name="emailAddress"
                      type="email"
                      placeholder="Email address"
                      value=""
                      onChange={() => { }}
                      label="Email Address"
                    />
                  </div>
                </div>
                <div className="w-full flex justify-between items-center mt-8">
                  <div className="w-[121px]">
                    <Button
                      height={60}
                      onClick={() => {
                        setStep(2);
                      }}
                      variant="secondary"
                      label="Back"
                      size="medium"
                    />
                  </div>
                  <div className="w-[121px]">
                    <Button
                      height={60}
                      onClick={async () => {
                        Object.keys(formik.values).forEach(key => {
                          if (
                            formik.values[key] === "" ||
                            formik.values[key] === null
                          ) {
                            delete formik.values[key];
                          }
                        });
                        updateBillerProfile({
                          aapcLicenseNumber: formik.values?.aapcLicenseNumber,
                          aapcLicenseExpiry: formik.values?.aapcLicenseExpiry,
                          cpbLicenseExpiry: formik.values?.cpbLicenseExpiry,
                          cpbLicenseNumber: formik.values?.cpbLicenseNumber,
                          address: formik.values?.address,
                          paymentMethod: formik.values?.paymentType
                        });
                        if (isSuccess) {
                          navigate("/biller/dashboard");
                        }
                      }}
                      variant="primary"
                      label="Submit"
                      size="medium"
                    />
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
      <div className="lg:h-[20px] hidden lg:block" />
    </div>
  );
};

export default SPMainRegistrationPage;
