type Props = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>;
};

export const removeRecords = ({ setIsLoading, setIsSuccess }: Props) => {
  localStorage.removeItem("practiceReg");
  localStorage.removeItem("credentialingReg");
  // setIsLoading(false);
  // setIsSuccess(false);
};
