
import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthState } from '../../api/hooks/patient/auth/useAuthState';

export const RequireAuth = ({
	children,
}: {
	children: ReactNode;
}) => {
	const { pathname } = useLocation();
	const { isAuthenticated } = useAuthState();
	const authenticated = isAuthenticated();

	if (!authenticated) {
		return (
			<>
				<Navigate to='/patient/login' state={{ from: pathname }} replace />
			</>
		);
	}

	return <>{children}</>;
};
