import * as Yup from "yup";

export const initialValues = {
  password: ""
};
export const validationSchema = Yup.object({
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,40}$/,
      "Password must have at least one uppercase, one lowercase, one number, and be at least 8 characters long"
    )
});
