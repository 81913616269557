import { useQuery } from '@tanstack/react-query';
import billerHttp from '../../../utils/billerHttp';

export function useGeEraList() {
  const { data, isLoading, ...rest } = useQuery({
    queryKey: ["get eraList"],
    queryFn: async () => {
      const response = await billerHttp.get(`billers/eralist`);
      return response.data;
    },
    staleTime: Infinity,
  });
  return {
    eraList: data || [],
    isLoading,
    ...rest,
  }
}