import jwtDecode from "jwt-decode";
import { setAdminToken } from "../utils/adminHttp";

export const getCurrentAdminUser = () => {
  const token = localStorage.getItem("temple_admin_token");
  if (token) {
    setAdminToken(token);

    const response: any = jwtDecode(token);
    return response.data;
  }
};
