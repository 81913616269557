import { Skeleton } from "@mui/material";
import axios from "axios";
import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useGetAdminProviderDetails } from "../../api/hooks/admin/get_admin_provider_list";
import AdminHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";

interface AdminDashboardLayoutProps {
  children: React.ReactNode;
  headerTitle?: string;
  pageName?: string;
  loggedIn?: boolean;
  overflowStyle?: string;
  defaultLeftMargin?: boolean;
  rescroll?: () => void;
  reff?: any;
}

const AdminDashboardLayout: React.FC<AdminDashboardLayoutProps> = ({
  children,
  headerTitle,
  pageName,
  loggedIn,
  overflowStyle = "auto",
  defaultLeftMargin = false,
  rescroll,
  reff
}) => {

  const pageTop = useRef<null | HTMLDivElement>(null);

  const scrollToTop = () => {
    pageTop.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest"
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const { data, isLoading, isError, error } = useGetAdminProviderDetails()

  if (isError) {
    const message = axios.isAxiosError(error) ? error?.response?.data?.error : "Error";
    toast.error(message, { toastId: "customId" })
  }

  return (
    <div className="flex flex-col items-center h-[100vh] bg-[#FAFAFA] pb-[91px] w-full overflow-hidden">
      {/* <DashboardMainLayout className="block lg:grid"> */}
      <div className="dashh">
        <div className="w-full flex">
          <AdminSidebar />
        </div>
        <div className="w-full relative h-[100vh] dash-body">
          {isLoading ? (
            <div className="z-10 top-0 flex items-center justify-between bg-[#fff] w-full h-[100px] m-auto mt-0 relative">
              <Skeleton height={100} animation="wave" />
            </div>
          ) : (
            <div className="w-full flex z-9999">
              <AdminHeader
                  currentUserInfo={data?.user}
                headerTitle={headerTitle}
              />
              <div ref={pageTop} />
              <div ref={reff} />
            </div>
          )}
          <div
            className="w-full relative pb-[30px] h-full"
            style={{ overflow: overflowStyle }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardLayout;
