export const CertifiedBackgroundIcon = ({
  width = 90,
  height = 90,
  color = "#FDDAD2",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
			className={className}
      viewBox="0 0 90 90"
      fill="none"
    >
      <path
        d="M45 90C69.8528 90 90 69.8528 90 45C90 20.1472 69.8528 0 45 0C20.1472 0 0 20.1472 0 45C0 69.8528 20.1472 90 45 90Z"
        fill={color}
      />
      <path
        d="M45.0006 18.3334C30.334 18.3334 18.334 30.3334 18.334 45C18.334 59.6667 30.334 71.6667 45.0006 71.6667C59.6673 71.6667 71.6673 59.6667 71.6673 45C71.6673 30.3334 59.6673 18.3334 45.0006 18.3334ZM56.2006 40.4667L43.4006 53.2667C42.334 54.3334 40.734 54.3334 39.6673 53.2667L33.8007 47.4C32.734 46.3334 32.734 44.7334 33.8007 43.6667C34.8673 42.6 36.4673 42.6 37.534 43.6667L41.534 47.6667L52.4673 36.7334C53.534 35.6667 55.134 35.6667 56.2006 36.7334C57.2673 37.8 57.2673 39.4 56.2006 40.4667Z"
        fill="#34623F"
      />
    </svg>
  );
};
