import React, { useState } from "react";
import { AdminTableActionIcon } from "../../svgs/AdminTableActionIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { getCurrentAdminUser } from "../../api/admin";

interface IProps {
  status: boolean;
  editUser: () => void;
  deactivateUser: () => void;
  removeUser: () => void;
}

const AdminUsersActionCell = ({
  status,
  editUser,
  deactivateUser,
  removeUser
}: IProps) => {
  const currentUser = getCurrentAdminUser();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="flex items-center gap-x-4">
      <button
        type="button"
        className="border-none outline-none cursor-pointer"
        onClick={event => {
          event.stopPropagation();
          handleClick(event);
        }}
      >
        <AdminTableActionIcon />
      </button>
      {currentUser?.role === "superAdmin" && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button"
          }}
        >
          <MenuItem onClick={editUser}>Edit</MenuItem>
          <MenuItem onClick={() => {}}>Remove</MenuItem>
          <MenuItem onClick={() => {}}>
            {status ? "Deactivate" : "Activate"}
          </MenuItem>
        </Menu>
      )}
    </div>
  );
};

export default AdminUsersActionCell;
