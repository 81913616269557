import clsx from "clsx";

interface MedicalCardOneProps {
  body?: React.ReactNode;
  title: string;
  description: string;
  yellow?: boolean;
  icon?: React.ReactNode;
}

const MedicalCardOne = ({
  title,
  body,
  description,
  yellow,
  icon
}: MedicalCardOneProps) => (
  <div
    className={clsx({
      "border-t-2 border-red-500 rounded-[8px] shadow-md shadow-[-2px 4px 25px #EEEEE9] bg-[#fff] w-full h-[404px] py-8 px-4 box-border":
        true,
      "border-yellow-500": yellow
    })}
  >
    <div>{icon}</div>
    <p className="text-left text-[#324705] font-semibood text-[24px] py-4">
      {title}
    </p>
    <div
      className="text-left text-[#324705] font-normal text-[16px] py-4"
      data-toggle="menu"
    >
      {description}
    </div>
    {/* <Spacer height={40} /> */}
    <div className="w-full mx-auto flex justify-center items-center text-center py-4">
      {body}
    </div>
  </div>
);

export default MedicalCardOne;
