import React from "react";

interface SingleProviderDetailsType {
  providerInfo: any;
}

const SingleProviderDetails: React.FC<SingleProviderDetailsType> = ({
  providerInfo
}) => {
  return (
    <div className="w-full my-6">
      <div className="w-[300px] flex justify-start items-center mr-4 py-6">
        <img
          className="h-[96px] w-[96px] rounded-full mr-6"
          src="/assets/images/avatar.png"
          alt="Profile img"
        />
        <p className="text-[20px] font-[600] text-[#324705]">
          {`${providerInfo?.firstName} ${providerInfo?.lastName} ${providerInfo?.title}`}
        </p>
      </div>
      <div className="h-4" />
      <div className="w-full flex flex-col items-center justify-start">
        <div className="w-full flex justify-start">
          <div className="flex flex-col mr-auto text-left w-[178.5px]">
            <p className="text-[#87928A] pb-2">Specialty</p>
            <p className="text-[#324705] break-all max-w-[178.5px]">
              {providerInfo?.specialty}
            </p>
          </div>
          <div className="flex flex-col mr-auto text-left w-[178.5px]">
            <p className="text-[#87928A] pb-2">Languages</p>
            <div className="w-full">
              {providerInfo?.languages &&
                providerInfo?.languages.split(",").length > 1 &&
                providerInfo?.languages?.map((a: string) => (
                  <p className="text-[#324705] break-all max-w-[178.5px]">
                    {a}
                  </p>
                ))}
            </div>
          </div>
          <div className="flex flex-col mr-auto text-left w-[178.5px]">
            <p className="text-[#87928A] pb-2">Provider NPI</p>
            <p className="text-[#324705]  break-all max-w-[178.5px]">
              {providerInfo?.providerNPI}
            </p>
          </div>
          <div className="flex flex-col mr-auto text-left w-[178.5px]">
            <p className="text-[#87928A] pb-2">Practice Address</p>
            <p className="text-[#324705] break-all w-auto">
              {providerInfo?.address}
            </p>
          </div>
        </div>
        <div className="h-6" />
        <div className="w-full flex justify-start">
          <div className="flex flex-col mr-auto text-left w-[178.5px]">
            <p className="text-[#87928A] pb-2">Email</p>
            <p className="text-[#324705] break-all max-w-[178.5px]">
              {providerInfo?.email}
            </p>
          </div>
          <div className="flex flex-col mr-auto text-left w-[178.5px]">
            <p className="text-[#87928A] pb-2">Phone Number</p>
            <p className="text-[#324705] break-all max-w-[178.5px]">
              {providerInfo?.phoneNumber}
            </p>
          </div>
          <div className="flex flex-col mr-auto text-left w-[178.5px]">
            <p className="text-[#87928A] pb-2">License Number</p>
            <p className="text-[#324705] break-all max-w-[178.5px]">
              {providerInfo?.licenseNumber}
            </p>
          </div>
          <div className="flex flex-col mr-auto text-left w-[178.5px]">
            <p className="text-[#87928A] pb-2">License State</p>
            <p className="text-[#324705] break-all max-w-[178.5px]">
              {providerInfo?.licenseState}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleProviderDetails;
