import { AxiosInstance } from "axios";
import { differenceInYears, parse } from "date-fns";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { v4 as uuidv4 } from "uuid";

export const truncateText = (fullText: string, truncationLength?: number) => {
  const truncationFactor = truncationLength || 200;
  if (fullText?.length <= truncationFactor) {
    return fullText;
  }
  return `${fullText?.slice(0, truncationFactor)}...`;
};

export const generateUUID = () => {
  return uuidv4();
};

export const removeEmptyObjectValues = (data: { [key: string]: any }) => {
  const dataCopy = { ...data };
  for (const [key, property] of Object.entries(dataCopy)) {
    if (!property?.toString().length) {
      delete dataCopy[key];
    }
  }

  return dataCopy;
};

export const capitalizeString = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

// TO HELP RAISE COMPONENTS LIKE A MODAL ABOVE THE COMPONENT TREE HIRACHY
export const createWrapperAndAppendToBody = (wrapperId: string) => {
  const wrapperElement = document.createElement("div");
  wrapperElement.setAttribute("id", wrapperId);
  document.body.appendChild(wrapperElement);
  return wrapperElement;
};

export const getGreeting = (): string => {
  const date = new Date();
  const hour = date.getHours();

  if (hour >= 0 && hour < 12) {
    return "Good morning, ";
  } else if (hour >= 12 && hour < 17) {
    return "Good afternoon, ";
  } else {
    return "Good evening, ";
  }
};

export const calculateAge = (dob: string) => {
  const date = parse(dob, "MM/dd/yyyy", new Date());
  const age = differenceInYears(new Date(), date);
  return age;
};

export const toFeet = (numberInCm: number) => {
  const realFeet = (numberInCm * 0.3937) / 12;
  const feet = Math.floor(realFeet);
  const inches = Math.round((realFeet - feet) * 12);
  return { feet, inches };
};

// We will gradually use this function to convert the time values in other places where need be
export const removeZerosAndCheckAMPM = (timeValue: string) => {
  const [hours, minutes] = timeValue.split(":");
  const hoursNum = parseInt(hours, 10);
  const ampm = hoursNum < 12 ? "AM" : "PM";
  const updatedTimeValue = `${hours}:${minutes} ${ampm}`;
  return updatedTimeValue;
};

export function calculateAgeFromDOB(dateString: string) {
  const birthDate = new Date(dateString);
  const currentDate = new Date();

  let age = currentDate.getFullYear() - birthDate.getFullYear();

  const currentMonth = currentDate.getMonth();
  const birthMonth = birthDate.getMonth();
  const currentDay = currentDate.getDate();
  const birthDay = birthDate.getDate();

  if (
    currentMonth < birthMonth ||
    (currentMonth === birthMonth && currentDay < birthDay)
  ) {
    age--;
  }

  // Check if the birth date has passed this year's February 29th
  const isLeapDay = birthMonth === 1 && birthDay === 29;
  if (isLeapDay && currentMonth >= 2) {
    const currentYear = currentDate.getFullYear();
    if (
      (currentYear % 4 === 0 && currentYear % 100 !== 0) ||
      currentYear % 400 === 0
    ) {
      // This year is a leap year and has passed February 29th
      age++;
    }
  }

  return age;
}

export type singlePatientsAppointment = {
  patientsAppointment: {
    id: number;
    userId: string;
    firstName: string;
    lastName: string;
    type: string;
    email: string;
    isEmailVerified: boolean;
    jobTitle: string;
    gender: string;
    pronoun: string;
    zipCode: string;
    phoneNumber: string;
    dateOfBirth: string | Date;
    acceptsCash: boolean;
    acceptsInsurance: boolean;
    avatarUrl: string;
    bio: string;
    created_at: string;
    updated_at: string;
    appointmentId: string;
    appointmentDate: string;
    appointmentStartTime: string;
    appointmentEndTime: string;
    duration: string;
    isAppointmentBooked: boolean;
    providerId: string;
    patientId: string;
    paymentType: string;
    paymentProviderNumber: string;
    didAppointmentHold: boolean;
    appointmentReason: string;
    intakeFormAnswers: null | string[];
  };
};

export type newDepenedantPayload = {
  dateOfBirth: string | Date;
  email: string;
  firstName: string;
  lastName: string;
  gender: string;
};

export interface DecodedPatientData {
  id: number;
  firstName: string;
  middleName: string | null;
  lastName: string;
  userType: "patient";
  email: string;
  address: string;
  city: string;
  state: string;
  isEmailVerified: boolean;
  isActivated: boolean;
  gender: string;
  pronoun: string | null;
  zipCode: string;
  phoneNumber: string;
  dateOfBirth: string;
  avatarUrl: string | null;
  governmentIdUrl: string | null;
  ssn: string | null;
  created_at: string;
  updated_at: string;
  userId: string;
  isEmancipatedMinor: boolean | null;
  emancipationDecisionKey: string | null;
  insuranceName: string | null;
  insuranceNumber: string | null;
  cardToCharge: string | null;
  stateOfIdIssue: string;
  idNumber: string;
  stripeId: string;
  race: string;
  maritalStatus: string;
  dxScriptId: string | null;
  googleRefreshToken: string | null;
  guardianId: string | null;
  dependentRelationship: string | null;
  hasFilledPersonalDetails: boolean;
  canReceiveEmailNotifications: boolean;
  userSource: string;
}

export interface DecodedJwt<T> extends JwtPayload {
  data: T;
  iat: number;
  exp: number;
}

const setToken = (token: string | null) => {
  if (token) {
    localStorage.setItem("temple_user_token", token);
  }
};

const removeToken = (path: string) => {
  localStorage.removeItem("temple_user_token");
  window.location.href = `/${
    path === "provider" ? "provider" : "patient"
  }/login?authFail=true`;
};

function getDecodedJwt<T>(tkn = ""): DecodedJwt<T> | null {
  try {
    const token = tkn || getToken();
    if (!token) {
      return null;
    }
    const decoded = jwtDecode<DecodedJwt<T>>(token);
    return decoded;
  } catch (error) {
    console.error("Error decoding JWT:", error);
    return null;
  }
}

function isAuthenticated<T>(): boolean {
  try {
    const decodedToken = getDecodedJwt<T>();
    if (decodedToken && decodedToken.exp) {
      const currentTime = Date.now() / 1000;
      return decodedToken.exp > currentTime;
    }
    return false;
  } catch (error) {
    console.error("Error checking authentication:", error);
    return false;
  }
}

const getToken = () => {
  const token = localStorage.getItem("temple_user_token");
  if (token) {
    return token;
  }
  return null;
};

const getBillerToken = () => {
  const token = localStorage.getItem("temple_biller_token");
  if (token) {
    return token;
  }
  return null;
};

const setBillerToken = (billerHttp: AxiosInstance, token: string | null) => {
  if (token) {
    billerHttp.defaults.headers["temple_biller_token"] = token;
    localStorage.setItem("temple_biller_token", token);
    billerHttp.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete billerHttp.defaults.headers.common.Authorization;
  }
};

const removeBillerToken = (httpProp: AxiosInstance) => {
  localStorage.removeItem("temple_biller_token");
  delete httpProp.defaults.headers.common.Authorization;
  window.location.href = '/"biller"/login';
};

function getBillerDecodedJwt<T>(tkn = ""): DecodedJwt<T> | null {
  try {
    const token = tkn || getBillerToken();
    if (!token) {
      return null;
    }
    const decoded = jwtDecode<DecodedJwt<T>>(token);
    return decoded;
  } catch (error) {
    console.error("Error decoding JWT:", error);
    return null;
  }
}

function isBillerAuthenticated<T>(): boolean {
  try {
    const decodedToken = getBillerDecodedJwt<T>();
    if (decodedToken && decodedToken.exp) {
      const currentTime = Date.now() / 1000;
      return decodedToken.exp > currentTime;
    }
    return false;
  } catch (error) {
    console.error("Error checking authentication:", error);
    return false;
  }
}

export {
  getBillerDecodedJwt,
  getBillerToken,
  getDecodedJwt,
  getToken,
  isAuthenticated,
  isBillerAuthenticated,
  removeBillerToken,
  removeToken,
  setBillerToken,
  setToken
};
