import { FC } from "react";
import { Template } from "../../../types/template";
import { Form, FormContainer } from "../../components/templates";

const TemplateList: FC<{
  templates: Template[];
  previewTemplate: (template: Template) => void;
}> = ({ templates, previewTemplate }) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-8 p-4 !pt-10 xl:p-10">
      {(templates || []).map((data) => (
        <FormContainer
          key={data.id}
          Form={() => (
            <Form form={data.templateValues} formToView={data.noteType} />
          )}
          formType={data.noteType}
          onPreview={() => previewTemplate(data)}
        />
      ))}
    </div>
  );
};

export default TemplateList;
