
import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useBillerAuthState } from '../../../api/hooks/biller/useBillerAuthState';

export const RequireBillerAuth = ({
	children,
}: {
	children: ReactNode;
}) => {
	const { pathname } = useLocation();
	const { isAuthenticated } = useBillerAuthState();

	const authenticated = isAuthenticated();
	if (!authenticated) {
		return (
			<>
				<Navigate to='/biller/login' state={{ from: pathname }} replace />
			</>
		);
	}

	return <>{children}</>;
};
