import { Divider } from "@mui/material";
import axios, { isAxiosError } from "axios";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useGeBillerProfileDetails } from '../../api/hooks/biller/useGetBillerProfileDetails';
import { useUpdateBillerPassword } from "../../api/hooks/biller/useUpdateBillerPassword";
import { useUpdateBillerProfileDetails } from "../../api/hooks/biller/useUpdateBillerProfileDetails";
import { getS3SignedUrl } from "../../api/hooks/common/fetchers.common";
import PasswordField from "../../components/form/PasswordField";
import RadioField from "../../components/form/RadioField";
import TextField from "../../components/form/TextField";
import Button from "../../components/shared/Button";
import ListTabsWidget from "../../components/shared/ListTabsWidget";
import { Loading } from "../../components/shared/Loading";
import { statesInTheUS } from "../../utils/consts";
import { generateUUID } from "../../utils/helpers";
import SPDashboardLayout from "../components/SPDashboardLayout";
import { spSettingSchema, spSettingsInitialValues } from "../schema/biller.schema";

function SPSettings() {
  const usaStates = statesInTheUS;
  const [showImageFileUpload, setShowImageFileUpload] = useState<boolean>(false)
  const [activeTabLabel, setActiveTabLabel] = useState<string>("Your Account");
  const [isImageUploadInProgress, setIsImageUploadInProgress] = useState<boolean>(false)

  const [initialSettingsFormValues, setInitialSettingsFormValues] =
    useState<any>({
      aapcLicenseExpiry: new Date(),
      aapcLicenseNumber: "",
      address: "",
      avatarUrl: null,
      city: null,
      cpbLicenseExpiry: new Date(),
      cpbLicenseNumber: "",
      created_at: "",
      dateOfBirth: null,
      email: "",
      firstName: "",
      gender: null,
      governmentIdUrl: null,
      hasSeenLoginModal: false,
      isActivated: false,
      isEmailVerified: false,
      lastName: "",
      paymentMethod: "",
      phoneNumber: null,
      pronoun: null,
      ssn: null,
      state: null,
      stripeId: null,
      updated_at: "",
      userId: "",
      userType: "",
      zipCode: null
    });

  const { billerProfileData, isLoading, isError, error } = useGeBillerProfileDetails()
  const { updateBillerPassword, isPending: isUpdateBillerPasswordPending } = useUpdateBillerPassword()

  const { user } = billerProfileData || {}


  useEffect(() => {
    if (user) {
      setInitialSettingsFormValues({
        aapcLicenseExpiry: user?.aapcLicenseExpiry,
        aapcLicenseNumber: user?.aapcLicenseNumber,
        address: user?.address,
        avatarUrl: user?.avatarUrl,
        city: user?.city,
        cpbLicenseExpiry: user?.cpbLicenseExpiry,
        cpbLicenseNumber: user?.cpbLicenseNumber,
        created_at: user?.created_at,
        dateOfBirth: user?.dateOfBirth,
        email: user?.email,
        firstName: user?.firstName,
        gender: user?.gender,
        governmentIdUrl: user?.governmentIdUrl,
        hasSeenLoginModal: user?.hasSeenLoginModal,
        isActivated: user?.isActivated,
        isEmailVerified: user?.isEmailVerified,
        lastName: user?.lastName,
        paymentMethod: user?.paymentMethod,
        phoneNumber: user?.phoneNumber,
        pronoun: user?.pronoun,
        state: user?.state,
        ssn: user?.ssn,
        stripeId: user?.stripeId,
        userId: user?.userId,
        userType: user?.userType,
        zipCode: user?.zipCode
      });
    }

  }, []);

  const formik = useFormik({
    initialValues: {
      aapcLicenseExpiry: initialSettingsFormValues?.aapcLicenseExpiry
        ? new Date(initialSettingsFormValues?.aapcLicenseExpiry)
        : new Date(),
      aapcLicenseNumber: initialSettingsFormValues?.aapcLicenseNumber,
      address: initialSettingsFormValues?.address,
      avatarUrl: initialSettingsFormValues?.avatarUrl,
      city: initialSettingsFormValues?.city,
      cpbLicenseExpiry: initialSettingsFormValues?.cpbLicenseExpiry
        ? new Date(initialSettingsFormValues?.cpbLicenseExpiry)
        : new Date(),
      cpbLicenseNumber: initialSettingsFormValues?.cpbLicenseNumber,
      dateOfBirth: initialSettingsFormValues?.dateOfBirth,
      email: initialSettingsFormValues?.email,
      firstName: initialSettingsFormValues?.firstName,
      gender: initialSettingsFormValues?.gender,
      governmentIdUrl: initialSettingsFormValues?.governmentIdUrl,
      hasSeenLoginModal: initialSettingsFormValues?.hasSeenLoginModal,
      isActivated: initialSettingsFormValues?.isActivated,
      isEmailVerified: initialSettingsFormValues?.isEmailVerified,
      lastName: initialSettingsFormValues?.lastName,
      paymentMethod: initialSettingsFormValues?.paymentMethod,
      phoneNumber: initialSettingsFormValues?.phoneNumber,
      pronoun: initialSettingsFormValues?.pronoun,
      state: initialSettingsFormValues?.state,
      ssn: initialSettingsFormValues?.ssn,
      stripeId: initialSettingsFormValues?.stripeId,
      userId: initialSettingsFormValues?.userId,
      userType: initialSettingsFormValues?.userType,
      zipCode: initialSettingsFormValues?.zipCode
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      email: Yup.string().required("Email is required"),
      providerNPI: Yup.string().required(
        "Provider NPI is required"
      ),
      licenseNumber: Yup.string().required("License Number is required"),
      practiceAddress: Yup.string().required("Practice Address is required"),
      specialty: Yup.string().required("Practice Address is required"),
      title: Yup.string().required("Practice Address is required"),
      pronoun: Yup.string(),
      zipcode: Yup.string().required("Zipcode is required"),
      bio: Yup.string(),
      focusAreas: Yup.string().required("Focus Areas is required")
    }),
    onSubmit: () => { }
  });

  const passwordFormik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: ""
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required("Old password is required"),
      newPassword: Yup.string().required("New password is required"),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("You need to confirm your password")
    }),
    onSubmit: () => { }
  });

  const paymentFormik = useFormik({
    initialValues: spSettingsInitialValues,
    validationSchema: spSettingSchema,
    enableReinitialize: true,
    onSubmit: () => { }
  });
  const { updateBillerProfile, isPending } = useUpdateBillerProfileDetails()

  const handleFormSubmit = async () => {
    if (activeTabLabel !== "Payment information") {
      updateBillerProfile({
        ...(formik.values.firstName && { firstName: formik.values?.firstName }),
        ...(formik.values.lastName && { lastName: formik.values?.lastName }),
        ...(formik.values?.phoneNumber && {
          phoneNumber: formik.values?.phoneNumber
        }),
        ...(formik.values?.pronoun && { pronoun: formik.values?.pronoun }),
        ...(formik.values?.aapcLicenseNumber && {
          aapcLicenseNumber: formik.values?.aapcLicenseNumber
        }),
        ...(formik.values?.aapcLicenseExpiry && {
          aapcLicenseExpiry: formik.values?.aapcLicenseExpiry
        }),
        ...(formik.values?.cpbLicenseNumber && {
          cpbLicenseNumber: formik.values?.cpbLicenseNumber
        }),
        ...(formik.values?.cpbLicenseExpiry && {
          cpbLicenseExpiry: formik.values?.cpbLicenseExpiry
        }),
        ...(formik.values?.state && {
          state: formik.values?.state
        }),
        ...(formik.values?.city && {
          city: formik.values?.city
        }),
        ...(formik.values?.avatarUrl && {
          avatarUrl: formik.values?.avatarUrl
        })
      });

    }
  };

  const handleFileUpload = (e: any) => {
    try {
      const file = e.target.files?.[0];
      if (!file) {
        toast.info("No file selected");
        return;
      }

      const maxAllowedSize = 2 * 1024 * 1024;
      if (file.size > maxAllowedSize) {
        toast.error("File size should be less than 2MB");
        return;
      } else {
        const key =
          generateUUID() + file.name?.slice(file.name?.lastIndexOf("."));
        (async () => {
          setIsImageUploadInProgress(true);
          const response = await getS3SignedUrl({
            operation: "putObject",
            key,
            object: "account"
          });
          if (response?.signedUrlParams) {
            const { signedUrl, host } = response.signedUrlParams;
            const avatarUrl = `${host}/${key}`;
            const [res] = await Promise.all([
              axios.put(signedUrl, file),
              updateBillerProfile({ avatarUrl })
            ]);
            if (res.status === 200) {
              formik.setFieldValue('avatarUrl', avatarUrl);
              e.target.value = "";
              setShowImageFileUpload(false);
              setIsImageUploadInProgress(false);
              e.target.value = "";
            }
          }
        })();
      }
    } catch (error) {
      toast.error(
        "An error occurred. Please try your profile picture upload again."
      );
    }
  };


  const handleTabClick = (tabName: string) => {
    setActiveTabLabel(tabName);
  };

  if (isLoading) {
    <div className="w-1/2 h-1/2 flex items-center justify-center">
      <Loading />
    </div>
  }

  if (isError) {
    if (isAxiosError(error)) {
      const errorMessage = error?.response?.data?.error || "Error processing request"
      toast.error(errorMessage,
        { toastId: "customId" })
    } else {
      toast.error(error?.error || "Error: fetch profile details failed", { toastId: "customId" })
    }
  }


  return (
    <SPDashboardLayout headerTitle="Settings">
      <>
        <div className="mb-6 px-4">
          <ListTabsWidget
            tabs={[{ label: "Your Account" }, { label: "Payment information" }]}
            handleTabClick={label => {
              handleTabClick(label);
            }}
            activeTabLabel={activeTabLabel}
          />
        </div>

        {activeTabLabel === "Your Account" && (
          <div className="h-full w-full mb-10 overflow-y-auto">
            <form onSubmit={handleFormSubmit}>
              <p className="font-[600]] text-[20px] text-black pl-4">
                Personal Information
              </p>
              <div className="flex w-full items-center my-6 ml-4">
                <div className="relative mr-8 h-[80px] w-[80px] rounded-full">
                  <img
                    className="w-full h-full rounded-full"
                    src={formik.values.avatarUrl || "/assets/images/avatar.png"}
                    width={96}
                    height={96}
                    alt="Profile img"
                  />
                  {isImageUploadInProgress && (
                    <div className="absolute bg-[#000000] opacity-50 h-full w-[80px] rounded-full top-0 flex items-center justify-center">
                      Loading...
                    </div>
                  )}
                </div>
                <input
                  onChange={handleFileUpload}
                  type="file"
                  accept="image/*"
                  className={showImageFileUpload ? "" : "hidden"}
                />
                <p
                  onClick={() => setShowImageFileUpload(!showImageFileUpload)}
                  className="cursor-pointer text-[14px] font-[500] text-[#3D874E]"
                >
                  {showImageFileUpload ? "Cancel" : "Change profile photo"}
                </p>
              </div>
              <div className="w-1/2 my-8 pl-4">
                <div className="w-full flex flex-row items-center justify-between my-6">
                  <div className="w-full mr-2">
                    <TextField
                      name="firstName"
                      type="text"
                      placeholder="Full name"
                      value={formik.values?.firstName}
                      onChange={formik.handleChange}
                      label="First Name"
                    />
                  </div>
                  <div className="w-full">
                    <TextField
                      name="lastName"
                      type="text"
                      placeholder="last name"
                      value={formik.values?.lastName}
                      onChange={formik.handleChange}
                      label="Last Name"
                    />
                  </div>
                </div>
                <div className="flex flex-row items-center justify-end my-4">
                  <div className="w-full">
                    <TextField
                      name="email"
                      type="email"
                      placeholder="Enter email"
                      label="Email address"
                      value={formik.values?.email}
                      onChange={formik.handleChange}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="w-full pl-4">
                <Divider />
              </div>
              <div className="w-1/2 my-8 pl-4">
                <p className="font-[600]] text-[20px] text-black">
                  Professional Information
                </p>
                <div className="w-full flex flex-row items-center justify-between my-6">
                  <div className="w-[275px] mr-2">
                    <TextField
                      name="aapcLicenseNumber"
                      type="text"
                      placeholder="09399239717"
                      value={formik.values?.aapcLicenseNumber}
                      onChange={formik.handleChange}
                      label="AAPC license"
                    />
                  </div>
                  <div className="w-[275px]">
                    <div className="text-[18px] font-[500] text-left">
                      Expiry Date
                    </div>
                    <div className="flex flex-row items-center justify-end">
                      <div className="w-full bg-white border border-[#CECFC5]">
                        <ReactDatePicker
                          selected={
                            formik.values?.aapcLicenseExpiry || new Date()
                          }
                          name="aapcLicenseExpiry"
                          dateFormat="MM/dd/yyyy"
                          onChange={(date: Date) => {
                            formik.setFieldValue("aapcLicenseExpiry", date);
                          }}
                          placeholderText="MM/DD/YYYY"
                          className="date-picker"
                          shouldCloseOnSelect={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full flex flex-row items-center justify-between my-6">
                  <div className="w-[275px] mr-2">
                    <TextField
                      name="cpbLicenseNumber"
                      type="text"
                      placeholder="09399239717"
                      value={formik.values?.cpbLicenseNumber}
                      onChange={formik.handleChange}
                      label="CPB license"
                    />
                  </div>
                  <div className="w-[275px]">
                    <div className="text-[18px] font-[500] text-left">
                      Expiry Date
                    </div>
                    <div className="flex flex-row items-center justify-end">
                      <div className="w-full bg-white border border-[#CECFC5]">
                        <ReactDatePicker
                          selected={
                            formik.values?.cpbLicenseExpiry || new Date()
                          }
                          name="cpbLicenseExpiry"
                          dateFormat="MM/dd/yyyy"
                          onChange={(date: Date) => {
                            formik.setFieldValue("cpbLicenseExpiry", date);
                          }}
                          placeholderText="MM/DD/YYYY"
                          className="date-picker"
                          shouldCloseOnSelect={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full my-4">
                  <TextField
                    name="address"
                    type="text"
                    placeholder="149 Fink Ln, Linden, PA 17744, USA"
                    label="Address"
                    value={formik.values?.address}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="w-full flex items-center justify-between my-4">
                  <div className="w-full mr-2">
                    <div className="">State</div>
                    <select
                      className="w-full py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded h-[47px]"
                      name="state"
                      value={formik.values?.state}
                      onChange={e => {
                        formik.setFieldValue("state", e.target.value);
                      }}
                    >
                      <option value="" disabled>
                        Select an option
                      </option>
                      {usaStates.map(state => (
                        <option value={state.toLowerCase()} key={state}>
                          {state}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-full">
                    <div className="">City</div>
                    <div className="w-full">
                      <TextField
                        name="city"
                        type="tel"
                        placeholder="Enter City"
                        value={formik.values?.city}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <div className="w-full pl-4">
              <Divider />
            </div>

            <form
              // onSubmit={passwordFormik.handleSubmit}
              className="w-1/2 my-8 pl-4"
            >
              <p className="font-[600]] text-[20px] text-black my-6">
                Password
              </p>
              <div className="w-full my-4  mb-12">
                <div className="w-full my-4">
                  <PasswordField
                    name="currentPassword"
                    placeholder="At least 8 characters"
                    value={passwordFormik.values?.currentPassword}
                    onChange={passwordFormik.handleChange}
                    label="Current password"
                    showPasswordToggler={true}
                  />
                </div>
                <div className="w-full my-4">
                  <PasswordField
                    name="newPassword"
                    placeholder="At least 8 characters"
                    value={passwordFormik.values?.newPassword}
                    onChange={passwordFormik.handleChange}
                    label="New password"
                    showPasswordToggler={true}
                  />
                </div>
                <div className="w-full my-4  mb-12">
                  <PasswordField
                    name="confirmNewPassword"
                    placeholder="At least 8 characters"
                    value={passwordFormik.values?.confirmNewPassword}
                    onChange={passwordFormik.handleChange}
                    label="Repeat new password"
                    showPasswordToggler={true}
                  />
                </div>
              </div>
            </form>
          </div>
        )}
        {activeTabLabel === "Payment information" && (
          <div className="h-full w-full mb-10 overflow-y-auto">
            {" "}
            <form>
              <div className="w-[500px] px-4 my-4 ">
                <div className="my-6">
                  <div className="text-[18px] font-[500] text-left">
                    Payment method
                  </div>
                  <RadioField
                    name="paymentType"
                    placeholder="Payment Method"
                    display="inline"
                    options={[
                      { item: "Direct Deposit", value: "directDeposit" },
                      { item: "Wire transfer", value: "wireTransfer" },
                      { item: "Check", value: "check" }
                    ]}
                    hasOther={false}
                    isRequired={true}
                    value=""
                    onChange={() => { }}
                  />
                </div>

                <div className="my-6">
                  <TextField
                    name="accountHoldersName"
                    type="text"
                    placeholder="Account holder's name"
                    value=""
                    onChange={() => { }}
                    label="Account holder's name"
                  />
                </div>

                <div className="my-6">
                  <TextField
                    name="accountNumber"
                    type="text"
                    placeholder="Account number"
                    value=""
                    onChange={() => { }}
                    label="Account Number"
                  />
                </div>

                <div className="my-6">
                  <TextField
                    name="routingNumber"
                    type="text"
                    placeholder="Routing number"
                    value=""
                    onChange={() => { }}
                    label="Routing Number"
                  />
                </div>

                <div className="my-6">
                  <TextField
                    name="emailAddress"
                    type="email"
                    placeholder="Email address"
                    value=""
                    onChange={() => { }}
                    label="Email Address"
                  />
                </div>
              </div>
            </form>
          </div>
        )}
        <div className="h-[96px] w-full bg-white border-t border-[#ddd] bottom-0 sticky">
          { }
          <div className="h-full flex justify-end items-center">
            <div className="w-[188px]">
              <Button
                type="button"
                variant="primary"
                label="Save changes"  
                size="medium"
                loading={isPending || isUpdateBillerPasswordPending}
                onClick={() => {

                  if (passwordFormik.values.currentPassword) {
                    updateBillerPassword({
                      currentPassword: passwordFormik.values?.currentPassword,
                      newPassword: passwordFormik.values?.newPassword
                    });

                  } else {
                    handleFormSubmit();
                  }
                }}
              />
            </div>
          </div>
        </div>
      </>
    </SPDashboardLayout>
  );
}

export default SPSettings;
