import { styled } from "@mui/material";
import { Form, Formik } from "formik";
import { Fragment, useState } from 'react';
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useRegisterProvider } from "../../api/hooks/provider/useCreateProvider";
import { RegisterPayload } from "../../api/types";
import Spacer from "../../components/Spacer";
import PasswordField from "../../components/form/PasswordField";
import RadioField from "../../components/form/RadioField";
import TextField from "../../components/form/TextField";
import Button from "../../components/shared/Button";
import { Lifebuoy } from "../../svgs/Lifebuoy";
import { TempleHSLogo } from "../../svgs/TempleHSLogo";
import { phoneRegExp } from "../../utils/consts";

const ProviderCreateAccount = () => {
  const [formData, setFormData] = useState<RegisterPayload>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    isExistingPractice: false,
    title: ""
  });


  const activateButton = ({
    criteria,
    formData
  }: {
    criteria: Array<string>;
    formData: any;
  }) => {
    let disabled = false;
    for (const item of criteria) {
      if (typeof formData[item] === "string") {
        if (!formData[item] || formData[item].length <= 0) {
          disabled = true;
        }
      }
    }
    return disabled;
  };

  const { createProvider, isPending: isLoading, isError, error } = useRegisterProvider()



  const benefits = [
    "Free tailored support for setting up your practice",
    "List your practice and get bookings for free",
    "Create and manage intake and consent forms",
    "Create and manage initial and follow up notes",
    "Access to customizable templates",
    "Access to tele-health services"
  ];

  return (
    <Fragment>
      <CreateContainer>
        <div className="container-one">
          <div className="text-black mt-20">
            <p className="text-[18px] mb-4 font-bold">
              Whether you are looking to start a new practice or scale and
              existing one, enjoy these amazing benefits when you join us!
            </p>
            {benefits.map((benefit, index) => (
              <div
                className="flex items-center gap-2 mb-4 text-[18px]"
                key={index}
              >
                <Lifebuoy color="#000" />
                <p>{benefit}</p>
              </div>
            ))}
          </div>
        </div>
        <Formik
          initialValues={{
            isExistingPractice: formData?.isExistingPractice ?? false,
            firstName: formData?.firstName ?? "",
            lastName: formData?.lastName ?? "",
            email: formData?.email ?? "",
            phoneNumber: formData?.phoneNumber ?? "",
            password: formData?.password ?? "",
            title: formData?.title ?? ""
          }}
          validationSchema={Yup.object({
            specialty: Yup.string().required("Specialty is required"),
            firstName: Yup.string().required("First name is required"),
            phoneNumber: Yup.string()
              .required("Phone number is required")
              .test(
                "is-valid-us-phone-number",
                "Invalid US phone number",
                value => {
                  return phoneRegExp.test(value as string);
                }
              ),
            lastName: Yup.string().required("Last name is required"),
            email: Yup.string()
              .email("Invalid Email")
              .required("Email is required"),
            password: Yup.string()
              .required("Password is required")
              .matches(
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,40}$/,
                "Password must have at least one uppercase, one lowercase, one number, and be at least 8 characters long"
              ),
            title: Yup.string().required("Title is required")
          })}
          onSubmit={() => { }}
        >
          {({
            values,
            handleChange,
            touched,
            errors,
            setFieldValue,
            handleBlur,
          }) => (
            <Form className="page-form-container">
              <a
                className="mt-20 mb-10"
                href={process.env.REACT_APP_LANDING_PAGE_URL}
              >
                <TempleHSLogo />
              </a>
              <div className="w-full">
                {/* <div className="head-text">Start your 2-month free trial</div>
                <p>No credit card required to sign up!</p> */}
                {Object.keys(isError).length > 0 && (
                  <div
                    className={
                      isError
                        ? "error-container mt-4"
                        : "error-container-warning mt-4"
                    }
                  >
                    <span className="text-[13px] font-medium">
                      {error?.message}
                    </span>
                  </div>
                )}
                <div className="mt-5 mb-2">
                  <RadioField
                    name="isExistingPractice"
                    placeholder="How do you want to use TempleHS?"
                    label="How do you want to use TempleHS?"
                    display="inline"
                    options={[
                      { item: "Manage an existing practice", value: true },
                      { item: "Start a practice", value: false }
                    ]}
                    hasOther={false}
                    isRequired={true}
                    value={values?.isExistingPractice}
                    onChange={e => {
                      if (e.target.value === "true") {
                        setFieldValue("isExistingPractice", true);
                      } else {
                        setFieldValue("isExistingPractice", false);
                      }
                    }}
                    error={
                      touched.isExistingPractice
                        ? errors.isExistingPractice
                        : ""
                    }
                  />
                </div>
                <div className="mb-4">
                  <TextField
                    name="title"
                    type="text"
                    placeholder="e.g MD, DO, NP, PA, LMHC, LCMHC"
                    value={values?.title}
                    onChange={handleChange}
                    label="Title"
                    error={touched.title ? errors.title : ""}
                    isRequired={true}
                    showAsterisk={true}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="relative flex flex-row justify-between w-[100%] mb-2">
                  <TextField
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                    value={values?.firstName}
                    onChange={handleChange}
                    label="First name"
                    error={touched.firstName ? errors.firstName : ""}
                    isRequired={true}
                    showAsterisk={true}
                    onBlur={handleBlur}
                  />
                  <Spacer width={20} />
                  <TextField
                    name="lastName"
                    type="text"
                    placeholder="Last Name"
                    value={values?.lastName}
                    onChange={handleChange}
                    label="Last name"
                    error={touched.lastName ? errors.lastName : ""}
                    isRequired={true}
                    showAsterisk={true}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="mb-2">
                  <TextField
                    name="email"
                    type="email"
                    placeholder="johnddoe@xyz.com"
                    value={values?.email}
                    onChange={handleChange}
                    label="Email address"
                    error={touched.email ? errors.email : ""}
                    isRequired={true}
                    showAsterisk={true}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="mb-2">
                  <TextField
                    name="phoneNumber"
                    type="tel"
                    placeholder="Phone"
                    value={values?.phoneNumber}
                    onChange={handleChange}
                    label="Phone"
                    error={touched.phoneNumber ? errors.phoneNumber : ""}
                    onBlur={handleBlur}
                    isRequired={true}
                    showAsterisk={true}
                  />
                </div>
                <div className="mb-4">
                  <PasswordField
                    name="password"
                    placeholder="At least 8 characters"
                    value={values?.password}
                    onChange={handleChange}
                    label="Password"
                    showPasswordToggler={true}
                    error={touched.password ? errors.password : ""}
                    isRequired={true}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="flex justify-start mb-4">
                  <p>
                    By signing up, you consent to TempleHS&#39;s{" "}
                    <a
                      href={`${process.env.REACT_APP_LANDING_PAGE_URL}/terms-and-conditions`}
                      className="text-[#5f91c0]"
                    >
                      Terms and Conditions
                    </a>{" "}
                    and{" "}
                    <a
                      href={`${process.env.REACT_APP_LANDING_PAGE_URL}/privacy-policy`}
                      className="text-[#5f91c0]"
                    >
                      Privacy Policy
                    </a>
                  </p>
                </div>
                <div className="flex flex-row items-center justify-end w-100 mb-2">
                  <Button
                    onClick={() => {
                      setFormData({} as RegisterPayload)
                      createProvider(values)
                    }}
                    width={112}
                    height={60}
                    type="button"
                    variant="primary"
                    label="Submit"
                    disabled={
                      isLoading ||
                      activateButton({
                        criteria: [
                          "title",
                          "email",
                          "phoneNumber",
                          "firstName",
                          "lastName",
                          "password",
                          "dateOfBirth"
                        ],
                        formData: values
                      })
                    }
                    loading={isLoading}
                    size="medium"
                  />
                </div>
                <p className="text-center font-[400] mt-5">
                  Do you have an account?{" "}
                  <span className="text-[#1A9D39]">
                    {" "}
                    <Link to={"/provider/login"}>Sign in</Link>{" "}
                  </span>
                </p>
              </div>
            </Form>
          )}
        </Formik>
      </CreateContainer>
    </Fragment>
  );
};

const CreateContainer = styled("div")(() => ({
  display: "flex",
  width: "100%",
  backgroundColor: "#fff",
  height: "100%",
  justifyContent: "space-evenly",
  padding: "0 5%",
  " @media (max-width: 1023px)": {
    display: "flex",
    marginTop: "250px",
    padding: "0 10%",
    height: "100%",
    flexDirection: "column-reverse"
  },
  " @media (max-width: 639px)": {
    marginTop: "300px",
  },
  " @media (max-width: 424px)": {
    marginTop: "400px",
  },

  "& .form-error": {
    color: "red",
    fontSize: ".8rem"
  },

  "& .page-form-container": {
    width: "100%",
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    "& .head-text": {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "#2E3011",
    },
    "& .error-container": {
      border: "1px solid #F9B3A3",
      backgroundColor: "#FEF0ED",
      padding: ".7rem",
      borderRadius: "4px"
    },
    "& .error-container-warning": {
      border: "1px solid #FBE1AE",
      backgroundColor: "#FEF4E2",
      padding: ".7rem",
      borderRadius: "4px"
    }
  },
  "& .container-one": {
    width: "100%",
    height: "100%",
    display: "flex",
    padding: "2% 5% 0 0",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start"
  }
}));

export default ProviderCreateAccount;
