export const SPERASIcon = ({
  width = 23,
  height = 20,
  color = "black",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.172 1.797v0A1.12 1.12 0 012.29.68h17.867c.619 0 1.117.502 1.117 1.117v14.176a.052.052 0 01-.052.052H1.224a.052.052 0 01-.052-.052V1.797zm18.65 12.867a.412.412 0 00.411-.411V2.137a.412.412 0 00-.412-.411H2.63a.412.412 0 00-.412.411v12.116c0 .227.185.412.412.412h17.192zM21.558 18.61l-.002.002a2.39 2.39 0 01-1.705.708H2.59c-1.22 0-2.23-.91-2.389-2.088-.008-.066.047-.142.147-.142H7.62c.059 0 .114.03.144.081l.775 1.293a.528.528 0 00.453.257h4.462a.528.528 0 00.452-.257l.776-1.293a.168.168 0 01.143-.081h7.273c.1 0 .155.076.146.142a2.387 2.387 0 01-.686 1.378z"
        stroke={color}
        strokeWidth={0.36}
      />
      <path
        d="M13.54 17.059l-.374.625H9.28l-.375-.625h4.635z"
        stroke={color}
        strokeWidth={0.3}
      />
      <path
        d="M11.44 8.718a.654.654 0 01.185.086c.056.042.089.097.089.187 0 .08-.036.14-.096.183a.459.459 0 01-.178.073v-.53z"
        stroke={color}
        strokeWidth={0.115854}
      />
      <path
        d="M11.055 9.196a1.288 1.288 0 01-.506-.285l-.066-.058-.057.068-.274.326-.055.066.064.056c.265.23.563.371.894.42V10.073H11.469v-.277c.22-.023.42-.1.572-.227a.777.777 0 00.276-.613c0-.273-.1-.464-.271-.6-.152-.12-.356-.191-.577-.247v-.53c.141.034.274.095.4.184l.07.051.05-.071.245-.346.05-.072-.072-.05a1.67 1.67 0 00-.743-.288v-.2H11.055v.195a1.007 1.007 0 00-.553.23.78.78 0 00-.265.579h0v.027c0 .261.09.446.254.58.145.116.341.188.564.245v.553zm2.496-.766a2.327 2.327 0 11-4.654 0 2.327 2.327 0 014.654 0z"
        stroke={color}
        strokeWidth={0.17378}
      />
      <path
        d="M10.839 7.773c0-.076.032-.133.085-.174a.386.386 0 01.16-.069v.502a.583.583 0 01-.165-.082c-.05-.04-.08-.09-.08-.177z"
        stroke={color}
        strokeWidth={0.115854}
      />
      <path
        d="M17.37 9.452c.072 0 .112.084.067.14l-1.27 1.603a.087.087 0 01-.137 0l-1.274-1.602a.087.087 0 01.068-.141h.277a.376.376 0 00.377-.377V5.622c0-.072.059-.131.131-.131h.975c.072 0 .13.059.13.131v3.453c0 .208.17.377.377.377h.278zM5.933 11.37a.2.2 0 01-.2-.201V7.785a.377.377 0 00-.377-.377h-.278a.087.087 0 01-.068-.14l1.27-1.603a.087.087 0 01.137 0l1.274 1.602a.087.087 0 01-.068.141h-.277a.377.377 0 00-.377.377v3.384a.2.2 0 01-.2.2h-.836z"
        stroke={color}
        strokeWidth={0.289634}
      />
    </svg>
  );
};
