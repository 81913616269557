import clsx from "clsx";
import "react-phone-number-input/style.css";
import Spacer from "../Spacer";
interface TextFieldProps {
  type?: "text" | "email" | "tel" | "date";
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<any, Element>) => void;
  value: string;
  label?: string;
  placeholder: string;
  name: string;
  size?: string;
  disabled?: boolean;
  error?: string;
  height?: number;
  width?: number;
  isRequired?: boolean;
  showAsterisk?: boolean;
  isPasswordFieldWithoutToggle?: boolean;
  inputValid?: boolean;
  isVerificationInput?: boolean;
  onVerificationClick?: () => void;
  additionalClassname?: any;
}

const TextField: React.FC<TextFieldProps> = ({
  type = "text",
  onChange = () => {},
  onBlur = () => {},
  value,
  label,
  placeholder,
  size,
  name,
  disabled = false,
  error,
  height,
  width,
  isRequired = true,
  showAsterisk = false,
  inputValid,
  isVerificationInput = false,
  onVerificationClick = () => {},
  additionalClassname
}) => {
  const computedRootStyle = () => {
    const style: { [key: string]: number | string } = {};

    if (width) {
      style.width = `${width}px`;
    }

    return style;
  };

  const computedInputStyle = () => {
    const style: { [key: string]: number | string } = {};

    if (height) {
      style.height = `${height}px`;
    }

    return style;
  };

  return (
    <div
      className={clsx({
        "w-full flex flex-col": true,
        "lg:w-1/2": size === "half",
        [additionalClassname]: additionalClassname
      })}
      style={computedRootStyle()}
    >
      <span className="flex flex-row items-center">
        {label && (
          <label
            className="text-[#000] font-medium mb-2 text-[16px]"
            htmlFor={`grid-${name}`}
          >
            {label}
          </label>
        )}
        {!isRequired ? (
          <span className="ml-2 text-[13px] text-[#6E8877]">(optional)</span>
        ) : isRequired && showAsterisk ? (
          <>
            <Spacer width={5} />
            <p className="text-red-600">*</p>
          </>
        ) : (
          <></>
        )}
      </span>
      <div className="flex items-center bg-white justify-between border border-[#949589] text-[#103C1B] rounded py-3 px-4 leading-tight focus:outline-none focus:bg-none focus:border-gray-500 hover:border-black">
        <input
          className="w-full bg-white appearance-none placeholder:text-[#6E8877] text-black bg-transparent border border-none focus:outline-none text-s"
          type={type}
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          style={computedInputStyle()}
        />
        {isVerificationInput && (
          <div
            className="cursor-pointer text-[13px] text-[#3D874E w-[100px]"
            onClick={onVerificationClick}
          >
            Resend code
          </div>
        )}
      </div>
      {error && <p className="mt-2 text-xs text-red-600 capitalize">{error}</p>}
    </div>
  );
};

export default TextField;
