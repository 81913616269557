import axios from "axios";
import { useFormik } from "formik";
import { Fragment, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Column } from "react-table";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useAddNewPrescription } from '../../../api/hooks/patient/useAddNewPrescription';
import { useGetDXScriptSSOLogin } from '../../../api/hooks/provider/useGetDXScriptSSOLogin';
import ReusableTable from "../../../components/ReusableTable";
import TextField from "../../../components/form/TextField";
import AccordionComponent, {
  AccordionProps
} from "../../../components/shared/Accordion";
import BtnButton from "../../../components/shared/BtnButton";
import Button from "../../../components/shared/Button";
import CustomDropdown from "../../../components/shared/CustomDropdown";
import ListTabsWidget from "../../../components/shared/ListTabsWidget";
import { Loading } from "../../../components/shared/Loading";
import ModalPortal from "../../../components/shared/ModalPortal";
import { CancelIcon } from "../../../svgs/CancelIcon";
import { getLoggedInUser } from "../../../utils/loggedInUser";
import { Prescription as PrescriptionFromPatientType } from "./interfaces/patientDetails";
import { LocationState, PrescriptionProps, PrescriptionType, mobileDrugBodyParams, prescriptionTabs } from "./interfaces/prescription";


const Prescription = ({ activePatient, updatePrescriptionList }: PrescriptionProps) => {
  const loggedInUser = getLoggedInUser({ user: "provider" });

  const location = useLocation();
  const navigate = useNavigate();
  const [showNotAllowedModal, setShowNotAllowedModal] = useState<boolean>(false);

  const [activePrescriptionTabLabel, setActivePrescriptionTabLabel] = useState(
    (location.state as LocationState).activePrescriptionTab ?? "Current"
  );

  const [isModalVisible, setModalVisibility] = useState<boolean>(false);
  const userId = location.pathname.split("/")[3];

  const TABLE_COLUMNS = useMemo<Column<PrescriptionType>[]>(
    () => [
      {
        Header: "Drug Description",
        accessor: "drug",
        Cell: ({ cell: { value } }) => {
          return <span className="capitalize">{value || "-"}</span>;
        }
      },
      {
        Header: "Frequency",
        accessor: "dosage",
        Cell: ({ cell: { value } }) => {
          return (
            <Fragment>
              {value ? value[0].toUpperCase() + value.slice(1) : "-"}
            </Fragment>
          );
        }
      },
      {
        Header: "Duration",
        accessor: "duration",
        Cell: ({ cell: { value } }) => {
          return <Fragment>{value || "-"}</Fragment>;
        }
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => {
          return <span className="capitalize">{value || "Active"}</span>;
        }
      },
      {
        Header: "Provider",
        accessor: "providerName",
        Cell: ({ cell: { value } }) => {
          return <span className="capitalize">{value || "-"}</span>;
        }
      },
      {
        Header: "Pharmacy",
        accessor: "pharmacy",
        Cell: ({ cell: { value } }) => {
          return <span className="capitalize">{value || "-"}</span>;
        }
      }
    ],
    []
  );

  // const tableData = useMemo<{
  //   current: PrescriptionFromPatientType[]
  //   previous: PrescriptionFromPatientType[]
  // }>(
  //   () =>
  //     (activePatient?.prescriptions ? activePatient?.prescriptions : []).reduce(
  //       (acc, next) => {
  //         if (next.hasCompletedDose) {
  //           acc?.previous.push(next);
  //         } else {
  //           acc?.current.push(next);
  //         }
  //         return acc;
  //       },
  //       {
  //         current: [],
  //         previous: []
  //       }
  //     ),
  //   [activePatient]
  // );

  const tableData = useMemo<{
    current: PrescriptionFromPatientType[];
    previous: PrescriptionFromPatientType[];
  }>(() => {
    if (!activePatient) return { current: [], previous: [] };

    return activePatient?.patientDetails.prescriptions.reduce(
      (acc, next) => {
        if (next.hasCompletedDose) {
          acc.previous.push(next);
        } else {
          acc.current.push(next);
        }
        return acc;
      },
      { current: [] as PrescriptionFromPatientType[], previous: [] as PrescriptionFromPatientType[] }
    );
  }, [activePatient]);

  // const mobileViewData = useMemo<{
  //   current: IAccordionComponent;
  //   previous: IAccordionComponent;
  // }>(
  //   () =>
  //     (activePatient?.prescriptions ?? []).reduce(
  //       (acc, next) => {
  //         if (next.hasCompletedDose) {
  //           acc.previous.data.push({
  //             id: next.prescriptionId,
  //             title: next.drug,
  //             subtitle: next.pharmacy,
  //             body: mobileDrugBodyParams.map(d => ({
  //               field: d.displayName,
  //               value: next[d.fieldName]
  //             }))
  //           });
  //         } else {
  //           acc.current.data.push({
  //             id: next.prescriptionId,
  //             title: next.drug,
  //             subtitle: next.pharmacy,
  //             body: mobileDrugBodyParams.map(d => ({
  //               field: d.displayName,
  //               value: next[d.fieldName]
  //             }))
  //           });
  //         }
  //         return acc;
  //       },
  //       {
  //         current: {
  //           heading: "Drug",
  //           data: []
  //         },
  //         previous: {
  //           heading: "Drug",
  //           data: []
  //         }
  //       }
  //     ),
  //   [activePatient]
  // );

  const mobileViewData = useMemo<{
    current: AccordionProps;
    previous: AccordionProps;
  }>(() => {
    if (!activePatient) return { current: { heading: "Drug", data: [] }, previous: { heading: "Drug", data: [] } };

    return activePatient?.patientDetails.prescriptions.reduce<{
      current: AccordionProps;
      previous: AccordionProps;
    }>(
      (acc, next) => {
        const item = {
          id: next.prescriptionId,
          title: next.drug,
          subtitle: next.pharmacy,
          body: mobileDrugBodyParams.map(d => ({
            field: d.displayName,
            value: next[d.fieldName as keyof PrescriptionFromPatientType] as string
          }))
        };
        if (next.hasCompletedDose) {
          acc.previous.data.push(item);
        } else {
          acc.current.data.push(item);
        }
        return acc;
      },
      { current: { heading: "Drug", data: [] }, previous: { heading: "Drug", data: [] } } as {
        current: AccordionProps;
        previous: AccordionProps;
      }
    );
  }, [activePatient]);

  const handleTabClick = (tabName: string) => {
    setActivePrescriptionTabLabel(tabName);
    navigate(`/provider/patients/${userId}/details`, {
      state: {
        activePrescriptionTab: tabName
      }
    });
  };

  const { addNewPrescription, isPending, newPrescription } = useAddNewPrescription()

  const formik = useFormik({
    initialValues: {
      drug: "",
      dosage: "",
      duration: "",
      other: "",
      pharmacy: ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      drug: Yup.string().required("Drug is required"),
      dosage: Yup.string().required("Dosage is required"),
      duration: Yup.string().required("Duration is required"),
      pharmacy: Yup.string().required("Role is required")
    }),
    onSubmit: async () => {
      if (formik.values.dosage === "other" && formik.values.other.length < 1) {
        formik.setFieldError("other", "Frequency is required");
        return;
      }
      const requestData = {
        drug: formik.values.drug,
        dosage:
          formik.values.dosage === "other"
            ? formik.values.other
            : formik.values.dosage,
        duration: formik.values.duration,
        pharmacy: formik.values.pharmacy
      };
      addNewPrescription({ patientId: userId, data: requestData });
      updatePrescriptionList(newPrescription);
      setModalVisibility(false);

    }
  });

  const { token, isLoading, isError, error } = useGetDXScriptSSOLogin()

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full"><Loading /></div>
    )
  }

  if (isError) {
    const message = axios.isAxiosError(error) ? error?.response?.data?.error : "Error processing request";
    toast.error(message, { toastId: "customId" })
  }

  return (
    <div className="pr-3 pl-3 lg:pr-8">
      {(isPending) && (
        <div className="fixed inset-0 z-[10000000] backdrop-blur-sm flex justify-center w-full h-screen items-center">
          <Loading />
        </div>
      )}
      <div className="flex row justify-between items-center">
        <h2 className="text-[20px] text-[#324705] font-[600] py-4">
          Prescription
        </h2>
        <Button
          size="small"
          additionalClassname={
            "rounded-[40px] flex lg:hidden bg-[#3D874E] text-white text-xs font-medium"
          }
          label="Add New"
          onClick={() => {
            setModalVisibility(true);
            formik.resetForm();
          }}
        />
        <Button
          size="medium"
          additionalClassname={
            "rounded-[40px] bg-[#3D874E] hidden lg:flex text-white text-xs font-medium w-[140px]"
          }
          label="New Prescription"
          onClick={async () => {
            if (
              loggedInUser?.data.subscriptionPlan === "elite" ||
              loggedInUser?.data.subscriptionPlan === "pro"
            ) {
              window.open(
                `${process.env.REACT_APP_DXSCRIPT_LOGIN_URL}${token}`,
                "myWindow",
                "width=800,height=600"
              );
            } else {
              setShowNotAllowedModal(true);
            }
          }}
        />
      </div>
      <div className="bg-[##EEEEE9]">
        <div className="lg:shadow-table">
          <ListTabsWidget
            tabs={prescriptionTabs}
            handleTabClick={label => {
              handleTabClick(label);
            }}
            activeTabLabel={activePrescriptionTabLabel}
          />
        </div>
        <div className="hidden lg:block">
          {activePrescriptionTabLabel === "Current" && (
            <ReusableTable
              showTitleHideSearch
              padding={false}
              tableColumns={TABLE_COLUMNS}
              tableData={tableData.current}
              onClick={data => { }}
              searchFieldPlaceholder="Search Complaint"
            />
          )}
          {activePrescriptionTabLabel === "Previous" && (
            <ReusableTable
              showTitleHideSearch
              padding={false}
              tableColumns={TABLE_COLUMNS}
              tableData={tableData.previous}
              onClick={data => { }}
              searchFieldPlaceholder="Search Complaint"
            />
          )}
        </div>
        <div className="block lg:hidden">
          {activePrescriptionTabLabel === "Previous" && (
            <AccordionComponent {...mobileViewData.previous} />
          )}
          {activePrescriptionTabLabel === "Current" && (
            <AccordionComponent {...mobileViewData.current} />
          )}
        </div>
      </div>

      {showNotAllowedModal && (
        <ModalPortal>
          <div
            style={{
              backgroundColor: "rgba(38, 38, 38, 0.75)",
              zIndex: 100,
              display: "flex",
              justifyContent: "center"
            }}
            className="w-screen h-screen fixed flex flex-col justify-center items-center top-0 bg-opacity-20 backdrop-blur-[5px]"
          >
            <div className=" w-full sm:w-3/5 h-[85%] sm:h-[30%] bg-white rounded-md flex flex-col items-center space-y-8 justify-center">
              <div className="text-[#2E3011] text-[18px] font-[500]">
                Please upgrade your plan to elite or Pro to create prescriptions
              </div>
              <div className="flex flex-row items-center gap-5">
                <BtnButton
                  label="Close"
                  variant="secondary"
                  size="medium"
                  onClick={() => setShowNotAllowedModal(false)}
                />
                <BtnButton
                  label="Upgrade"
                  variant="primary"
                  size="medium"
                  onClick={() => {
                    navigate("/provider/subscription");
                  }}
                />
              </div>
            </div>
          </div>
        </ModalPortal>
      )}

      {isModalVisible && (
        <ModalPortal>
          <div
            style={{
              backgroundColor: "rgba(38, 38, 38, 0.75)",
              zIndex: 100,
              display: "flex",
              justifyContent: "center"
            }}
            className="w-screen h-screen fixed flex flex-col justify-center items-center top-0 bg-opacity-20 backdrop-blur-[5px]"
          >
            <div className="flex justify-end w-[90%] lg:w-[462px] mx-auto py-2">
              <div
                onClick={() => {
                  setModalVisibility(false);
                }}
                className="p-2 bg-white rounded-full cursor-pointer"
              >
                <CancelIcon className="h-full w-full" />
              </div>
            </div>
            <div className="bg-white min-h-[600px] max-h-[700px] w-[90%] lg:w-[462px] px-6 pt-6 pb-4 lg:p-6 shadow-lg rounded-[16px] border-box">
              <p className="text-[#2E3011] text-[24px] font-[600]">
                Add a new prescription
              </p>
              <form
                className="flex flex-col  items-center justify-center h-full"
                onSubmit={formik.handleSubmit}
              >
                <div className="w-full h-full px-2 pt-2">
                  <div className="w-full my-4">
                    <TextField
                      name="drug"
                      type="text"
                      placeholder="Name of drug"
                      label="Drug Description"
                      value={formik.values?.drug}
                      onChange={formik.handleChange}
                    />
                    <>
                      {formik.touched.drug && formik.errors.drug && (
                        <p className="mt-2 text-xs text-red-600 capitalize">
                          Drug description is required
                        </p>
                      )}
                    </>
                  </div>
                  <div className="w-full mt-8 mb-2">
                    <div className="pb-2 text-[#000] font-medium text-[16px]">
                      Frequency
                    </div>
                    <div className="w-full flex justify-start">
                      <CustomDropdown
                        name="dosage"
                        value={formik.values?.dosage}
                        optionsList={[
                          { name: "Once daily", value: "once daily" },
                          { name: "Twice daily", value: "twice daily" },
                          {
                            name: "Three times daily",
                            value: "three times daily"
                          },
                          {
                            name: "Four times daily",
                            value: "four times daily"
                          },
                          { name: "Other", value: "other" }
                        ]}
                        onChange={event => {
                          formik.setFieldValue("dosage", event.target.value);
                        }}
                        defaultValue="Select a dosage"
                        placeholder="Select a dosage"
                      />
                    </div>
                    <>
                      {formik.touched.dosage && formik.errors.dosage && (
                        <p className="mt-2 text-xs text-red-600 capitalize">
                          Frequency is required
                        </p>
                      )}
                    </>
                  </div>
                  {formik.values &&
                    formik.values.dosage &&
                    formik.values.dosage === "other" && (
                      <div className="w-full mb-8 mt-2">
                        <TextField
                          name="other"
                          type="text"
                          placeholder="Enter custom dosage"
                          value={formik.values?.other}
                          onChange={formik.handleChange}
                        />
                        <>
                          {formik.touched.other && formik.errors.other && (
                            <p className="mt-2 text-xs text-red-600 capitalize">
                              Frequency is required
                            </p>
                          )}
                        </>
                      </div>
                    )}
                  <div className="w-full my-8">
                    <TextField
                      name="duration"
                      type="text"
                      placeholder="Enter a duration"
                      label="Duration"
                      value={formik.values?.duration}
                      onChange={formik.handleChange}
                    />
                    <>
                      {formik.touched.duration && formik.errors.duration && (
                        <p className="mt-2 text-xs text-red-600 capitalize">
                          Duration is required
                        </p>
                      )}
                    </>
                  </div>
                  <div className="w-full my-8">
                    <TextField
                      name="pharmacy"
                      type="text"
                      placeholder="Enter a Pharmacy"
                      label="Pharmacy"
                      value={formik.values?.pharmacy}
                      onChange={formik.handleChange}
                    />
                    <>
                      {formik.touched.pharmacy && formik.errors.pharmacy && (
                        <p className="mt-2 text-xs text-red-600 capitalize">
                          Pharmacy is required
                        </p>
                      )}
                    </>
                  </div>
                  <div className="flex row justify-end mt-8 w-full">
                    <BtnButton
                      type="submit"
                      variant="primary"
                      label="Save"
                      // onClick={handleSubmit}
                      size="medium"
                      additionalClassname={
                        "rounded-[40px] bg-[#3D874E] w-[60px]"
                      }
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ModalPortal>
      )}
    </div>
  );
};

export default Prescription;
