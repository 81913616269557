import { useQuery } from '@tanstack/react-query';
import http from '../../../utils/http';
import { ErrorResponse } from '../../types';
import { EmergencyContacts } from './types/emergency.type';


export function useGetEmergencyContacts() {
  const { data, refetch, ...rest } = useQuery<EmergencyContacts, ErrorResponse>({
    queryKey: ['emergency contacts'],
    queryFn: async () => {
      const response = await http.get("emergency-contacts/contacts")
      return response.data;
    }
  });
  return {
    emergencyContactsData: data,
    ...rest,
  }
}





