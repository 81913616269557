import { format } from "date-fns";
import { Fragment, useMemo } from "react";
import { Column } from "react-table";
import { PracticeRegistration } from "../../types/practice_reg";

export function usePracticeRegistrationsTableCols() {
  const PRACTICE_INSUR_COLUMNS = useMemo<Column<PracticeRegistration>[]>(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Provider ID",
        accessor: "providerId",
      },
      {
        Header: "Business Address",
        accessor: "businessAddress1",
      },
      {
        Header: "City",
        accessor: "businessCity",
      },
      {
        Header: "State",
        accessor: "businessState",
      },
      {
        Header: "Zip Code",
        accessor: "businessZipCode",
      },
      {
        Header: "Created At",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => {
          return <Fragment>{value ? format(new Date(value), "yyyy-MM-dd") : "N/A"}</Fragment>;
        },
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
        Cell: ({ cell: { value } }) => {
          return <Fragment>{value ? format(new Date(value), "yyyy-MM-dd") : "N/A"}</Fragment>;
        },
      },
    ],
    []
  );

  return {
    PRACTICE_INSUR_COLUMNS,
  };
}
