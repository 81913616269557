import { useQuery } from '@tanstack/react-query';
import http from '../../../utils/http';
import { ErrorResponse } from '../../types';
import { PatientDetails } from './types/patient_details.type';


export function useGetPatientProfileDetails() {
  const { data, refetch, ...rest } = useQuery<PatientDetails, ErrorResponse>({
    queryKey: ['patient profile details'],
    queryFn: async () => {
      const response = await http.get("patients/details")
      return response.data;
    }
  });
  return {
    patientProfileDetails: data,
    ...rest,
  }
}





