import { useQuery } from '@tanstack/react-query';
import http from '../../../utils/http';
import { ErrorResponse, Schedule } from '../../types';

export function useGetProviderSchedule() {
  const { data, isLoading, refetch, ...rest } = useQuery<Schedule, ErrorResponse>({
    queryKey: ["get provider schedule"],
    queryFn: async () => {
      const response = await http.get(`schedule/provider`);
      return response.data;
    },
    // stale after 1 hour
    staleTime: 1000 * 60 * 60,
    refetchInterval: 1000 * 60 * 60, // refetch every hour
  });
  return {
    schedule: data as Schedule,
    isLoading,
    refetchProviderSchedule: refetch,
    ...rest,
  }
}