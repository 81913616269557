import axios from "axios";
import { format } from "date-fns";
import jsPDF from "jspdf";
import { Fragment, memo, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { getCurrentUser } from "../../../api/auth";
import { useProviderAuthState } from '../../../api/hooks/provider/auth/useProviderAuthState';
import { useEditDocumentation } from "../../../api/hooks/provider/useEditDocumentation";
import { useGetCompletedAppointmentData } from "../../../api/hooks/provider/useGetCompletedAppointmentData";
import { useGetProviderForm } from "../../../api/hooks/provider/useGetProviderForm";
import { useSaveChargeSheetDraft } from "../../../api/hooks/provider/useSaveChargeSheet";
import { useSaveFormResponse } from '../../../api/hooks/provider/useSaveFormResponse';
import { useSendChargeSheetToBilling } from '../../../api/hooks/provider/useSendChargeSheetToBilling';
import CustomDrawer from "../../../components/drawer";
import FormBuilder from "../../../components/form/FormBuilder";
import ReusableTable from "../../../components/ReusableTable";
import AccordionComponent from "../../../components/shared/Accordion";
import Button from "../../../components/shared/Button";
import { Loading } from "../../../components/shared/Loading";
import { useModal } from '../../../context/ModalContext';
import { AgreementModal } from "../../../reuseables/AgreementModal";
import { MainModal } from "../../../reuseables/mainModal";
import { CancelIcon } from "../../../svgs/CancelIcon";
import { SimpleChargeSheetModal } from "../../components/SimpleModal";
import { useDocsTableCols } from '../../components/table/hooks/documentation_table_cols';
import { FormTypes } from "../Settings/Templates";
import {
  ActiveData,
  DocumentationProps
} from "./interfaces/documentation";
import {
  AllDocumentsWithMobile,
  formTitleMapper,
  NoteValue
} from "./interfaces/index";

const Documentation = ({
  allDocumentation,
  patientDetails
}: DocumentationProps) => {
  const { user } = useProviderAuthState()
  const patientName = `${patientDetails?.firstName}${" "}${patientDetails?.lastName}`;
  const currentUser = getCurrentUser();
  const [isAddDocumentationView, setIsAddDocumentationView] = useState<boolean>(false);
  const [noteFields, setNoteFields] = useState<NoteValue>({});
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openPolicyModal, setOpenPolicyModal] = useState<boolean>(false);
  const [disableDownload, setDisableDownload] = useState<boolean>(true);
  const [activeData, setActiveData] = useState<ActiveData[]>([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [editDocumentationValues, setEditDocumentationValues] = useState<NoteValue>({});
  const [showLockConfirmModal, setShowLockConfirmModal] = useState<boolean>(false);
  const [selectedButtonType, setSelectedButtonType] = useState<"lock" | "edit">(
    "edit"
  );
  const { isModalOpen, closeModal, openModal, setModalOpen } = useModal();
  const { dateOfBirth, gender, insuranceName, userId: patientId } = patientDetails ?? {}

  const { TABLE_COLUMNS } = useDocsTableCols()

  const { completedAppointments } = useGetCompletedAppointmentData(patientId);

  const patientData = useMemo(() => {
    return {
      appointmentId: completedAppointments?.completedAppointments[0]?.appointmentId,
      patientName: patientName,
      providerId: currentUser?.userId,
      dateOfBirth: dateOfBirth,
      gender: gender,
      patientId,
      insurance: insuranceName,
      modeOfEncounter: completedAppointments?.completedAppointments[0]?.appointmentType as "virtual" | "physical",
      dateOfEncounter: completedAppointments?.completedAppointments[0]?.appointmentDate as string,
    }
  }, [patientName, dateOfBirth, gender, patientId, insuranceName, currentUser?.userId, completedAppointments?.completedAppointments])

  const handleDownload = () => {
    const content = document.getElementById("print");

    //TODO:  Find and remove the div you want to exclude from download
    const divToRemove = content?.querySelector(".no-print");
    if (divToRemove) {
      divToRemove.parentNode?.removeChild(divToRemove);
    }

    if (content) {
      const pdf = new jsPDF();
      pdf.html(content, {
        callback: function (doc) {
          doc.save("documentation.pdf")
        },
        margin: [10, 0, 10, 0],
        x: 0,
        y: 0,
        autoPaging: "text",
        width: 200,
        windowWidth: 500,
      })

    }
  };

  const mobileTableData = allDocumentation?.reduce(
    (acc: AllDocumentsWithMobile[], curr) => {
      const values = curr?.values;
      if (values && values.length > 0) {
        // Check if values is defined and not empty
        acc.push({
          id: String(curr?.id ?? ""),
          subtitle: format(new Date(curr?.created_at ?? 0), "MM/dd/yyyy"),
          title: formTitleMapper[curr?.noteType ?? "Unknown"],
          body:
            values.flatMap(value => {
              // Check if value is defined and not null before processing
              if (value) {
                return Object.entries(value)?.map(([field, val]) => ({
                  field,
                  value: Array.isArray(val) ? val?.join(", ") : val
                }));
              } else {
                return [];
              }
            }) || []
        });
      }
      return acc;
    },
    []
  );

  const isSubsequentVisit = allDocumentation?.some(
    (note: any) => note.noteType === "initialVisitNote"
  );
  const noteType: FormTypes = isSubsequentVisit
    ? "progressNote"
    : "initialVisitNote";

  const noteTypes: FormTypes[] = useMemo(
    () => ["progressNote", "initialVisitNote"],
    []
  );

  const print = () => {
    if (typeof window !== "undefined") {
      window.print();
    }
  };

  const { providerFormData } = useGetProviderForm({
    formType: noteTypes,
    providerId: user?.userId as string
  });

  const { data, isError, error, isLoading, refetch } = providerFormData[0];
  const {
    data: data2,
    isError: isError2,
    error: error2,
    isLoading: isloading
  } = providerFormData[1];

  if (isError) {
    const message = axios.isAxiosError(error)
      ? error?.response?.data?.error
      : "Error fetching progressNote";
    toast.error(message, { toastId: "customId" });
  }

  if (isError2) {
    const message = axios.isAxiosError(error2)
      ? error2?.response?.data?.error
      : "Error fetching initialVisitNote";
    toast.error(message, { toastId: "customId" });
  }

  const {
    editDocumentation,
    data: editDocsData,
    isPending: isUpdating
  } = useEditDocumentation(selectedButtonType);

  useEffect(() => {
    if (editDocsData?.updatedDocumentation) {
      refetch()
    }
  }, [editDocsData?.updatedDocumentation, refetch])

  const updateDocumentation = ({
    requestType
  }: {
    requestType: "lock" | "edit";
  }) => {
    editDocumentation({
      documentationId: activeData[0].documentationId,
      ...(requestType === "edit" && { values: [editDocumentationValues] }),
      ...(requestType === "lock" && { isLocked: true })
    });
    if (editDocsData?.updatedDocumentation) {
      const updatedActiveData = activeData.map((item: any) => ({
        ...item,
        values: [editDocumentationValues]
      }));
      setActiveData(updatedActiveData);
      //TODO: triggerUserRefresh(); see if this is needed to add from react-query
    }
  };

  const isChecked = (data: string, item: string) => {
    if (activeData?.length > 0 && activeData[0].values) {
      const value = activeData[0].values[0][data];
      if (Array.isArray(value)) {
        return value.some(val => {
          if (typeof val === "string") {
            return val === item;
          } else if (typeof val === "object") {
            return Object.values(val).some(v => v === true);
          }
          return false;
        });
      } else if (typeof value === "string") {
        return value.includes(item);
      }
    }
    return false;
  };

  const { postChargeSheetToBilling, isPending: isSendingToBilling } = useSendChargeSheetToBilling()

  const { saveChargeSheet, isPending: isSavingDraft } = useSaveChargeSheetDraft()

  const { saveFormResponse } = useSaveFormResponse();

  return (
    <Fragment>
      <div className="pr-3 pl-3 lg:pr-8">
        {isUpdating && (
          <div className="fixed inset-0 z-[10000000] backdrop-blur-sm flex justify-center w-full h-screen items-center">
            <Loading />
          </div>
        )}
        {isAddDocumentationView ? (
          <section className="shadow-lg rounded-[16px] border-box">
            <div className="flex justify-end mx-auto py-2">
              <div
                onClick={() => {
                  setIsAddDocumentationView(false);
                }}
              >
                <CancelIcon
                  height="50px"
                  width="50px"
                  className="cursor-pointer"
                />
              </div>
            </div>
            <div className="px-5">

              <p className="text-[rgb(46,48,17)] text-[24px] font-[600]">
                {formTitleMapper[noteType]}
              </p>

              <FormBuilder
                enableVoice={true}
                formParams={
                  !isLoading && (!isloading && isSubsequentVisit)
                    ? data?.progressNote?.noteFields
                    : data2?.initialVisitNote?.noteFields
                }
                formType={noteType}
                submitAction={() => {
                  saveFormResponse({ patientId: patientId, noteType, noteFields: [noteFields] },
                    {
                      // onSuccess: () => {
                      //   openChargeSheetModal()
                      // },
                      onError: () => {
                        setIsAddDocumentationView(true);
                        closeModal();
                      }
                    }
                  )
                  // openChargeSheetModal()
                }}
                isPreview={false}
                formAnswers={noteFields}
                setFormAnswers={setNoteFields}
              />
            </div>
          </section>
        ) : (
          <section>
            <div className="flex row justify-between items-center">
              <h2 className="text-[20px] text-[#324705] font-[600] py-4">
                Documentation
              </h2>
              <Button
                size="small"
                additionalClassname={
                  "rounded-[40px] flex lg:hidden bg-[#3D874E] text-white text-xs font-medium"
                }
                label="Add New"
                onClick={() => {
                  setIsAddDocumentationView(true);
                }}
              />
              <Button
                size="medium"
                additionalClassname={
                  "rounded-[40px] bg-[#3D874E] hidden lg:flex text-white text-xs font-medium w-[140px]"
                }
                label="Add Documentation"
                  onClick={() => {
                    setIsAddDocumentationView(true)
                  }
                  }
              />
            </div>
            <div className="bg-[##EEEEE9]">
              <div className="hidden lg:block">
                <ReusableTable
                  showTitleHideSearch
                  padding={false}
                  tableColumns={TABLE_COLUMNS}
                  tableData={allDocumentation}
                  onClick={data => {
                    setOpenDrawer(true);
                    setActiveData([data]);
                    setEditDocumentationValues(data?.values && data.values[0]);
                  }}
                />
              </div>
              <div className="block lg:hidden">
                <AccordionComponent data={mobileTableData} />
              </div>
              <CustomDrawer
                open={openDrawer}
                onClose={() => {
                  setOpenDrawer(false);
                  setIsEditMode(false);
                }}
                drawerTitle={formTitleMapper[activeData[0]?.noteType]}
              >
                <section className="p-5 print" id="print">
                  <section className="pb-5">
                    <section className="flex justify-between">
                      <p className="font-medium text-[20px] pb-2">
                        {patientName}
                      </p>
                      {noteTypes.includes(activeData[0]?.noteType) && (
                        <section>
                          {activeData[0]?.isLocked ? (
                            <Button
                              additionalClassname={"no-print"}
                              variant="secondary"
                              label="Download"
                              size="small"
                              onClick={() => setOpenPolicyModal(true)}
                            />
                          ) : (
                            <div className="flex gap-2">
                              <Button
                                additionalClassname={"no-print"}
                                variant="primary"
                                label={isEditMode ? "Save" : "Edit"}
                                size="small"
                                    loading={isUpdating}
                                    onClick={() => {
                                  if (isEditMode) {
                                    updateDocumentation({
                                      requestType: "edit"
                                    });
                                    setSelectedButtonType("edit");
                                  }
                                  setIsEditMode(!isEditMode);
                                }}
                              />
                              <Button
                                additionalClassname={"no-print"}
                                variant="secondary"
                                label="Lock"
                                size="small"
                                onClick={() => setShowLockConfirmModal(true)}
                              />
                            </div>
                          )}
                        </section>
                      )}
                    </section>
                    {activeData[0]?.created_at && (
                      <p className="pb-[10px]">
                        {format(
                          new Date(activeData[0]?.created_at),
                          "EEE, PPp"
                        )}
                      </p>
                    )}
                      <hr />
                  </section>
                  {activeData[0]?.values &&
                    (isEditMode ? (
                      <FormBuilder
                        enableVoice={true}
                        formParams={
                          !isLoading &&
                            (!isloading &&
                              activeData[0]?.noteType === "progressNote")
                            ? data?.progressNote?.noteFields
                            : data2?.initialVisitNote?.noteFields
                        }
                        formType={noteType}
                        submitAction={() => {
                          setIsEditMode(false);
                          updateDocumentation({ requestType: "edit" });
                          setSelectedButtonType("edit");
                        }}
                        isPreview={false}
                        formAnswers={editDocumentationValues}
                        setFormAnswers={setEditDocumentationValues}
                      />
                    ) : (
                        activeData.length > 0 &&
                        Object.keys(activeData[0].values[0] || {}).map(
                          (data, index) => (
                            <section key={index} className="text-[18px] pb-5">
                            <p className="font-semibold">{data}</p>
                            <div>
                              {typeof activeData[0].values[0][data] ===
                                "string" ? (
                                <p>{activeData[0].values[0][data]}</p>
                              ) : (
                                  Array.isArray(activeData[0].values[0][data]) &&
                                  (activeData[0].values[0][data] as string[]).map(
                                    (item, i) => {
                                      if (typeof item === "string") {
                                        return (
                                          <li key={i}>
                                            <input
                                              className="accent-[#3D874E]"
                                              type="checkbox"
                                              checked={isChecked(data, item)}
                                              readOnly={true}
                                            />
                                            <span>{item}</span>
                                          </li>
                                        );
                                    } else if (typeof item === "object") {
                                      return Object.keys(item)
                                        .filter(key => item[key] === true)
                                        .map((key, j) => (
                                          <div key={j}>
                                            <input
                                              className="accent-[#3D874E]"
                                              type="checkbox"
                                              checked={true}
                                              readOnly={true}
                                            />
                                            <span>{key}</span>
                                          </div>
                                        ));
                                    }
                                    return (
                                      <li key={i}>
                                        <input
                                          className="accent-[#3D874E]"
                                          type="checkbox"
                                          checked={isChecked(data, item)}
                                          readOnly={true}
                                        />
                                        <span>{item}</span>
                                      </li>
                                    );
                                  }
                                )
                              )}
                            </div>
                          </section>
                        )
                      )
                      ))}
                </section>
              </CustomDrawer>
            </div>
          </section>
        )}
      </div>
      <SimpleChargeSheetModal
        isSendingToBilling={isSendingToBilling}
        isSavingDraft={isSavingDraft}
        saveAction={({ appointmentId, procedureCodes, diagnosisCodes, modeOfEncounter, dateOfEncounter, locationOfEncounter, patientId }) => {
          saveChargeSheet({
            patientId,
            appointmentId,
            procedureCodes,
            diagnosisCodes,
            status: "draft",
            modeOfEncounter,
            dateOfEncounter,
            locationOfEncounter,
          }, {
            onError: () => {
              setIsAddDocumentationView(true);
              openModal();
            }
          });

          setIsAddDocumentationView(false)
          closeModal();
          // go back to previous page
          // navigate(-1);
        }}
        sendToBillingAction={({ appointmentId, procedureCodes, diagnosisCodes, modeOfEncounter,
          dateOfEncounter,
          locationOfEncounter, patientId }) => {
          postChargeSheetToBilling({
            patientId,
            appointmentId,
            procedureCodes,
            diagnosisCodes,
            status: "processing",
            modeOfEncounter,
            dateOfEncounter,
            locationOfEncounter,
          }, {
            onError: () => {
              setIsAddDocumentationView(true);
              openModal();
            }
          });
          setIsAddDocumentationView(false);
          closeModal();
          // go back to previous page
          // navigate(-1);
        }}
        open={isModalOpen}
        setOpen={setModalOpen}
        chargeSheetData={patientData}
      />

      <MainModal
        open={showLockConfirmModal}
        onClose={() => setShowLockConfirmModal(false)}
        closeAfterTransition={false}
        title="Locking this document would make it impossible to edit. Continue?"
        onOk={() => {
          setSelectedButtonType("lock");
          updateDocumentation({ requestType: "lock" });
          setShowLockConfirmModal(false);
        }}
        onCancel={() => setShowLockConfirmModal(false)}
      />
      <AgreementModal
        open={openPolicyModal}
        onClose={() => setOpenPolicyModal(false)}
        closeAfterTransition={false}
        title="Agreement: Download Note"
        onOk={() => {
          handleDownload();
          setOpenPolicyModal(false);
          setDisableDownload(false);
        }}
        onCancel={() => setOpenPolicyModal(false)}
      />
    </Fragment>
  );
};

export default memo(Documentation);
