import { ProviderCharge, status } from "../../../api/types";
export type SaveAction = {
  patientId: string;
  appointmentId: string;
  procedureCodes: string | string[];
  diagnosisCodes: string | string[];
  status: status;
  modeOfEncounter: "virtual" | "physical";
  locationOfEncounter: string;
  dateOfEncounter: string;
};

export interface UpdateChargeSheet {
  chargeId: string;
  procedureCodes?: string | string[];
  diagnosisCodes?: string | string[];
  status: status;
  modeOfEncounter?: "virtual" | "physical";
  locationOfEncounter?: string;
  dateOfEncounter?: string;
}
export interface ChargeSheetProps {
  updateChargesheet: ({
    chargeId,
    procedureCodes,
    diagnosisCodes,
    status,
    modeOfEncounter,
    locationOfEncounter,
    dateOfEncounter
  }: UpdateChargeSheet) => void;
  sendToBillingAction: ({
    patientId,
    appointmentId,
    procedureCodes,
    diagnosisCodes,
    status,
    modeOfEncounter,
    locationOfEncounter,
    dateOfEncounter
  }: SaveAction) => void;
  saveAction: ({
    patientId,
    appointmentId,
    procedureCodes,
    diagnosisCodes,
    status,
    modeOfEncounter,
    locationOfEncounter,
    dateOfEncounter
  }: SaveAction) => void;
  chargeSheetData: ProviderCharge | null;
  useDropdownPatientNameField?: boolean;
  isFromNewBilling?: boolean;
  isSendingToBilling: boolean;
  isSavingDraft: boolean;
  setIsDetailsView?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsNewBillingFormVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDetailsForStatusPaid?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFromNewBillingTable?: React.Dispatch<React.SetStateAction<boolean>>;
  IsFromNewBillingTable?: boolean;
  isUpdatingChargeSheet: boolean;
}

export interface ProcedureFieldInputType {
  text: string;
}

export interface DiagnosisFieldInputType {
  text: string;
}

export const ProcedureFieldInputInitialState: ProcedureFieldInputType[] = [
  { text: "" }
];

export const DiagnosisFieldInputInitialState: DiagnosisFieldInputType[] = [
  { text: "" }
];

export type FormValues = {
  patientName: string;
  dateOfBirth: string;
  gender:
    | "Ambiguous"
    | "Female"
    | "Male"
    | "Not applicable"
    | "Other"
    | "Unknown"
    | undefined;
  copay: string;
  providerName: string;
  dateOfEncounter: string;
  locationOfEncounter: string;
  modeOfEncounter: "virtual" | "physical";
  procedureCodes: string | string[];
  diagnosisCodes: string | string[];
  patientInsurance: string;
  patientId: string;
  appointmentId: string;
  status: status;
};

export const initialValues: FormValues = {
  patientName: "",
  dateOfBirth: "",
  gender: undefined,
  copay: "",
  providerName: "",
  dateOfEncounter: "",
  locationOfEncounter: "",
  modeOfEncounter: "virtual",
  procedureCodes: [],
  diagnosisCodes: [],
  patientInsurance: "",
  patientId: "",
  appointmentId: "",
  status: null
};
