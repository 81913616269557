export const MoneyMadeDashIcon = ({
  height = 90,
  width = 90,
  color = "#0863B8",
  className
}: {
  height?: number;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={height/2} cy={height/2} r={height/2} fill="#DCEEFF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.187 35.375c0-.725.587-1.313 1.312-1.313h35c.725 0 1.313.588 1.313 1.313v21c0 .725-.588 1.313-1.313 1.313h-35a1.312 1.312 0 01-1.312-1.313v-21zm2.625 1.313v18.374h32.375V36.689H28.812z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.187 35.375c0-.725.587-1.313 1.312-1.313h7c.725 0 1.313.588 1.313 1.313a8.313 8.313 0 01-8.313 8.313 1.312 1.312 0 01-1.312-1.313v-7zm2.625 1.313v4.222a5.696 5.696 0 004.223-4.222h-4.223zM26.187 49.375c0-.725.587-1.313 1.312-1.313a8.313 8.313 0 018.313 8.313c0 .725-.588 1.313-1.313 1.313h-7a1.312 1.312 0 01-1.312-1.313v-7zm2.625 1.465v4.222h4.223a5.696 5.696 0 00-4.223-4.222zM56.965 55.063h4.223V50.84a5.696 5.696 0 00-4.223 4.222zm-2.777 1.312a8.312 8.312 0 018.312-8.313c.725 0 1.313.588 1.313 1.313v7c0 .725-.588 1.313-1.313 1.313h-7a1.312 1.312 0 01-1.312-1.313zM54.188 35.375c0-.725.587-1.313 1.312-1.313h7c.725 0 1.313.588 1.313 1.313v7c0 .725-.588 1.313-1.313 1.313a8.312 8.312 0 01-8.312-8.313zm2.777 1.313a5.696 5.696 0 004.223 4.222v-4.222h-4.223zM39.312 45.875c0-3.39 2.333-6.563 5.688-6.563 3.355 0 5.687 3.173 5.687 6.563 0 3.39-2.332 6.563-5.687 6.563-3.355 0-5.688-3.173-5.688-6.563zM45 41.937c-1.478 0-3.063 1.53-3.063 3.938 0 2.409 1.585 3.938 3.063 3.938 1.477 0 3.062-1.53 3.062-3.938 0-2.409-1.585-3.938-3.062-3.938z"
        fill={color}
      />
    </svg>
  );
};
