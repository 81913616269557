import { ArrowForwardIos } from "@mui/icons-material";
import { CircularProgress, styled } from "@mui/material";
import { format } from "date-fns";

interface VisitCardProps {
  onClick?: (arg: any) => any;
  imageUrl?: string;
  cardData?: any;
  drawerIndexLoading?: number | null;
  index?: number;
}

const VisitCard = (props: VisitCardProps) => {
  const { onClick, cardData, index, drawerIndexLoading } = props;
  return (
    <VisitCardContainer className="m-visit cursor-pointer" onClick={onClick}>
      {drawerIndexLoading === index ? (
        <div className="flex justify-center">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="card-user-profile">
            <div className="user-image">
              <img
                src={cardData?.avatarUrl ?? "/assets/images/avatar.png"}
                className="user-img-src rounded-full"
                alt=""
              />
            </div>
            <div>
              <p className="user-name">{`${cardData?.firstName} ${cardData?.lastName}`}</p>
              <p className="user-desc">{cardData?.title}</p>
            </div>
          </div>
          <div className="card-br">
            <p className="card-title-head">Date</p>
            <p className="card-title-body">
              {format(new Date(cardData?.appointmentDate), "EEEE, dd LLL")}
            </p>
          </div>
          <div className="card-br">
            <p className="card-title-head">Time</p>
            <p className="card-title-body">{`${cardData?.appointmentStartTime} - ${cardData?.appointmentEndTime}`}</p>
          </div>
          <div>
            <p className="card-title-head">Location</p>
            <div className="card-title-body">
              {cardData?.appointmentType === "physical" ? "In-office" : "Virtual"}
            </div>
          </div>
          <div>
            <ArrowForwardIos className="arrow-icon" />
          </div>
        </>
      )}
    </VisitCardContainer>
  );
};

export default VisitCard;

const VisitCardContainer = styled("div")(() => ({
  position: "relative",
  width: "100%",
  height: "fit-content",
  backgroundColor: "#fff",
  padding: ".8rem",
  borderRadius: "4px",
  display: "grid",
  gridTemplateColumns: "35% 18% 18% 18% auto",
  gridTemplateRows: "auto autos",
  gap: "1%",
  alignItems: "center",
  justifyContent: "space-between",
  boxShadow: "-2px 4px 25px #EEEEE9",
  marginTop: "10px",

  "& .card-br": {
    borderRight: "1px solid #EEEEE9"
  },
  "& .arrow-icon": {
    fontSize: ".7rem",
    color: "#87928A"
  },
  "& .card-title-head": {
    fontSize: ".74rem",
    color: "#87928A"
  },

  "& .card-title-body": {
    fontSize: ".75rem",
    color: "#324705",
    fontWeight: "bold"
  },

  "& .card-user-profile": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    position: "relative",
    width: "100%",
    overflowX: "auto",
    borderRight: "1px solid #EEEEE9",

    "& .user-image": {
      width: "50px",
      height: "50px",
      marginRight: "6px",
      borderRadius: "100%",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",

      "& .user-img-src": {
        width: "100%",
        height: "auto",
        position: "relative"
      }
    },
    "& .user-name": {
      color: "#103C1B",
      fontWeight: "bold",
      fontSize: "1.12rem"
    },
    "& .user-desc": {
      color: "#103C1B",
      fontSize: ".78rem"
    }
  }
}));
