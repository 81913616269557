import React from "react";
import Button from "../../components/shared/Button";

interface SingleSearchedProviderProps {
  addAction: () => void;
  providerInfo: any;
}

const SingleSearchedProvider: React.FC<SingleSearchedProviderProps> = ({
  addAction,
  providerInfo
}) => {
  return (
    <div className="flex flex-col justify-between items-center grow w-[450px] h-[350px] rounded-[8px] shadow-[-2px_4px_25px_#EEEEE9] border p-4">
      <div className="w-full flex justify-between items-center">
        <div className="w-full flex justify-start items-center mr-4 py-6">
          <img
            className="h-[82px] w-[82px] rounded-full mr-6"
            src="/assets/images/avatar.png"
            alt="Profile img"
          />
          <div>
            <p className="text-[28px] font-[600] text-[#324705] pb-4">
              {`${providerInfo?.firstName} ${providerInfo?.lastName}`}
            </p>
            <p className="text-[18px] font-[400] text-[#103C1B]">
              {providerInfo?.title}
            </p>
          </div>
        </div>
      </div>
      <div className="w-full flex items-center my-4">
        <div className="w-[25%] text-[18px] font-[400] text-[#103C1B] mr-[70px]">
          NPI
        </div>
        <div className="w-[70%]text-[18px] font-[500] text-[#103C1B]">
          {providerInfo?.providerNPI}
        </div>
      </div>
      <div className="w-full flex items-center my-4">
        <div className="w-[25%] text-[18px] font-[400] text-[#103C1B] mr-[70px]">
          Email address
        </div>
        <div className="w-[70%]text-[18px] font-[500] text-[#103C1B]">
          {providerInfo?.email}
        </div>
      </div>
      <div className="w-full flex items-center justify-end">
        <Button
          onClick={addAction}
          label="Add Provider"
          size="medium"
          variant="secondary"
        />
      </div>
    </div>
  );
};

export default SingleSearchedProvider;
