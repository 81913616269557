import * as Yup from "yup";

export const billerLoginSchema = Yup.object({
  email: Yup.string().email("Invalid Email").required("Email is required"),
  password: Yup.string().required("Password is required")
});

export const billerInitialValues = {
  email: "",
  password: ""
};

export const spSettingSchema = Yup.object({
  paymentType: Yup.string().required("Payment Type is required"),
  accountHoldersName: Yup.string().required("Address is required"),
  cashPayAmount: Yup.string(),
  accountNumber: Yup.string().required("Account Number is required"),
  routingNumber: Yup.string().required("Routing Number is required"),
  CPBlicenseExpiryDate: Yup.string()
    .email()
    .required("Email Address is required")
});

export const spSettingsInitialValues = {
  paymentType: "directDeposit",
  accountHoldersName: "",
  cashPayAmount: 0,
  accountNumber: "",
  routingNumber: "",
  emailAddress: "",
  acceptsCash: 0,
  acceptsInsurance: 0,
  insuranceProviders: 0
};
