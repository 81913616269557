import { ChangeEvent, useState } from "react";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import { useAddPatientForProvider } from '../../../api/hooks/patient/usePostSingleProviderPatient';
import BtnButton from "../../../components/shared/BtnButton";
import ModalPortal from "../../../components/shared/ModalPortal";
import { CancelIcon } from "../../../svgs/CancelIcon";
import { Patient } from "../../../types/patientType";

const AddPatientFile = ({
  setShowAddPatientFile
}:
  {
    setShowAddPatientFile: React.Dispatch<React.SetStateAction<boolean>>;
  }) => {

  const [fileName, setFileName] = useState<string>("");
  const [patientFileData, setPatientFileData] = useState<Partial<Patient>[]>([]);
  const fileTemplate = [
    [
      "First Name",
      "Last Name",
      "Email",
      "Date of Birth",
      "Phone Number",
      "Insurance Name",
      "Insurance Number"
    ]
  ];
  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setFileName(file?.name);
      const fileUrl = URL.createObjectURL(file);

      const response = await fetch(fileUrl);
      const text = await response.text();
      const lines = text.split("\n");
      const fileData = lines.map(line =>
        line.replaceAll('"', "").replace("\r", "").split(",")
      );

      const incorrectHeaderIndex = fileData[0].findIndex(
        (item, index) =>
          item.toLowerCase() !== fileTemplate[0][index].toLowerCase()
      );

      if (incorrectHeaderIndex >= 0) {
        setFileName("Error in file headers");
        return toast.error(
          `${fileTemplate[0][incorrectHeaderIndex]} is missing or is in the wrong position`,
          {
            type: "error"
          }
        );
      } else {
        const data = fileData
          .slice(1)
          .reduce((accumulator: Partial<Patient>[], currentValue: string[]) => {
            const patientKeys: (keyof Patient)[] = [
              "firstName",
              "lastName",
              "email",
              "dateOfBirth",
              "phoneNumber",
              "insuranceName",
              "insuranceNumber"
            ];
            const newPatient: Partial<Patient> = currentValue.filter((item) => item).reduce(
              (prev, current, index) => ({
                ...prev,
                [patientKeys[index]]: current
              })
              , {});
            accumulator.push(newPatient);
            return accumulator;
          }, []);
        setPatientFileData(data);
      }
    }
  };

  const { addPatientForProvider, isPending } = useAddPatientForProvider()

  return (
    <ModalPortal>
      <section
        style={{
          backgroundColor: "rgba(38, 38, 38, 0.75)",
          zIndex: 100,
          display: "flex",
          justifyContent: "center"
        }}
        className="w-screen h-screen fixed justify-center items-center top-0 bg-opacity-20 backdrop-blur-[5px]"
        onClick={() => setShowAddPatientFile(false)}
      >
        <div
          className="w-[60%] bg-white px-5 py-10 flex flex-col gap-5 rounded-md"
          onClick={e => e.stopPropagation()}
        >
          <section className="flex justify-end">
            <CancelIcon
              width={32}
              height={32}
              className="cursor-pointer"
              onClick={() => setShowAddPatientFile(false)}
            />
          </section>
          <section className="text-[18px] flex flex-col gap-4">
            <p>
              Add multiple patients to your TempleHS account by uploading a CSV
              file
            </p>
            <p>
              Ensure that the columns in your file include our required fields:
              First Name, Last Name, and Email. Other fields are optional
            </p>
            <section className="w-[150px]">
              <CSVLink
                data={fileTemplate}
                filename="patient-list.csv"
                uFEFF={true}
              >
                <BtnButton
                  variant="secondary"
                  label="DownloadTemplate"
                  size="medium"
                  additionalClassname="!w-[150px]"
                />
              </CSVLink>
            </section>
          </section>
          <div className="relative text-center border-[1px] border-[#7E954B] border-dashed p-4 cursor-pointer rounded-md w-[200px]">
            <h4 className="cursor-pointer">{fileName || "Click to upload file"}</h4>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              className="opacity-0 inset-0 absolute block h-full w-full"
            />
          </div>
          <section className="flex justify-end mt-5">
            <BtnButton
              label="Save"
              size="small"
              variant="primary"
              loading={isPending}
              disabled={isPending}
              onClick={() => {
                addPatientForProvider({ data: patientFileData });
                setShowAddPatientFile(false);
              }}
            />
          </section>
        </div>
      </section>
    </ModalPortal>
  );
};

export default AddPatientFile;
