import { useQuery } from '@tanstack/react-query';
import adminHttp from '../../../utils/adminHttp';

type Props = {
  userId: string
}

export function useGetSingleAdminPatient({ userId }: Props) {
  const { data, isLoading, ...rest } = useQuery({
    queryKey: ["get single admin patient", userId],
    queryFn: async () => {
      const response = await adminHttp.get(`admins/patient/${userId}`);
      return response.data;
    },
    staleTime: Infinity,
  });
  return {
    singlePatientData: data,
    isLoading,
    ...rest,
  }
}