import { useQuery } from '@tanstack/react-query';
import http from '../../../utils/http';
import { ErrorResponse, ProviderCharges } from '../../types';


export function useGetChargeSheetProvider() {
  const { data, isLoading, refetch, ...rest } = useQuery<ProviderCharges, ErrorResponse>({
    queryKey: ["payment and billing table"],
    queryFn: async () => {
      const response = await http.get(`/charge-sheet/provider`);
      return response.data;
    },
    staleTime: Infinity, // data is never stale
  });
  return {
    data: data as ProviderCharges,
    isLoading,
    refetchChargeSheetProvider: refetch,
    ...rest,
  }
}