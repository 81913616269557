export const BusinessOfficeIcon = ({
  width = 18,
  height = 16,
  color = "#87928A",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <path
          id="Vector"
          d="M16.2075 3.57809H12.6503C12.4545 2.14219 10.9207 1 9.09324 1C7.26573 1 5.73193 2.14219 5.53613 3.57809H1.97902C1.42424 3.57809 1 4.00233 1 4.55711V14.021C1 14.5758 1.42424 15 1.97902 15H16.2401C16.7949 15 17.2191 14.5758 17.2191 14.021V4.55711C17.1865 4.03496 16.7622 3.57809 16.2075 3.57809ZM9.09324 1.68531C10.5618 1.68531 11.8019 2.5338 11.9977 3.61072H6.18881C6.38461 2.50117 7.62471 1.68531 9.09324 1.68531ZM1.97902 4.23077H5.82984H12.3566H16.2075C16.4033 4.23077 16.5338 4.36131 16.5338 4.55711V6.31935H12.683V5.82984C12.683 5.63403 12.5524 5.5035 12.3566 5.5035C12.1608 5.5035 12.0303 5.63403 12.0303 5.82984V6.31935H6.15618V5.82984C6.15618 5.63403 6.02564 5.5035 5.82984 5.5035C5.63403 5.5035 5.5035 5.63403 5.5035 5.82984V6.31935H1.65268V4.55711C1.65268 4.39394 1.78322 4.23077 1.97902 4.23077ZM16.2075 14.3473H1.97902C1.78322 14.3473 1.65268 14.2168 1.65268 14.021V6.97203H5.5035V7.46154C5.5035 7.65734 5.63403 7.78788 5.82984 7.78788C6.02564 7.78788 6.15618 7.65734 6.15618 7.46154V6.97203H12.0303V7.46154C12.0303 7.65734 12.1608 7.78788 12.3566 7.78788C12.5524 7.78788 12.683 7.65734 12.683 7.46154V6.97203H16.5338V14.021C16.5338 14.2168 16.4033 14.3473 16.2075 14.3473Z"
          fill={color}
          stroke={color}
          strokeWidth="0.32634"
          strokeMiterlimit="10"
        />
      </g>
    </svg>
  );
};
