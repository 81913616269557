import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import billerHttp from "../../../utils/billerHttp";
import { NewChargeSheet } from "../../types";

type Payload = {
  procedureCodes: string | string[]
  diagnosisCodes: string | string[]
  patientName: string,
  patientDateOfBirth: string,
  patientGender: "Ambiguous" | "Female" | "Male" | "Not applicable" | "Other" | "Unknown" | undefined,
  patientPrimaryInsurance: string
  providerName: string,
  dateOfAppointment: string,
  locationOfAppointment: string,
  modeOfAppointment: string
}

export const useCreateBillerChargeSheet = () => {
  const queryClient = useQueryClient();
  const { mutate, data, ...rest } = useMutation({
    mutationKey: ['create biller charge-sheet'],
    mutationFn: async ({ ...data }: Payload) => {
      const response = await billerHttp.post("billers/charge-sheet", { ...data })
      return response.data?.newChargeSheet;
    },

    onSuccess: () => {
      toast.success("Charge-sheet created successfully");
      queryClient.invalidateQueries({ queryKey: ['get all biller charge-sheet'] });
    },

    onError: (error) => {
      if (axios.isAxiosError(error)) {
        toast.error(
          error?.response?.data?.error || error.response?.statusText,
        );
      } else {
        toast.error(
          error?.message
          || "Error saving charge sheet",
        );
      }
    }
  });

  return {
    postBillerChargeSheet: mutate,
    chargeSheetData: data as NewChargeSheet || {},
    ...rest,
  };
}
