export const AdminReportsIcon = ({
  width = 24,
  height = 25,
  color = "white",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5133_520)">
        <path
          d="M15 5.75C14.9999 5.90286 15.0466 6.05208 15.1337 6.17768C15.2209 6.30327 15.3443 6.39925 15.4875 6.45275L12.75 9.1895V6.5C12.7499 6.35169 12.7059 6.20671 12.6235 6.08341C12.5411 5.9601 12.424 5.86399 12.287 5.80724C12.1499 5.75049 11.9992 5.73564 11.8537 5.76456C11.7082 5.79349 11.5746 5.86489 11.4697 5.96975L6.21972 11.2198C6.14809 11.2889 6.09095 11.3717 6.05164 11.4632C6.01234 11.5547 5.99165 11.6531 5.99078 11.7527C5.98992 11.8523 6.00889 11.951 6.0466 12.0432C6.08431 12.1354 6.14 12.2191 6.21042 12.2895C6.28084 12.36 6.36458 12.4157 6.45675 12.4534C6.54893 12.4911 6.64769 12.5101 6.74727 12.5092C6.84685 12.5083 6.94527 12.4876 7.03677 12.4483C7.12828 12.409 7.21103 12.3519 7.28022 12.2802L11.25 8.3105V11C11.25 11.1483 11.294 11.2933 11.3764 11.4166C11.4588 11.5399 11.576 11.636 11.713 11.6928C11.85 11.7495 12.0008 11.7644 12.1462 11.7354C12.2917 11.7065 12.4253 11.6351 12.5302 11.5302L16.5472 7.51325C16.6087 7.6781 16.7261 7.8161 16.879 7.90311C17.032 7.99012 17.2106 8.02061 17.3837 7.98924C17.5568 7.95787 17.7134 7.86664 17.826 7.7315C17.9387 7.59637 18.0003 7.42593 18 7.25V5.75C18 5.55109 17.921 5.36032 17.7803 5.21967C17.6396 5.07902 17.4489 5 17.25 5H15.75C15.5511 5 15.3603 5.07902 15.2196 5.21967C15.079 5.36032 15 5.55109 15 5.75ZM7.49997 14.75C7.69888 14.75 7.88965 14.829 8.0303 14.9697C8.17095 15.1103 8.24997 15.3011 8.24997 15.5V18.5C8.24997 18.6989 8.17095 18.8897 8.0303 19.0303C7.88965 19.171 7.69888 19.25 7.49997 19.25C7.30106 19.25 7.11029 19.171 6.96964 19.0303C6.82899 18.8897 6.74997 18.6989 6.74997 18.5V15.5C6.74997 15.3011 6.82899 15.1103 6.96964 14.9697C7.11029 14.829 7.30106 14.75 7.49997 14.75Z"
          fill="white"
        />
        <path
          d="M10.5 11.75C10.3011 11.75 10.1103 11.829 9.96967 11.9697C9.82902 12.1103 9.75 12.3011 9.75 12.5V18.5C9.75 18.6989 9.82902 18.8897 9.96967 19.0303C10.1103 19.171 10.3011 19.25 10.5 19.25C10.6989 19.25 10.8897 19.171 11.0303 19.0303C11.171 18.8897 11.25 18.6989 11.25 18.5V12.5C11.25 12.3011 11.171 12.1103 11.0303 11.9697C10.8897 11.829 10.6989 11.75 10.5 11.75ZM13.5 14C13.6989 14 13.8897 14.079 14.0303 14.2197C14.171 14.3603 14.25 14.5511 14.25 14.75V18.5C14.25 18.6989 14.171 18.8897 14.0303 19.0303C13.8897 19.171 13.6989 19.25 13.5 19.25C13.3011 19.25 13.1103 19.171 12.9697 19.0303C12.829 18.8897 12.75 18.6989 12.75 18.5V14.75C12.75 14.5511 12.829 14.3603 12.9697 14.2197C13.1103 14.079 13.3011 14 13.5 14ZM16.5 10.25C16.3011 10.25 16.1103 10.329 15.9697 10.4697C15.829 10.6103 15.75 10.8011 15.75 11V18.5C15.75 18.6989 15.829 18.8897 15.9697 19.0303C16.1103 19.171 16.3011 19.25 16.5 19.25C16.6989 19.25 16.8897 19.171 17.0303 19.0303C17.171 18.8897 17.25 18.6989 17.25 18.5V11C17.25 10.8011 17.171 10.6103 17.0303 10.4697C16.8897 10.329 16.6989 10.25 16.5 10.25Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 0.5C5.00544 0.5 4.05161 0.895088 3.34835 1.59835C2.64509 2.30161 2.25 3.25544 2.25 4.25V20.75C2.25 21.7446 2.64509 22.6984 3.34835 23.4016C4.05161 24.1049 5.00544 24.5 6 24.5H18C18.9946 24.5 19.9484 24.1049 20.6516 23.4016C21.3549 22.6984 21.75 21.7446 21.75 20.75V4.25C21.75 3.25544 21.3549 2.30161 20.6516 1.59835C19.9484 0.895088 18.9946 0.5 18 0.5L6 0.5ZM3.75 4.25C3.75 3.65326 3.98705 3.08097 4.40901 2.65901C4.83097 2.23705 5.40326 2 6 2H18C18.5967 2 19.169 2.23705 19.591 2.65901C20.0129 3.08097 20.25 3.65326 20.25 4.25V20.75C20.25 21.3467 20.0129 21.919 19.591 22.341C19.169 22.7629 18.5967 23 18 23H6C5.40326 23 4.83097 22.7629 4.40901 22.341C3.98705 21.919 3.75 21.3467 3.75 20.75V4.25Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5133_520">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
