import { useLocation, useNavigate } from "react-router-dom";
import MenuItem from "../../components/dashboard/MenuItem";
import { AdminComplaintsIcon } from "../../svgs/AdminComplaintsIcon";
import { AdminPatientsIcon } from "../../svgs/AdminpatientsIcon";
import { AdminProvidersIcon } from "../../svgs/AdminProvidersIcon";
import { AdminReportsIcon } from "../../svgs/AdminReportsIcon";
import { AdminServiceProvidersIcon } from "../../svgs/AdminServiceProvidersIcon";
import { AdminUsersIcon } from "../../svgs/AdminUsersIcon";
import { BusinessOfficeIcon } from "../../svgs/BusinessOfficeIcon";
import { ReactComponent as DashboardIcon } from "../../svgs/temple-dashboard.svg";

const AdminSidebar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleMenuClick = (path: string) => {
    navigate(`${path}`);
  };

  const menuItems = [
    {
      path: "/admin/overview",
      label: "Overview",
      icon: <DashboardIcon stroke="#fff" />,
      activeIcon: <DashboardIcon stroke="#ABE9B9" />
    },
    {
      path: "/admin/providers",
      label: "Providers",
      icon: <AdminProvidersIcon />,
      activeIcon: <AdminProvidersIcon color="#ABE9B9" />
    },
    {
      path: "/admin/patients",
      label: "Patients",
      icon: <AdminPatientsIcon />,
      activeIcon: <AdminPatientsIcon color="#ABE9B9" />
    },
    {
      path: "/admin/billers",
      label: "Service Providers",
      icon: <AdminServiceProvidersIcon />,
      activeIcon: <AdminServiceProvidersIcon color="#ABE9B9" />
    },
    {
      path: "/admin/reports",
      label: "Payment Reports",
      icon: <AdminReportsIcon />,
      activeIcon: <AdminReportsIcon color="#ABE9B9" />
    },
    {
      path: "/admin/complaints",
      label: "Complaints",
      icon: <AdminComplaintsIcon />,
      activeIcon: <AdminComplaintsIcon color="#ABE9B9" />
    },
    {
      path: "/admin/users",
      label: "Users",
      icon: <AdminUsersIcon />,
      activeIcon: <AdminUsersIcon color="#ABE9B9" />
    },
    {
      path: "/admin/business-office",
      label: "Business Office",
      icon: <BusinessOfficeIcon />,
      activeIcon: <BusinessOfficeIcon color="#ABE9B9" />
    }
  ];

  return (
    <div className="hidden lg:block relative w-full bg-[#000] min-h-[100vh] text-sm font-semibold border border-t-0 border-[#d1d2d4]-500 lg:left-0 lg:top-0 content-center lg:content-start text-left justify-between ml-0 pl-0">
      <div className="font-[600] text-[#ABE9B9] text-[28px] flex justify-start pl-8  items-center pt-[50px] pb-0 px-[20px] w-full">
        Tem<span className="text-white">ple</span>
      </div>
      <div className="h-[40px]" />
      <div className="h-[100vh] flex flex-col justify-between">
        <div>
          {menuItems.map(item => (
            <MenuItem
              key={item.label}
              isActive={location.pathname.startsWith(item.path)}
              icon={
                location.pathname === item.path ? item.activeIcon : item.icon
              }
              label={item.label}
              onClick={() => {
                handleMenuClick(item.path);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminSidebar;
