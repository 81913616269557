export const AdminServiceProvidersIcon = ({
  width = 20,
  height = 21,
  color = "white",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 16.5H11V13.5H14V11.5H11V8.5H9V11.5H6V13.5H9V16.5ZM2 20.5C1.45 20.5 0.979333 20.3043 0.588 19.913C0.196 19.521 0 19.05 0 18.5V6.5C0 5.95 0.196 5.47933 0.588 5.088C0.979333 4.696 1.45 4.5 2 4.5H6V2.5C6 1.95 6.196 1.479 6.588 1.087C6.97933 0.695667 7.45 0.5 8 0.5H12C12.55 0.5 13.021 0.695667 13.413 1.087C13.8043 1.479 14 1.95 14 2.5V4.5H18C18.55 4.5 19.021 4.696 19.413 5.088C19.8043 5.47933 20 5.95 20 6.5V18.5C20 19.05 19.8043 19.521 19.413 19.913C19.021 20.3043 18.55 20.5 18 20.5H2ZM2 18.5H18V6.5H2V18.5ZM8 4.5H12V2.5H8V4.5ZM2 18.5V6.5V18.5Z"
        fill={color}
      />
    </svg>
  );
};
