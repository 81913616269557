interface AdminOverviewCardProps {
  title: string;
  icon: React.ReactNode;
  dataCount: number | string;
  footerText: string;
  onClick?: (arg: any) => void;
}

const AdminOverviewCard: React.FC<AdminOverviewCardProps> = ({
  title,
  icon,
  dataCount,
  footerText,
  onClick = () => {}
}) => {
  return (
    <div className="border-t-2 rounded-[24px] shadow-md shadow-[-2px 4px 25px #EEEEE9] bg-[#fff] w-full h-[198px] py-2 px-4 box-border flex items-center justify-start">
      <div className="mr-8">{icon}</div>
      <div className="flex flex-col">
        <p className="text-[20px] text-[#324705] font-[500]">{title}</p>
        <p className="text-[40px] text-[#324705] font-[600]">{dataCount}</p>
        <p className="text-[20px] text-[#324705] font-[500]">{footerText}</p>
      </div>
    </div>
  );
};

export default AdminOverviewCard;
