import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import adminHttp, { setAdminToken } from "../../../../utils/adminHttp";

type Payload = {
  email: string,
  password: string
}

type Props = {
  setLoginError: React.Dispatch<React.SetStateAction<string>>
}

export const useAdminLogin = ({ setLoginError }: Props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { state } = useLocation();
  const from = state?.path || '/admin/overview';


  const { mutate, data, variables, reset, ...rest } = useMutation({
    mutationKey: ['admin sign-in'],
    mutationFn: async ({ ...credentials }: Payload) => {
      const response = await adminHttp.post("admins/login", {
        ...credentials
      });
      return response.data;

    },

    onSuccess: ({ token }) => {
      queryClient.invalidateQueries({
        queryKey: ['admin dashboard']
      });
      setAdminToken(token);
      navigate(from, { replace: true });
      setLoginError("")
    },

    onError: (error) => {
      if (axios.isAxiosError(error)) {
        toast.error(
          error?.response?.data?.error || error.response?.statusText,
        );
        setLoginError(error?.response?.data?.error)
      } else {
        toast.error(
          error?.message
          || "Error creating account, try again",
        );
      }
    },
  });

  return {
    adminLogin: mutate,
    variables,
    ...rest,
  };
}
