import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Spacer from "../../components/Spacer";
import { ReactComponent as LogoutIcon } from "../../svgs/temple-logout.svg";
import { styled } from "@mui/material";
import SPSidebarMenu from "./SPSidebarMenu";
import { SPClaimForReviewSIcon } from "../../svgs/SPClaimForReviewSIcon";
import { SPClaimSIcon } from "../../svgs/SPClaimSIcon";
import { SPERASIcon } from "../../svgs/SPERASIcon";
import { ProviderOverviewSidebarIcon } from "../../svgs/ProviderOverviewSidebarIcon";
import { ProviderSettingsSidebarIcon } from "../../svgs/ProviderSettingsSidebarIcon";
import { SPproviderSIcon } from "../../svgs/SPproviderSIcon";

const SPSidebar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleMenuClick = (path: string) => {
    navigate(`${path}`);
  };

  const menuItems = [
    {
      path: "/biller/dashboard",
      label: "Dashboard",
      icon: <ProviderOverviewSidebarIcon color="#000" />,
      activeIcon: <ProviderOverviewSidebarIcon color="#3D874E" />
    },
    {
      path: "/biller/providers",
      label: "Providers",
      icon: <SPproviderSIcon color="#000" />,
      activeIcon: <SPproviderSIcon color="#3D874E" />
    },
    {
      path: "/biller/claims-for-review",
      label: "Claims for review",
      icon: <SPClaimForReviewSIcon color="#000" />,
      activeIcon: <SPClaimForReviewSIcon color="#3D874E" />
    },
    {
      path: "/biller/all-claims",
      label: "All claims",
      icon: <SPClaimSIcon color="#000" />,
      activeIcon: <SPClaimSIcon color="#3D874E" />
    },

    {
      path: "/biller/eras",
      label: "ERAs",
      icon: <SPERASIcon color="#000" />,
      activeIcon: <SPERASIcon color="#3D874E" />
    },
    {
      path: "/biller/settings",
      label: "Settings",
      icon: <ProviderSettingsSidebarIcon color="#000" />,
      activeIcon: <ProviderSettingsSidebarIcon color="#3D874E" />
    }
  ];

  const bottomMenus = [
    {
      path: "",
      label: "Log out",
      icon: <LogoutIcon stroke="#000" />,
      activeIcon: <LogoutIcon stroke="#3D874E" />
    }
  ];

  return (
    <SidebarContainer>
      <div className="w-full">
        <div className="p-[25px]">
          <div className="text-[#34623F] text-[28px] font-[600]">TempleHS </div>
        </div>
        <Spacer height={30} />
        <div className="w-[100%]">
          {menuItems.map(item => (
            <SPSidebarMenu
              key={item.label}
              isActive={location.pathname.startsWith(item.path)}
              icon={
                location.pathname === item.path ? item.activeIcon : item.icon
              }
              label={item.label}
              onClick={() => {
                if (item.label === "Log out") {
                  localStorage.removeItem("temple_biller_token");
                  navigate("/biller/login");
                } else {
                  handleMenuClick(item.path);
                }
              }}
            />
          ))}
        </div>
      </div>
      <footer className="w-full flex flex-row items-center">
        <div className="bottom-0 w-full">
          {bottomMenus.map(item => (
            <SPSidebarMenu
              key={item.label}
              isActive={location.pathname === item.path}
              icon={
                location.pathname === item.path ? item.activeIcon : item.icon
              }
              label={item.label}
              onClick={() => {
                if (item.label === "Log out") {
                  localStorage.clear();
                  navigate("/biller/login");
                } else {
                  handleMenuClick(item.path);
                }
              }}
            />
          ))}
        </div>
      </footer>
    </SidebarContainer>
  );
};

const SidebarContainer = styled("div")(() => ({
  position: "relative",
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-between",
  paddingBottom: "10%",

  backgroundColor: "#fff",
  boxShadow:
    "0px 4.62128px 18.4851px rgba(53, 55, 81, 0.06), 7px 0px 25px rgba(192, 191, 191, 0.05);",

  "& .side_user_avatar": {
    backgroundColor: "#F8F8F7",
    border: "1px solid #CECFC5",
    padding: ".6rem",
    borderRadius: "30px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly"
  },
  "& .user_avatar_container": {
    width: "30px",
    height: "30px",
    borderRadius: "100%",
    overflow: "hidden",
    border: "1px solid red",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  }
}));

export default SPSidebar;
