export const SPCheckWhite = ({
  width = 35,
  height = 35,
  color = "white"
}: {
  width?: number | string;
  height?: number | string;
  color?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33105 7.33115C10.572 4.09026 15.0529 2.0835 20 2.0835C24.9472 2.0835 29.4281 4.09026 32.669 7.33116L31.7851 8.21505L32.669 7.33117C35.9099 10.5721 37.9167 15.053 37.9167 20.0002C37.9167 24.9473 35.9099 29.4282 32.669 32.6691C29.4281 35.91 24.9472 37.9168 20 37.9168C15.0529 37.9168 10.572 35.91 7.33105 32.6691L8.21492 31.7852L7.33104 32.6691C4.09014 29.4282 2.08337 24.9473 2.08337 20.0002C2.08337 15.053 4.09014 10.5721 7.33103 7.33117L7.33105 7.33115ZM20 4.5835C15.7425 4.5835 11.8901 6.3077 9.0988 9.09894C6.30756 11.8902 4.58337 15.7427 4.58337 20.0002C4.58337 24.2577 6.30758 28.1101 9.09881 30.9014C11.8901 33.6926 15.7425 35.4168 20 35.4168C24.2575 35.4168 28.11 33.6926 30.9012 30.9014C33.6925 28.1101 35.4167 24.2577 35.4167 20.0002C35.4167 15.7427 33.6925 11.8902 30.9012 9.09893C28.11 6.3077 24.2575 4.5835 20 4.5835Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.2171 14.1161C29.7053 14.6043 29.7053 15.3957 29.2171 15.8839L19.2171 25.8839C18.729 26.372 17.9375 26.372 17.4494 25.8839L12.4494 20.8839C11.9612 20.3957 11.9612 19.6043 12.4494 19.1161C12.9375 18.628 13.729 18.628 14.2171 19.1161L18.3333 23.2322L27.4494 14.1161C27.9375 13.628 28.729 13.628 29.2171 14.1161Z"
        fill={color}
      />
    </svg>
  );
};
