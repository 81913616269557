import { format } from "date-fns";
import { CalendarDateIcon } from "../../../../svgs/CalendarDateIcon";
import { CalendarIcon } from "../../../../svgs/CalendarIcon";
import { EmailIconBox } from "../../../../svgs/EmailIconBox";
import { HeightIcon } from "../../../../svgs/HeightIcon";
import { HomeIcon } from "../../../../svgs/HomeIcon";
import { PharmacyHomeIcon } from "../../../../svgs/PharmacyHomeIcon";
import { PhoneIcon } from "../../../../svgs/PhoneIcon";
import { UserProfileIcon } from "../../../../svgs/UserProfileIcon";
import { WeightIcon } from "../../../../svgs/WeightIcon";
import { calculateAge, toFeet } from "../../../../utils/helpers";
import { PatientResultType } from "./patientDetails";


export const itemValues = [
  {
    icon: CalendarDateIcon,
    key: "dateOfBirth",
    keyTitle: "Date of Birth",
    hasIcon: true,
    fn: (value?: PatientResultType) => {
      return value ? (
        `${format(
          new Date(value?.patient.dateOfBirth),
          "MM/dd/yyyy"
        )} | ${calculateAge(
          format(new Date(value?.patient.dateOfBirth), "MM/dd/yyyy")
        )} years old`
      ) : (
        <span className="ml-1">&#8212;</span>
      );
    }
  },
  {
    icon: UserProfileIcon,
    key: "pronouns",
    keyTitle: "Pronouns",
    hasIcon: true,
    fn: (value?: PatientResultType) => {
      return value?.patient.pronoun ?? <span className="ml-1">&#8212;</span>;
    }
  },
  {
    icon: PhoneIcon,
    key: "phoneNumber",
    keyTitle: "Phone Number",
    hasIcon: true,
    fn: (value?: PatientResultType) => {
      return (
        value?.patient.phoneNumber ?? <span className="ml-1">&#8212;</span>
      );
    }
  },
  {
    icon: HomeIcon,
    key: "address",
    keyTitle: "Address",
    hasIcon: true,
    fn: (value?: PatientResultType) => {
      return value?.patient.address ?? <span className="ml-1">&#8212;</span>;
    }
  },
  {
    icon: HomeIcon,
    key: "city",
    keyTitle: "City",
    hasIcon: true,
    fn: (value?: PatientResultType) => {
      return value?.patient.city ?? <span className="ml-1">&#8212;</span>;
    }
  },
  {
    icon: HomeIcon,
    key: "state",
    keyTitle: "State",
    hasIcon: true,
    fn: (value?: PatientResultType) => {
      return value?.patient.state ?? <span className="ml-1">&#8212;</span>;
    }
  },
  {
    icon: HomeIcon,
    key: "zipcode",
    keyTitle: "Zip Code",
    hasIcon: true,
    fn: (value?: PatientResultType) => {
      return value?.patient.zipCode ?? <span className="ml-1">&#8212;</span>;
    }
  },
  {
    icon: EmailIconBox,
    key: "email",
    keyTitle: "Email Address",
    hasIcon: true,
    fn: (value?: PatientResultType) => {
      return value?.patient.email ?? <span className="ml-1">&#8212;</span>;
    }
  },
  {
    icon: WeightIcon,
    key: "weight",
    keyTitle: "Weight",
    hasIcon: true,
    fn: (value?: PatientResultType) => {
      return value && value?.medicalRecord?.[0]?.weight ? (
        `${value?.medicalRecord[0].weight} lb`
      ) : (
        <span className="ml-1">&#8212;</span>
      );
    }
  },
  {
    icon: HeightIcon,
    key: "height",
    keyTitle: "Height",
    hasIcon: true,
    fn: (value?: PatientResultType) => {
      return value && value?.medicalRecord?.[0]?.height ? (
        <span>
          {toFeet(Number(value?.medicalRecord[0].height)).feet}
          <>&prime;</>
          {toFeet(Number(value?.medicalRecord[0].height)).inches}
          <>&Prime;</>
        </span>
      ) : (
        <span className="ml-1">&#8212;</span>
      );
    }
  },
  {
    icon: CalendarIcon,
    key: "lastVisit",
    keyTitle: "Last Visit",
    hasIcon: false,
    fn: (value?: PatientResultType) => {
      return value && value?.patient?.lastVisit ? (
        format(new Date(value?.patient?.lastVisit), "MMMM dd, yyyy")
      ) : (
        <span className="ml-1">&#8212;</span>
      );
    }
  },
  {
    icon: CalendarDateIcon,
    key: "nextVisit",
    keyTitle: "Next Visit",
    hasIcon: false,
    fn: (value?: PatientResultType) => {
      return value && value?.patient?.nextVisit ? (
        format(new Date(value?.patient?.nextVisit), "MMMM dd, yyyy")
      ) : (
        <span className="ml-1">&#8212;</span>
      );
    }
  },
  {
    icon: PharmacyHomeIcon,
    key: "pharmacy",
    keyTitle: "Pharmacy",
    hasIcon: true,
    fn: (value?: PatientResultType) => {
      return value?.patient?.pharmacy ?? <span className="ml-1">&#8212;</span>;
    }
  },
  {
    icon: PharmacyHomeIcon,
    key: "pharmacyAddress",
    keyTitle: "Pharmacy Address",
    hasIcon: true,
    fn: (value?: PatientResultType) => {
      return (
        value?.patient?.pharmacyAddress ?? <span className="ml-1">&#8212;</span>
      );
    }
  }
];