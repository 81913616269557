import { Link, useLocation } from "react-router-dom";
import { getCurrentUser } from "../../api/auth";
import SubscriptionTable from "../../components/SubscriptTable";
import { CurrentUser } from "../../types/table";
import { subscriptionData } from "../../utils/consts";
import ProviderDashboardLayout from "../components/dashboard/ProviderDashboardLayout";

const ProviderSubscription = () => {
  const location = useLocation();
  const currentUser: CurrentUser = getCurrentUser();

  return (
    <ProviderDashboardLayout headerTitle="Subscription">
      <div className="w-full sm:pl-5 mt-8 space-y-4">
        <div className="">
          You are currently on{" "}
          <span className="font-bold">
            {currentUser?.subscriptionPlan} plan,{" "}
          </span>
          your plan expires on{" "}
          <span className="font-bold">{currentUser?.planEndDate}. </span>
        </div>
        <div className="text-center font-bold">Provider Billing Schedule</div>
        <SubscriptionTable
          cancel_url={location.pathname}
          currentUser={currentUser}
          data={subscriptionData}
        />
        <div className="pb-8 flex">
          <p>*** TempleHS billing service charge</p>
          <p>
            Sign up as a biller and do your billing yourself, use a biller or
            any billing service if you do not want to use our internal billing
            service. For more information, refer to the{" "}
            <Link
              to={`${process.env.REACT_APP_LANDING_PAGE_URL}/biller`}
              className="underline"
            >
              billing page
            </Link>
          </p>
        </div>
      </div>
    </ProviderDashboardLayout>
  );
};

export default ProviderSubscription;
