import { useNavigate } from "react-router-dom";
import Spacer from "../Spacer";
import Button from "../shared/Button";
import { ReactComponent as SignupSuccessIcon } from "../../svgs/success-icon.svg";

const SignupSuccess = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full box-border lg:w-[614px] bg-white flex flex-col justify-center h-full">
      <div className="text-center">
        <div className="w-full mx-auto flex justify-center">
          <SignupSuccessIcon />
        </div>
        <Spacer height={30} />
        <p className="text-[34px] font-[800] text-[#103C1B]">
          Welcome to TempleHS
        </p>
        <Spacer height={10} />
        <p className="font-[400] text-[20px] text-[#103C1B] w-full lg:w-[555px] mx-auto">
          Your account has been created successfully.
        </p>
      </div>
      <Spacer height={40} />
      <div className="flex justify-center items-center w-full mx-auto">
        <Button
          additionalClassname={"text-[18px] font-[400]"}
          width={178}
          height={60}
          onClick={() => navigate("/signup/insurance")}
          type="submit"
          variant="primary"
          label="Sign in now"
        />
      </div>
    </div>
  );
};

export default SignupSuccess;
