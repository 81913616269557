export const ProviderOverviewSidebarIcon = ({
  width = 20,
  height = 20,
  color = "",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      className={className}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.14189 3.50117C3.7883 3.50117 3.50166 3.78781 3.50166 4.14141V7.45937C3.50166 7.81297 3.7883 8.09961 4.14189 8.09961H7.45986C7.81346 8.09961 8.1001 7.81297 8.1001 7.45937V4.14141C8.1001 3.78781 7.81346 3.50117 7.45986 3.50117H4.14189ZM2.20166 4.14141C2.20166 3.06984 3.07033 2.20117 4.14189 2.20117H7.45986C8.53142 2.20117 9.4001 3.06984 9.4001 4.14141V7.45937C9.4001 8.53094 8.53142 9.39961 7.45986 9.39961H4.14189C3.07033 9.39961 2.20166 8.53094 2.20166 7.45937V4.14141Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5403 3.50117C12.1867 3.50117 11.9001 3.78781 11.9001 4.14141V7.45937C11.9001 7.81297 12.1867 8.09961 12.5403 8.09961H15.8583C16.2119 8.09961 16.4985 7.81297 16.4985 7.45937V4.14141C16.4985 3.78781 16.2119 3.50117 15.8583 3.50117H12.5403ZM10.6001 4.14141C10.6001 3.06984 11.4688 2.20117 12.5403 2.20117H15.8583C16.9299 2.20117 17.7985 3.06984 17.7985 4.14141V7.45937C17.7985 8.53094 16.9299 9.39961 15.8583 9.39961H12.5403C11.4688 9.39961 10.6001 8.53094 10.6001 7.45937V4.14141Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.14189 11.8996C3.7883 11.8996 3.50166 12.1863 3.50166 12.5398V15.8578C3.50166 16.2114 3.7883 16.498 4.14189 16.498H7.45986C7.81346 16.498 8.1001 16.2114 8.1001 15.8578V12.5398C8.1001 12.1863 7.81346 11.8996 7.45986 11.8996H4.14189ZM2.20166 12.5398C2.20166 11.4683 3.07033 10.5996 4.14189 10.5996H7.45986C8.53142 10.5996 9.4001 11.4683 9.4001 12.5398V15.8578C9.4001 16.9294 8.53142 17.798 7.45986 17.798H4.14189C3.07033 17.798 2.20166 16.9294 2.20166 15.8578V12.5398Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5403 11.8996C12.1867 11.8996 11.9001 12.1863 11.9001 12.5398V15.8578C11.9001 16.2114 12.1867 16.498 12.5403 16.498H15.8583C16.2119 16.498 16.4985 16.2114 16.4985 15.8578V12.5398C16.4985 12.1863 16.2119 11.8996 15.8583 11.8996H12.5403ZM10.6001 12.5398C10.6001 11.4683 11.4688 10.5996 12.5403 10.5996H15.8583C16.9299 10.5996 17.7985 11.4683 17.7985 12.5398V15.8578C17.7985 16.9294 16.9299 17.798 15.8583 17.798H12.5403C11.4688 17.798 10.6001 16.9294 10.6001 15.8578V12.5398Z"
        fill={color}
      />
    </svg>
  );
};
