import { Dispatch, createContext, useState } from "react";

export interface SelectedUserContextType {
  selectedPatientId: string;
  setSelectedPatientId: Dispatch<React.SetStateAction<string>>
}
type Props = { children: React.ReactNode }

export const SelectedUserContext = createContext<SelectedUserContextType>({
  selectedPatientId: "",
  setSelectedPatientId: () => { }
})

export const SelectedUserProvider = ({ children }: Props) => {
  const [selectedPatientId, setSelectedPatientId] = useState<string>("");

  return (
    <SelectedUserContext.Provider value={{ selectedPatientId, setSelectedPatientId }}>
      {children}
    </SelectedUserContext.Provider>
  )
}