import { FC } from 'react';
import FormBuilder, { FormFields } from '../../../../components/form/FormBuilder';
import { FormTypes } from '../../../pages/Settings/Templates';

const Form: FC<{ form: FormFields[]; formToView: FormTypes; fullScreen?: boolean; }> = ({ form, formToView, fullScreen = false }) => {
  return (
    <div className='relative pt-5 h-full overflow-hidden'>
      <FormBuilder
        formParams={form}
        formType={formToView}
        submitAction={() => { }}
        isPreview={true}
      />
    </div>
  )
}

export default Form
