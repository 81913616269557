import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AdminAuthRouteContext } from "../../utils/AdminAuthRoute";
import { AdminLogoutIcon } from "../../svgs/AdminLogoutIcon";
import { getCurrentAdminUser } from "../../api/admin";

interface AdminHeaderProps {
  currentUserInfo?: any;
  headerTitle?: string;
}

const AdminHeader: React.FC<AdminHeaderProps> = ({
  currentUserInfo,
  headerTitle
}) => {
  const navigate = useNavigate();
  const adminUser = useContext(AdminAuthRouteContext);
  const currentUser = getCurrentAdminUser();

  if (!adminUser) {
    return null;
  }

  return (
    <div className="hidden lg:flex z-10 top-0 items-center justify-between bg-[#FFFFFF] w-full h-[100px] m-auto mt-0 relative">
      <div className="text-[24px] font-[600] text-[#324705] pl-0 mt-8 m-auto w-[100%] ml-3">
        {headerTitle}
      </div>
      <div className="flex items-center justify-end mr-4 w-full">
        <span className="text-[16px] font-bold text-[#324705]">
          {currentUser && `${currentUser?.firstName} ${currentUser?.lastName}`}
        </span>
        <button
          onClick={() => {
            localStorage.removeItem("temple_admin_token");
            navigate("/admin/login");
          }}
          className="pl-6 flex items-center"
        >
          <AdminLogoutIcon /> <div className="w-2" /> Log Out
        </button>
      </div>
    </div>
  );
};

export default AdminHeader;
