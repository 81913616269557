import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import adminHttp from "../../../utils/adminHttp";


type Payload = {
  userId: string;
  status: string
}

export const useUpdateAdminComplaints = () => {
  const queryClient = useQueryClient();
  const { mutate, data, variables, ...rest } = useMutation({
    mutationKey: ['update admin complaints'],
    mutationFn: async ({ userId, status }: Payload) => {
      const response = await adminHttp.patch(`complaint/status/${userId}`, {
        status
      });
      return response.data;
    },

    onSuccess: () => {
      toast.success(
        `updated successfully`,
        { toastId: "customId" }
      );
      queryClient.invalidateQueries({
        queryKey: ["get all admin complaints list"]
      });
    },

    onError: (error) => {
      if (axios.isAxiosError(error)) {
        toast.error(
          `Error updating data ` || error.response?.statusText,
          { toastId: "customId" }
        );

      } else {
        toast.error(
          error?.message
          || "Error processing your request",
        );
      }
    },
  });

  return {
    updateAdminComplaint: mutate,
    data,
    variables,
    ...rest,
  };
}