export const AdminRevenueIcon = ({
  width = 90,
  height = 90,
  color = "#34623F",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="45" cy="45" r="45" fill="#D8F7E0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.1875 35.3749C26.1875 34.65 26.7751 34.0624 27.5 34.0624H62.5C63.2249 34.0624 63.8125 34.65 63.8125 35.3749V56.3749C63.8125 57.0997 63.2249 57.6874 62.5 57.6874H27.5C26.7751 57.6874 26.1875 57.0997 26.1875 56.3749V35.3749ZM28.8125 36.6874V55.0624H61.1875V36.6874H28.8125Z"
        fill="#34623F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.1875 35.3749C26.1875 34.65 26.7751 34.0624 27.5 34.0624H34.5C35.2249 34.0624 35.8125 34.65 35.8125 35.3749C35.8125 39.9658 32.0909 43.6874 27.5 43.6874C26.7751 43.6874 26.1875 43.0998 26.1875 42.3749V35.3749ZM28.8125 36.6874V40.9102C30.8995 40.4172 32.5423 38.7744 33.0353 36.6874H28.8125Z"
        fill="#34623F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.1875 49.3751C26.1875 48.6502 26.7751 48.0626 27.5 48.0626C32.0909 48.0626 35.8125 51.7842 35.8125 56.3751C35.8125 57.1 35.2249 57.6876 34.5 57.6876H27.5C26.7751 57.6876 26.1875 57.1 26.1875 56.3751V49.3751ZM28.8125 50.8398V55.0626H33.0353C32.5423 52.9756 30.8995 51.3328 28.8125 50.8398Z"
        fill="#34623F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.9647 55.0626H61.1875V50.8398C59.1005 51.3328 57.4577 52.9756 56.9647 55.0626ZM54.1875 56.3751C54.1875 51.7842 57.9091 48.0626 62.5 48.0626C63.2249 48.0626 63.8125 48.6502 63.8125 49.3751V56.3751C63.8125 57.1 63.2249 57.6876 62.5 57.6876H55.5C54.7751 57.6876 54.1875 57.1 54.1875 56.3751Z"
        fill="#34623F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.1875 35.3749C54.1875 34.65 54.7751 34.0624 55.5 34.0624H62.5C63.2249 34.0624 63.8125 34.65 63.8125 35.3749V42.3749C63.8125 43.0998 63.2249 43.6874 62.5 43.6874C57.9091 43.6874 54.1875 39.9658 54.1875 35.3749ZM56.9647 36.6874C57.4577 38.7744 59.1005 40.4172 61.1875 40.9102V36.6874H56.9647Z"
        fill="#34623F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.3125 45.875C39.3125 42.4849 41.645 39.3125 45 39.3125C48.355 39.3125 50.6875 42.4849 50.6875 45.875C50.6875 49.2651 48.355 52.4375 45 52.4375C41.645 52.4375 39.3125 49.2651 39.3125 45.875ZM45 41.9375C43.5225 41.9375 41.9375 43.4662 41.9375 45.875C41.9375 48.2838 43.5225 49.8125 45 49.8125C46.4775 49.8125 48.0625 48.2838 48.0625 45.875C48.0625 43.4662 46.4775 41.9375 45 41.9375Z"
        fill={color}
      />
    </svg>
  );
};
