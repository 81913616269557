import { useQuery } from '@tanstack/react-query';
import { PatientDetailsType } from '../../../provider/pages/PatientDetail/interfaces/patientDetails';
import http from '../../../utils/http';

export function useGetSinglePatientRecord(patientId: string) {
  const { data, isLoading, refetch, ...rest } = useQuery({
    queryKey: ['get provider patients', patientId],
    queryFn: async () => {
      const response = await http.get(`providers/patient?patientId=${patientId}`);
      return response.data;
    },
    staleTime: Infinity,
    enabled: !!patientId
  });
  return {
    data: data as PatientDetailsType,
    isLoading,
    refetchProviders: refetch,
    ...rest,
  }
}
