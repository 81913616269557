import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useProviderAuthState } from '../../../api/hooks/provider/auth/useProviderAuthState';
import { useGetSinglePatientRecord } from "../../../api/hooks/provider/useGetSinglePatientRecord";
import Button from "../../../components/shared/Button";
import ListTabsWidget from "../../../components/shared/ListTabsWidget";
import { ArrowBackIcon } from "../../../svgs/ArrowBackIcon";
import ProviderDashboardLayout from "../../components/dashboard/ProviderDashboardLayout";
import Documentation from "./Documentation";
import Overview from "./Overview";
import Prescription from "./Prescription";
import { AllDocumentsProps, PatientDetailProps, tabs } from "./interfaces";
import {
  Documentation as DocumentationType,
  Patient,
  PatientDetailsType,
  Prescription as PrescriptionType
} from "./interfaces/patientDetails";

const PatientDetail = ({
  activePatientData,
  goBack,
  diagnosis,
  patientId
}: PatientDetailProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const userId = patientId || location.pathname.split("/")[3];
  const locationState: any = location.state;
  const [activeTabLabel, setActiveTabLabel] = useState(
    locationState?.activeTab ?? "Overview"
  );
  const [activePatient, setActivePatient] = useState<
    PatientDetailsType | undefined
  >(activePatientData);
  const { user } = useProviderAuthState()
  const userTabs = user?.specialty === "therapist" ? tabs.slice(0, 2) : tabs;

  const { data, isError, isLoading, error } = useGetSinglePatientRecord(userId);

  if (isError) {
    const message = axios.isAxiosError(error)
      ? error?.response?.data?.error
      : "Error";
    toast.error(message, { toastId: "customId" });
  }

  useEffect(() => {
    if (!isLoading && data) {
      setActivePatient(data);
    }
  }, [data, isLoading]);

  const patientName =
    activePatient?.patientDetails &&
    `${activePatient?.patientDetails["patient"].firstName} ${activePatient?.patientDetails["patient"].lastName}`;

  const handleTabClick = (tabName: string) => {
    setActiveTabLabel(tabName);
    navigate(`/provider/patients/${userId}/details`, {
      state: {
        activeTab: tabName
      }
    });
  };

  const updatePrescriptionList = (
    data: PrescriptionType | PrescriptionType[]
  ) => {
    setActivePatient(prev => {
      if (!prev) return prev;

      // If data is a single Prescription object
      if (!Array.isArray(data)) {
        return {
          ...prev,
          prescriptions: [...prev.patientDetails.prescriptions, data]
        };
      }

      // If data is an array of Prescription objects
      return {
        ...prev,
        prescriptions: [...prev.patientDetails.prescriptions, ...data]
      };
    });
  };

  const updateDocumentationList = (
    data: DocumentationType | DocumentationType[]
  ) => {
    setActivePatient(prev => {
      if (!prev) return prev;

      // If data is a single Documentation object
      if (!Array.isArray(data)) {
        return {
          ...prev,
          documentation: [...prev.patientDetails.documentation, data]
        };
      }

      // If data is an array of Documentation objects
      return {
        ...prev,
        documentation: [...prev.patientDetails.documentation, ...data]
      };
    });
  };

  const allDocumentation: AllDocumentsProps = useMemo(() => {
    if (activePatient?.patientDetails?.documentation) {
      return activePatient?.patientDetails?.documentation
        .map(note => Object.values(note)[0])
        .filter(note => Array.isArray(note) && note.length)
        .flat()
        .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
    }
    return [];
  }, [activePatient]);

  const RenderPages = (props: any) => {
    const { activeLabel } = props;
    switch (activeLabel.toLowerCase()) {
      case "overview":
        return (
          <Overview
            activePatient={activePatient}
            diagnosis={locationState?.data?.diagnosis || diagnosis || "None"}
            setActiveTabLabel={setActiveTabLabel}
            allDocumentation={allDocumentation}
          />
        );
      case "prescription":
        return (
          <Prescription
            activePatient={activePatient}
            updatePrescriptionList={updatePrescriptionList}
          />
        );
      case "documentation":
        return (
          <Documentation
            activePatient={activePatient}
            updateDocumentationList={updateDocumentationList}
            allDocumentation={allDocumentation}
            patientDetails={activePatient?.patientDetails.patient as Patient}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <ProviderDashboardLayout
      headerTitle={activePatient ? patientName : "Patients"}
    >
      <>
        <div className="mt-0 lg:mt-8">
          <div className="flex justify-start w-full">
            <Button
              label={"Go back to patients"}
              icon={<ArrowBackIcon />}
              additionalClassname={"text-lg text-[#103C1B]"}
              onClick={() => {
                goBack?.();
                navigate("/provider/patients");
              }}
            />
          </div>
          <div className="flex flex-row items-center py-[10px] lg:pl-[20px] w-full overflow-x-scroll lg:overflow-x-hidden">
            <ListTabsWidget
              tabs={userTabs}
              handleTabClick={handleTabClick}
              activeTabLabel={activeTabLabel}
            />
          </div>
          <div className="ml-[15px] mt-[10px]">
            <RenderPages activeLabel={activeTabLabel} />
          </div>
        </div>
      </>
    </ProviderDashboardLayout>
  );
};

export default PatientDetail;
