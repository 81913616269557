import * as Yup from 'yup';

export const loginFormSchema = Yup.object({
  email: Yup.string().email("Invalid Email").required("Email is required"),
  password: Yup.string().required("Password is required")
})

export const loginFormInitialValues = {
  email: "",
  password: ""
}