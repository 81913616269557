export const AdminPatientsIcon = ({
  width = 25,
  height = 19,
  color = "white",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.793458 18.645H17.5977C18.0756 18.6452 18.5489 18.5512 18.9904 18.3684C19.432 18.1856 19.8332 17.9176 20.1711 17.5797C20.509 17.2418 20.777 16.8406 20.9598 16.3991C21.1426 15.9575 21.2365 15.4843 21.2363 15.0064V11.0805L22.6726 10.3145C23.2349 10.0117 23.7051 9.56286 24.0337 9.01523C24.3622 8.46759 24.537 7.84146 24.5395 7.20283V0.834475C24.5395 0.547218 24.348 0.355713 24.0607 0.355713H17.4538C17.1666 0.355713 16.9751 0.547218 16.9751 0.834475V7.20283C16.9776 7.8416 17.1524 8.46785 17.481 9.01559C17.8097 9.56333 18.2801 10.0123 18.8425 10.3151L20.2788 11.0811V15.0069C20.2793 15.3591 20.2103 15.708 20.0757 16.0335C19.9412 16.359 19.7437 16.6548 19.4946 16.9038C19.2456 17.1529 18.9498 17.3504 18.6243 17.4849C18.2988 17.6195 17.9499 17.6885 17.5977 17.688H17.119V17.4007C17.119 13.5706 14.5336 10.3151 11.039 9.30938C12.4276 8.49521 13.3852 7.01132 13.3852 5.28778C13.3834 4.05658 12.8936 2.8763 12.023 2.00571C11.1524 1.13512 9.97211 0.645254 8.7409 0.643517C6.20319 0.643517 4.09664 2.75007 4.09664 5.28778C4.09664 7.01132 5.05416 8.49521 6.44285 9.30938C2.94761 10.3145 0.362847 13.5701 0.362847 17.4007V18.1667C0.315244 18.4058 0.506748 18.6455 0.794005 18.6455L0.793458 18.645ZM17.9331 7.20283V1.31433H23.5349V7.20283C23.5369 7.66423 23.4131 8.11745 23.1769 8.51384C22.9407 8.91022 22.6011 9.2348 22.1944 9.45273L20.7581 10.2188L19.3218 9.45273C18.9069 9.2404 18.5581 8.91841 18.3134 8.52174C18.0686 8.12507 17.9373 7.6689 17.9337 7.20283H17.9331ZM5.00656 5.28778C5.007 4.31022 5.39548 3.37282 6.08667 2.68154C6.77785 1.99025 7.7152 1.60162 8.69275 1.60104C10.7041 1.60104 12.3795 3.27698 12.3795 5.28778C12.3795 7.29858 10.7041 8.97452 8.69275 8.97452C7.7152 8.97394 6.77785 8.58531 6.08667 7.89402C5.39548 7.20273 5.007 6.26533 5.00656 5.28778ZM1.27222 17.4007C1.27222 13.2834 4.62355 9.93204 8.7409 9.93204C12.8583 9.93204 16.2096 13.2834 16.2096 17.4007V17.688H1.27222V17.4007Z"
        fill={color}
        stroke="white"
        strokeWidth="0.711303"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
