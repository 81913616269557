export const AdminLogoutIcon = ({
  width = 22,
  height = 22,
  color = "#2E3011",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9375 2.75C19.9375 2.3703 19.6297 2.0625 19.25 2.0625H11.0038C10.6241 2.0625 10.3163 2.3703 10.3163 2.75C10.3163 3.1297 10.6241 3.4375 11.0038 3.4375H18.5625V18.5625H11C10.6203 18.5625 10.3125 18.8703 10.3125 19.25C10.3125 19.6297 10.6203 19.9375 11 19.9375H19.25C19.6297 19.9375 19.9375 19.6297 19.9375 19.25V2.75Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.36114 6.38886C7.09265 6.12038 6.65735 6.12038 6.38886 6.38886L2.26386 10.5139C1.99538 10.7823 1.99538 11.2177 2.26386 11.4861L6.38886 15.6111C6.65735 15.8796 7.09265 15.8796 7.36114 15.6111C7.62962 15.3427 7.62962 14.9073 7.36114 14.6389L3.72227 11L7.36114 7.36114C7.62962 7.09265 7.62962 6.65735 7.36114 6.38886Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3542 10.9962C15.3542 10.6165 15.0464 10.3087 14.6667 10.3087H2.75001C2.37031 10.3087 2.06251 10.6165 2.06251 10.9962C2.06251 11.3759 2.37031 11.6837 2.75001 11.6837H14.6667C15.0464 11.6837 15.3542 11.3759 15.3542 10.9962Z"
        fill={color}
      />
    </svg>
  );
};
