import styled from "@emotion/styled";
import axios from "axios";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getS3SignedUrl } from "../../api/hooks/common/fetchers.common";
import { usePatientSignup } from "../../api/hooks/provider/auth/patientRegister";
import { ReactComponent as InfoIcon } from "../../svgs/info-icon.svg";
import { generateUUID } from "../../utils/helpers";
import Spacer from "../Spacer";
import Button from "../shared/Button";

type EmancipatedDoc = {
  file?: File
};

const EmancipatedMinorRegistration = () => {
  const navigate = useNavigate();
  const [isEmancipatedMinor, setIsEmancipatedMinor] = useState(false);
  const [emancipationDoc, setEmancipationDoc] = useState({} as EmancipatedDoc);
  const location = useLocation();
  const locationState = location.state as any;
  const [emancipationDecisionKey, setEmancipationDecisionKey] =
    useState<string>("");
  const [s3SignedUrl, setS3SignedUrl] = useState<string>("");


  const { patientSignup, isPending: isPatientSignupPending } = usePatientSignup()


  const handleFileUpload = async (e: any) => {
    const file = e.target.files?.[0];
    if (!file) {
      toast.info("No file selected");
      return;
    }

    const maxAllowedSize = 2 * 1024 * 1024;
    if (file.size > maxAllowedSize) {
      toast.error("File size should be less than 2MB");
      return;
    } else {
      setEmancipationDoc({ file });
      const key = generateUUID() + file.name.slice(file.name.lastIndexOf("."));
      (async () => {
        const response = await getS3SignedUrl({
          operation: "putObject",
          key,
          object: "emancipation"
        });
        setS3SignedUrl(response.signedUrlParams.signedUrl);
        setEmancipationDecisionKey(`${response.signedUrlParams.host}/${key}`);
      })();
    }

  };

  const handleDeleteEmanipationDoc = () => {
    setEmancipationDoc({});
  };

  return (
    <>
      <div className="w-full lg:w-[614px] flex flex-col justify-between h-[100%]">
        <div className="w-full">
          <div className="w-full text-center">
            <p className="text-[34px] font-[800] text-[#103C1B]">
              Minor Registration
            </p>
            <Spacer height={15} />
          </div>
          {isEmancipatedMinor ? null : (
            <div className="w-full lg:w-[478px] bg-[#EEF6FD] text-[#324705]">
              <div className="flex flex-row justify-between p-[3px]">
                <InfoIcon height={40} width={40} />
                <div className="w-full pl-[10px]">
                  If you are attempting to register a minor dependent, the
                  primary member must complete their registration and add the
                  minor dependents from their account.
                </div>
              </div>
            </div>
          )}
          <Spacer height={25} />
          <div>
            <p className="text-[18px] text-[#103C1B]">Are you emancipated?</p>
          </div>
          <Spacer height={8} />
          <div className="text-[20px]">
            <input
              type="radio"
              value="yes"
              onChange={e => {
                setIsEmancipatedMinor(true);
              }}
              name="emancipated-minor"
              style={{
                height: "20px",
                width: "20px",
                border: "2px solid #EEEEE9"
              }}
            />
            {"  "}
            <label>Yes</label>
          </div>
          <Spacer height={8} />
          <div className="text-[20px]">
            <input
              type="radio"
              value="no"
              onChange={e => {
                setIsEmancipatedMinor(false);
                setEmancipationDoc({});
              }}
              name="emancipated-minor"
              style={{
                height: "20px",
                width: "20px",
                border: "2px solid #EEEEE9"
              }}
            />
            {"  "}
            <label>No</label>
          </div>
          {isEmancipatedMinor && (
            <EmancipatedMinorContainer className="emancipate_cont">
              <input
                id="emancipated_minor_file_upload"
                className="invisible_file_upload"
                onChange={handleFileUpload}
                type="file"
                accept=".pdf, image/*"
              />
              <p className="text-[#103C1B] text-[18px]">
                Court's emancipation decision
              </p>
              <div
                className="file_upload_cont"
                onClick={() =>
                  document
                    .getElementById("emancipated_minor_file_upload")
                    ?.click()
                }
              >
                <div className="trigger-text-cont">
                  <span className="text-[#103C1B] text-[18px]">
                    Click to upload file (max-file-size: 2MB.)
                  </span>
                </div>
              </div>
              {emancipationDoc?.file && (
                <div className="eminor-name-cont">
                  <span>{emancipationDoc?.file?.name}</span>
                  <span
                    className="remove-edoc"
                    onClick={handleDeleteEmanipationDoc}
                  >
                    Delete
                  </span>
                </div>
              )}
            </EmancipatedMinorContainer>
          )}
          {isEmancipatedMinor ? (
            <div className="w-[100%] flex justify-between items-center mt-8 text-xs text-normal">
              <div className="flex justify-end w-full">
                <Button
                  additionalClassname={"text-[18px] font-[400] mb-8"}
                  type="submit"
                  variant="secondary"
                  onClick={() => {
                    patientSignup({
                      ...locationState,
                      emancipationDecisionKey,
                      isEmancipatedMinor: true
                    }, {
                      onSuccess: () => {
                        axios.put(s3SignedUrl, emancipationDoc.file);
                      }
                    });

                    // axios.put(s3SignedUrl, emancipationDoc.file);
                  }}
                  loading={isPatientSignupPending}
                  label="Continue"
                  size="medium"
                  disabled={!emancipationDoc.file}
                />
              </div>
            </div>
          ) : (
            <div className="w-[100%] flex justify-between items-center mt-8 text-xs text-normal">
              <div className="flex justify-end w-full">
                <Button
                  additionalClassname={"text-[18px] font-[400] mb-8"}
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    navigate("/patient/signup");
                  }}
                    loading={isPatientSignupPending}
                  label="Back"
                  size="medium"
                />
              </div>
            </div>
          )}
        </div>
        <div className="mb-[4%]">
          <Spacer height={40} />
          <div className="border-[1px] border-gray-200 w-[70%] m-auto mb-2"></div>
          <p className="text-[17px] text-center font-[400]">
            Already a member?
            <span className="text-[#1A9D39]">
              {" "}
              <Link to="/patient/login" className="text-[17px]">
                Sign in
              </Link>{" "}
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

const EmancipatedMinorContainer = styled.div`
  marginTop: ".9rem",
  "& .invisible_file_upload": {
    opacity: 0,
    position: "absolute",
    left: "-100%"
  },
  "& .file_upload_cont": {
    border: " 1px dashed #34623F",
    borderColor: "#103C1B",
    cursor: "pointer",
    background: "rgba(52, 98, 63, 0.07)",
    borderRadius: "4px",
    padding: "2rem",
    maxWidth: "300px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    marginTop: ".4rem",

    "& .trigger-text-cont": {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center"
    }
  },

  "& .eminor-name-cont": {
    marginTop: ".4rem",
    fontSize: ".76rem",
    position: "relative",
    width: "70%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",

    "& .remove-edoc": {
      cursor: "pointer",
      color: "#F83535"
    }
  }
  `;

export default EmancipatedMinorRegistration;
