import { Divider } from "@mui/material";
import { useState } from "react";
import { useGeAllClaims } from '../../api/hooks/biller/useGetAllClaimsByStatus';
import ReusableTable from "../../components/ReusableTable";
import RadioField from "../../components/form/RadioField";
import Button from "../../components/shared/Button";
import ListTabsWidget from "../../components/shared/ListTabsWidget";
import { ArrowBackIcon } from "../../svgs/ArrowBackIcon";
import SPDashboardLayout from "../components/SPDashboardLayout";
import { useSPAllClaimsTableRow } from '../components/table_row/hooks/spallClaim_rows';

// UPDATE THE CHARGE SHEET ON THIS PAGE WHEN INTEGRATING API

export interface ClaimsType {
  claimId: string;
  denialDate: Date;
  reasonForDenial: string;
  reasonForRejection: string;
  rejectionDate: Date;
  patientName: string;
  provider: string;
  insurance: string;
  charge: string;
}

function SPAllClaims() {
  const [isDetailsView, setIsDetailsView] = useState<boolean>(false);
  const [isDetailsEditMode, setIsDetailsEditMode] = useState<boolean>(false);
  const [activeTabLabel, setActiveTabLabel] = useState("All Claims");

  const { allClaims } = useGeAllClaims()
  const { CLAIMS_REJECTED, CLAIMS_ACCEPTED } = useSPAllClaimsTableRow()

  const fetchSingleClaim = async (value: string) => {
    //  FETCH API FOR AND IF SUCCESSFUL SHOW THE DETAILS AND SET THE RIGHT VIEW
    setIsDetailsView(true);
  };

  const handleTabClick = (tabName: string) => {
    setActiveTabLabel(tabName);
  };

  // const CLAIMS_DENIED = useMemo<Column<ClaimsType>[]>(
  //   () => [
  //     {
  //       Header: "Claim ID",
  //       accessor: "claimId",
  //       Cell: ({ cell: { value } }) => {
  //         return (
  //           <React.Fragment>
  //             {value ? "#" + value.slice(0, 7) : ""}
  //           </React.Fragment>
  //         );
  //       }
  //     },
  //     {
  //       Header: "Reason for denial",
  //       accessor: "reasonForDenial",
  //       Cell: ({ cell: { value } }) => {
  //         return <React.Fragment>{value}</React.Fragment>;
  //       }
  //     },
  //     {
  //       Header: "Denial date",
  //       accessor: "denialDate",
  //       Cell: ({ cell: { value }, row }) => {
  //         return (
  //           <React.Fragment>
  //             {value ? format(new Date(value), "MM/dd/yyyy") : "N/A"}
  //           </React.Fragment>
  //         );
  //       }
  //     },
  //     {
  //       Header: "Patient Name",
  //       accessor: "patientName",
  //       Cell: ({ cell: { value } }) => {
  //         return <React.Fragment>{value}</React.Fragment>;
  //       }
  //     },
  //     {
  //       Header: "Provider",
  //       accessor: "provider",
  //       Cell: ({ cell: { value } }) => {
  //         return <React.Fragment>{value}</React.Fragment>;
  //       }
  //     },
  //     {
  //       Header: "Insurance",
  //       accessor: "insurance",
  //       Cell: ({ cell: { value }, row }) => {
  //         return <React.Fragment>{value}</React.Fragment>;
  //       }
  //     },
  //     {
  //       Header: "Charge",
  //       accessor: "charge"
  //     }
  //   ],
  //   []
  // );



  return (
    <SPDashboardLayout headerTitle={isDetailsView ? "Claim Details" : "Claims"}>
      {isDetailsView ? (
        <div className="w-full h-full">
          <div className="w-full flex items-center justify-between">
            <div
              onClick={() => setIsDetailsView(false)}
              className="w-full cursor-pointer pl-4 flex justify-start items-center"
            >
              <ArrowBackIcon />{" "}
              <div className="ml-3">Go back to denied claims</div>
            </div>
            <div className="w-full flex items-center justify-end">
              <Button
                variant="secondary"
                label="Proof of timely filing"
                size="large"
              />
              <div className="w-4" />
              <Button
                variant="secondary"
                label={!isDetailsEditMode ? "Edit" : "Save"}
                size="small"
                onClick={() => {
                  setIsDetailsEditMode(!isDetailsEditMode);
                }}
              />
              <div className="w-4" />
              <div className="w-[122px]">
                <Button variant="primary" label="Rebill" size="medium" />
              </div>
            </div>
          </div>
          <div className="h-6" />
          <div className="w-[740px] px-4">
            <p className="pt-8">Claim ID - 0001</p>
            <p className="text-[24px] py-6">
              Reason for denial -{" "}
              <span className="text-[18px]">
                Inaccurate information. Kindly crosscheck the information to
                ensure that it matches with the insurance company’s records.
              </span>
            </p>
            <p className="">Denial Date - 11/06/2022</p>
          </div>
          <div className="w-[85%] my-8 mx-4">
            <div className="w-full my-10">
              <p className="text-[#324705] text-[24px] font-[500]">
                Patient Information
              </p>
              <div className="h-6" />
              <div className="flex flex-wrap lg:flex-nowrap items-center">
                <div className="w-[358px]">
                  <span className="text-black text-[18px] font-[500]">
                    Patient Name
                  </span>
                  <input
                    placeholder="patient name"
                    type="text"
                    name="patientName"
                    value=""
                    className="w-full p-2 h-[60px] border border-solid rounded outline-none"
                    onChange={() => { }}
                    disabled={true}
                  />
                </div>
                <div className="w-3" />

                <div className="w-[358px]">
                  <span className="text-black text-[18px] font-[500]">
                    Date of Birth
                  </span>
                  <input
                    placeholder="10/01/1996 (27 years)"
                    type="text"
                    name="date"
                    value=""
                    className=" w-full p-2 h-[60px] border border-solid rounded outline-none"
                    onChange={() => { }}
                    disabled={true}
                  />
                </div>
                <div className="w-3" />

                <div className="w-[358px]">
                  <span className="text-black text-[18px] font-[500]">
                    Gender
                  </span>
                  <input
                    placeholder="Male"
                    type="text"
                    name="gender"
                    value=""
                    className="w-full p-2 h-[60px] border border-solid rounded outline-none"
                    onChange={() => { }}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="h-8" />
              <div className="flex flex-wrap lg:flex-nowrap items-center">
                <div className="w-[358px]">
                  <span className="text-black text-[18px] font-[500]">
                    Primary Insurance
                  </span>
                  <input
                    placeholder="Enter primary insurance"
                    type="text"
                    name="primaryInsurance"
                    value=""
                    className="w-full p-2 h-[60px] border border-solid rounded outline-none"
                    onChange={() => { }}
                    disabled={true}
                  />
                </div>
                <div className="w-3" />

                <div className="w-[358px]">
                  <span className="text-black text-[18px] font-[500]">
                    Secondary Insurance
                  </span>
                  <input
                    placeholder="None"
                    type="text"
                    name="secondaryInsurance"
                    value=""
                    className=" w-full p-2 h-[60px] border border-solid rounded outline-none"
                    onChange={() => { }}
                    disabled={true}
                  />
                </div>
                <div className="w-3" />

                <div className="w-[358px]">
                  <span className="text-black text-[18px] font-[500]">
                    Copay
                  </span>
                  <input
                    placeholder="$150"
                    type="text"
                    name="copay"
                    value=""
                    className="w-full p-2 h-[60px] border border-solid rounded outline-none"
                    onChange={() => { }}
                    disabled={true}
                  />
                </div>
              </div>
            </div>

            <Divider />

            <div className="w-full my-8">
              <p className="text-[#324705] text-[24px] font-[500]">
                Visit/Provider Information
              </p>
              <div className="h-6" />
              <div className="flex flex-wrap lg:flex-nowrap items-center">
                <div className="w-[358px]">
                  <span className="text-black text-[18px] font-[500]">
                    Provider Name
                  </span>
                  <input
                    placeholder="Provider name"
                    type="text"
                    name="providerName"
                    value=""
                    className="w-full p-2 h-[60px] border border-solid rounded outline-none"
                    onChange={() => { }}
                    disabled={true}
                  />
                </div>
                <div className="w-3" />

                <div className="w-[358px]">
                  <span className="text-black text-[18px] font-[500]">
                    Date of Appointment
                  </span>
                  <input
                    placeholder="example: 10/01/1996 (27 years)"
                    type="text"
                    name="date"
                    value=""
                    className=" w-full p-2 h-[60px] border border-solid rounded outline-none"
                    onChange={() => { }}
                    disabled={true}
                  />
                </div>
                <div className="w-3" />

                <div className="w-[358px]">
                  <span className="text-black text-[18px] font-[500]">
                    Location of Appointment
                  </span>
                  <input
                    placeholder="Location of Appointment"
                    type="text"
                    name="gender"
                    value=""
                    className="w-full p-2 h-[60px] border border-solid rounded outline-none"
                    onChange={() => { }}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="h-6" />
              <div className="form-item-text">Mode of Appointment</div>
              <div className="flex flex-row items-center justify-start">
                <div className="form-item-data">
                  <RadioField
                    name="modeOfEncounter"
                    placeholder="Mode of Appointment"
                    display="block"
                    options={[
                      { item: "Office", value: "office" },
                      { item: "Telehealth", value: "telehealth" }
                    ]}
                    disabled={true}
                    isRequired={true}
                    value="office"
                    onChange={() => { }}
                  />
                </div>
              </div>
            </div>
            <Divider />

            <div className="w-full my-6"></div>

            <Divider />
            <div className="w-full my-6">
              <p className="text-[#324705] text-[24px] font-[500]">
                Diagnosis Code(s)
              </p>
              <div className="h-6" />
              <div className="w-[358px]">
                <input
                  placeholder="E10.10"
                  type="text"
                  name="diagnosisCode"
                  value=""
                  className="w-full p-2 h-[60px] border border-solid rounded outline-none"
                  onChange={() => { }}
                  disabled={isDetailsEditMode}
                />
              </div>
            </div>
            <div className="h-10" />
          </div>
        </div>
      ) : (
        <div className="w-full">
          <div className="mb-6 px-4">
            <ListTabsWidget
              tabs={[
                { label: "All Claims" },
                { label: "Accepted Claims" },
                { label: "Rejected Claims" },
                  // { label: "Denied Claims" }
              ]}
              handleTabClick={label => {
                handleTabClick(label);
              }}
              activeTabLabel={activeTabLabel}
            />
          </div>
          {/* TODO, FETCH EACH CLAIKM STATUS DATA AND DISPLAY IN EACH TABLE */}
          {activeTabLabel === "All Claims" && (
            <ReusableTable
              tableColumns={CLAIMS_ACCEPTED}
                tableData={(allClaims?.acknowledged || [])?.concat(allClaims?.rejected || []) || []}
              onClick={data => {
                fetchSingleClaim("id");
              }}
              pageSizeCount={10}
              searchFieldPlaceholder="Search claims"
            />
          )}
          {activeTabLabel === "Accepted Claims" && (
            <ReusableTable
              tableColumns={CLAIMS_ACCEPTED}
                tableData={allClaims?.acknowledged ?? []} //TODO: add live data here
              onClick={data => {
                fetchSingleClaim("id");
              }}
              pageSizeCount={10}
              searchFieldPlaceholder="Search accepted claims"
            />
          )}
          {activeTabLabel === "Rejected Claims" && (
            <ReusableTable
              tableColumns={CLAIMS_REJECTED}
                tableData={allClaims?.rejected ?? []} //TODO: add live data here
              onClick={data => {
                fetchSingleClaim("id");
              }}
              pageSizeCount={10}
              searchFieldPlaceholder="Search rejected claims"
            />
          )}
            {/* {activeTabLabel === "Denied Claims" && (
            <ReusableTable
              tableColumns={CLAIMS_DENIED}
                tableData={[]} //TODO: add live data here
              onClick={data => {
                fetchSingleClaim("id");
              }}
              pageSizeCount={10}
              searchFieldPlaceholder="Search denied claims"
            />
          )} */}
        </div>
      )}
    </SPDashboardLayout>
  );
}

export default SPAllClaims;
