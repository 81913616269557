import AuthLayout from "../../components/auth/AuthLayout";
import SPSignupForm from "../components/SPSignupForm";

const SPSignupPage = () => {
  return (
    <AuthLayout>
      <SPSignupForm />
    </AuthLayout>
  );
};

export default SPSignupPage;
