import axios from "axios";
import { add, format } from "date-fns";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useGetProviderAppointments } from '../../../api/hooks/provider/useGetProviderAppointments';
import { useGeProviderPatient } from "../../../api/hooks/provider/useGetProviderPatients";
import { useProviderBookedPatients } from "../../../api/hooks/provider/useProviderBookedPatients";
import RadioField from "../../../components/form/RadioField";
import BtnButton from "../../../components/shared/BtnButton";
import CustomDropdown from "../../../components/shared/CustomDropdown";
import { Loading } from "../../../components/shared/Loading";
import ModalPortal from "../../../components/shared/ModalPortal";
import { CancelIcon } from "../../../svgs/CancelIcon";
import { Patient } from "../../../types/patientType";
import { initialValues, validationSchema } from "./schema/appointment.schema";

export type AppointmentWithClientProps = {
  patientId: string;
  appointmentDate: string;
  appointmentStartTime: string;
  isInitialAppointment: boolean | string;
  appointmentType: string;
};

interface Props {
  addAppointmentAction: (args: AppointmentWithClientProps) => void;
  closeModal: () => void;
  isVisible: boolean;
  loading: boolean;
}

const NewAppointmentModal = ({
  addAppointmentAction,
  closeModal,
  isVisible = false,
  loading
}: Props) => {
  const [selectedDateTimes, setSelectedDateTimes] = useState<any[]>([]);
  const [availableDatesAndTimes, setAvailableDatesAndTimes] = useState<{
    [key: string]: Array<string>;
  }>({});

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      bookAppointmentWithPatient(values);
      resetForm();
    }
  });

  const {
    bookedPatients: allBookedPatients,
    isError: isBookedError,
    error: bookError,
    isLoading: isLoadingBooked
  } = useProviderBookedPatients();

  if (isBookedError && axios.isAxiosError(bookError)) {
    toast.error(bookError?.response?.data?.error, { toastId: "customId" });
  }

  if (isLoadingBooked) {
    <div className=" h-1/2 flex justify-center items-center">
      <Loading />
    </div>;
  }

  const {
    availableAppointments: dateList,
    isError: isAptError,
    error: AptError,
    isLoading: isLoadingAptmnt
  } = useGetProviderAppointments();

  if (isLoadingAptmnt) {
    <div className=" h-1/2 flex justify-center items-center">
      <Loading />
    </div>;
  }
  if (isAptError && axios.isAxiosError(AptError)) {
    toast.error(AptError?.response?.data?.error, { toastId: "customId" });
  }

  const {
    providerPatientsData: allPatients,
    isError,
    error,
    isLoading: isLoadingPatients
  } = useGeProviderPatient();

  if (isError && axios.isAxiosError(error)) {
    toast.error(error?.response?.data?.error, { toastId: "customId" });
  }
  if (isLoadingPatients) {
    <div className=" h-1/2 flex justify-center items-center">
      <Loading />
    </div>;
  }

  useEffect(() => {
    if (dateList && dateList.providerAvailability) {
      const datesAndTimesAvailable: { [key: string]: Array<string> } = {};
      dateList?.providerAvailability[0]?.availability?.forEach(
        ({ appointmentDate, appointmentTimes }) => {
          appointmentTimes
            .map(time => {
              const composeDate = add(new Date(appointmentDate), {
                hours: Number(time.slice(0, 2)),
                minutes: Number(time.slice(3))
              })
              const formatTime = format(
                composeDate,
                "MM-dd-yy HH:mm"
              );
              return formatTime;
            })
            .forEach(dateAndTime => {
              const [date, time] = dateAndTime.split(" ");
              if (datesAndTimesAvailable.hasOwnProperty(date)) {
                datesAndTimesAvailable[date].push(time);
              } else {
                datesAndTimesAvailable[date] = [time];
              }
            });
        }
      );
      setAvailableDatesAndTimes(datesAndTimesAvailable);
    }
  }, [dateList]);

  const bookAppointmentWithPatient = (values: AppointmentWithClientProps) => {
    const { appointmentDate, appointmentStartTime } = values;
    const formattedDate = format(new Date(`${appointmentDate}`), "yyyy-MM-dd");
    const dateToUtc = new Date(
      `${formattedDate}:${appointmentStartTime}`
    ).toISOString();
    addAppointmentAction({
      patientId: values?.patientId,
      appointmentDate: dateToUtc.slice(0, 10),
      appointmentStartTime: dateToUtc.slice(11, 16),
      appointmentType: values.appointmentType,
      isInitialAppointment: values.isInitialAppointment === "no" ? false : true
    });
  };

  return isVisible ? (
    <ModalPortal>
      <div
        style={{
          backgroundColor: "rgba(38, 38, 38, 0.75)",
          zIndex: 100,
          display: "flex",
          justifyContent: "center"
        }}
        className="w-screen h-screen fixed flex flex-col justify-center items-center top-0 bg-opacity-20 backdrop-blur-[5px]"
      >
        <div className="flex justify-end  w-[90%] min-[821px]:w-[775px] mx-auto py-2">
          <div
            onClick={() => {
              closeModal();
            }}
            className="p-2 bg-white rounded-full cursor-pointer"
          >
            <CancelIcon className="h-full w-full" />
          </div>
        </div>
        <div className="bg-white w-[90%] min-[821px]:w-[775px] px-6 pt-6 pb-4 lg:p-6 shadow-lg rounded-[16px] border-box">
          <p className="text-[#2E3011] text-[24px] font-[600]">
            Schedule appointment
          </p>
          <div className="flex flex-col  items-center justify-center h-full">
            <form className="w-full h-full p-2" onSubmit={formik.handleSubmit}>
              <div className="w-full my-8">
                <p className="w-full text-black text-[16px] font-[500] pb-2">
                  Patient
                </p>
                <select
                  className="w-full py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded"
                  // placeholder="Courtney Henry"
                  name="patientId"
                  value={formik.values?.patientId}
                  onChange={e => {
                    formik.setFieldValue("patientId", e.target.value);
                    formik.setFieldValue("appointmentType", "virtual");
                    if (
                      allBookedPatients?.providersPatients?.some(
                        (patient: Patient) => patient.userId === e.target.value
                      )
                    ) {
                      formik.setFieldValue("isInitialAppointment", "no");
                    }
                  }}
                >
                  <option value="" disabled>
                    Select patient
                  </option>
                  {allPatients?.providersPatients &&
                    allPatients?.providersPatients?.map(item => {
                      return (
                        <option
                          key={item?.userId}
                          value={item?.userId}
                          id={item?.userId}
                        >
                          {item?.name}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="w-full my-8 flex items-center gap-x-2">
                <div className="w-1/2">
                  <div className="pb-2 text-[#6E8877]">Start Date</div>
                  <div className="flex flex-row items-center justify-end">
                    <select
                      className="w-full py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded"
                      name="patientId"
                      value={formik.values?.appointmentDate}
                      onChange={e => {
                        formik.setFieldValue("appointmentDate", e.target.value);
                        const times = Object.keys(availableDatesAndTimes).find(
                          appointmentDate => appointmentDate === e.target.value
                        );
                        if (times) {
                          setSelectedDateTimes(availableDatesAndTimes[times]);
                        }
                      }}
                    >
                      <option value="" disabled>
                        Select date
                      </option>
                      {Object.keys(availableDatesAndTimes).map(
                        (appointmentDate, index) => (
                          <option
                            key={index}
                            value={appointmentDate}
                            id={index.toString()}
                          >
                            {appointmentDate}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
                <div className="w-1/2">
                  <p className="mb-2 text-[#6E8877]">Start Time</p>
                  <select
                    className="w-full py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded"
                    // placeholder="Courtney Henry"
                    name="appointmentStartTime"
                    value={formik.values?.appointmentStartTime}
                    onChange={e => {
                      formik.setFieldValue(
                        "appointmentStartTime",
                        e.target.value
                      );
                    }}
                  >
                    <option value="" disabled>
                      Select time
                    </option>
                    {selectedDateTimes &&
                      selectedDateTimes.length &&
                      selectedDateTimes?.map((item, index) => {
                        return (
                          <option
                            key={index}
                            value={item}
                            id={index.toString()}
                          >
                            {item}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>

              <div className="w-full my-8">
                <p className="w-full text-black text-[16px] font-[500] pb-2">
                  Location
                </p>
                <CustomDropdown
                  name="appointmentType"
                  value={formik.values?.appointmentType}
                  optionsList={[
                    { name: "Virtual Visit", value: "virtual" },
                    { name: "Physical", value: "physical" }
                  ]}
                  onChange={event => {
                    formik.setFieldValue("appointmentType", event.target.value);
                  }}
                  placeholder="Physical or virtual visit"
                  defaultValue=""
                />
              </div>
              <div>
                <p className="text-black text-[16px] font-[500]">
                  Is this an initial appointment?
                </p>
                <RadioField
                  display="inline"
                  placeholder="radio"
                  value={
                    allBookedPatients?.providersPatients?.some(
                      (patient: Patient) =>
                        patient.userId === formik.values?.patientId
                    )
                      ? "no"
                      : formik.values.isInitialAppointment
                  }
                  name="appointmentType"
                  options={[
                    { value: "yes", item: "Yes" },
                    { value: "no", item: "No" }
                  ]}
                  onChange={e =>
                    formik.setFieldValue("isInitialAppointment", e.target.value)
                  }
                  disabled={allBookedPatients?.providersPatients?.some(
                    (patient: Patient) =>
                      patient.userId === formik.values?.patientId
                  )}
                />
              </div>
              <div className="flex justify-end mt-8 w-full">
                <div className="flex w-[383px] h-[60px]">
                  <BtnButton
                    loading={loading}
                    type="submit"
                    variant="primary"
                    label="Add Appointment"
                    size="large"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ModalPortal>
  ) : null;
};

export default NewAppointmentModal;
