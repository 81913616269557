import AuthLayout from "../components/auth/AuthLayout";
import SignupSuccesView from "../components/auth/SignupSuccess";

const SignupSuccess = () => {
  return (
    <AuthLayout>
      <SignupSuccesView />
    </AuthLayout>
  );
};

export default SignupSuccess;
