import clsx from "clsx";

interface IProps {
  notificationDetails: any;
  onClick?: (arg: any) => void;
  isSelected?: boolean;
}

const NotificationListItem = ({
  onClick = () => { },
  isSelected,
  notificationDetails
}: IProps) => (
  <div
    onClick={onClick}
    className={clsx({
      "flex items-end justify-between border-b border-[#EEEEE9] bg-[#F5FAF5] min-w-full min-h-[104px] cursor-pointer text-black pb-[25px] px-4 hover:bg-[#DEEDDE]":
        true,
      "bg-[#DEEDDE]": isSelected === true
    })}
  >
    <div className="flex w-full flex-col">
      <div className="flex  w-full items-center justify-between pb-4">
        <div className="text-[0.875rem] text-[#324705]">
          {notificationDetails?.providerName}
        </div>
        <div className="text-[#87928A] text-[0.8125rem]">
          {notificationDetails?.time}
        </div>
      </div>
      <div className="text-[#324705] text-[0.875rem]">
        {notificationDetails?.message}
      </div>
    </div>
  </div>
);


export default NotificationListItem;
