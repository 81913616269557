import { useQuery } from '@tanstack/react-query';
import http from '../../../utils/http';
import { DataSourceType, ErrorResponse } from '../../types';

export function useVerifyProcedureCode(code: string) {
  const newCode = code.toLocaleUpperCase();

  const { data, isError, error, ...rest } = useQuery<DataSourceType[], ErrorResponse>({
    queryKey: ["verify procedure code", newCode],
    queryFn: async () => {
      const response = await http.get(`/charge-sheet/cpt/lookup/${newCode}`);
      return response?.data?.value;
    },
    enabled: false,
    // refetchOnMount: false,
  });
  return {
    procedureData: data,
    isError,
    error,
    ...rest,
  }
}