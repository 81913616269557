import { CalendarDateIcon } from "../../svgs/CalendarDateIcon";
import { ReactComponent as DosageIcon } from "../../svgs/dosage-small.svg";
import { ReactComponent as ExpandIcon } from "../../svgs/expand-icon.svg";
import { ReactComponent as PharmacyIcon } from "../../svgs/pharmacy-small.svg";

// TODO: UPDATE CARD RESPONSIVENESS LATER

interface PrescriptioCardProps {
  drug?: string;
  dosage?: string;
  duration?: string;
  pharmacy?: string;
  note?: string;
  onClick?: (arg: any) => void;
}

const PrescriptioCard = ({
  drug,
  dosage,
  duration,
  pharmacy,
  note,
  onClick = () => {}
}: PrescriptioCardProps) => {
  return (
    <div className="Prescriptio-Card flex flex-col justify-start rounded bg-[#fff] w-full lg:w-[375px] min-h-[395px]  mb-4 p-4">
      <h1 className="text-[#324705] text-[24px] font-[500] mb-6">{drug}</h1>
      <div className="w-full flex justify-between items-center mb-6 text-[14px] font-[400]">
        <div>
          <span className="flex items-center justify-start mb-4">
            <DosageIcon className="mr-2" />
            <p className="text-[#87928A]">Dosage</p>
          </span>
          <p className="text-[#324705]">{dosage}</p>
        </div>
        <div>
          <span className="flex items-center justify-start mb-4">
            <CalendarDateIcon className="mr-2" />
            <p className="text-[#87928A]">Duration</p>
          </span>
          <p className="text-[#324705]">{duration}</p>
        </div>
      </div>
      <div className="text-[14px] font-[400] mb-6">
        <span className="flex items-center justify-start mb-4">
          <PharmacyIcon className="mr-2" />
          <p className="text-[#87928A]">Pharmacy</p>
        </span>
        <p className="text-[#324705]">{pharmacy}</p>
      </div>

      <div className="text-[14px] font-[400] mb-6">
        <span className="flex items-center justify-start mb-4">
          <p className="text-[#87928A]">Provider’s Note</p>
        </span>
        <p className="text-[#324705]">{note}</p>
      </div>
      <span
        onClick={onClick}
        className="cursor-pointer w-full text-left text-[#1A9D39] font-[400] text-[15px] flex items-center justify-start mb-4"
      >
        <ExpandIcon className="mr-2" />
        <p>View Full Details</p>
      </span>
    </div>
  );
};

export default PrescriptioCard;
