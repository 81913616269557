import { styled } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { forgotPassword } from "../../api/auth";
import AuthLayout from "../../components/auth/AuthLayout";
import ResendPasswordResetEmail from "../../components/auth/ResendPasswordResetEmail";
import TextField from "../../components/form/TextField";
import Button from "../../components/shared/Button";
import Spacer from "../../components/Spacer";

const ForgotPasswordPage = () => {
  const [isResendScreen, setResendScreen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <AuthLayout>
      {!isResendScreen ? (
        <SignInPageContainer>
          <div className="w-[90%] lg:w-full flex flex-col items-center justify-start">
            <div className="head-text">Forgot your password?</div>
            <div>
              <span className="text-[1rem]">Let's help you retrieve it</span>
            </div>
            <Formik
              initialValues={{
                email: ""
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email("Invalid Email")
                  .required("Email is required")
              })}
              onSubmit={async values => {
                setEmail(values.email);
                await forgotPassword({
                  email: values.email,
                  userType: "provider"
                });
                setResendScreen(true);
              }}
            >
              {({ values, handleChange, errors, handleSubmit }) => (
                <div className="page-form-container">
                  <TextField
                    name="email"
                    type="email"
                    placeholder="johnddoe@xyz.com"
                    value={values?.email}
                    label="Email Address"
                    onChange={handleChange}
                    error={errors.email ? errors.email : ""}
                  />
                  <Spacer height={20} />
                  <div className="flex items-center justify-between lg:justify-end">
                    <div
                      className="mr-9 text-[14px] text-[#3D874E] cursor-pointer"
                      onClick={() => navigate("/provider/login")}
                    >
                      Go back to Sign In
                    </div>
                    <div className="w-[130px]">
                      <Button
                        onClick={handleSubmit}
                        type="submit"
                        variant="primary"
                        label={"Send reset link"}
                        disabled={isLoading}
                        size="medium"
                      />
                    </div>
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </SignInPageContainer>
      ) : (
        <ResendPasswordResetEmail email={email} userType="provider" />
      )}
    </AuthLayout>
  );
};

const SignInPageContainer = styled("div")(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "20px 0 15px 0",
  " @media (max-width: 820px)": {
    marginTop: "100px"
  },
  "& .head-text": {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#2E3011",
    margin: "8px 0",
    textAlign: "center",
    " @media (max-width: 820px)": {
      fontSize: "24px"
    }
  },
  "& .page-form-container": {
    width: "600px",
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    " @media (max-width: 820px)": {
      width: "100%"
    }
  }
}));

export default ForgotPasswordPage;
