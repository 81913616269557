export const GreenCheckIcon = ({
  width = 16,
  height = 17,
  color = "#009969",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
			className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66649 10.2239L12.7945 4.09521L13.7378 5.03788L6.66649 12.1092L2.42383 7.86655L3.36649 6.92388L6.66649 10.2239Z"
        fill={color}
      />
    </svg>
  );
};
