import { createContext, useContext, useState } from "react";

export interface AppStateType {
  data: any;
  personnelNo: (data: any) => void;
}

export interface AppContextType {
  data: any;
  setData: (newState: any) => void;
}

const AppContextContext = createContext<AppContextType>({
  data: null,
  setData: (newState: any) => {}
});

export function AppContextProviders({
  children
}: {
  children: React.ReactNode;
}) {
  const [appState, setAppState] = useState<any>(null);
  return (
    <AppContextContext.Provider
      value={{ setData: setAppState, data: appState }}
    >
      {children}
    </AppContextContext.Provider>
  );
}

export const useAppContext = () => {
  return useContext(AppContextContext);
};
