import { format, isValid, parse } from "date-fns";

export const formatDateSafely = (
  dateString: string | undefined,
  fallbackValue = ""
) => {
  if (!dateString) return fallbackValue;

  try {
    const parsedDate = parse(dateString, "MM/dd/yyyy", new Date());
    if (isValid(parsedDate)) {
      return format(parsedDate, "yyyy-MM-dd");
    }
  } catch (error) {
    console.error("Error parsing date:", error);
  }

  // If parsing fails, try treating the string as an ISO date
  const isoDate = new Date(dateString);
  if (isValid(isoDate)) {
    return format(isoDate, "yyyy-MM-dd");
  }

  return fallbackValue;
};
