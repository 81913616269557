import { useFormik } from "formik";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useBillerSignUp } from "../../api/hooks/biller/auth/billerSignup";
import Spacer from "../../components/Spacer";
import PasswordField from "../../components/form/PasswordField";
import TextField from "../../components/form/TextField";
import Button from "../../components/shared/Button";
import { initialValues, validationSchema } from '../schema/biller_signup';

const SPSignupForm = (props: any) => {
  const [signupError, setSignupError] = useState("");
  const [emailData, setEmail] = useState("");

  const { billerSignup, isPending } = useBillerSignUp({ email: emailData, setSignupError })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async () => {
      setEmail(formik.values.email);
      billerSignup({
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        email: formik.values.email,
        password: formik.values.password
      });
    }
  });

  return (
    <div className="w-full box-border lg:w-[614px] bg-white">
      <div className="text-center">
        <p className="text-[34px] font-[800] text-[#103C1B]">
          Let’s get you started
        </p>

        <Spacer height={40} />
        <div className="flex flex-col mx-auto items-center w-full lg:w-[478px]">
          {signupError?.length > 0 && (
            <p className="text-[18px] font-[700] text-red-500 font-[gentiumBasic] mb-4">
              Sign up failed: {signupError}
            </p>
          )}

          <TextField
            name="firstName"
            type="text"
            placeholder="First Name"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            label="First Name"
            error={formik.touched.firstName ? formik.errors.firstName : ""}
          />
          <Spacer height={25} />

          <TextField
            name="lastName"
            type="text"
            placeholder="Last Name"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            label="Last Name"
            error={formik.touched.lastName ? formik.errors.lastName : ""}
          />

          <Spacer height={25} />

          <TextField
            name="email"
            type="email"
            placeholder="name@mail.com"
            value={formik.values.email}
            onChange={formik.handleChange}
            label="Email address"
            error={formik.touched.email ? formik.errors.email : ""}
          />

          <Spacer height={25} />

          <PasswordField
            name="password"
            placeholder="At least 8 characters"
            value={formik.values.password}
            onChange={formik.handleChange}
            label="Password"
            showPasswordToggler={true}
            error={formik.touched.password ? formik.errors.password : ""}
          />
          <div className="w-[100%] flex justify-between items-center mt-8 text-xs text-normal">
            <div className="flex justify-end w-full">
              <div className="w-[213px]">
                <Button
                  onClick={formik.submitForm}
                  additionalClassname={"text-[18px] font-[400]"}
                  width={178}
                  height={60}
                  type="submit"
                  variant="primary"
                  label="Create Account"
                  disabled={isPending}
                />
              </div>
            </div>
          </div>
          <div className="lg:h-[80px] h-[40px]" />
          <div className="border-[1px] border-gray-200 w-[300px]" />
          <div className="lg:h-[80px] h-[40px]" />
          <p className="text-center font-[400]">
            Already a member?{" "}
            <span className="text-[#1A9D39]">
              {" "}
              <Link to="/biller/login">Sign in</Link>{" "}
            </span>
          </p>
          <div className="lg:h-[20px] hidden lg:block" />
        </div>
      </div>
    </div>
  );
};

export default SPSignupForm;
