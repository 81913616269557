export const UserProfileIcon = ({
  width = 16,
  height = 17,
  color = "#87928A",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00004 2.33398C6.80342 2.33398 5.83337 3.30403 5.83337 4.50065C5.83337 5.69727 6.80342 6.66732 8.00004 6.66732C9.19666 6.66732 10.1667 5.69727 10.1667 4.50065C10.1667 3.30403 9.19666 2.33398 8.00004 2.33398ZM4.83337 4.50065C4.83337 2.75175 6.25114 1.33398 8.00004 1.33398C9.74894 1.33398 11.1667 2.75175 11.1667 4.50065C11.1667 6.24955 9.74894 7.66732 8.00004 7.66732C6.25114 7.66732 4.83337 6.24955 4.83337 4.50065Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 15.166C1.5 11.5762 4.41016 8.66602 8 8.66602C11.5898 8.66602 14.5 11.5762 14.5 15.166C14.5 15.4422 14.2761 15.666 14 15.666C13.7239 15.666 13.5 15.4422 13.5 15.166C13.5 12.1285 11.0376 9.66602 8 9.66602C4.96244 9.66602 2.5 12.1285 2.5 15.166C2.5 15.4422 2.27614 15.666 2 15.666C1.72386 15.666 1.5 15.4422 1.5 15.166Z"
        fill={color}
      />
    </svg>
  );
};
