export const EditIcon = ({
  height = 24,
  width = 24,
  color = "#949589",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6881 1.42084C17.981 1.12795 18.4559 1.12795 18.7488 1.42084L22.9914 5.66347C23.1321 5.80412 23.2111 5.99489 23.2111 6.1938C23.2111 6.39271 23.1321 6.58348 22.9914 6.72413L7.43508 22.2805C7.29443 22.4211 7.10366 22.5002 6.90474 22.5002L2.66222 22.5001C2.24802 22.5 1.91225 22.1643 1.91224 21.7501L1.91211 17.5075C1.9121 17.3086 1.99112 17.1178 2.13178 16.9772L17.6881 1.42084ZM18.2185 3.01183L3.41212 17.8182L3.41222 21.0001L6.5941 21.0001L21.4004 6.1938L18.2185 3.01183Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4462 5.66303C13.7391 5.37014 14.214 5.37014 14.5069 5.66303L18.7495 9.90568C19.0424 10.1986 19.0424 10.6734 18.7495 10.9663C18.4566 11.2592 17.9818 11.2592 17.6889 10.9663L13.4462 6.72369C13.1533 6.4308 13.1533 5.95592 13.4462 5.66303Z"
        fill={color}
      />
    </svg>
  );
};
