
import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useProviderAuthState } from '../../../api/hooks/provider/auth/useProviderAuthState';

export const RequireProviderAuth = ({
	children,
}: {
	children: ReactNode;
}) => {
	const { pathname } = useLocation();
	const { isAuthenticated } = useProviderAuthState();
	const authenticated = isAuthenticated();

	if (!authenticated) {
		return (
			<>
				<Navigate to='/provider/login' state={{ from: pathname }} replace />
			</>
		);
	}

	return <>{children}</>;
};
