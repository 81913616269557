import NotificationListItem from "./NotificationListItem";
interface IProps {
  list: any[];
  expandMessage: (arg: any) => void;
  isExpanded?: boolean;
}

const NotificationsList: React.FC<IProps> = ({
  list,
  expandMessage
}) => {
  return (
    <div className="flex flex-col justify-start w-full lg:w-full lg:h-full border-[1px] border-solid border-[#EBEBEB] rounded-lg lg:border-none px-0 py-2">
      {list.map((listItem, index) => {
        return (
          <NotificationListItem
            key={index}
            onClick={() => {
              expandMessage(listItem);
            }}
            notificationDetails={listItem}
            // isSelected={listItem.isActiveChat}
          />
        );
      })}
    </div>
  );
};

export default NotificationsList;
