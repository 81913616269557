import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import { UserType } from "../../../../types/common";
import billerHttp from "../../../../utils/billerHttp";

type Payload = {
  password: string;
  userType: UserType;
  id?: string;
  token?: string;
}

type Props = {
  setScreen?: React.Dispatch<React.SetStateAction<number>>
}

export const useSPRessetPassword = ({ setScreen }: Props) => {
  const queryClient = useQueryClient();

  const { mutate, data, variables, reset, ...rest } = useMutation({
    mutationKey: ['update biller password'],
    mutationFn: async ({ password,
      userType,
      id,
      token }: Payload) => {
      const response = await billerHttp.patch(`${userType}s/password/${id}/${token}`, {
        password
      });
      return response;

    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get biller profile details']
      });
      toast.success("Password reset successful");
      if (setScreen) {
        setScreen(1);
      }
    },

    onError: (error) => {
      if (axios.isAxiosError(error)) {
        toast.error(
          error?.response?.data?.error || error.response?.statusText,
        );
      } else {
        toast.error(
          error?.message
          || "Error resetting password, try again",
        );
      }
    },
  });

  return {
    updatePassword: mutate,
    variables,
    ...rest,
  };
}
