import "react-phone-number-input/style.css";
import { ReactComponent as SearchIcon } from "../../svgs/search-icon.svg";
interface SearchFieldProps {
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<any, Element>) => void;
  value: string;
  placeholder: string;
  name: string;
  disabled?: boolean;
  height?: number;
  width?: number;
  inputValid?: boolean;
}

const SearchField: React.FC<SearchFieldProps> = ({
  onChange = () => {},
  onBlur = () => {},
  value,
  placeholder,
  name,
  disabled = false,
  height,
  width
}) => {
  const computedRootStyle = () => {
    const style: { [key: string]: number | string } = {};

    if (width) {
      style.width = `${width}px`;
    }

    return style;
  };

  const computedInputStyle = () => {
    const style: { [key: string]: number | string } = {};

    if (height) {
      style.height = `${height}px`;
    }

    return style;
  };

  return (
    <div className="w-full flex flex-col" style={computedRootStyle()}>
      <div>
        <div className="flex items-center border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
          <SearchIcon className="flex justify-left ml-0" />
          <input
            className="appearance-none pl-4 text-gray-700 border border-none focus:outline-none text-xs"
            type="text"
            placeholder={placeholder}
            value={value}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            style={computedInputStyle()}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchField;
