export const WeightIcon = ({
  width = 16,
  height = 17,
  color = "#87928A",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.833 2.834a1.5 1.5 0 011.5-1.5h11.334a1.5 1.5 0 011.5 1.5v11.333a1.5 1.5 0 01-1.5 1.5H2.333a1.5 1.5 0 01-1.5-1.5V2.834zm1.5-.5a.5.5 0 00-.5.5v11.333a.5.5 0 00.5.5h11.334a.5.5 0 00.5-.5V2.834a.5.5 0 00-.5-.5H2.333z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5.352c-1.392 0-2.59.586-3.615 1.82a.5.5 0 01-.77-.64C4.808 5.099 6.276 4.352 8 4.352c1.725 0 3.193.747 4.384 2.18a.5.5 0 11-.768.64C10.59 5.937 9.392 5.351 8 5.351z"
        fill={color}
      />
      <path d="M8 10.834a1 1 0 100-2 1 1 0 000 2z" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.042 7.093a.5.5 0 01.698.116l1.67 2.333a.5.5 0 01-.814.582l-1.67-2.333a.5.5 0 01.116-.698z"
        fill={color}
      />
    </svg>
  );
};
