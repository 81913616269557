import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import billerHttp from "../../../../utils/billerHttp";

type Payload = {
  firstName: string,
  lastName: string,
  email: string,
  password: string
}

type Props = {
  setSignupError: React.Dispatch<React.SetStateAction<string>>
  email: string
}

export const useBillerSignUp = ({ setSignupError, email }: Props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();


  const { mutate, data, variables, reset, ...rest } = useMutation({
    mutationKey: ['biller signup'],
    mutationFn: async ({ ...data }: Payload) => {
      const response = await billerHttp.post("billers", data);
      return response.data?.data;

    },

    onSuccess: ({ newBiller }) => {
      queryClient.invalidateQueries({
        queryKey: ['get biller profile details']
      });
      toast.success("Password reset successful");
      navigate(
        `/biller/verify-email/billers/${email}/${newBiller?.userId}`
      );
      setSignupError("")
    },

    onError: (error) => {
      if (axios.isAxiosError(error)) {
        toast.error(
          error?.response?.data?.error || error.response?.statusText,
        );
        setSignupError(error?.response?.data?.error)
      } else {
        toast.error(
          error?.message
          || "Error creating account, try again",
        );
      }
    },
  });

  return {
    billerSignup: mutate,
    variables,
    ...rest,
  };
}
