import { useMemo } from 'react';
import { getDecodedJwt, getToken, isAuthenticated } from '../../../../utils/helpers';
import { User } from '../../../types';

export function useProviderAuthState() {
	const token = getToken();

	const user = useMemo<User | null>(() => {
		if (token) {
			const decoded = getDecodedJwt<User>(token);
			return decoded?.data || null;
		}
		return null;
	}, [token]);

	return {
		user,
		isAuthenticated: () => isAuthenticated<User>(),
	};
}
