import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import http from "../../../utils/http";
import { status } from "../../types";


type Payload = {
  patientId: string;
  appointmentId: string
  procedureCodes: string | string[]
  diagnosisCodes: string | string[];
  status: status;
  modeOfEncounter: "virtual" | "physical";
  locationOfEncounter: string;
  dateOfEncounter: string;
}

export const useSendChargeSheetToBilling = () => {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation({
    mutationKey: ['send charge-sheet to billing'],
    mutationFn: async ({ ...data }: Payload) => {
      const response = await http.post("charge-sheet", data)
      return response.data?.newCharge;
    },

    onSuccess: () => {
      toast.success("Sent to billing");
      queryClient.invalidateQueries({ queryKey: ['payment and billing table'] });
    },

    onError: (error) => {
      if (axios.isAxiosError(error)) {
        toast.error(
          error?.response?.data?.error || error.response?.statusText,
        );
      } else {
        toast.error(
          error?.message
          || "Error saving charge sheet",
        );
      }
    }
  });

  return {
    postChargeSheetToBilling: mutate,
    ...rest,
  };
}
