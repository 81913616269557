import {
  createContext,
  ReactElement,
  useCallback,
  useEffect,
  useState
} from "react";
import { useNavigate } from "react-router-dom";
import { useGetAdminProviderDetails } from "../api/hooks/admin/get_admin_provider_list";
import { Loading } from "../components/shared/Loading";

interface AdminUserDetails {
  details?: object | any;
}
interface RouteContext {
  user: AdminUserDetails;
}

export const AdminAuthRouteContext = createContext<RouteContext>({
  user: {}
});

const AdminAuthRoute = ({ element }: { element: ReactElement<any, any> }) => {
  const { data, isLoading } = useGetAdminProviderDetails()

  const [userDetails, setUserDetails] = useState<AdminUserDetails>({
    details: null
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("temple_admin_token")) {
      navigate("/admin/login");
    }
  }, [navigate]);

  const handleFetchUser = useCallback(async () => {
    try {
      if (!data?.user) {
        setUserDetails({ details: null });
        localStorage.setItem("temple_admin_token", "");
        navigate("/admin/login");
      } else {
        setUserDetails({ details: data?.user });
      }
    } catch (e) {
      setUserDetails({ details: null });
      localStorage.setItem("temple_admin_token", "");
      navigate("/admin/login");
    }
  }, [navigate, data?.user]);

  useEffect(() => {
    if (!userDetails) {
      handleFetchUser();
    }
  }, [userDetails, navigate, handleFetchUser]);

  if (isLoading) {
    return (
      <div className="flex flex-col justify-center items-center w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <AdminAuthRouteContext.Provider
      value={{
        user: userDetails
      }}
    >
      {element}
    </AdminAuthRouteContext.Provider>
  );
};


export default AdminAuthRoute;
