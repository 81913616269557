import { format } from "date-fns";
import { useState } from "react";
import DeleteEmergencyContactModal from "../../../components/MyAccount/Patient/DeleteEmergencyContactModal";
import { DeleteIcon } from "../../../svgs/DeleteIcon";
import { ReactComponent as EditIcon } from "../../../svgs/edit-pen.svg";

interface MobileEmergencyContactItemProps {
  onclick: (arg: any) => any;
  imageUrl?: string;
  cardData: any;
  index: number;
}

const MobileEmergencyContactItem = ({
  cardData,
  onclick,
}: MobileEmergencyContactItemProps) => {
  const [showDeleteEmergencyContactModal, setShowDeleteEmergencyContactModal] =
    useState(false);
  return (
    <div
      className="block lg:hidden w-full min-h-[289px] p-4 rounded-[8px] bg-white mb-4"
      style={{ cursor: "pointer", boxShadow: "-2px 4px 25px #EEEEE9" }}
    >
      <div className="w-full flex justify-start">
        <p className="text-[#103C1B] font-[500] text-[18px] break-all">
          {cardData?.name}
        </p>
      </div>

      <div className="w-full flex items-center justify-between my-4">
        <p className="text-[#87928A] text-[14px] font-500">Email Address</p>
        <p className="text-[#324705] text-[14px] font-500 break-all">
          {cardData?.email}
        </p>
      </div>

      <div className="w-full flex items-center justify-between">
        <p className="text-[#87928A] text-[14px] font-500">Gender</p>
        <p className="text-[#324705] text-[14px] font-500">
          {cardData?.phoneNumber}
        </p>
      </div>

      <div className="w-full flex items-center justify-between my-4">
        <p className="text-[#87928A] text-[14px] font-500">Gender</p>
        <p className="text-[#324705] text-[14px] font-500">
          {cardData?.gender}
        </p>
      </div>

      <div className="w-full flex items-center justify-between">
        <p className="text-[#87928A] text-[14px] font-500">Relationship</p>
        <p className="text-[#324705] text-[14px] font-500">
          {cardData?.relationship}
        </p>
      </div>

      <div className="w-full flex items-center justify-between my-4">
        <p className="text-[#87928A] text-[14px] font-500">Date of Birth</p>
        <p className="text-[#324705] text-[14px] font-500">
          {format(new Date(cardData?.dateOfBirth), "MM/dd/yyyy")}
        </p>
      </div>

      <div className="w-full flex justify-end items-center text-[.7rem]">
        <button
          onClick={() => {
            onclick(cardData);
          }}
          className="mr-4"
        >
          <EditIcon />
        </button>
        <button
          onClick={() => {
            setShowDeleteEmergencyContactModal(true);
          }}
        >
          <DeleteIcon className="ml-4" />
        </button>
      </div>
      <DeleteEmergencyContactModal
        isVisible={showDeleteEmergencyContactModal}
        closeModal={() => setShowDeleteEmergencyContactModal(false)}
        contactId={cardData?.contactId}
        emergencyContactName={cardData?.name}
      />
    </div>
  );
};

export default MobileEmergencyContactItem;
