import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useRetrievePaymentSession from '../../api/hooks/payment/useRetrievePaymentSession';
import { useSaveProviderInsuranceCredentialing } from '../../api/hooks/provider/useSaveProviderInsuranceCredentialing';
import { useSaveProviderPracticeRegistration } from '../../api/hooks/provider/useSaveProviderPracticeRegistration';
import Button from "../../components/shared/Button";
import { Loading } from '../../components/shared/Loading';
import errorIcon from "../../svgs/error_icon.svg";
import successIcon from "../../svgs/success-icon.svg";
import { removeRecords } from "./utils/removeRecords";

const PracticeRegCheckout = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get("session_id");
  const page = queryParams.get("page");
  const navigate = useNavigate();
  const [msg, setMsg] = useState<string>("Your Business Registration Request Failed");
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [loading, setIsLoading] = useState<boolean>(true);

  const { retrieveSessions, status } = useRetrievePaymentSession()
  const { providerInsuranceCredential, providerInsuranceCredentialData, error: credentialingError } = useSaveProviderInsuranceCredentialing()
  const { saveProviderPractionReg, providerPractionReg, error: practiceRegError } = useSaveProviderPracticeRegistration()


  const confirmPayment = async () => {
    if (sessionId) {
      try {

        retrieveSessions({ sessionId });

        if (status === "success") {
          const regData = JSON.parse(
            localStorage.getItem(
              page === "credentialReg" ? "credentialingReg" : "practiceReg"
            ) || "{}"
          );

          if ([...Object.keys(regData)].length > 0) {
            if (page === "credentialReg") {
              providerInsuranceCredential({ data: regData });
            } else if (page === "practiceReg") {
              saveProviderPractionReg({ data: regData });
            }
            if (providerInsuranceCredentialData) {
              removeRecords({ setIsSuccess, setIsLoading });
              setIsSuccess(true);
            }
            if (providerPractionReg) {
              removeRecords({ setIsSuccess, setIsLoading });
              setIsSuccess(true);
            }
            if (axios.isAxiosError(credentialingError)) {
              setMsg(credentialingError?.response?.data?.error);
              removeRecords({ setIsSuccess, setIsLoading });
            }
            if (axios.isAxiosError(practiceRegError)) {
              setMsg(practiceRegError?.response?.data?.error);
              removeRecords({ setIsSuccess, setIsLoading });
            }
          } else {
            removeRecords({ setIsSuccess, setIsLoading });
            setMsg("An error occurred. Please try again");
          }
        } else {
          removeRecords({ setIsSuccess, setIsLoading });
          setMsg("An error occurred. Please try again");
        }
      } catch (err: any) {
        removeRecords({ setIsSuccess, setIsLoading });
        setMsg(err.response?.data.error);
      }
    } else {
      removeRecords({ setIsSuccess, setIsLoading });
      setMsg(`No session id found`);
    }
  };
  useEffect(() => {
    confirmPayment();
  }, []);

  return (
    <div className="min-w-screen min-h-screen flex flex-col items-center justify-center space-y-3">
      {loading ? (
        <Loading />
      ) : isSuccess ? (
        <div className="text-center flex flex-col items-center justify-center space-y-3">
          {" "}
          <img src={successIcon} alt="success" />
          <p className="text-[#103C1B] text-[30px] font-[500]">
            Business Registration Form Submitted Successfully!
          </p>
          <div className="w-full flex items-center justify-center">
            <p className="text-[#103C1B] text-[18px] font-[400] w-[50%]">
              Thank you for submitting your Business Registration form. We are
              processing your application and you will receive an email
              notification once your 
              {page === "credentialReg"
                ? " insurance credentialing"
                : " practice registration"}
              is complete
            </p>
          </div>
          <Button
            variant="primary"
            label="Dashboard"
            size="medium"
            onClick={() => navigate("/provider/dashboard")}
          />
        </div>
      ) : (
        <div className="text-center flex flex-col items-center justify-center space-y-3">
          {" "}
          <img src={errorIcon} alt="error" className="h-[100px] w-[100px]" />
          <p className="text-[#103C1B] text-[30px] font-[400]">Error!</p>
          <p className="text-[#103C1B] text-[24px] font-[400]">{msg}</p>
          <Button
            variant="primary"
            label="Try Again"
            size="medium"
            onClick={() => navigate("/provider/business-office")}
          />
        </div>
      )}
    </div>
  );
};

export default PracticeRegCheckout;
