import { capitalize } from "@mui/material";
import clsx from "clsx";
import React, { useState } from "react";
import { status } from "../../api/types";
import { ArrowDownIcon } from "../../svgs/ArrowDownIcon";
import { ArrowUpIcon } from "../../svgs/ArrowUpIcon";

const StatusEl = ({ status }: { status: status }) => (
  <div className="flex items-center">
    <p
      className={clsx({
        "flex justify-center items-center rounded-[32px] w-[105px] h-[32px]":
          true,
        "text-[#E45B3D] bg-[#FEF0ED] border border-[#F9B3A3] ":
          status === "draft",
        "text-[#34623F] bg-[#E7FBEC] border border-[#8BDF9F]":
          status === "approved",
        "text-[#5F91C0] bg-[#F8F8F7] border border-[#CECFC5]":
          status === "processing"
      })}
    >
      {status && capitalize(status)}
    </p>
  </div>
);

interface MobilePaymentAndBillingListCardProps {
  paymentAndBillingDetails?: any;
  onClick?: () => void;
}

const MobilePaymentAndBillingListCard: React.FC<
  MobilePaymentAndBillingListCardProps
> = ({ paymentAndBillingDetails, onClick }) => {
  const [showFullView, setShowFullView] = useState(false);
  return (
    <div
      onClick={onClick}
      className="w-full block lg:hidden p-2 mb-2 border-b border-[#EEEEE9]"
    >
      <div className="w-full flex justify-start text-[#3D874E] font-[600]">
        {paymentAndBillingDetails?.provider}
      </div>
      <div className="w-full flex items-center justify-end">
        {showFullView ? (
          <>
            <p
              onClick={(e: any) => {
                e.stopPropagation();
                setShowFullView(false);
              }}
              className="text-[12px] font-[400] text-[#2E3011]"
            >
              Hide <br /> Details
            </p>
            <div
              onClick={(e: any) => {
                e.stopPropagation();
                setShowFullView(!showFullView);
              }}
            >
              <ArrowUpIcon />
            </div>
          </>
        ) : (
          <>
            <p
              onClick={(e: any) => {
                e.stopPropagation();
                setShowFullView(true);
              }}
              className="text-[12px] font-[400] text-[#2E3011]"
            >
              Show <br /> Details
            </p>
            <div
              onClick={(e: any) => {
                e.stopPropagation();
                setShowFullView(!showFullView);
              }}
            >
              <ArrowDownIcon />
            </div>
          </>
        )}
      </div>

      {!showFullView && (
        <div className="flex items-center my-4">
          <p className="mr-2">{paymentAndBillingDetails?.charges}</p>
          <p
            className={clsx({
              "flex justify-center items-center": true,
              "text-[#E45B3D]": paymentAndBillingDetails?.status === "Draft",
              "text-[#34623F]": paymentAndBillingDetails?.status === "Paid",
              "text-[#5F91C0]":
                paymentAndBillingDetails?.status === "Processing"
            })}
          >
            {`(${paymentAndBillingDetails?.status &&
              capitalize(paymentAndBillingDetails?.status)
              })`}
          </p>
        </div>
      )}
      <div className="w-full">
        {showFullView && (
          <div className="my-4">
            <p>Date</p>
            <p>N/A</p>
          </div>
        )}

        {showFullView && (
          <div className="my-4">
            <p>Procedural code</p>
            <p>N/A</p>
          </div>
        )}
        {showFullView && (
          <div className="my-4">
            <p>Diagnosis code</p>
            <p>N/A</p>
          </div>
        )}
        {showFullView && (
          <div className="my-4">
            <p>Status</p>
            <StatusEl status={paymentAndBillingDetails?.status} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MobilePaymentAndBillingListCard;
