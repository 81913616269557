import { useState } from "react";
import { ReactComponent as EmptyMsgIcon } from "../svgs/empty-smg.svg";
import NotificationsList from "./NotificationsList";
import CustomDrawer from "./drawer";

interface NotificationDrawerProps {
  drawerTitle: string;
  onClose: () => void;
  open: boolean;
  notificationsList: any[];
}

const NotificationDrawer = ({
  drawerTitle,
  onClose,
  open,
  notificationsList
}: NotificationDrawerProps) => {
  const [selectedNotificationDetails, setSelectedNotificationDetails] =
    useState<null | any>(null);
  return (
    <>
      <CustomDrawer
        noWidth
        open={open}
        onClose={onClose}
        drawerTitle={drawerTitle}
      >
        <div className="h-full w-full lg:w-[600px] overflow-y-auto py-[.8rem] px-[.9rem] mt-4">
          {!notificationsList.length ? (
            <div className="h-full w-full m-auto mt-[200px] flex flex-col justify-center items-center">
              <EmptyMsgIcon />
              <div className="font-semibold text-[#34623f] mt-4 text-center">
                Nothing here
              </div>
              <div className="text-[#103C1B] pt-2 lg:w-[322px] text-center">
                You don’t have any new notifications 🙂
              </div>
            </div>
          ) : (
            <div>
              <div className="w-full flex items-center justify-between mb-4 text-[#7E954B]">
                <p className="text-[1.125rem]">Mark all as read</p>
                <p className="text-[1rem]"> Clear all</p>
              </div>
              <div>
                <NotificationsList
                  list={notificationsList}
                  expandMessage={selectedMessage => {
                    setSelectedNotificationDetails(selectedMessage);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </CustomDrawer>
    </>
  );
};

export default NotificationDrawer;
