export const PaymentBillingIcon = ({
  width = 17,
  height = 18,
  color = "#3D874E",
  className
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.885376 3.33203C0.885376 3.03863 1.12322 2.80078 1.41663 2.80078H15.5833C15.8767 2.80078 16.1145 3.03863 16.1145 3.33203V6.8737C16.1145 7.1671 15.8767 7.40495 15.5833 7.40495C15.2899 7.40495 15.052 7.1671 15.052 6.8737V3.86328H1.94788V6.8737C1.94788 7.1671 1.71003 7.40495 1.41663 7.40495C1.12322 7.40495 0.885376 7.1671 0.885376 6.8737V3.33203Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.885376 6.875C0.885376 6.5816 1.12322 6.34375 1.41663 6.34375H15.5833C15.8767 6.34375 16.1145 6.5816 16.1145 6.875V14.6667C16.1145 14.9601 15.8767 15.1979 15.5833 15.1979H1.41663C1.12322 15.1979 0.885376 14.9601 0.885376 14.6667V6.875ZM1.94788 7.40625V14.1354H15.052V7.40625H1.94788Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.71875 10.7695C3.71875 10.4761 3.9566 10.2383 4.25 10.2383H4.95833C5.25173 10.2383 5.48958 10.4761 5.48958 10.7695C5.48958 11.0629 5.25173 11.3008 4.95833 11.3008H4.25C3.9566 11.3008 3.71875 11.0629 3.71875 10.7695Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.55212 10.7695C6.55212 10.4761 6.78997 10.2383 7.08337 10.2383H7.79171C8.08511 10.2383 8.32296 10.4761 8.32296 10.7695C8.32296 11.0629 8.08511 11.3008 7.79171 11.3008H7.08337C6.78997 11.3008 6.55212 11.0629 6.55212 10.7695Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.38538 10.7695C9.38538 10.4761 9.62322 10.2383 9.91663 10.2383H10.625C10.9184 10.2383 11.1562 10.4761 11.1562 10.7695C11.1562 11.0629 10.9184 11.3008 10.625 11.3008H9.91663C9.62322 11.3008 9.38538 11.0629 9.38538 10.7695Z"
        fill={color}
      />
    </svg>
  );
};
