import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { MutationCache, QueryCache, QueryClient, QueryClientProvider, QueryErrorResetBoundary } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ErrorBoundary } from "react-error-boundary";
import "react-phone-number-input/style.css";
import 'react-toastify/dist/ReactToastify.css';
import 'regenerator-runtime/runtime';
import App from "./App";
import { ModalProvider } from "./context/ModalContext";
import PageTitleProvider from "./context/PageTitleContext";
import { SelectedUserProvider } from "./provider/pages/PaymentAndBilling/context/selectedUser";
import * as serviceWorker from "./serviceWorker";
import "./styles/global.scss";
import { AppContextProviders } from "./utils/AppContext";



const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onSuccess: () => {
      queryClient.invalidateQueries()
    },
  }),
  queryCache: new QueryCache({
    onError: (error: any, query) => {
      // 🎉 only show error toasts if we already have data in the cache
      // which indicates a failed background update
      if (query.state.data !== undefined) {
        console.error(`Failed background update, Something went wrong, here's the cause of the error: ${error.message}`)
        // return toast.error(`Something went wrong, dont worry it just means data is already in the cache ${error?.message}`)
      }
    }
  })
})

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AppContextProviders>
          <PageTitleProvider>
            <SelectedUserProvider>
              <QueryErrorResetBoundary>
                {({ reset }) => (
                  <ErrorBoundary
                    onReset={reset}
                    fallbackRender={({ resetErrorBoundary, error }) => (
                      <div className="flex justify-center items-center h-1/2">
                        <p>That's an application error, but don’t fret — let’s give it another shot.</p>
                        <pre>
                          Here's why: {process.env.MODE === 'development' ? error.stack : error.message}
                        </pre>
                        <button onClick={() => resetErrorBoundary()}>Try again</button>
                      </div>
                    )}
                  >
                    <ModalProvider>
                      <App />
                    </ModalProvider>
                  </ErrorBoundary>
                )}
              </QueryErrorResetBoundary>
            </SelectedUserProvider>
          </PageTitleProvider>
          <ToastContainer newestOnTop />
          <ReactQueryDevtools initialIsOpen={false} />
        </AppContextProviders>
      </BrowserRouter>
    </QueryClientProvider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
