import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Dispatch, SetStateAction } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { setToken } from "../../../../utils/helpers";
import http from "../../../../utils/http";


type Payload = {
  email: string;
  password: string;
}

type Props = {
  setLoginError: Dispatch<SetStateAction<string>>
}

export const usePostProviderLogin = ({ setLoginError }: Props) => {
  const navigate = useNavigate();
  const { search, state } = useLocation();


  const urlParams = new URLSearchParams(search);
  const referrer = urlParams.get("referrer");
  const from = state?.path || "/provider/dashboard"


  const { mutate, data, variables, reset, ...rest } = useMutation({
    mutationKey: ['login provider'],
    mutationFn: async ({ email, password }: Payload) => {
      const response = await http.post('providers/login', {
        email,
        password
      })
      return response.data;
    },

    onSuccess: ({ token }) => {
      setToken(token);
      if (token) {
        navigate(from, {
          replace: true
        });
      }
    },

    onError: (error) => {
      if (axios.isAxiosError(error)) {
        setLoginError(
          error?.response?.data?.error || error?.response?.statusText || "An error occurred during login"
        );
      } else {
        setLoginError(
          error?.message
          || "Error tryng to login",
        );
      }
    },
  });

  return {
    providerLogin: mutate,
    variables,
    ...rest,
  };
}
