import { toast } from "react-toastify";
import { forgotPassword } from "../../api/auth";
import { EmailIconBox } from "../../svgs/EmailIconBox";
import { UserType } from "../../types/common";
import Button from "../shared/Button";
import Spacer from "../Spacer";


type Props = {
  email: string;
  userType: UserType;
}
const ResendPasswordResetEmail = ({
  email,
  userType
}: Props) => {
  const handleSubmit = async () => {
    try {
      await forgotPassword({ email, userType });
      toast.success("Password reset email resent successfully");
    } catch (error) {
      toast.error("Error sending password reset email");
    }
  };
  return (
    <div className="box-border min-[821px]:w-[614px] h-full m-auto bg-white">
      <div className="text-center">
        <div className="w-[100px] h-[100px] rounded-[55px] bg-[#103C1B] items-center mx-auto flex justify-center">
          <EmailIconBox width={50} height={50} color="white" />
        </div>
        <Spacer height={20} />
        <p className="text-[34px] font-[800] text-[#103C1B]">
          Check your email
        </p>
        <Spacer height={10} />
        <p className="font-[400] text-[18px] text-[#103C1B]">
          <span>
            We sent a link to your email{" "}
            <span className="font-[800]">{email}</span>. Please check your
            promotions and spam.
          </span>
        </p>
      </div>
      <Spacer height={30} />
      <div className="flex  justify-center items-center w-[100%]">
        <Button
          additionalClassname={"text-[18px] font-[400] w-[150px]"}
          width={98}
          height={60}
          type="submit"
          variant="primary"
          label="Resend Email"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default ResendPasswordResetEmail;
