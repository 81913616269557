import { JaaSMeeting } from "@jitsi/react-sdk";
import { UnfoldLess, UnfoldMore } from "@mui/icons-material";
import { Box, Card, CircularProgress, Tab, Tabs, styled } from "@mui/material";
import clsx from "clsx";
import { addMinutes, format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAllPatientPreviousAppointments
} from "../api/appointments";
import { useAuthState } from '../api/hooks/patient/auth/useAuthState';
import { useGetPatientJitsiToken } from "../api/hooks/patient/usePatientJitsiToken";
import { useCancelAppointment } from "../api/hooks/provider/useCancelAppointment";
import { useRescheduleAppointment } from '../api/hooks/provider/useRescheduleAppoint';
import {
  getPatientAppointmentsList,
  getSinglePatientAppointmentDetails,
  getSingleProviderAppointments,
} from "../api/patients";
import MobileAppointmentCard from "../components/cards/MobileAppointmentCard";
import SingleProviderCard from "../components/cards/SingleProviderCard";
import VisitCard from "../components/cards/VisitCard";
import DashboardLayout from "../components/dashboard/DashboardLayout";
import CustomDrawer from "../components/drawer";
import Button from "../components/shared/Button";
import { ArrowBackIcon } from "../svgs/ArrowBackIcon";
import { CorrectGreenCheck } from "../svgs/CorrectGreenCheck";
import { ReactComponent as DurationIcon } from "../svgs/duration-icon.svg";
import { ReactComponent as CalendarIcon } from "../svgs/green-calendar.svg";
import { ReactComponent as CloseIcon } from "../svgs/small-close.svg";

const Appointments = () => {
  const { user } = useAuthState();

  const { state, pathname: url }: { state: any; pathname: string } = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(state?.tab ?? "upcomingvisit");
  const [isFetchingTabDetails, setFetchingTabDetails] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [userDetailsFold, setUserDetailFold] = useState(false);
  const [isRescheduleViewVisible, setIsRescheduleViewVisible] = useState(false);
  const [isRescheduleConfirmViewVisible, setIsRescheduleConfirmViewVisible] =
    useState(false);
  const [appointmentsList, setAppointmentsList] = useState<[] | null>(null);
  //  TODO: TAKE OUT TYPE ANY
  const [singleAppointment, setSingleAppointment]: any = useState(null);
  const [singleProvider, setSingleProvider]: any = useState(null);
  const [oldAppointmentForReschedule, setOldAppointmentForReschedule] =
    useState("");
  const [newAppointmentForReschedule, setNewAppointmentForReshedule]: any =
    useState(null);
  const [pastAppointmentsList, setPastAppointmentsList] = useState<[] | null>(
    null
  );
  const [joinCall, setJoinCall] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");
  const [drawerIndexLoading, setDrawerIndexLoading] = useState<number | null>(
    null
  ); 

  const jaasAppId = process.env.REACT_APP_JAAS_APP_ID || "";

  const fetchAppointmentsLists = async () => {
    setFetchingTabDetails(true);
    const result = await getPatientAppointmentsList();
    const upcomingAppointments = result?.appointments?.filter(
      (item: any) => !item?.didAppointmentHold
    );
    setAppointmentsList(upcomingAppointments);
    setFetchingTabDetails(false);
  };

  const fetchSingleAppointmentDetails = async (id: string) => {
    const result = await getSinglePatientAppointmentDetails(id);
    setSingleAppointment(result?.patientsAppointment);
  };

  const fetchSingleProviderDetails = async (id: string) => {
    const result = await getSingleProviderAppointments(id);
    setSingleProvider(result?.providersAvailability);
  };

  const fetchPastAppointmentsList = async () => {
    const result = await getAllPatientPreviousAppointments();
    setPastAppointmentsList(result?.appointments);
  };

  useEffect(() => {
    fetchAppointmentsLists();
    fetchPastAppointmentsList();
  }, []);

  const { refetchGetJitsiToken } = useGetPatientJitsiToken(joinCall)
  const { rescheduleAppointment } = useRescheduleAppointment()

  useEffect(() => {
    if (joinCall) {
      refetchGetJitsiToken();
    }
  }, []);

  const handleSwitchTab = (event: React.SyntheticEvent, tab: string) => {
    try {
      navigate(url, {
        state: {
          tab
        }
      });
      setActiveTab(tab);
    } catch (e) {
      // console.log(e);
    }
  };

  const { cancelAppointment } = useCancelAppointment()


  const handleCardItemClick = async (id: string, index: number) => {
    setDrawerIndexLoading(index);
    await fetchSingleAppointmentDetails(id);
    setShowDrawer(true);
    setDrawerIndexLoading(null);
  };

  const handleCloseDrawer = () => {
    setUserDetailFold(false);
    setShowDrawer(false);
  };

  if (isFetchingTabDetails) {
    return (
      <div className="w-[100%] h-[100vh] flex items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  const UpcomingVisitPage = () => {
    return (
      <>
        {appointmentsList && appointmentsList.length
          ? appointmentsList.map((appointment: any, idx) => (
            <div key={appointment?.id}>
              <div className="hidden lg:block mb-4 px-2">
                <VisitCard
                  cardData={appointment}
                  onClick={() =>
                    handleCardItemClick(appointment?.appointmentId, idx)
                  }
                  drawerIndexLoading={drawerIndexLoading}
                  index={idx}
                />
              </div>
              <MobileAppointmentCard
                cardData={appointment}
                onClick={() =>
                  handleCardItemClick(appointment?.appointmentId, idx)
                }
              />
            </div>
          ))
          : null}
      </>
    );
  };

  const PastVisitPage = () => {
    return (
      <>
        {pastAppointmentsList && pastAppointmentsList.length
          ? pastAppointmentsList.map((appointment: any, idx) => (
            <React.Fragment key={appointment?.id}>
              <div className="hidden lg:block mb-4 px-2">
                <VisitCard
                  cardData={appointment}
                  onClick={() =>
                    handleCardItemClick(appointment?.appointmentId, idx)
                  }
                  drawerIndexLoading={drawerIndexLoading}
                  index={idx}
                />
              </div>
              <MobileAppointmentCard
                cardData={appointment}
                onClick={() =>
                  handleCardItemClick(appointment?.appointmentId, idx)
                }
              />
            </React.Fragment>
          ))
          : null}
      </>
    );
  };

  const HandleShowTabPage = ({ tab }: { tab: string }) => {
    switch (tab) {
      case "upcomingvisit":
        return <UpcomingVisitPage />;
      default:
        return <PastVisitPage />;
    }
  };

  const addDuration = (date: string, time: string) =>
    addMinutes(
      new Date(`${date?.slice(0, 11)}${time}`),
      singleAppointment?.isInitialAppointment
        ? singleAppointment?.firstCallDuration
        : singleAppointment?.followUpCallDuration
    );

  return (
    <>
      {joinCall ? (
        <>
          <JaaSMeeting
            appId={jaasAppId}
            roomName={`${user?.userId}${singleAppointment.providerId}`}
            jwt={token}
            // useStaging={true} // dev should remove this prop when the app goes live
            configOverwrite={{
              disableThirdPartyRequests: true,
              disableLocalVideoFlip: true,
              backgroundAlpha: 0.5,
              disableModeratorIndicator: true
              // startWithAudioMuted: true,
              // startScreenSharing: false,
              // enableEmailInStats: false
            }}
            interfaceConfigOverwrite={{
              VIDEO_LAYOUT_FIT: "nocrop",
              MOBILE_APP_PROMO: false,
              TILE_VIEW_MAX_COLUMNS: 4
              // DISABLE_JOIN_LEAVE_NOTIFICATIONS: false
              // SHOW_PROMOTIONAL_CLOSE_PAGE: true
            }}
            userInfo={{
              displayName: `${user?.firstName} ${user?.lastName}`,
              email: String(user?.email)
            }}
            onApiReady={(externalApi: any) => { }}
            onReadyToClose={() => {
              setJoinCall(false);
            }}
            getIFrameRef={(iframeRef: any) => {
              iframeRef.style.height = "100%";
              iframeRef.style.width = "100%";
            }}
          />
        </>
      ) : (
        <DashboardLayout
          headerTitle={
            isRescheduleViewVisible ? "Reschedule Appointment" : "Appointments"
          }
          overflowStyle="hidden"
        >
          <div className="flex justify-between items-center pr-0 pl-0  mt-[20px]">
            <PageContainer>
              {isRescheduleViewVisible ? (
                <>
                  {isRescheduleConfirmViewVisible ? (
                    <div className="w-full px-[1rem] py-[.9rem]">
                      <button
                        className="flex items-center justify-start text-[#103C1B] text-[.77rem] font-[400] cursor-pointer my-4"
                        onClick={() => {
                          setIsRescheduleViewVisible(false);
                        }}
                      >
                        <ArrowBackIcon className="mr-2" />
                        Go back
                      </button>
                      <div className="w-full flex justify-between items-baseline">
                        <div className="text-[40px] font-[400] text-[#103C1B] h-full flex items-start">
                          Confirm appointment <br></br>time.
                        </div>
                        <div className="flex flex-col justify-end">
                          <Card>
                            <div className="p-2">
                              <p className="text-[#324705] w-[400px]">
                                Your appointment will start by{" "}
                                <b>
                                  {format(
                                    new Date(
                                      `06/08/2023 ${newAppointmentForReschedule?.selectedTime}`
                                    ),
                                    "p"
                                  )}
                                </b>{" "}
                                and end by{" "}
                                <b>
                                  {format(
                                    addDuration(
                                      newAppointmentForReschedule?.selectedDate,
                                      newAppointmentForReschedule?.selectedTime
                                    ),
                                    "p"
                                  )}
                                </b>{" "}
                                on{" "}
                                <b>
                                  {format(
                                    new Date(
                                      newAppointmentForReschedule?.selectedDate
                                    ),
                                    "eeee, MMMM do"
                                  )}
                                </b>
                              </p>
                            </div>
                          </Card>

                          <div className="mt-4">
                            <Button
                              width={108}
                              height={60}
                              type="submit"
                              variant="primary"
                              label="Submit"
                              size="medium"
                              onClick={() => {
                                rescheduleAppointment({
                                  oldId: oldAppointmentForReschedule,
                                  date: newAppointmentForReschedule?.selectedDate,
                                  time: newAppointmentForReschedule?.selectedTime
                                });
                                setIsRescheduleViewVisible(false);
                                setIsRescheduleConfirmViewVisible(false);
                                fetchAppointmentsLists();                              
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {" "}
                      <div className="w-full px-[1rem] py-[.9rem]">
                        <button
                          className="flex items-center justify-start text-[#103C1B] text-[.77rem] font-[400] cursor-pointer my-4"
                          onClick={() => {
                            setIsRescheduleViewVisible(false);
                          }}
                        >
                          <ArrowBackIcon className="mr-2" />
                          Go back
                        </button>
                        <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-baseline">
                          <div className="text-[40px] font-[400] text-[#103C1B] h-full flex items-start">
                            Select new <br></br> appointment time.
                          </div>
                          <div className="w-full lg:w-[706px] flex justify-end">
                            {Object.keys(singleProvider ?? {})?.map(
                              (key: any, idx: number) => (
                                <SingleProviderCard
                                  key={key}
                                  onChipClick={value => {
                                    setNewAppointmentForReshedule(value);
                                    setIsRescheduleConfirmViewVisible(true);
                                  }}
                                  providerObject={{
                                    ...singleProvider[key]?.details,
                                    availableSlots:
                                      singleProvider[key]?.availability
                                  }}
                                />
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div className="w-full lg:w-auto">
                  <Box>
                    <Tabs value={activeTab} onChange={handleSwitchTab}>
                      <Tab label="Upcoming Visit" value="upcomingvisit" />
                      <Tab label="Past Visit" value="pastvisit" />
                    </Tabs>
                  </Box>
                  <div className="tab-page">
                    <HandleShowTabPage tab={activeTab} />
                  </div>
                </div>
              )}
            </PageContainer>
          </div>
          <div className="min-w-full max-w-full lg:w-[600px] overflow-x-hidden mx-0 px-0">
            <CustomDrawer
              open={showDrawer}
              onClose={handleCloseDrawer}
              drawerTitle="Appointment Details"
              noWidth
            >
              <div className="min-w-full max-w-full lg:w-[600px] overflow-x-hidden mx-0 px-2">
                <DrawerContent>
                  <div className="drawer-user-profile">
                    <div className="user-profile-card">
                      <div className="user-image">
                        <img
                          src={
                            singleAppointment?.avatarUrl ??
                            "/assets/images/avatar.png"
                          }
                          className="user-img-src rounded-full"
                          alt=""
                        />
                      </div>
                      <div className="user-profile-deets">
                        <p className="user-name">{`${singleAppointment?.firstName} ${singleAppointment?.lastName}`}</p>
                        <p className="user-desc">
                          {singleAppointment?.jobTitle}
                        </p>
                        <div
                          className="fold-data-row"
                          onClick={() => setUserDetailFold(!userDetailsFold)}
                        >
                          {userDetailsFold ? (
                            <UnfoldLess className="fold-icon" />
                          ) : (
                            <UnfoldMore className="fold-icon" />
                          )}
                          <span className="fold-text">
                            {userDetailsFold
                              ? "Hide Full Profile"
                              : "View Full Profile"}
                          </span>
                        </div>
                        {userDetailsFold ? (
                          <div className="fold-details">
                            <div className="fold-sub-deets">
                              <p className="fsd-head">Focus Areas</p>
                              {singleAppointment.focusAreas?.map(
                                (area: string, ind: number) => (
                                  <p className="fsd-body" key={ind}>
                                    {area}
                                  </p>
                                )
                              )}
                            </div>
                            <div className="fold-sub-deets">
                              <p className="fsd-head">Languages</p>
                              {singleAppointment.languages?.map(
                                (item: string, ind: number) => (
                                  <p key={ind}>{item}</p>
                                )
                              )}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="w-full flex flex-wrap sm:flex-nowrap justify-between align-center gap-5 mt-4">
                      <div className="mr-4 lg:mr-0">
                        <div className="text-[#87928A] text-[.9rem] font-[400]">
                          Date
                        </div>
                        <div className="text-[#324705] text-[.77rem] font-[400]">
                          {singleAppointment?.appointmentDate &&
                            format(
                              new Date(singleAppointment?.appointmentDate),
                              "EEEE, dd LLL"
                            )}
                        </div>
                      </div>
                      <div className="mr-4 lg:mr-0">
                        <div className="text-[#87928A] text-[.9rem] font-[400] flex justify-center">
                          Time
                        </div>
                        <div className="flex flex-col items-center">
                          <div className="text-[#324705] text-[.77rem] font-[400]">
                            {`${singleAppointment?.appointmentStartTime.substring(
                              0,
                              5
                            )} - ${singleAppointment?.appointmentEndTime.substring(
                              0,
                              5
                            )}`}
                          </div>
                          <div className="flex justify-end">
                            <div className="flex items-center justify-start text-[#2F9F4A] text-[.9rem] font-[400]">
                              <DurationIcon className="mr-2 w-[18px] h-[18px] " />
                              {singleAppointment?.duration} mins
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mr-4 lg:mr-0">
                        <div className="text-[#87928A] text-[.77rem] font-[400]">
                          Location
                        </div>
                        <span>
                          <div className="flex items-center justify-start text-[#324705] text-[.77rem] font-[400]">
                            {singleAppointment?.appointmentType === "physical"
                              ? "In-office"
                              : "Virtual"}
                          </div>
                        </span>
                      </div>
                      <div className="mr-4 lg:mr-0">
                        <div className="text-[#87928A] text-[.77rem] font-[400]">
                          Appointment Held?
                        </div>
                        {singleAppointment?.didAppointmentHold ? (
                          <div className="flex justify-center items-center gap-2">
                            <span>
                              <CorrectGreenCheck />
                            </span>{" "}
                            <span>Yes</span>
                          </div>
                        ) : (
                          <div>No</div>
                        )}
                      </div>
                    </div>

                    {activeTab === "upcomingvisit" && (
                      <div className="flex flex-wrap lg:flex-nowrap items-center justify-between mt-4">
                        <div className="mr-4 lg:mr-12">
                          <span>
                            <button
                              className={clsx({
                                "flex items-center justify-start text-[#2F9F4A] text-[.77rem] font-[400] cursor-pointer":
                                  true
                              })}
                            >
                              <CalendarIcon className="mr-2 w-[20px] h-[20px] " />
                              Add to calendar
                            </button>
                          </span>
                        </div>
                        <div className="mr-4 lg:mr-12">
                          <span>
                            <button
                              className={clsx({
                                "flex items-center justify-start text-[#2F9F4A] text-[.77rem] font-[400] cursor-pointer":
                                  true
                              })}
                              onClick={() => {
                                handleCloseDrawer();
                                setIsRescheduleViewVisible(true);
                                fetchSingleProviderDetails(
                                  singleAppointment?.providerId
                                );
                                setOldAppointmentForReschedule(
                                  singleAppointment?.appointmentId
                                );
                              }}
                              disabled={singleAppointment?.didAppointmentHold}
                            >
                              <DurationIcon className="mr-2 w-[16px] h-[16px] " />
                              Reschedule Appointment
                            </button>
                          </span>
                        </div>
                        <div className="mt-4 lg:mt-0">
                          <span>
                            <button
                              className={clsx({
                                "flex items-center justify-start text-[#E45B3D] text-[.77rem] font-[400] cursor-pointer":
                                  true
                              })}
                              onClick={async () => {
                                cancelAppointment(
                                  singleAppointment?.appointmentId
                                );
                                fetchAppointmentsLists();
                                handleCloseDrawer();
                              }}
                              disabled={singleAppointment?.didAppointmentHold}
                            >
                              <CloseIcon className="mr-2 w-[16px] h-[16px] " />
                              Cancel Appointment
                            </button>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="my-4 px-[.9rem] flex flex-col gap-5">
                    <div className="text-[#324705] text-[20px] font-[400]">
                      Joining the appointment
                    </div>
                    <div className="text-[#324705] font-[400]">
                      Please join the call few minutes early to test your device
                      microphone and video. Your provider will join by{" "}
                      <b>{singleAppointment?.appointmentStartTime}</b>
                    </div>
                    <div className="mt-4">
                      <Button
                        width={108}
                        height={60}
                        type="submit"
                        variant={
                          singleAppointment?.didAppointmentHold
                            ? "secondary"
                            : "primary"
                        }
                        label="Join Call"
                        size="medium"
                        disabled={singleAppointment?.didAppointmentHold}
                        onClick={() => {
                          setJoinCall(true);
                        }}
                      />
                    </div>
                  </div>
                </DrawerContent>
              </div>
            </CustomDrawer>
          </div>
        </DashboardLayout>
      )}
    </>
  );
};

const DrawerContent = styled("div")(() => ({
  width: "100%",
  height: "100%",
  overflowY: "auto",

  "& .drawer-user-profile": {
    padding: "1rem .9rem",
    height: "auto",
    position: "relative",
    borderBottom: "2px solid #EEEEE9",
    "& .user-profile-card": {
      marginTop: "4px",
      position: "relative",
      display: "grid",
      gap: "2%",
      width: "100%",
      height: "fit-content",
      gridTemplateColumns: "49% 49%",
      gridTemplateRows: "auto",

      "& .fold-details": {
        width: "100%",
        height: "auto",
        display: "grid",
        gap: "2%",
        gridTemplateColumns: "49% 49%",
        gridTemplateRows: "auto",
        marginTop: "10px",

        "& .fold-sub-deets": {
          width: "100%",
          margin: ".3rem 0",
          "& .fsd-head": {
            color: "#87928A",
            fontSize: "1rem"
          },
          "& .fsd-body": {
            color: "#324705",
            fontSize: "1rem"
          }
        }
      },

      "& .fold-data-row": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContents: "flex-start",
        fontSize: ".7rem",
        cursor: "pointer",
        marginTop: "2px",
        color: "#1A9D39",

        "& .fold-icon": {
          margin: 0,
          fontSize: ".9rem",
          marginRight: ".3rem",
          transform: "rotate(45deg)"
        }
      },

      "& .user-name": {
        color: "#103C1B",
        fontWeight: "bold",
        fontSize: "1.12rem"
      },
      "& .user-desc": {
        color: "#103C1B",
        fontSize: ".78rem"
      },

      "& .user-profile-deets": {
        position: "relative",
        height: "fit-content",
        width: "100%"
      },

      "& .user-image": {
        position: "relative",
        width: "113px",
        height: "110px",
        marginRight: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",

        "& .user-img-src": {
          width: "100%",
          position: "relative",
          height: "100%",
          borderRadius: "100%"
        }
      }
    }
  }
}));

const PageContainer = styled("div")(() => ({
  width: "100%",
  "& .tab-page": {
    position: "relative",
    width: "100%",
    marginTop: "2rem",
    height: "80vh",
    overflowY: "auto"
  },
  "& .css-1aquho2-MuiTabs-indicator": {
    backgroundColor: "#324705"
  },
  "& .css-1h9z7r5-MuiButtonBase-root-MuiTab-root": {
    textTransform: "capitalize",
    color: "#34623F99",
    fontWeight: 500
  },
  "& .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected": {
    color: "#324705"
  }
}));
export default Appointments;
