import styled from "@emotion/styled";
import { Form, Formik } from "formik";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import zipcodes from "zipcodes";
import {
  getTempAppointmentDetails,
  patientUpdateDetails
} from "../../api/auth";
import { getS3SignedUrl } from "../../api/hooks/common/fetchers.common";
import ToolTip from "../../reuseables/Tooltip";
import { MainModal } from "../../reuseables/mainModal";
import { DeleteIcon } from "../../svgs/DeleteIcon";
import { PlusIcon } from "../../svgs/PlusIcon";
import { ReactComponent as CheckFill } from "../../svgs/success-icon.svg";
import {
  maritalStatusList,
  phoneRegExp,
  raceList,
  statesInTheUS
} from "../../utils/consts";
import { generateUUID } from "../../utils/helpers";
import { isValidUSPhoneNumber } from "../../utils/validUsPhoneNumber";
import Spacer from "../Spacer";
import RadioField from "../form/RadioField";
import TextField from "../form/TextField";
import { getInsurancePayers } from "../lib/utils/utils";
import Button from "../shared/Button";

export type GovtIdDoc = {
  file?: File;
};

const personalDetailsFields: any = {
  firstName: "",
  lastName: "",
  gender: "",
  pronoun: "",
  phoneNumber: "",
  zipCode: "",
  idNumber: "",
  stateOfIdIssue: "",
  insuranceName: "",
  insuranceNumber: "",
  isTakingMedication: "",
  medications: "",
  hasAllergies: "",
  allergies: [],
  feet: "",
  inches: "",
  state: "",
  address: "",
  city: "",
  ssn: "",
  maritalStatus: "",
  race: "",
  governmentIdUrl: "",
  weight: ""
};

const onChangeHandler = (
  e: ChangeEvent<any>,
  handleChange: (e: ChangeEvent<any>) => void
) => {
  handleChange(e);
  personalDetailsFields[e.target.name] = e.target.value;
};

const RenderPersonalDetailsForm = ({
  setShowToolTip,
  setWhyShowToolTip,
  handleOnNext,
  showWhyTooltip,
  showTooltip,
  handleFileUpload,
  governmentId,
  handleDeleteEmanipationDoc
}: {
  setShowToolTip(): void;
  setWhyShowToolTip(): void;
  showWhyTooltip: boolean;
  showTooltip: boolean;
  handleOnNext(): void;
  handleFileUpload(e: any): void;
  governmentId: any;
  handleDeleteEmanipationDoc(): void;
}) => {

  return (
    <Formik
      initialValues={{
        firstName: `${personalDetailsFields.firstName}`,
        lastName: `${personalDetailsFields.lastName}`,
        gender: `${personalDetailsFields.gender}`,
        pronoun: `${personalDetailsFields.pronoun}`,
        phoneNumber: `${personalDetailsFields.phoneNumber}`,
        zipCode: `${personalDetailsFields.zipCode}`,
        idNumber: `${personalDetailsFields.idNumber}`,
        stateOfIdIssue: `${personalDetailsFields.stateOfIdIssue}`,
        address: `${personalDetailsFields.address}`,
        state: `${personalDetailsFields.state}`,
        city: `${personalDetailsFields.city}`,
        ssn: `${personalDetailsFields.ssn}`,
        maritalStatus: `${personalDetailsFields.maritalStatus}`,
        race: `${personalDetailsFields.race}`
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required("First name is required"),
        lastName: Yup.string().required("Last name is required"),
        gender: Yup.string(),
        pronoun: Yup.string(),
        stateOfIdIssue: Yup.string().required("State of Id Issue is required"),
        phoneNumber: Yup.string()
          .required("Phone number is required")
          .test(
            "is-valid-us-phone-number",
            "Invalid US phone number",
            value => {
              return phoneRegExp.test(value as string);
            }
          ),
        zipCode: Yup.string().required("Zip code is required"),
        idNumber: Yup.string().required("Issued ID number is required"),
        address: Yup.string().required("Address is required"),
        state: Yup.string().required("State is required"),
        city: Yup.string().required("City is required"),
        race: Yup.string().required("Race is required"),
        maritalStatus: Yup.string().required("Marital Status is required"),
        ssn: Yup.string().matches(
          /^\d{3}-\d{2}-\d{4}$/,
          "Invalid Social Security Number, number must be in XXX-XX-XXXX"
        )
      })}
      onSubmit={handleOnNext}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleSubmit,
        handleBlur
      }) => (
        <Form className="flex flex-col mx-auto items-center w-full lg:w-[478px] h-full pb-[50px]">
          <div className="flex flex-row items-center mx-auto w-[100%]">
            <TextField
              name="firstName"
              type="text"
              placeholder="First Name"
              value={values.firstName}
              onChange={e => onChangeHandler(e, handleChange)}
              label="First Name"
              error={touched.firstName ? errors.firstName : ""}
            />
            <Spacer width={20} />
            <TextField
              name="lastName"
              type="text"
              placeholder="Last Name"
              value={values.lastName}
              onChange={e => onChangeHandler(e, handleChange)}
              label="Last Name"
              error={touched.lastName ? errors.lastName : ""}
            />
          </div>
          <div className="w-[100%] flex flex-col mt-4">
            <RadioField
              name="gender"
              placeholder="Type Gender"
              label="Gender"
              display="inline"
              options={[
                { item: "Male", value: "male" },
                { item: "Female", value: "female" }
              ]}
              hasOther={true}
              isRequired={false}
              value={values.gender}
              onChange={e => onChangeHandler(e, handleChange)}
              error={touched.gender ? errors.gender : ""}
            />
          </div>
          <div className="mt-4 w-[100%]">
            <TextField
              name="pronoun"
              type="text"
              isRequired={false}
              placeholder="Enter pronoun"
              value={values.pronoun}
              onChange={e => onChangeHandler(e, handleChange)}
              label="Pronoun"
              error={touched.pronoun ? errors.pronoun : ""}
            />
          </div>
          <div className="flex flex-row items-center mx-auto w-[100%] mt-4">
            <TextField
              name="phoneNumber"
              type="text"
              placeholder="e.g 9023456789"
              value={values.phoneNumber}
              onChange={e => onChangeHandler(e, handleChange)}
              label="Phone"
              error={touched.phoneNumber ? errors.phoneNumber : ""}
            />
            <Spacer width={20} />
            <TextField
              name="zipCode"
              type="text"
              placeholder="e.g 102400"
              value={values.zipCode}
              onChange={e => onChangeHandler(e, handleChange)}
              label="Zip Code"
              error={touched.zipCode ? errors.zipCode : ""}
            />
          </div>
          <div className="mt-4 w-[100%]">
            <TextField
              name="address"
              type="text"
              placeholder="123 Oak Street"
              value={values.address}
              onChange={e => onChangeHandler(e, handleChange)}
              label="Address"
              error={touched.address ? errors.address : ""}
            />
          </div>
          <div className="flex flex-col sm:flex-row items-center mx-auto w-[100%] mt-4 gap-5">
            <div className="flex flex-col items-start w-full">
              <label
                htmlFor="state"
                className="text-[#000] font-medium mb-2 text-[16px]"
              >
                State
              </label>
              <select
                id="state"
                className="w-full gap-[20px] py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded"
                // placeholder="Select State"
                name="state"
                value={values.state}
                onChange={e => onChangeHandler(e, handleChange)}
              >
                <option value="" disabled>
                  Select State
                </option>
                {statesInTheUS.map((state, index) => {
                  return (
                    <option key={index} value={state} id={state}>
                      {state}
                    </option>
                  );
                })}
              </select>
              <Spacer height={10} />
              {touched.state && errors.state && (
                <div className="text-[red] text-xs">{errors.state}</div>
              )}
            </div>

            <div className="flex flex-col items-start w-full">
              <TextField
                name="city"
                type="text"
                placeholder="Birmingham"
                value={values.city}
                onChange={e => onChangeHandler(e, handleChange)}
                label="City"
                error={touched.city ? errors.city : ""}
              />
            </div>
          </div>
          <div className="mt-4 w-[100%]">
            <TextField
              name="ssn"
              type="text"
              placeholder="Enter Social Security Number"
              isRequired={false}
              value={values.ssn}
              onBlur={handleBlur}
              onChange={e => onChangeHandler(e, handleChange)}
              label="Social Security Number"
              error={touched.ssn ? errors.ssn : ""}
            />
            <div className="py-2">
              <div
                className="text-[#1A9D39] text-[14px] cursor-pointer"
                onClick={setWhyShowToolTip}
              >
                Why do we need this?
              </div>
              {showWhyTooltip && (
                <ToolTip>
                  Your provider needs to verify your identity. Be rest assured
                  that your SSN is safe and encrypted.
                </ToolTip>
              )}
            </div>
          </div>
          <div className="w-full my-5">
            <div className="flex flex-row items-start justify-between">
              <div className="text-[#000] font-medium mb-2 text-[16px]">
                Upload Government issued ID
              </div>
            </div>
            <GovtIdUploadContainer className="govt_id_file_cont">
              <input
                id="govt_id_file_upload"
                className="invisible_file_upload"
                onChange={handleFileUpload}
                type="file"
                accept=".pdf, image/*"
              />
              <div
                className="file_upload_cont"
                onClick={() =>
                  document.getElementById("govt_id_file_upload")?.click()
                }
              >
                <div className="trigger-text-cont">
                  <span className="text-[#103C1B] text-[16px]">
                    Click to upload file (max-file-size: 2MB.)
                  </span>
                </div>
              </div>
              {governmentId?.file && (
                <div className="id-name-cont space-x-2">
                  <span>{governmentId?.file?.name}</span>
                  <span
                    className="remove-edoc"
                    onClick={handleDeleteEmanipationDoc}
                  >
                    Delete
                  </span>
                </div>
              )}
            </GovtIdUploadContainer>
          </div>
          <div className="flex flex-row items-center justify-between mx-auto w-[100%] mt-4">
            <p className="text-[#000] font-medium text-[16px]">
              Government Issued ID
            </p>
          </div>
          <div className="flex flex-row items-center mx-auto w-[100%] mt-4">
            <div className="w-full">
              <select
                className="w-fit py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded"
                name="stateOfIdIssue"
                value={values.stateOfIdIssue}
                onChange={e => onChangeHandler(e, handleChange)}
              >
                <option value="" disabled>
                  Select issuing state
                </option>
                {statesInTheUS.map((state, index) => {
                  return (
                    <option key={index} value={state} id={state}>
                      {state}
                    </option>
                  );
                })}
              </select>
              <Spacer height={10} />
              {touched.stateOfIdIssue && errors.stateOfIdIssue && (
                <div className="text-[red] text-xs">
                  {errors.stateOfIdIssue}
                </div>
              )}
            </div>
            <Spacer width={20} />
            <div className="w-full">
              <input
                className="w-full py-3 px-4 appearance-none text-[#6E8877] border border-grey focus:outline-none text-s rounded"
                type="text"
                placeholder="Enter ID number"
                name="idNumber"
                value={values.idNumber}
                onChange={e => onChangeHandler(e, handleChange)}
              />
              <Spacer height={10} />
              {touched.idNumber && errors.idNumber && (
                <div className="text-[red] text-xs">{errors.idNumber}</div>
              )}
            </div>
          </div>
          <div className="flex flex-col sm:flex-row items-center mx-auto w-[100%] mt-4 gap-5">
            <div className="flex flex-col items-start w-full">
              <label
                htmlFor="maritalStatus"
                className="text-[#000] font-medium mb-2 text-[16px]"
              >
                Marital Status
              </label>
              <select
                id="maritalStatus"
                className="w-full gap-[20px] py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded"
                name="maritalStatus"
                value={values.maritalStatus}
                onChange={e => onChangeHandler(e, handleChange)}
              >
                <option value="" disabled>
                  Select Marital Status
                </option>
                {maritalStatusList.map((status, index) => {
                  return (
                    <option key={index} value={status.value} id={status.name}>
                      {status.name}
                    </option>
                  );
                })}
              </select>
              <Spacer height={10} />
              {touched.maritalStatus && errors.maritalStatus && (
                <div className="text-[red] text-xs">{errors.maritalStatus}</div>
              )}
            </div>

            <div className="flex flex-col items-start w-full">
              <label
                htmlFor="race"
                className="text-[#000] font-medium mb-2 text-[16px]"
              >
                Race
              </label>
              <select
                id="race"
                className="py-3.5 px-4 w-full text-[#6E8877] border border-grey focus:outline-none text-s rounded"
                name="race"
                value={values.race}
                onChange={e => onChangeHandler(e, handleChange)}
              >
                <option value="" disabled>
                  Select Race
                </option>
                {raceList.map((race, index) => {
                  return (
                    <option key={index} value={race.value} id={race.name}>
                      {race.name}
                    </option>
                  );
                })}
              </select>
              <Spacer height={10} />
              {touched.race && errors.race && (
                <div className="text-[red] text-xs">{errors.race}</div>
              )}
            </div>
          </div>
          <div className="mt-4 w-full flex justify-end items-center">
            <Button
              label="Continue"
              onClick={() => handleSubmit()}
              additionalClassname={
                "text-[18px] font-[400] w-fit py-3 px-4 border border-grey focus:outline-none text-s rounded"
              }
              width={108}
              height={60}
              variant="primary"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

const RenderInsuranceDetailsForm = ({
  handleOnNext,
  insuranceData,
  insuranceName,
  setInsuranceName
}: {
  handleOnNext(): void;
  insuranceData: any;
  insuranceName: string;
  setInsuranceName: any;
}) => {
  const [isClicked, setIsClicked] = useState(false);
  return (
    <Formik
      initialValues={{
        insuranceName: `${personalDetailsFields.insuranceName}`,
        insuranceNumber: `${personalDetailsFields.insuranceNumber}`
      }}
      validationSchema={Yup.object().shape({
        insuranceName: Yup.string().required("Insurance is required"),
        insuranceNumber: Yup.string().required("Insurance number is required")
      })}
      onSubmit={handleOnNext}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldError
      }) => (
        <Form className="flex flex-col mx-auto items-center w-full lg:w-[478px] h-auto pb-[50px] no-scrollbar scrollbar-hide">
          <div className="mt-4 w-[100%]">
            <label
              className="text-[#000] font-medium mb-2 text-[16px] block"
              htmlFor={`insuranceName`}
            >
              Insurance
            </label>
            <div>
              <TextField
                name="insuranceName"
                type="text"
                placeholder="Enter the insurance name here"
                value={insuranceName}
                onChange={e => {
                  setInsuranceName(e.target.value);
                  setIsClicked(false);
                  setFieldValue("insuranceName", e.target.value);
                }}
              />
              {!isClicked &&
                insuranceName.length > 2 &&
                insuranceData &&
                insuranceData?.map((c: any, idx: number) => (
                  <div
                    className="flex flex-row items-center cursor-pointer"
                    key={idx}
                  >
                    <p
                      className="mb-0 mt-0"
                      onClick={() => {
                        setInsuranceName(c.payer_name);
                        setIsClicked(true);
                        setFieldValue("insuranceName", c.payer_name);
                        setFieldError("insuranceName", "");
                      }}
                    >
                      {c.payer_name}
                    </p>
                  </div>
                ))}
              {touched.insuranceName && errors.insuranceName && (
                <div className="text-[red] text-xs">{errors.insuranceName}</div>
              )}
            </div>
          </div>
          <div className="mt-4 w-[100%]">
            <TextField
              name="insuranceNumber"
              type="text"
              placeholder="Personal ID"
              value={values.insuranceNumber}
              onChange={e => onChangeHandler(e, handleChange)}
              label="Personal ID"
              error={touched.insuranceNumber ? errors.insuranceNumber : ""}
            />
          </div>
          <div className="mt-4 w-full flex justify-end items-center">
            <Button
              label="Skip"
              onClick={handleOnNext}
              additionalClassname={
                "text-[18px] font-[400] w-fit py-3 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded"
              }
              width={108}
              height={60}
              variant="secondary"
            />
            <Spacer width={10} />
            <Button
              label="Continue"
              type="submit"
              onClick={() => {
                if (
                  insuranceName.length > 0 &&
                  values.insuranceNumber?.length > 0
                ) {
                  handleOnNext();
                }
              }}
              additionalClassname={
                "text-[18px] font-[400] w-fit py-3 px-4 border border-grey focus:outline-none text-s rounded"
              }
              width={108}
              height={60}
              variant="primary"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

interface AllergiesFormInputType {
  allergy: string;
  severity: string;
  reaction: string;
}

const AllergiesFormInputInitialState: AllergiesFormInputType[] = [
  { allergy: "", severity: "", reaction: "" }
];

const RenderHealthRecordForm = ({
  handleSubmitForm
}: {
  handleSubmitForm(values: any): void;
}) => {
  const [allergiesList, setAllergiesList] = useState<AllergiesFormInputType[]>(
    AllergiesFormInputInitialState
  );

  const handleAllergyChange = (e: any, index: number) => {
    const newInputs = [...allergiesList];
    newInputs[index].allergy = e.target.value;
    // If they dont add a new field, we still have to set the array with the current field values
    setAllergiesList(newInputs);
    personalDetailsFields.allergies = allergiesList;
  };

  const handleReactionChange = (e: any, index: number) => {
    const newInputs = [...allergiesList];
    newInputs[index].reaction = e.target.value;
    // If they dont add a new field, we still have to set the array with the current field values
    setAllergiesList(newInputs);
    personalDetailsFields.allergies = allergiesList;
  };

  return (
    <Formik
      initialValues={{
        isTakingMedication: `${personalDetailsFields.isTakingMedication}`,
        medications: `${personalDetailsFields.medications}`,
        hasAllergies: `${personalDetailsFields.hasAllergies}`,
        allergies: personalDetailsFields?.allergies,
        feet: `${personalDetailsFields.feet}`,
        inches: `${personalDetailsFields.inches}`,
        weight: `${personalDetailsFields.weight}`
      }}
      onSubmit={() => handleSubmitForm(personalDetailsFields)}
      validateOnChange={true}
      validateOnBlur={true}
      validationSchema={Yup.object().shape({
        isTakingMedication: Yup.string()
          .oneOf(["yes", "no"])
          .required("Currently taking meds is required"),
        hasAllergies: Yup.string()
          .oneOf(["yes", "no"])
          .required("Has allergies is required"),
        feet: Yup.number().max(10).required("Feet is required").strict(),
        inches: Yup.number().required("Inches is required"),
        weight: Yup.number().required("Weight is required")
      })}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        // handleSubmit,
        isSubmitting
      }) => (
        <Form className="flex flex-col mx-auto items-center w-full lg:w-[478px] h-auto pb-[50px]">
          <div className="border-0 border-b border-solid pb-4 mb-5 w-[100%]">
            <label className="text-[#103C1B]">
              Are you currently taking any medication?
            </label>
            <div className="flex flex-row items-center mt-2">
              <input
                type="radio"
                name="isTakingMedication"
                value="yes"
                onChange={e => onChangeHandler(e, handleChange)}
                checked={personalDetailsFields.isTakingMedication === "yes"}
              />
              <span className="ml-3 text-[#103C1B]">Yes</span>
            </div>
            <div className="flex flex-row items-center mt-2">
              <input
                type="radio"
                name="isTakingMedication"
                value="no"
                onChange={e => onChangeHandler(e, handleChange)}
                checked={personalDetailsFields.isTakingMedication === "no"}
              />
              <span className="ml-3 text-[#103C1B]">No</span>
            </div>
            {values.isTakingMedication === "yes" ? (
              <div className="mt-4 w-[100%]">
                <textarea
                  value={values.medications}
                  name="medications"
                  placeholder="Add medications, separated by commas"
                  className="w-full py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded"
                  onChange={e => onChangeHandler(e, handleChange)}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="border-0 border-b border-solid pb-4 mb-5 w-[100%]">
            <label className="text-[#103C1B]">
              Do you have any allergies or drug sensitivities?
            </label>
            <div className="flex flex-row items-center mt-2">
              <input
                type="radio"
                name="hasAllergies"
                value="yes"
                onChange={e => onChangeHandler(e, handleChange)}
                checked={personalDetailsFields.hasAllergies === "yes"}
              />
              <span className="ml-3 text-[#103C1B]">Yes</span>
            </div>
            <div className="flex flex-row items-center mt-2">
              <input
                type="radio"
                name="hasAllergies"
                value="no"
                onChange={e => onChangeHandler(e, handleChange)}
                checked={personalDetailsFields.hasAllergies === "no"}
              />
              <span className="ml-3 text-[#103C1B]">No</span>
            </div>
            {personalDetailsFields.hasAllergies === "yes" ? (
              <div className="mt-4 w-[100%]">
                <div className="w-full">
                  {allergiesList &&
                    allergiesList?.map((item, index) => (
                      <div
                        className="w-full p-2 flex flex-wrap lg:flex-nowrap justify-start items-center mb-4"
                        key={index}
                      >
                        <div className="mr-2">
                          <TextField
                            name="allergy"
                            type="text"
                            placeholder="Peanuts"
                            value={item?.allergy}
                            onChange={e => handleAllergyChange(e, index)}
                          />
                        </div>

                        <div className="lg:w-[240px] mr-2">
                          <select
                            className="w-full py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded h-[47px]"
                            name="severity"
                            value={item?.severity}
                            onChange={e => {
                              const newInputs = [...allergiesList];
                              newInputs[index].severity = e.target.value;
                              // If they dont add a new field, we still have to set the array with the current field values
                              setAllergiesList(newInputs);
                              personalDetailsFields.allergies = allergiesList;
                            }}
                          >
                            <option value="" disabled>
                              {item?.severity || "Severe"}
                            </option>
                            {["Severe", "Mild", "Moderate"].map(value => (
                              <option value={value.toLowerCase()} key={value}>
                                {value}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="">
                          <TextField
                            name="reaction"
                            type="text"
                            placeholder="Swollen tongue"
                            value={item?.reaction}
                            onChange={e => handleReactionChange(e, index)}
                          />
                        </div>

                        <button
                          className="ml-2"
                          onClick={() => {
                            const newFields = [...allergiesList];
                            newFields.splice(index, 1);
                            setAllergiesList(newFields);
                          }}
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                    ))}
                  <div
                    className="cursor-pointer flex flex-row"
                    onClick={() => {
                      setAllergiesList([
                        ...allergiesList,
                        {
                          allergy: "",
                          severity: "",
                          reaction: ""
                        }
                      ]);
                    }}
                  >
                    <PlusIcon />
                    <span className="text-[#1A9D39] text-[16px] ml-3">
                      Add another allergy
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="pb-4 mb-5">
            <label className="text-[#103C1B]">
              Enter your body measurement
            </label>
            <div className="flex flex-row items-center mt-2">
              <span className="text-[#103C1B]">Height:</span>
              <div className="ml-3 flex flex-row items-center">
                <input
                  placeholder="Feet"
                  type="number"
                  name="feet"
                  maxLength={2}
                  max={10}
                  value={Number(personalDetailsFields.feet)}
                  className="p-2 w-2/4 border border-solid rounded outline-none mr-2"
                  onChange={v => {
                    if (Number(v.target.value) > 10) return;
                    onChangeHandler(v, handleChange);
                  }}
                />
                <input
                  placeholder="Inch"
                  type="number"
                  name="inches"
                  maxLength={2}
                  max={12}
                  value={Number(values.inches)}
                  className="p-2 w-2/4 border border-solid rounded outline-none mr-2"
                  onChange={v => {
                    if (Number(v.target.value) > 12) return;
                    onChangeHandler(v, handleChange);
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row items-center mt-2">
              <span className="text-[#103C1B]">Weight:</span>
              <div className="ml-3 flex flex-row items-center">
                <input
                  placeholder="Lbs"
                  type="number"
                  name="weight"
                  max={600}
                  value={Number(values.weight)}
                  className="p-2 w-2/4 border border-solid rounded outline-none mr-2"
                  onChange={v => {
                    if (Number(v.target.value) > 600) return;
                    onChangeHandler(v, handleChange);
                  }}
                />
              </div>
            </div>
          </div>
          <Spacer height={50} />
          <div className="flex flex-row mx-auto items-center justify-end w-4/5">
            <Spacer width={15} />
            <Button
              label="Submit"
              variant="primary"
              width={100}
              onClick={() => handleSubmitForm(personalDetailsFields)}
              loading={isSubmitting}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

const PersonalRegistration = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showTooltip, setShowToolTip] = useState(false);
  const [showWhyTooltip, setShowWhyToolTip] = useState(false);
  const [govtUrlKey, setGovtUrlKeyKey] = useState<string>("");
  const [modalData, setModal] = useState({
    isOpen: false,
    title: ""
  });
  const searchParams = useSearchParams()[0];
  const referrer = searchParams.get("referrer");
  const [governmentId, setGovernmentId] = useState({} as GovtIdDoc);
  const insuranceCompaniesFromLocalStorage = getInsurancePayers()
  const [insuranceName, setInsuranceName] = useState("");
  const [insuranceData, setInsuranceData] = useState<Array<any>>([]);

  const handleSearchInsuranceName = useCallback((val: string) => {
    const filteredData = insuranceCompaniesFromLocalStorage?.filter(
      (e: any, i: number) =>
        e.payer_name.toLowerCase().includes(val.toLowerCase())
    );
    setInsuranceData(filteredData);
  }, [insuranceCompaniesFromLocalStorage])


  useEffect(() => {
    if (insuranceName.length > 2) {
      handleSearchInsuranceName(insuranceName);
    }
  }, [insuranceName, handleSearchInsuranceName]);

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      toast.info("No file selected");
      return;
    }

    const maxAllowedSize = 2 * 1024 * 1024;
    if (file.size > maxAllowedSize) {
      toast.error("File size should be less than 2MB");
      return;
    } else {
      setGovernmentId({ file });
      const key = generateUUID() + file.name.slice(file.name.lastIndexOf("."));
      (async () => {
        const response = await getS3SignedUrl({
          operation: "putObject",
          key,
          object: "account"
        });
        setGovtUrlKeyKey(`${response.signedUrlParams.host}/${key}`);
      })();
    }
  };

  const handleDeleteEmanipationDoc = () => {
    setGovernmentId({});
    setGovtUrlKeyKey("");
  };

  const handleNextStep = () => {
    if (zipcodes.lookup(personalDetailsFields.zipCode) === undefined) {
      toast.error("Invalid zipcode, enter a valid USA or Canadian zip code");
    } else if (!isValidUSPhoneNumber(personalDetailsFields.phoneNumber)) {
      toast.error("Invalid phone number, enter a valid USA phone number");
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleBack = () => {
    setCurrentPage(currentPage - 1);
  };

  const getPageHeaderDetails = () => {
    switch (currentPage) {
      case 0:
        return {
          header: "Personal Details",
          subHeader: "Tell us more about yourself"
        };
      case 1:
        return {
          header: "Insurance Details",
          subHeader: "Add your insurance details for appointment payments."
        };
      default:
        return {
          header: "Health Record",
          subHeader:
            "Tell us more about your health record so we can serve you better."
        };
    }
  };

  const handleSubmitForm = async (values: any) => {
    values.governmentIdUrl = govtUrlKey;
    values.insuranceName = insuranceName;

    try {
      setIsSubmitting(true);
      const medRecords = {
        isTakingMedication: values.isTakingMedication === "yes" ? true : false,
        hasAllergies: values.hasAllergies === "yes" ? true : false,
        medications: values.medications ? values.medications.split(",") : [],
        height: `${Math.ceil(
          (Number(values.feet * 12) + Number(values.inches)) * 2.54
        )}`,
        weight: `${values.weight}`
      };
      localStorage.setItem("medRecords", JSON.stringify(medRecords));
      const requestData = {
        ...values,
        ...medRecords,
        pronoun: values.pronoun ? values.pronoun?.toLowerCase() : ""
      };
      delete requestData.feet;
      delete requestData.inches;
      if (values.hasAllergies === "no") {
        delete requestData.allergies;
      } else {
        requestData.allergies = values.allergies;
      }
      if (values.isTakingMedication === "no") {
        delete requestData.medications;
      } else {
        requestData.medications = values.medications
          ? values.medications.split(",")
          : [];
      }
      [
        "pronoun",
        "gender",
        "insuranceName",
        "insuranceNumber",
        "ssn",
        "governmentIdUrl"
      ].forEach(field => {
        if (!values[field]) {
          delete requestData[field];
        }
      });
      const response = await patientUpdateDetails(requestData);
      if (referrer === "appointment") {
        const tempAppointment = await getTempAppointmentDetails();
        localStorage.setItem(
          "bookingDetails",
          JSON.stringify(tempAppointment.appointmentDetails)
        );
      }
      setIsSubmitting(false);
      if (response.status === 200) {
        localStorage.setItem("temple_user_token", response.data.token);
        setModal({
          isOpen: true,
          title: "Data saved sucessfully"
        });
      } else {
        toast.error(response.error ?? "An error occurred");
      }
    } catch (err) {
      setIsSubmitting(false);
      toast.error("An error occurred");
    }
  };

  return (
    <div className="w-full lg:w-[614px] no-scrollbar scrollbar-hide">
      {isSubmitting && <div>Wait...We're submitting your form!</div>}
      {currentPage === 3 ? (
        <div className="w-full h-full flex flex-col items-center justify-center">
          <div className="w-full flex flex-row items-center justify-center mt-4">
            <CheckFill width={90} height={90} />
          </div>
          <Spacer height={30} />
          <p className="text-[34px] font-[800] text-[#103C1B]">
            Welcome to TempleHS
          </p>
          <Spacer height={10} />
          <p className="font-[400] text-[20px] text-[#103C1B]">
            Your account has been created successfully.
          </p>
          <Spacer height={10} />
          <div className="w-full flex flex-row items-center justify-center mt-4">
            <Button
              label="Sign in now"
              variant="primary"
              width={100}
              onClick={() => navigate("/dashboard")}
            />
          </div>
        </div>
      ) : (
        <div className="w-full lg:w-[614px] bg-white">
          <div className="text-center w-full flex flex-col items-center justify-center">
            <Spacer height={30} />
            <p className="text-[34px] font-[800] text-[#103C1B]">
              {getPageHeaderDetails().header}
            </p>
            <Spacer height={10} />
            <p className="font-[400] text-[20px] text-[#103C1B] w-full lg:w-[500px]">
              {getPageHeaderDetails().subHeader}
            </p>
            {currentPage >= 1 ? (
              <div className="flex flex-col  w-full lg:w-[478px] items-start justify-start">
                <Spacer height={20} />
                <div className="w-full text-left">
                  <Button
                    label="Go back"
                    onClick={handleBack}
                    additionalClassname={
                      "text-[16px] font-[400] w-[100px] py-3 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded"
                    }
                    size="medium"
                    variant="secondary"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <Spacer height={25} />
          <section className="w-[100%]">
            {currentPage === 0 && (
              <RenderPersonalDetailsForm
                handleFileUpload={handleFileUpload}
                handleDeleteEmanipationDoc={handleDeleteEmanipationDoc}
                governmentId={governmentId}
                handleOnNext={handleNextStep}
                showTooltip={showTooltip}
                showWhyTooltip={showWhyTooltip}
                setShowToolTip={() => setShowToolTip(!showTooltip)}
                setWhyShowToolTip={() => setShowWhyToolTip(!showWhyTooltip)}
              />
            )}
            {currentPage === 1 && (
              <RenderInsuranceDetailsForm
                handleOnNext={handleNextStep}
                setInsuranceName={setInsuranceName}
                insuranceData={insuranceData}
                insuranceName={insuranceName}
              />
            )}
            {currentPage === 2 && (
              <RenderHealthRecordForm handleSubmitForm={handleSubmitForm} />
            )}
          </section>
          <MainModal
            open={modalData.isOpen}
            title={modalData.title}
            onClose={() => setModal({ isOpen: false, title: "" })}
            closeAfterTransition={false}
            onOk={() =>
              navigate(
                `/dashboard${referrer === "appointment"
                  ? "/dependent/visit/schedule/landingpage?referrer=appointment&signupRef=personalDetails"
                  : ""
                }`
              )
            }
          />
        </div>
      )}
    </div>
  );
};

const GovtIdUploadContainer = styled.div`
  marginTop: ".9rem",
  "& .invisible_file_upload": {
    opacity: 0,
    position: "absolute",
    left: "-100%"
  },
  "& .file_upload_cont": {
    border: " 1px dashed #34623F",
    borderColor: "#103C1B",
    cursor: "pointer",
    background: "rgba(52, 98, 63, 0.07)",
    borderRadius: "4px",
    padding: "2rem",
    maxWidth: "300px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    marginTop: ".4rem",

    "& .trigger-text-cont": {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center"
    }
  },
  "& .id-name-cont": {
    marginTop: ".4rem",
    fontSize: ".76rem",
    position: "relative",
    width: "70%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",

    "& .remove-edoc": {
      cursor: "pointer",
      color: "#F83535"
    }
  }
`;

export default PersonalRegistration;
