import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import billerHttp from "../../../utils/billerHttp";
import { UpdatedSheet } from "../../types";

type Payload = {
  procedureCodes: string | string[]
  diagnosisCodes: string | string[]
  patientName: string,
  patientDateOfBirth: string,
  patientGender: "Ambiguous" | "Female" | "Male" | "Not applicable" | "Other" | "Unknown" | undefined,
  patientPrimaryInsurance: string
  providerName: string,
  dateOfAppointment: string,
  locationOfAppointment: string,
  modeOfAppointment: string
  chargeSheetId: string
}

export const useEditBillerChargeSheet = () => {
  const queryClient = useQueryClient();
  const { mutate, data, ...rest } = useMutation({
    mutationKey: ['edit biller charge-sheet'],
    mutationFn: async ({ ...data }: Payload) => {
      const response = await billerHttp.patch(`billers/charge-sheet/${data.chargeSheetId}`, {
        procedureCodes: data.procedureCodes,
        diagnosisCodes: data.diagnosisCodes,
        patientName: data.patientName,
        patientDateOfBirth: data.patientDateOfBirth,
        patientGender: data.patientGender,
        patientPrimaryInsurance: data.patientPrimaryInsurance,
        providerName: data.providerName,
        dateOfAppointment: data.dateOfAppointment,
        locationOfAppointment: data.locationOfAppointment,
        modeOfAppointment: data.modeOfAppointment
      })
      return response.data?.updatedSheet;
    },

    onSuccess: () => {
      toast.success("Charge-sheet updated successfully");
      queryClient.invalidateQueries({ queryKey: ['get all biller charge-sheet'] });
    },

    onError: (error) => {
      if (axios.isAxiosError(error)) {
        toast.error(
          error?.response?.data?.error || error.response?.statusText,
        );
      } else {
        toast.error(
          error?.message
          || "Error updating charge sheet",
        );
      }
    }
  });

  return {
    editBillerChargeSheet: mutate,
    editedChargeSheetData: data as UpdatedSheet || {},
    ...rest,
  };
}
