import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import Button from "../components/shared/Button";

export type MainModalProps = {
  open: boolean;
  onClose: () => void;
  closeAfterTransition: boolean;
  title: string;
  onOk: () => void;
  onCancel?: () => void;
};

const MainModal = (props: MainModalProps) => {
  return (
    <Dialog
      open={props.open}
      closeAfterTransition={props.closeAfterTransition}
      onClose={props.onClose}
    >
      <DialogContent className="flex flex-col items-center justify-center gap-5">
        <DialogTitle>{props.title}</DialogTitle>
        <section className="flex gap-5">
          <DialogActions>
            <Button
              variant="secondary"
              label="OK"
              size="small"
              onClick={props.onOk}
            />
          </DialogActions>
          {props.onCancel && (
            <DialogActions>
              <Button
                variant="secondary"
                label="Cancel"
                size="small"
                onClick={props.onCancel}
              />
            </DialogActions>
          )}
        </section>
      </DialogContent>
    </Dialog>
  );
};

export { MainModal };
